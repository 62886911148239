<section>
  <div class="container main-content">
    <div [ngClass]="{ 'tile': largeView }">
      <div [ngClass]="{ 'tile': largeView, 'is-vertical': true, 'is-parent': true, 'is-12': true }">
        <article [ngClass]="{ 'tile': largeView, 'is-child': true, 'notification': largeView, 'box': largeView, 'main': true }">
          <div class="table-wrapper">
              <div class="table-heading">
                <h1>API Explorer</h1>
              </div>
          </div>

          <div class="content-site">
            <form (ngSubmit)="onSubmit()" #cfgForm="ngForm">
              <div [ngClass]="{ 'tile': largeView, 'is-vertical': true,'is-child': true, 'box': largeView, 'is-12': true }">

                <!-- START: Max Refresh Hits to ESB -->
                <div class="form-group form-content columns">
                  <div class="column is-one-quarter">
                    <label class="form-label" for="placementEventId">Placement Event Id</label>
                  </div>
                  <div class="column is-half box">
                    <input type="text" class="form-control ml" id="placementEventId"
                          required pattern="[0-9]*" 
                          [(ngModel)]="placementEventId" name="placementEventId"
                          #placementEventIdControl="ngModel">
                    <div class="help-tip">
                      <p>
                        Enter a placement event id and press submit to show the data that would be returned to Carer Connect when it asks TSS for possible welcome pack placement information.
                      </p>
                    </div>
                  </div>

                </div>

                <div class="space-above">
                  <button type="submit" class="button" [disabled]="!cfgForm.form.valid">Submit</button>
                  <a *ngIf="dataLoading" class="button is-loading">Loading</a>
                </div>

                <pre [innerHTML]="dataToShow" class="content-shaded">
                </pre>
              </div>
            </form>
          </div>
        </article>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
</section>
