import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {
  @Input() fullName: string;
  //fullName: string = 'Guest';
  @Input() isAuthenticated: Boolean;

  constructor() { }

  ngOnInit() {
  }
/*
  public onSubMenuItemFocus(evt: FocusEvent) {
    evt.srcElement.parentElement.classList.add('submenu-item-hover');

    let ulParent = this.closestUlParent(evt.srcElement);
    if (ulParent) {
      ulParent.classList.add('hover-root-menu');
    }
  }

  public onSubMenuItemBlur(evt: FocusEvent) {
    evt.srcElement.parentElement.classList.remove('submenu-item-hover');

    let ulParent = this.closestUlParent(evt.srcElement);
    if (ulParent) {
      ulParent.classList.remove('hover-root-menu');
    }
  }

  public onSubMenuItemClick(evt: MouseEvent) {
    if (evt instanceof MouseEvent) {
      evt.srcElement.parentElement.classList.remove('submenu-item-hover');

      let ulParent = this.closestUlParent(evt.srcElement);
      if (ulParent) {
        ulParent.classList.remove('hover-root-menu');
      }
    }
  }

  private closestUlParent(element: Element) {
    let p = element.parentElement;

    while (p && p.tagName && p.tagName.toLocaleUpperCase() !== 'UL') {
      p = p.parentElement;
    }

    return p;
  }*/
}
