export enum CaseAllocation {
	Investigationandassessment_1 = "1",
	NotSpecified_0 = "0",
	Ongoingintervention_2 = "2",
	Specifictasks_3 = "3"
}

export interface ICaseAllocationGroupDescriptor { code: CaseAllocation; description: string; shortDescription: string; }
export var CaseAllocationGroup: ICaseAllocationGroupDescriptor[] = [
	{ code: CaseAllocation.Investigationandassessment_1, description: `Investigation and assessment`, shortDescription: `Investigation and assessment` },
	{ code: CaseAllocation.NotSpecified_0, description: `Not Specified`, shortDescription: `Not Specified` },
	{ code: CaseAllocation.Ongoingintervention_2, description: `Ongoing intervention`, shortDescription: `Ongoing intervention` },
	{ code: CaseAllocation.Specifictasks_3, description: `Specific tasks`, shortDescription: `Specific tasks` }
];

export function getCaseAllocationGroupDescriptor(CaseAllocationCode: string) : ICaseAllocationGroupDescriptor {
	for(var i = 0; i < CaseAllocationGroup.length; i++)
	{
		if(CaseAllocationGroup[i].code == CaseAllocationCode)
		{
			return CaseAllocationGroup[i];
		}
	}
	return null;
}


export enum CaseManagement {
	Adoption_11 = "11",
	ChildProtectionfollowup_12 = "12",
	CPO_8 = "8",
	Interstateorder_10 = "10",
	Interstateservice_13 = "13",
	IPA_7 = "7",
	IPAdirectiveCPO_9 = "9",
	IPAdirectiveCPO_20 = "20",
	Nonstatutory_14 = "14",
	NotSpecified_0 = "0",
	OrderRemand_15 = "15",
	Orders_16 = "16",
	Remandedinothercustody_17 = "17",
	RemandedintempcustodyofDG_18 = "18",
	Supportservice_6 = "6",
	UnsupervisedUnprovenCP_19 = "19"
}

export interface ICaseManagementGroupDescriptor { code: CaseManagement; description: string; shortDescription: string; }
export var CaseManagementGroup: ICaseManagementGroupDescriptor[] = [
	{ code: CaseManagement.Adoption_11, description: `Adoption`, shortDescription: `Adoption` },
	{ code: CaseManagement.ChildProtectionfollowup_12, description: `Child Protection followup`, shortDescription: `Child Protection followup` },
	{ code: CaseManagement.CPO_8, description: `CPO`, shortDescription: `CPO` },
	{ code: CaseManagement.Interstateorder_10, description: `Interstate order`, shortDescription: `Interstate order` },
	{ code: CaseManagement.Interstateservice_13, description: `Interstate service`, shortDescription: `Interstate service` },
	{ code: CaseManagement.IPA_7, description: `IPA`, shortDescription: `IPA` },
	{ code: CaseManagement.IPAdirectiveCPO_9, description: `IPA & directive CPO`, shortDescription: `IPA & directive CPO` },
	{ code: CaseManagement.IPAdirectiveCPO_20, description: `IPA & directive CPO`, shortDescription: `IPA & directive CPO` },
	{ code: CaseManagement.Nonstatutory_14, description: `Non-statutory`, shortDescription: `Non-statutory` },
	{ code: CaseManagement.NotSpecified_0, description: `Not Specified`, shortDescription: `Not Specified` },
	{ code: CaseManagement.OrderRemand_15, description: `Order/Remand`, shortDescription: `Order/Remand` },
	{ code: CaseManagement.Orders_16, description: `Orders`, shortDescription: `Orders` },
	{ code: CaseManagement.Remandedinothercustody_17, description: `Remanded in other custody`, shortDescription: `Remanded in other custody` },
	{ code: CaseManagement.RemandedintempcustodyofDG_18, description: `Remanded in temp custody of DG`, shortDescription: `Remanded in temp custody of DG` },
	{ code: CaseManagement.Supportservice_6, description: `Support service`, shortDescription: `Support service` },
	{ code: CaseManagement.UnsupervisedUnprovenCP_19, description: `Unsupervised/Unproven - CP`, shortDescription: `Unsupervised/Unproven - CP` }
];

export function getCaseManagementGroupDescriptor(CaseManagementCode: string) : ICaseManagementGroupDescriptor {
	for(var i = 0; i < CaseManagementGroup.length; i++)
	{
		if(CaseManagementGroup[i].code == CaseManagementCode)
		{
			return CaseManagementGroup[i];
		}
	}
	return null;
}


export enum CaseNoteDisplay {
	Agencyconsultation_40 = "40",
	Amendment_32 = "32",
	Carerachievements_44 = "44",
	Carerseekinginformationassistance_43 = "43",
	Casediscussion_29 = "29",
	Casediscussiondecision_5 = "5",
	Caseplanningimplementation_6 = "6",
	Changeincarercircumstances_39 = "39",
	Childhealthpassport_37 = "37",
	ChildSafetyService_55 = "55",
	ChildrenServicesTribunal_7 = "7",
	Communicationplanstrategiesinplace_78 = "78",
	Complaint_62 = "62",
	Complaints_52 = "52",
	ConfidentialLegalAdvicePrivilegedmustnotbedisclosed_73 = "73",
	Correspondence_28 = "28",
	Court_8 = "8",
	Courtrelated_27 = "27",
	Criticalincident_51 = "51",
	Educationsupportplan_36 = "36",
	Familycontact_9 = "9",
	Familycommunitycontact_26 = "26",
	Financial_10 = "10",
	Financialsupportnotrelatedtoachild_42 = "42",
	FOIamendment_34 = "34",
	FOIrequest_33 = "33",
	Generic_11 = "11",
	Harmreportactionplanreview_58 = "58",
	Independententity_80 = "80",
	InforeceivedfromQPS_74 = "74",
	InformationrationalefordecisionnottorecordMOC_45 = "45",
	Ministerialcorrespondence_41 = "41",
	MOCActionplanfollowup_23 = "23",
	MOCHistoricalconcerns_22 = "22",
	MOCHistoricalconcerns_47 = "47",
	MOCActionplanreview_46 = "46",
	MOCFollowup_50 = "50",
	NationalDisabilityInsuranceScheme_75 = "75",
	Notspecified_0 = "0",
	Notspecified_35 = "35",
	NotSpecified_54 = "54",
	OIReceivedConcerns_63 = "63",
	Other_53 = "53",
	Otheragencyvisit_49 = "49",
	OtherclientcontactYDC_31 = "31",
	OtherclientcontactYJS_30 = "30",
	PCOChildcontact_81 = "81",
	PCOGuardiancontact_82 = "82",
	Placement_12 = "12",
	Practicepanel_83 = "83",
	Prescribedhomevisit_13 = "13",
	Programattendance_25 = "25",
	QLDCivilandAdministrativeTribunal_76 = "76",
	QueenslandHealthother_57 = "57",
	Recognisedentityconsultation_14 = "14",
	Referral_15 = "15",
	Referralforactiveintervention_16 = "16",
	Reporting_24 = "24",
	Serviceagreementlicensing_48 = "48",
	SOCfollowup_60 = "60",
	SOChistoricalconcerns_59 = "59",
	Standardsofcare_61 = "61",
	Supportserviceplan_17 = "17",
	ToolCirclesofSafetyandSupport_64 = "64",
	ToolCollaborativeActionPlanning_65 = "65",
	ToolCollaborativeAssessmentandPlanningFramework_66 = "66",
	ToolSafetyandSupportPlanning_77 = "77",
	ToolTheFamilyRoadmap_67 = "67",
	ToolTheFutureHouse_68 = "68",
	ToolTheImmediateStory_69 = "69",
	ToolTheSafeContact_70 = "70",
	ToolTheSafetyHouse_71 = "71",
	ToolTheThreeHouses_72 = "72",
	Transfers_18 = "18",
	Victimofcrime_79 = "79",
	Visittocarer_21 = "21",
	Visittochild_19 = "19",
	Visittoparent_20 = "20",
	YouthJusticeServices_56 = "56"
}

export interface ICaseNoteDisplayGroupDescriptor { code: CaseNoteDisplay; description: string; shortDescription: string; }
export var CaseNoteDisplayGroup: ICaseNoteDisplayGroupDescriptor[] = [
	{ code: CaseNoteDisplay.Agencyconsultation_40, description: `Agency consultation`, shortDescription: `Agency consultation` },
	{ code: CaseNoteDisplay.Amendment_32, description: `Amendment`, shortDescription: `Amendment` },
	{ code: CaseNoteDisplay.Carerachievements_44, description: `Carer achievements`, shortDescription: `Carer achievements` },
	{ code: CaseNoteDisplay.Carerseekinginformationassistance_43, description: `Carer seeking information / assistance`, shortDescription: `Carer seeking information / assistance` },
	{ code: CaseNoteDisplay.Casediscussion_29, description: `Case discussion`, shortDescription: `Case discussion` },
	{ code: CaseNoteDisplay.Casediscussiondecision_5, description: `Case discussion/decision`, shortDescription: `Case discussion/decision` },
	{ code: CaseNoteDisplay.Caseplanningimplementation_6, description: `Case planning/implementation`, shortDescription: `Case planning/implementation` },
	{ code: CaseNoteDisplay.Changeincarercircumstances_39, description: `Change in carer circumstances`, shortDescription: `Change in carer circumstances` },
	{ code: CaseNoteDisplay.Childhealthpassport_37, description: `Child health passport`, shortDescription: `Child health passport` },
	{ code: CaseNoteDisplay.ChildSafetyService_55, description: `Child Safety Service`, shortDescription: `Child Safety Service` },
	{ code: CaseNoteDisplay.ChildrenServicesTribunal_7, description: `Children Services Tribunal`, shortDescription: `Children Services Tribunal` },
	{ code: CaseNoteDisplay.Communicationplanstrategiesinplace_78, description: `Communication plan/strategies in place`, shortDescription: `Communication plan/strategies in place` },
	{ code: CaseNoteDisplay.Complaint_62, description: `Complaint`, shortDescription: `Complaint` },
	{ code: CaseNoteDisplay.Complaints_52, description: `Complaints`, shortDescription: `Complaints` },
	{ code: CaseNoteDisplay.ConfidentialLegalAdvicePrivilegedmustnotbedisclosed_73, description: `Confidential Legal Advice - Privileged (must not be disclosed)`, shortDescription: `Confidential Legal Advice - Privileged (must not be disclosed)` },
	{ code: CaseNoteDisplay.Correspondence_28, description: `Correspondence`, shortDescription: `Correspondence` },
	{ code: CaseNoteDisplay.Court_8, description: `Court`, shortDescription: `Court` },
	{ code: CaseNoteDisplay.Courtrelated_27, description: `Court-related`, shortDescription: `Court-related` },
	{ code: CaseNoteDisplay.Criticalincident_51, description: `Critical incident`, shortDescription: `Critical incident` },
	{ code: CaseNoteDisplay.Educationsupportplan_36, description: `Education support plan`, shortDescription: `Education support plan` },
	{ code: CaseNoteDisplay.Familycontact_9, description: `Family contact`, shortDescription: `Family contact` },
	{ code: CaseNoteDisplay.Familycommunitycontact_26, description: `Family/community contact`, shortDescription: `Family/community contact` },
	{ code: CaseNoteDisplay.Financial_10, description: `Financial`, shortDescription: `Financial` },
	{ code: CaseNoteDisplay.Financialsupportnotrelatedtoachild_42, description: `Financial support not related to a child`, shortDescription: `Financial support not related to a child` },
	{ code: CaseNoteDisplay.FOIamendment_34, description: `FOI amendment`, shortDescription: `FOI amendment` },
	{ code: CaseNoteDisplay.FOIrequest_33, description: `FOI request`, shortDescription: `FOI request` },
	{ code: CaseNoteDisplay.Generic_11, description: `Generic`, shortDescription: `Generic` },
	{ code: CaseNoteDisplay.Harmreportactionplanreview_58, description: `Harm report action plan review`, shortDescription: `Harm report action plan review` },
	{ code: CaseNoteDisplay.Independententity_80, description: `Independent entity`, shortDescription: `Independent entity` },
	{ code: CaseNoteDisplay.InforeceivedfromQPS_74, description: `Info received from QPS`, shortDescription: `Info received from QPS` },
	{ code: CaseNoteDisplay.InformationrationalefordecisionnottorecordMOC_45, description: `Information / rationale for decision not to record MOC`, shortDescription: `Information / rationale for decision not to record MOC` },
	{ code: CaseNoteDisplay.Ministerialcorrespondence_41, description: `Ministerial correspondence`, shortDescription: `Ministerial correspondence` },
	{ code: CaseNoteDisplay.MOCActionplanfollowup_23, description: `MOC - Action plan follow up`, shortDescription: `MOC - Action plan follow up` },
	{ code: CaseNoteDisplay.MOCHistoricalconcerns_22, description: `MOC - Historical concerns`, shortDescription: `MOC - Historical concerns` },
	{ code: CaseNoteDisplay.MOCHistoricalconcerns_47, description: `MOC - Historical concerns`, shortDescription: `MOC - Historical concerns` },
	{ code: CaseNoteDisplay.MOCActionplanreview_46, description: `MOC Action plan review`, shortDescription: `MOC Action plan review` },
	{ code: CaseNoteDisplay.MOCFollowup_50, description: `MOC Follow up`, shortDescription: `MOC Follow up` },
	{ code: CaseNoteDisplay.NationalDisabilityInsuranceScheme_75, description: `National Disability Insurance Scheme`, shortDescription: `NDIS` },
	{ code: CaseNoteDisplay.Notspecified_0, description: `Not specified`, shortDescription: `Not specified` },
	{ code: CaseNoteDisplay.Notspecified_35, description: `Not specified`, shortDescription: `Not specified` },
	{ code: CaseNoteDisplay.NotSpecified_54, description: `Not Specified`, shortDescription: `Not Specified` },
	{ code: CaseNoteDisplay.OIReceivedConcerns_63, description: `OI - Received Concerns`, shortDescription: `OI - Received Concerns` },
	{ code: CaseNoteDisplay.Other_53, description: `Other`, shortDescription: `Other` },
	{ code: CaseNoteDisplay.Otheragencyvisit_49, description: `Other agency visit`, shortDescription: `Other agency visit` },
	{ code: CaseNoteDisplay.OtherclientcontactYDC_31, description: `Other client contact - YDC`, shortDescription: `Other client contact - YDC` },
	{ code: CaseNoteDisplay.OtherclientcontactYJS_30, description: `Other client contact - YJS`, shortDescription: `Other client contact - YJS` },
	{ code: CaseNoteDisplay.PCOChildcontact_81, description: `PCO - Child contact`, shortDescription: `PCO - Child contact` },
	{ code: CaseNoteDisplay.PCOGuardiancontact_82, description: `PCO - Guardian contact`, shortDescription: `PCO - Guardian contact` },
	{ code: CaseNoteDisplay.Placement_12, description: `Placement`, shortDescription: `Placement` },
	{ code: CaseNoteDisplay.Practicepanel_83, description: `Practice panel`, shortDescription: `Practice panel` },
	{ code: CaseNoteDisplay.Prescribedhomevisit_13, description: `Prescribed home visit`, shortDescription: `Prescribed home visit` },
	{ code: CaseNoteDisplay.Programattendance_25, description: `Program attendance`, shortDescription: `Program attendance` },
	{ code: CaseNoteDisplay.QLDCivilandAdministrativeTribunal_76, description: `QLD Civil and Administrative Tribunal`, shortDescription: `QCAT` },
	{ code: CaseNoteDisplay.QueenslandHealthother_57, description: `QueenslandHealth/ other`, shortDescription: `Queensland Health/ other` },
	{ code: CaseNoteDisplay.Recognisedentityconsultation_14, description: `Recognised entity consultation`, shortDescription: `Recognised entity consultation` },
	{ code: CaseNoteDisplay.Referral_15, description: `Referral`, shortDescription: `Referral` },
	{ code: CaseNoteDisplay.Referralforactiveintervention_16, description: `Referral for active intervention`, shortDescription: `Referral for active intervention` },
	{ code: CaseNoteDisplay.Reporting_24, description: `Reporting`, shortDescription: `Reporting` },
	{ code: CaseNoteDisplay.Serviceagreementlicensing_48, description: `Service agreement / licensing`, shortDescription: `Service agreement / licensing` },
	{ code: CaseNoteDisplay.SOCfollowup_60, description: `SOC follow up`, shortDescription: `SOC follow up` },
	{ code: CaseNoteDisplay.SOChistoricalconcerns_59, description: `SOC historical concerns`, shortDescription: `SOC historical concerns` },
	{ code: CaseNoteDisplay.Standardsofcare_61, description: `Standards of care`, shortDescription: `Standards of care` },
	{ code: CaseNoteDisplay.Supportserviceplan_17, description: `Support service plan`, shortDescription: `Support service plan` },
	{ code: CaseNoteDisplay.ToolCirclesofSafetyandSupport_64, description: `Tool - Circles of Safety and Support`, shortDescription: `Tool - Circles of Safety and Support` },
	{ code: CaseNoteDisplay.ToolCollaborativeActionPlanning_65, description: `Tool - Collaborative Action Planning`, shortDescription: `Tool - Collaborative Action Planning` },
	{ code: CaseNoteDisplay.ToolCollaborativeAssessmentandPlanningFramework_66, description: `Tool - Collaborative Assessment and Planning Framework`, shortDescription: `Tool - Collaborative Assessment and Planning Framework` },
	{ code: CaseNoteDisplay.ToolSafetyandSupportPlanning_77, description: `Tool - Safety and Support Planning`, shortDescription: `Tool - Safety and Support Planning` },
	{ code: CaseNoteDisplay.ToolTheFamilyRoadmap_67, description: `Tool - The Family Roadmap`, shortDescription: `Tool - The Family Roadmap` },
	{ code: CaseNoteDisplay.ToolTheFutureHouse_68, description: `Tool - The Future House`, shortDescription: `Tool - The Future House` },
	{ code: CaseNoteDisplay.ToolTheImmediateStory_69, description: `Tool - The Immediate Story`, shortDescription: `Tool - The Immediate Story` },
	{ code: CaseNoteDisplay.ToolTheSafeContact_70, description: `Tool - The Safe Contact`, shortDescription: `Tool - The Safe Contact` },
	{ code: CaseNoteDisplay.ToolTheSafetyHouse_71, description: `Tool - The Safety House`, shortDescription: `Tool - The Safety House` },
	{ code: CaseNoteDisplay.ToolTheThreeHouses_72, description: `Tool - The Three Houses`, shortDescription: `Tool - The Three Houses` },
	{ code: CaseNoteDisplay.Transfers_18, description: `Transfers`, shortDescription: `Transfers` },
	{ code: CaseNoteDisplay.Victimofcrime_79, description: `Victim of crime`, shortDescription: `Victim of crime` },
	{ code: CaseNoteDisplay.Visittocarer_21, description: `Visit to carer`, shortDescription: `Visit to carer` },
	{ code: CaseNoteDisplay.Visittochild_19, description: `Visit to child`, shortDescription: `Visit to child` },
	{ code: CaseNoteDisplay.Visittoparent_20, description: `Visit to parent`, shortDescription: `Visit to parent` },
	{ code: CaseNoteDisplay.YouthJusticeServices_56, description: `Youth Justice Services`, shortDescription: `Youth Justice Services` }
];

export function getCaseNoteDisplayGroupDescriptor(CaseNoteDisplayCode: string) : ICaseNoteDisplayGroupDescriptor {
	for(var i = 0; i < CaseNoteDisplayGroup.length; i++)
	{
		if(CaseNoteDisplayGroup[i].code == CaseNoteDisplayCode)
		{
			return CaseNoteDisplayGroup[i];
		}
	}
	return null;
}


export enum DepartmentUnit {
	AdoptionServicesQueensland_9249086 = "9249086",
	Adoptions_21072 = "21072",
	Aitkenvale_21073 = "21073",
	AitkenvaleCSSC_23004 = "23004",
	AlderleyCSSC_23005 = "23005",
	AthertonCSSC_22034 = "22034",
	AthertonYouthJusticeService_20205 = "20205",
	BaysideCSSC_25133 = "25133",
	BaysideYJS_22096 = "22096",
	BeaudesertCSSC_23006 = "23006",
	BeenleighCSSC_22019 = "22019",
	BneNthSSCoastPSU_9185142 = "9185142",
	BneNthSSCoastZoneOffice_22062 = "22062",
	BneNthSunshineCoastZone_22001 = "22001",
	BneSthGCoastPSU_9185143 = "9185143",
	BneSthGCoastZoneOffice_22063 = "22063",
	BneSthGoldCoastZone_22002 = "22002",
	BowenCSSC_22026 = "22026",
	BoysTownLiaisonUnit_21074 = "21074",
	BrisbaneNorthYouthJusticeConferencing_20310 = "20310",
	BrisbaneNorthYouthJusticeServiceCentre_20110 = "20110",
	BrisbaneRegion_9267090 = "9267090",
	BrisbaneRegion_9267097 = "9267097",
	BrisbaneRegionalIntakeService_9249079 = "9249079",
	BrisbaneRegionalOffice_9267114 = "9267114",
	BrisbaneRestorativeJusticeConferencing_20311 = "20311",
	BrisbaneRegionalServiceCentre_20008 = "20008",
	BrisbaneSouthYouthJusticeServiceCentre_20111 = "20111",
	BrisbaneYouthDetentionCentre_40002 = "40002",
	BrisbaneYFSS_22101 = "22101",
	BrownsPlainsCSSC_22045 = "22045",
	BundabergCSSC_22027 = "22027",
	BundabergYouthJusticeService_20213 = "20213",
	BundabergYJS_22095 = "22095",
	CabooltureCSSC_22009 = "22009",
	CabooltureYouthJusticeConferencing_20312 = "20312",
	CabooltureYouthJusticeServiceCentre_20112 = "20112",
	CairnsCSSC_22035 = "22035",
	CairnsRegionalServiceCentre_20001 = "20001",
	CairnsSouthCSSC_22036 = "22036",
	CairnsYouthJusticeConferencing_20302 = "20302",
	CairnsYouthJusticeServiceCentre_20102 = "20102",
	CaloundraCSSC_9267103 = "9267103",
	CannonHillCSSC_25141 = "25141",
	CapeTorresCSSC_22037 = "22037",
	CapeYorkNorthTorresStraitCSSC_9267119 = "9267119",
	CapeYorkSouthCSSC_9249078 = "9249078",
	CapeYorkYouthJusticeConferencing_20301 = "20301",
	Carramar_21093 = "21093",
	CCRIB_22055 = "22055",
	CentralPSU_9185144 = "9185144",
	CentralQueenslandRegIntakeTeam_21089 = "21089",
	CentralQueenslandRegion_25143 = "25143",
	CentralQueenslandRegion_9267088 = "9267088",
	CentralQueenslandRegion_9267095 = "9267095",
	CentralQueenslandRegion_9274985 = "9274985",
	CentralQueenslandRegionalIntakeService_9249080 = "9249080",
	CentralQueenslandRegionalOffice_9267107 = "9267107",
	CentralWestYouthJusticeServiceCentre_20212 = "20212",
	CentralZone_22003 = "22003",
	CentralZoneOffice_22064 = "22064",
	CharlevilleCSSC_22069 = "22069",
	CharlevilleYouthJusticeService_20217 = "20217",
	CherbourgYouthJusticeServiceCentre_20215 = "20215",
	ChermsideCSSC_22010 = "22010",
	ChildSafetyPracticeImprovement_9249096 = "9249096",
	ClevelandYouthDetentionCentre_40003 = "40003",
	ClientInformationSection_21075 = "21075",
	ComplaintsCaseReviewInvestigationBranch_21008 = "21008",
	CooktownYouthJusticeServiceCentre_20202 = "20202",
	Corinda_21076 = "21076",
	CourtServices_20602 = "20602",
	CourtServicesUnit_22056 = "22056",
	CPChildSafetyAfterHoursServiceCentre_22057 = "22057",
	CPDITO_23001 = "23001",
	CPServiceDesk_23003 = "23003",
	CPIS_21077 = "21077",
	DarlingDownsSouthWestQueensland_40010 = "40010",
	DataManagementServices_22058 = "22058",
	DepartmentofChildSafety_22061 = "22061",
	Dysart_21078 = "21078",
	EdmontonCSSC_9267108 = "9267108",
	EmeraldCSSC_22028 = "22028",
	EthicalStandards_21009 = "21009",
	ExecutiveDirectorUnit_21005 = "21005",
	FarNorthQueensland_40005 = "40005",
	FarNorthQueenslandInvestigationAssessmentUnit_25130 = "25130",
	FarNorthQueenslandRegion_9267086 = "9267086",
	FarNorthQueenslandRegion_9267093 = "9267093",
	FarNorthQueenslandRegionalIntakeService_9249081 = "9249081",
	FarNorthQueenslandRegionalOffice_9267110 = "9267110",
	FarNorthernPSU_9185145 = "9185145",
	FarNorthernZone_22004 = "22004",
	FarNorthernZoneOffice_22065 = "22065",
	FitzroyCentralWestQueensland_40008 = "40008",
	ForestLakeCSSC_9087604 = "9087604",
	FortitudeValleyCSSC_22011 = "22011",
	FreedomofinformationBranch_21003 = "21003",
	GladstoneCSSC_22029 = "22029",
	GladstoneYouthJusticeService_20211 = "20211",
	GladstoneYJS_22097 = "22097",
	GoldCoast_40013 = "40013",
	GoldCoastAssessmentandInhomeSupportCentre_25140 = "25140",
	GoldCoastRegIntakeTeam_21090 = "21090",
	GoldCoastRegionalServiceCentre_20010 = "20010",
	GoldCoastYouthJusticeConferencing_20313 = "20313",
	GoldCoastYouthJusticeServiceCentre_20114 = "20114",
	GoodnaCSSC_22039 = "22039",
	GreaterBrisbane_40004 = "40004",
	GulfCSSC_21095 = "21095",
	GympieCSSC_22013 = "22013",
	HerveyBayRegionalServiceCentre_20005 = "20005",
	HerveyBayYouthJusticeConferencing_20306 = "20306",
	HerveyBayYouthJusticeServiceCentre_20106 = "20106",
	ICMSDataCleansingTeam_23002 = "23002",
	InalaCSSC_22047 = "22047",
	InnisfailCSSC_22038 = "22038",
	InnisfailYouthJusticeService_20204 = "20204",
	InternalAudit_21010 = "21010",
	Interstate_21079 = "21079",
	IpswichWesternPSU_9185146 = "9185146",
	IpswichWesternZone_22005 = "22005",
	IpswichWesternZoneOffice_22066 = "22066",
	IpswichIntakeandAssessmentServiceConnect_25138 = "25138",
	IpswichNorthCSSC_22040 = "22040",
	IpswichRegionalServiceCentre_20007 = "20007",
	IpswichSouthCSSC_22041 = "22041",
	IpswichYouthJusticeConferencing_20308 = "20308",
	IpswichYouthJusticeServiceCentre_20109 = "20109",
	Kingaroy_21094 = "21094",
	LabradorCSSC_22070 = "22070",
	LegalService_21004 = "21004",
	LOCSU_22059 = "22059",
	LoganBneWestPSU_9185147 = "9185147",
	LoganBneWestZoneOffice_22067 = "22067",
	LoganBrisbaneWestZone_22006 = "22006",
	LoganCentralCSSC_22048 = "22048",
	LoganYouthJusticeConferencing_20309 = "20309",
	LoganYouthJusticeServiceCentre_20108 = "20108",
	LoganleaCSSC_22049 = "22049",
	LongreachCSSC_22030 = "22030",
	LongreachYouthJusticeServiceCentre_20210 = "20210",
	MackayWhitsundayRegIntakeTeam_21091 = "21091",
	MackayCSSC_22031 = "22031",
	MackayRegionalServiceCentre_20003 = "20003",
	MackayYouthJusticeConferencing_20304 = "20304",
	MackayYouthJusticeServiceCentre_20104 = "20104",
	MackayWhitsunday_40007 = "40007",
	MaroochydoreCSSC_9267102 = "9267102",
	MaryboroughCSSC_22032 = "22032",
	MattersofConcernReviewUnit_9249095 = "9249095",
	MermaidBeachCSSC_22020 = "22020",
	MiltonCSSC_22098 = "22098",
	MorayfieldCSSC_25137 = "25137",
	Moreton_40011 = "40011",
	MoretonRegion_25144 = "25144",
	MoretonRegion_9274986 = "9274986",
	MoretonYouthJusticeServiceCentre_20220 = "20220",
	MorningtonIslandYouthJusticeServiceCentre_20209 = "20209",
	MtGravattCSSC_22022 = "22022",
	MtIsaCSSC_22051 = "22051",
	MtIsaYouthJusticeServiceCentre_20207 = "20207",
	MtIsaGulfCSSC_25136 = "25136",
	MurgonYouthJusticeServiceCentre_20214 = "20214",
	NerangCSSC_22021 = "22021",
	NormantonYouthJusticeService_20206 = "20206",
	NorthCairnsLowerCapeYorkCSSC_25139 = "25139",
	NorthCoastRegion_9267089 = "9267089",
	NorthCoastRegion_9267096 = "9267096",
	NorthCoastRegionalIntakeService_9249082 = "9249082",
	NorthCoastRegionalOffice_9267101 = "9267101",
	NorthQueensland_40006 = "40006",
	NorthQueenslandRegion_9267087 = "9267087",
	NorthQueenslandRegion_9267094 = "9267094",
	NorthQueenslandRegionalIntakeService_9249083 = "9249083",
	NorthQueenslandRegionalOffice_9267116 = "9267116",
	NorthernOther_22099 = "22099",
	NorthernPSU_9185148 = "9185148",
	NorthernQueenslandRegion_25142 = "25142",
	NorthernQueenslandRegion_9274984 = "9274984",
	NorthernZone_22007 = "22007",
	NorthernZoneOffice_22068 = "22068",
	Nundah_21080 = "21080",
	OfficeoftheChildandFamilyOfficialSolicitor_25147 = "25147",
	OtherUnits_22008 = "22008",
	OtherWorkgroup_21081 = "21081",
	PalmIslandYouthJusticeServiceCentre_20208 = "20208",
	PerformanceMonitoringEvaluation_21002 = "21002",
	PineRiversCSSC_22014 = "22014",
	PlacementServicesBrisbane_9267113 = "9267113",
	PlacementServicesCentralQueensland_9267106 = "9267106",
	PlacementServicesFarNorthQueensland_9267109 = "9267109",
	PlacementServicesNorthCoast_9267100 = "9267100",
	PlacementServicesNorthQueensland_9267115 = "9267115",
	PlacementServicesSouthEast_9267104 = "9267104",
	PlacementServicesSouthWest_9267111 = "9267111",
	QualityStandardsAssessmentBranch_21001 = "21001",
	RedcliffeCSSC_22015 = "22015",
	RedlandsCSSC_22023 = "22023",
	RedlandsYouthJusticeServiceCentre_20219 = "20219",
	RemoteYouthJusticeServiceCentre_20101 = "20101",
	RighttoinformationInformationprivacyScreening_9267118 = "9267118",
	RockhamptonCSSC_25135 = "25135",
	RockhamptonNorthCSSC_22071 = "22071",
	RockhamptonRegionalServiceCentre_20004 = "20004",
	RockhamptonSouthCSSC_22033 = "22033",
	RockhamptonYouthJusticeConferencing_20305 = "20305",
	RockhamptonYouthJusticeServiceCentre_20105 = "20105",
	RomaCSSC_22042 = "22042",
	RomaYouthJusticeService_20216 = "20216",
	SACS_22060 = "22060",
	ServiceSupport_21007 = "21007",
	SexualAbuseCounsellingService_21006 = "21006",
	SouthBurnettCSSC_22016 = "22016",
	SouthEastRegion_25146 = "25146",
	SouthEastRegion_9267091 = "9267091",
	SouthEastRegion_9267098 = "9267098",
	SouthEastRegion_9274988 = "9274988",
	SouthEastRegionalIntakeService_9249084 = "9249084",
	SouthEastRegionalOffice_9267105 = "9267105",
	SouthWestConferencing_20307 = "20307",
	SouthWestRegion_25145 = "25145",
	SouthWestRegion_9267092 = "9267092",
	SouthWestRegion_9267099 = "9267099",
	SouthWestRegion_9274987 = "9274987",
	SouthWestRegionalIntakeService_9249085 = "9249085",
	SouthWestRegionalOffice_9267112 = "9267112",
	SpringfieldCSSC_25132 = "25132",
	StonesCornerCSSC_22024 = "22024",
	StrathpineCSSC_25134 = "25134",
	SunshineCoast_40012 = "40012",
	SunshineCoastNorthCSSC_22017 = "22017",
	SunshineCoastRegionalServiceCentre_20009 = "20009",
	SunshineCoastSouthCSSC_22018 = "22018",
	SunshineCoastYouthJusticeServiceCentre_20113 = "20113",
	ThuringowaCSSC_22052 = "22052",
	ThursdayIslandYouthJusticeServiceCentre_20201 = "20201",
	ToBeAdvised_21082 = "21082",
	Toowong_21083 = "21083",
	ToowoombaSouthWestRegIntakeTeam_21092 = "21092",
	ToowoombaNorthCSSC_22043 = "22043",
	ToowoombaRegionalServiceCentre_20006 = "20006",
	ToowoombaSouthCSSC_22044 = "22044",
	ToowoombaYouthJusticeServiceCentre_20107 = "20107",
	TownsvilleCSSC_9267117 = "9267117",
	TownsvilleNorthCSSC_22053 = "22053",
	TownsvilleNorthYouthJusticeServiceCentre_20103 = "20103",
	TownsvilleRegionalServiceCentre_20002 = "20002",
	TownsvilleSouthCSSC_22054 = "22054",
	TownsvilleSouthYouthJusticeServiceCentre_20221 = "20221",
	TownsvilleYouthJusticeConferencing_20303 = "20303",
	Unknown_21084 = "21084",
	Warwick_21085 = "21085",
	WatchhouseResponseTeam_20222 = "20222",
	WeipaYouthJusticeServiceCentre_20203 = "20203",
	Westbrook_21086 = "21086",
	WesternDistrictsYouthJusticeServiceCentre_20218 = "20218",
	WesternDownsInvestigationAssessmentCSSC_25131 = "25131",
	WideBayYouthJusticeService_22100 = "22100",
	WideBayBurnett_40009 = "40009",
	Woodridge_21087 = "21087",
	WoodridgeCSSC_22050 = "22050",
	Woolloongabba_21088 = "21088",
	WynnumCSSC_22025 = "22025",
	YJOtherUnits_40001 = "40001",
	YouthJusticeandCourtSupport_20601 = "20601",
	YJChildSafetyAfterHoursServiceCentre_40000 = "40000",
	YJDITO_40015 = "40015",
	YJInformationSystems_20501 = "20501",
	YJServiceDeliveryCentralOffice_20401 = "20401",
	YJServiceDesk_40014 = "40014",
	YouthDetentionCentres_40016 = "40016"
}

export interface IDepartmentUnitGroupDescriptor { code: DepartmentUnit; description: string; shortDescription: string; }
export var DepartmentUnitGroup: IDepartmentUnitGroupDescriptor[] = [
	{ code: DepartmentUnit.AdoptionServicesQueensland_9249086, description: `Adoption Services Queensland`, shortDescription: `Adoption Services Queensland` },
	{ code: DepartmentUnit.Adoptions_21072, description: `Adoptions`, shortDescription: `Adoptions` },
	{ code: DepartmentUnit.Aitkenvale_21073, description: `Aitkenvale`, shortDescription: `Aitkenvale` },
	{ code: DepartmentUnit.AitkenvaleCSSC_23004, description: `Aitkenvale CSSC`, shortDescription: `Aitkenvale CSSC` },
	{ code: DepartmentUnit.AlderleyCSSC_23005, description: `Alderley CSSC`, shortDescription: `Alderley CSSC` },
	{ code: DepartmentUnit.AthertonCSSC_22034, description: `Atherton CSSC`, shortDescription: `Atherton CSSC` },
	{ code: DepartmentUnit.AthertonYouthJusticeService_20205, description: `Atherton Youth Justice Service`, shortDescription: `Atherton YJS` },
	{ code: DepartmentUnit.BaysideCSSC_25133, description: `Bayside CSSC`, shortDescription: `Bayside CSSC` },
	{ code: DepartmentUnit.BaysideYJS_22096, description: `Bayside YJS`, shortDescription: `Bayside YJS` },
	{ code: DepartmentUnit.BeaudesertCSSC_23006, description: `Beaudesert CSSC`, shortDescription: `Beaudesert CSSC` },
	{ code: DepartmentUnit.BeenleighCSSC_22019, description: `Beenleigh CSSC`, shortDescription: `Beenleigh CSSC` },
	{ code: DepartmentUnit.BneNthSSCoastPSU_9185142, description: `Bne Nth & SS Coast PSU`, shortDescription: `Bne Nth & SS Coast PSU` },
	{ code: DepartmentUnit.BneNthSSCoastZoneOffice_22062, description: `Bne Nth & SS Coast Zone Office`, shortDescription: `Bne Nth & SS Coast Zone Office` },
	{ code: DepartmentUnit.BneNthSunshineCoastZone_22001, description: `Bne Nth & Sunshine Coast Zone`, shortDescription: `Bne Nth & Sunshine Coast Zone` },
	{ code: DepartmentUnit.BneSthGCoastPSU_9185143, description: `Bne Sth & G Coast PSU`, shortDescription: `Bne Sth & G Coast PSU` },
	{ code: DepartmentUnit.BneSthGCoastZoneOffice_22063, description: `Bne Sth & G Coast Zone Office`, shortDescription: `Bne Sth & G Coast Zone Office` },
	{ code: DepartmentUnit.BneSthGoldCoastZone_22002, description: `Bne Sth & Gold Coast Zone`, shortDescription: `Bne Sth & Gold Coast Zone` },
	{ code: DepartmentUnit.BowenCSSC_22026, description: `Bowen CSSC`, shortDescription: `Bowen CSSC` },
	{ code: DepartmentUnit.BoysTownLiaisonUnit_21074, description: `Boys Town Liaison Unit`, shortDescription: `Boys Town Liaison Unit` },
	{ code: DepartmentUnit.BrisbaneNorthYouthJusticeConferencing_20310, description: `Brisbane North Youth Justice Conferencing`, shortDescription: `Brisbane North YJ Conferencing` },
	{ code: DepartmentUnit.BrisbaneNorthYouthJusticeServiceCentre_20110, description: `Brisbane North Youth Justice Service Centre`, shortDescription: `Brisbane North YJSC` },
	{ code: DepartmentUnit.BrisbaneRegion_9267090, description: `Brisbane Region`, shortDescription: `Brisbane Region` },
	{ code: DepartmentUnit.BrisbaneRegion_9267097, description: `Brisbane Region`, shortDescription: `Brisbane Region` },
	{ code: DepartmentUnit.BrisbaneRegionalIntakeService_9249079, description: `Brisbane Regional Intake Service`, shortDescription: `Brisbane Regional Intake Service` },
	{ code: DepartmentUnit.BrisbaneRegionalOffice_9267114, description: `Brisbane Regional Office`, shortDescription: `Brisbane Regional Office` },
	{ code: DepartmentUnit.BrisbaneRestorativeJusticeConferencing_20311, description: `Brisbane Restorative Justice Conferencing`, shortDescription: `Brisbane RJ Conferencing` },
	{ code: DepartmentUnit.BrisbaneRegionalServiceCentre_20008, description: `Brisbane Regional Service Centre`, shortDescription: `Brisbane RSC` },
	{ code: DepartmentUnit.BrisbaneSouthYouthJusticeServiceCentre_20111, description: `Brisbane South Youth Justice Service Centre`, shortDescription: `Brisbane South YJSC` },
	{ code: DepartmentUnit.BrisbaneYouthDetentionCentre_40002, description: `Brisbane Youth Detention Centre`, shortDescription: `Brisbane YDC` },
	{ code: DepartmentUnit.BrisbaneYFSS_22101, description: `Brisbane YFSS`, shortDescription: `Brisbane YFSS` },
	{ code: DepartmentUnit.BrownsPlainsCSSC_22045, description: `Browns Plains CSSC`, shortDescription: `Browns Plains CSSC` },
	{ code: DepartmentUnit.BundabergCSSC_22027, description: `Bundaberg CSSC`, shortDescription: `Bundaberg CSSC` },
	{ code: DepartmentUnit.BundabergYouthJusticeService_20213, description: `Bundaberg Youth Justice Service`, shortDescription: `Bundaberg YJS` },
	{ code: DepartmentUnit.BundabergYJS_22095, description: `Bundaberg YJS`, shortDescription: `Bundaberg YJS` },
	{ code: DepartmentUnit.CabooltureCSSC_22009, description: `Caboolture CSSC`, shortDescription: `Caboolture CSSC` },
	{ code: DepartmentUnit.CabooltureYouthJusticeConferencing_20312, description: `Caboolture Youth Justice Conferencing`, shortDescription: `Caboolture YJ Conferencing` },
	{ code: DepartmentUnit.CabooltureYouthJusticeServiceCentre_20112, description: `Caboolture Youth Justice Service Centre`, shortDescription: `Caboolture YJSC` },
	{ code: DepartmentUnit.CairnsCSSC_22035, description: `Cairns CSSC`, shortDescription: `Cairns CSSC` },
	{ code: DepartmentUnit.CairnsRegionalServiceCentre_20001, description: `Cairns Regional Service Centre`, shortDescription: `Cairns RSC` },
	{ code: DepartmentUnit.CairnsSouthCSSC_22036, description: `Cairns South CSSC`, shortDescription: `Cairns South CSSC` },
	{ code: DepartmentUnit.CairnsYouthJusticeConferencing_20302, description: `Cairns Youth Justice Conferencing`, shortDescription: `Cairns YJ Conferencing` },
	{ code: DepartmentUnit.CairnsYouthJusticeServiceCentre_20102, description: `Cairns Youth Justice Service Centre`, shortDescription: `Cairns YJSC` },
	{ code: DepartmentUnit.CaloundraCSSC_9267103, description: `Caloundra CSSC`, shortDescription: `Caloundra CSSC` },
	{ code: DepartmentUnit.CannonHillCSSC_25141, description: `Cannon Hill CSSC`, shortDescription: `Cannon Hill CSSC` },
	{ code: DepartmentUnit.CapeTorresCSSC_22037, description: `Cape Torres CSSC`, shortDescription: `Cape Torres CSSC` },
	{ code: DepartmentUnit.CapeYorkNorthTorresStraitCSSC_9267119, description: `Cape York North & Torres Strait CSSC`, shortDescription: `Cape York North & Torres Strait CSSC` },
	{ code: DepartmentUnit.CapeYorkSouthCSSC_9249078, description: `Cape York South CSSC`, shortDescription: `Cape York South CSSC` },
	{ code: DepartmentUnit.CapeYorkYouthJusticeConferencing_20301, description: `Cape York Youth Justice Conferencing`, shortDescription: `Cape York YJ Conferencing` },
	{ code: DepartmentUnit.Carramar_21093, description: `Carramar`, shortDescription: `Carramar` },
	{ code: DepartmentUnit.CCRIB_22055, description: `CCRIB`, shortDescription: `CCRIB` },
	{ code: DepartmentUnit.CentralPSU_9185144, description: `Central PSU`, shortDescription: `Central PSU` },
	{ code: DepartmentUnit.CentralQueenslandRegIntakeTeam_21089, description: `Central Queensland Reg. Intake Team`, shortDescription: `Central Queensland Reg. Intake Team` },
	{ code: DepartmentUnit.CentralQueenslandRegion_25143, description: `Central Queensland Region`, shortDescription: `Central Queensland Region` },
	{ code: DepartmentUnit.CentralQueenslandRegion_9267088, description: `Central Queensland Region`, shortDescription: `Central Queensland Region` },
	{ code: DepartmentUnit.CentralQueenslandRegion_9267095, description: `Central Queensland Region`, shortDescription: `Central Queensland Region` },
	{ code: DepartmentUnit.CentralQueenslandRegion_9274985, description: `Central Queensland Region`, shortDescription: `Central Queensland Region` },
	{ code: DepartmentUnit.CentralQueenslandRegionalIntakeService_9249080, description: `Central Queensland Regional Intake Service`, shortDescription: `Central Queensland Regional Intake Service` },
	{ code: DepartmentUnit.CentralQueenslandRegionalOffice_9267107, description: `Central Queensland Regional Office`, shortDescription: `Central Queensland Regional Office` },
	{ code: DepartmentUnit.CentralWestYouthJusticeServiceCentre_20212, description: `Central West Youth Justice Service Centre`, shortDescription: `Central West YJSC` },
	{ code: DepartmentUnit.CentralZone_22003, description: `Central Zone`, shortDescription: `Central Zone` },
	{ code: DepartmentUnit.CentralZoneOffice_22064, description: `Central Zone Office`, shortDescription: `Central Zone Office` },
	{ code: DepartmentUnit.CharlevilleCSSC_22069, description: `Charleville CSSC`, shortDescription: `Charleville CSSC` },
	{ code: DepartmentUnit.CharlevilleYouthJusticeService_20217, description: `Charleville Youth Justice Service`, shortDescription: `Charleville YJS` },
	{ code: DepartmentUnit.CherbourgYouthJusticeServiceCentre_20215, description: `Cherbourg Youth Justice Service Centre`, shortDescription: `Cherbourg YJSC` },
	{ code: DepartmentUnit.ChermsideCSSC_22010, description: `Chermside CSSC`, shortDescription: `Chermside CSSC` },
	{ code: DepartmentUnit.ChildSafetyPracticeImprovement_9249096, description: `Child Safety Practice Improvement`, shortDescription: `Child Safety Practice Improvement` },
	{ code: DepartmentUnit.ClevelandYouthDetentionCentre_40003, description: `Cleveland Youth Detention Centre`, shortDescription: `Cleveland YDC` },
	{ code: DepartmentUnit.ClientInformationSection_21075, description: `Client Information Section`, shortDescription: `Client Information Section` },
	{ code: DepartmentUnit.ComplaintsCaseReviewInvestigationBranch_21008, description: `Complaints - Case Review & Investigation Branch`, shortDescription: `Complaints - Case Review & Investigation Branch` },
	{ code: DepartmentUnit.CooktownYouthJusticeServiceCentre_20202, description: `Cooktown Youth Justice Service Centre`, shortDescription: `Cooktown YJSC` },
	{ code: DepartmentUnit.Corinda_21076, description: `Corinda`, shortDescription: `Corinda` },
	{ code: DepartmentUnit.CourtServices_20602, description: `Court Services`, shortDescription: `Court Services` },
	{ code: DepartmentUnit.CourtServicesUnit_22056, description: `Court Services Unit`, shortDescription: `Court Services Unit` },
	{ code: DepartmentUnit.CPChildSafetyAfterHoursServiceCentre_22057, description: `CP Child Safety After Hours Service Centre`, shortDescription: `CP CSAHSC` },
	{ code: DepartmentUnit.CPDITO_23001, description: `CP DITO`, shortDescription: `CP DITO` },
	{ code: DepartmentUnit.CPServiceDesk_23003, description: `CP Service Desk`, shortDescription: `CP Service Desk` },
	{ code: DepartmentUnit.CPIS_21077, description: `CPIS`, shortDescription: `CPIS` },
	{ code: DepartmentUnit.DarlingDownsSouthWestQueensland_40010, description: `Darling Downs/South West Queensland`, shortDescription: `Darling Downs/South West Queensland` },
	{ code: DepartmentUnit.DataManagementServices_22058, description: `Data Management Services`, shortDescription: `Data Management Services` },
	{ code: DepartmentUnit.DepartmentofChildSafety_22061, description: `Department of Child Safety`, shortDescription: `Department of Child Safety` },
	{ code: DepartmentUnit.Dysart_21078, description: `Dysart`, shortDescription: `Dysart` },
	{ code: DepartmentUnit.EdmontonCSSC_9267108, description: `Edmonton CSSC`, shortDescription: `Edmonton CSSC` },
	{ code: DepartmentUnit.EmeraldCSSC_22028, description: `Emerald CSSC`, shortDescription: `Emerald CSSC` },
	{ code: DepartmentUnit.EthicalStandards_21009, description: `Ethical Standards`, shortDescription: `Ethical Standards` },
	{ code: DepartmentUnit.ExecutiveDirectorUnit_21005, description: `Executive Director Unit`, shortDescription: `Executive Director Unit` },
	{ code: DepartmentUnit.FarNorthQueensland_40005, description: `Far North Queensland`, shortDescription: `Far North Queensland` },
	{ code: DepartmentUnit.FarNorthQueenslandInvestigationAssessmentUnit_25130, description: `Far North Queensland Investigation & Assessment Unit`, shortDescription: `Far North Queensland Investigation & Assessment Unit` },
	{ code: DepartmentUnit.FarNorthQueenslandRegion_9267086, description: `Far North Queensland Region`, shortDescription: `Far North Queensland Region` },
	{ code: DepartmentUnit.FarNorthQueenslandRegion_9267093, description: `Far North Queensland Region`, shortDescription: `Far North Queensland Region` },
	{ code: DepartmentUnit.FarNorthQueenslandRegionalIntakeService_9249081, description: `Far North Queensland Regional Intake Service`, shortDescription: `Far North Queensland Regional Intake Service` },
	{ code: DepartmentUnit.FarNorthQueenslandRegionalOffice_9267110, description: `Far North Queensland Regional Office`, shortDescription: `Far North Queensland Regional Office` },
	{ code: DepartmentUnit.FarNorthernPSU_9185145, description: `Far Northern PSU`, shortDescription: `Far Northern PSU` },
	{ code: DepartmentUnit.FarNorthernZone_22004, description: `Far Northern Zone`, shortDescription: `Far Northern Zone` },
	{ code: DepartmentUnit.FarNorthernZoneOffice_22065, description: `Far Northern Zone Office`, shortDescription: `Far Northern Zone Office` },
	{ code: DepartmentUnit.FitzroyCentralWestQueensland_40008, description: `Fitzroy/Central West Queensland`, shortDescription: `Fitzroy/Central West Queensland` },
	{ code: DepartmentUnit.ForestLakeCSSC_9087604, description: `Forest Lake CSSC`, shortDescription: `Forest Lake CSSC` },
	{ code: DepartmentUnit.FortitudeValleyCSSC_22011, description: `Fortitude Valley CSSC`, shortDescription: `Fortitude Valley CSSC` },
	{ code: DepartmentUnit.FreedomofinformationBranch_21003, description: `Freedom of information Branch`, shortDescription: `Freedom of information Branch` },
	{ code: DepartmentUnit.GladstoneCSSC_22029, description: `Gladstone CSSC`, shortDescription: `Gladstone CSSC` },
	{ code: DepartmentUnit.GladstoneYouthJusticeService_20211, description: `Gladstone Youth Justice Service`, shortDescription: `Gladstone YJS` },
	{ code: DepartmentUnit.GladstoneYJS_22097, description: `Gladstone YJS`, shortDescription: `Gladstone YJS` },
	{ code: DepartmentUnit.GoldCoast_40013, description: `Gold Coast`, shortDescription: `Gold Coast` },
	{ code: DepartmentUnit.GoldCoastAssessmentandInhomeSupportCentre_25140, description: `Gold Coast Assessment and In-home Support Centre`, shortDescription: `Gold Coast Assessment and In-home Support Centre` },
	{ code: DepartmentUnit.GoldCoastRegIntakeTeam_21090, description: `Gold Coast Reg. Intake Team`, shortDescription: `Gold Coast Reg. Intake Team` },
	{ code: DepartmentUnit.GoldCoastRegionalServiceCentre_20010, description: `Gold Coast Regional Service Centre`, shortDescription: `Gold Coast RSC` },
	{ code: DepartmentUnit.GoldCoastYouthJusticeConferencing_20313, description: `Gold Coast Youth Justice Conferencing`, shortDescription: `Gold Coast YJ Conferencing` },
	{ code: DepartmentUnit.GoldCoastYouthJusticeServiceCentre_20114, description: `Gold Coast Youth Justice Service Centre`, shortDescription: `Gold Coast YJSC` },
	{ code: DepartmentUnit.GoodnaCSSC_22039, description: `Goodna CSSC`, shortDescription: `Goodna CSSC` },
	{ code: DepartmentUnit.GreaterBrisbane_40004, description: `Greater Brisbane`, shortDescription: `Greater Brisbane` },
	{ code: DepartmentUnit.GulfCSSC_21095, description: `Gulf CSSC`, shortDescription: `Gulf CSSC` },
	{ code: DepartmentUnit.GympieCSSC_22013, description: `Gympie CSSC`, shortDescription: `Gympie CSSC` },
	{ code: DepartmentUnit.HerveyBayRegionalServiceCentre_20005, description: `Hervey Bay Regional Service Centre`, shortDescription: `Hervey Bay RSC` },
	{ code: DepartmentUnit.HerveyBayYouthJusticeConferencing_20306, description: `Hervey Bay Youth Justice Conferencing`, shortDescription: `Hervey Bay YJ Conferencing` },
	{ code: DepartmentUnit.HerveyBayYouthJusticeServiceCentre_20106, description: `Hervey Bay Youth Justice Service Centre`, shortDescription: `Hervey Bay YJSC` },
	{ code: DepartmentUnit.ICMSDataCleansingTeam_23002, description: `ICMS Data Cleansing Team`, shortDescription: `ICMS Data Cleansing Team` },
	{ code: DepartmentUnit.InalaCSSC_22047, description: `Inala CSSC`, shortDescription: `Inala CSSC` },
	{ code: DepartmentUnit.InnisfailCSSC_22038, description: `Innisfail CSSC`, shortDescription: `Innisfail CSSC` },
	{ code: DepartmentUnit.InnisfailYouthJusticeService_20204, description: `Innisfail Youth Justice Service`, shortDescription: `Innisfail YJS` },
	{ code: DepartmentUnit.InternalAudit_21010, description: `Internal Audit`, shortDescription: `Internal Audit` },
	{ code: DepartmentUnit.Interstate_21079, description: `Interstate`, shortDescription: `Interstate` },
	{ code: DepartmentUnit.IpswichWesternPSU_9185146, description: `Ipswich & Western PSU`, shortDescription: `Ipswich & Western PSU` },
	{ code: DepartmentUnit.IpswichWesternZone_22005, description: `Ipswich & Western Zone`, shortDescription: `Ipswich & Western Zone` },
	{ code: DepartmentUnit.IpswichWesternZoneOffice_22066, description: `Ipswich & Western Zone Office`, shortDescription: `Ipswich & Western Zone Office` },
	{ code: DepartmentUnit.IpswichIntakeandAssessmentServiceConnect_25138, description: `Ipswich Intake and Assessment Service Connect`, shortDescription: `Ipswich Intake and Assessment Service Connect` },
	{ code: DepartmentUnit.IpswichNorthCSSC_22040, description: `Ipswich North CSSC`, shortDescription: `Ipswich North CSSC` },
	{ code: DepartmentUnit.IpswichRegionalServiceCentre_20007, description: `Ipswich Regional Service Centre`, shortDescription: `Ipswich RSC` },
	{ code: DepartmentUnit.IpswichSouthCSSC_22041, description: `Ipswich South CSSC`, shortDescription: `Ipswich South CSSC` },
	{ code: DepartmentUnit.IpswichYouthJusticeConferencing_20308, description: `Ipswich Youth Justice Conferencing`, shortDescription: `Ipswich YJ Conferencing` },
	{ code: DepartmentUnit.IpswichYouthJusticeServiceCentre_20109, description: `Ipswich Youth Justice Service Centre`, shortDescription: `Ipswich YJSC` },
	{ code: DepartmentUnit.Kingaroy_21094, description: `Kingaroy`, shortDescription: `Kingaroy` },
	{ code: DepartmentUnit.LabradorCSSC_22070, description: `Labrador CSSC`, shortDescription: `Labrador CSSC` },
	{ code: DepartmentUnit.LegalService_21004, description: `Legal Service`, shortDescription: `Legal Service` },
	{ code: DepartmentUnit.LOCSU_22059, description: `LOCSU`, shortDescription: `LOCSU` },
	{ code: DepartmentUnit.LoganBneWestPSU_9185147, description: `Logan & Bne West PSU`, shortDescription: `Logan & Bne West PSU` },
	{ code: DepartmentUnit.LoganBneWestZoneOffice_22067, description: `Logan & Bne West Zone Office`, shortDescription: `Logan & Bne West Zone Office` },
	{ code: DepartmentUnit.LoganBrisbaneWestZone_22006, description: `Logan & Brisbane West Zone`, shortDescription: `Logan & Brisbane West Zone` },
	{ code: DepartmentUnit.LoganCentralCSSC_22048, description: `Logan Central CSSC`, shortDescription: `Logan Central CSSC` },
	{ code: DepartmentUnit.LoganYouthJusticeConferencing_20309, description: `Logan Youth Justice Conferencing`, shortDescription: `Logan YJ Conferencing` },
	{ code: DepartmentUnit.LoganYouthJusticeServiceCentre_20108, description: `Logan Youth Justice Service Centre`, shortDescription: `Logan YJSC` },
	{ code: DepartmentUnit.LoganleaCSSC_22049, description: `Loganlea CSSC`, shortDescription: `Loganlea CSSC` },
	{ code: DepartmentUnit.LongreachCSSC_22030, description: `Longreach CSSC`, shortDescription: `Longreach CSSC` },
	{ code: DepartmentUnit.LongreachYouthJusticeServiceCentre_20210, description: `Longreach Youth Justice Service Centre`, shortDescription: `Longreach YJSC` },
	{ code: DepartmentUnit.MackayWhitsundayRegIntakeTeam_21091, description: `Mackay & Whitsunday Reg. Intake Team`, shortDescription: `Mackay & Whitsunday Reg. Intake Team` },
	{ code: DepartmentUnit.MackayCSSC_22031, description: `Mackay CSSC`, shortDescription: `Mackay CSSC` },
	{ code: DepartmentUnit.MackayRegionalServiceCentre_20003, description: `Mackay Regional Service Centre`, shortDescription: `Mackay RSC` },
	{ code: DepartmentUnit.MackayYouthJusticeConferencing_20304, description: `Mackay Youth Justice Conferencing`, shortDescription: `Mackay YJ Conferencing` },
	{ code: DepartmentUnit.MackayYouthJusticeServiceCentre_20104, description: `Mackay Youth Justice Service Centre`, shortDescription: `Mackay YJSC` },
	{ code: DepartmentUnit.MackayWhitsunday_40007, description: `Mackay/Whitsunday`, shortDescription: `Mackay/Whitsunday` },
	{ code: DepartmentUnit.MaroochydoreCSSC_9267102, description: `Maroochydore CSSC`, shortDescription: `Maroochydore CSSC` },
	{ code: DepartmentUnit.MaryboroughCSSC_22032, description: `Maryborough CSSC`, shortDescription: `Maryborough CSSC` },
	{ code: DepartmentUnit.MattersofConcernReviewUnit_9249095, description: `Matters of Concern Review Unit`, shortDescription: `Matters of Concern Review Unit` },
	{ code: DepartmentUnit.MermaidBeachCSSC_22020, description: `Mermaid Beach CSSC`, shortDescription: `Mermaid Beach CSSC` },
	{ code: DepartmentUnit.MiltonCSSC_22098, description: `Milton CSSC`, shortDescription: `Milton CSSC` },
	{ code: DepartmentUnit.MorayfieldCSSC_25137, description: `Morayfield CSSC`, shortDescription: `Morayfield CSSC` },
	{ code: DepartmentUnit.Moreton_40011, description: `Moreton`, shortDescription: `Moreton` },
	{ code: DepartmentUnit.MoretonRegion_25144, description: `Moreton Region`, shortDescription: `Moreton Region` },
	{ code: DepartmentUnit.MoretonRegion_9274986, description: `Moreton Region`, shortDescription: `Moreton Region` },
	{ code: DepartmentUnit.MoretonYouthJusticeServiceCentre_20220, description: `Moreton Youth Justice Service Centre`, shortDescription: `Moreton YJSC` },
	{ code: DepartmentUnit.MorningtonIslandYouthJusticeServiceCentre_20209, description: `Mornington Island Youth Justice Service Centre`, shortDescription: `Mornington Island YJSC` },
	{ code: DepartmentUnit.MtGravattCSSC_22022, description: `Mt Gravatt CSSC`, shortDescription: `Mt Gravatt CSSC` },
	{ code: DepartmentUnit.MtIsaCSSC_22051, description: `Mt Isa CSSC`, shortDescription: `Mt Isa CSSC` },
	{ code: DepartmentUnit.MtIsaYouthJusticeServiceCentre_20207, description: `Mt Isa Youth Justice Service Centre`, shortDescription: `Mt Isa YJSC` },
	{ code: DepartmentUnit.MtIsaGulfCSSC_25136, description: `Mt Isa-Gulf CSSC`, shortDescription: `Mt Isa-Gulf CSSC` },
	{ code: DepartmentUnit.MurgonYouthJusticeServiceCentre_20214, description: `Murgon Youth Justice Service Centre`, shortDescription: `Murgon YJS` },
	{ code: DepartmentUnit.NerangCSSC_22021, description: `Nerang CSSC`, shortDescription: `Nerang CSSC` },
	{ code: DepartmentUnit.NormantonYouthJusticeService_20206, description: `Normanton Youth Justice Service`, shortDescription: `Normanton YJS` },
	{ code: DepartmentUnit.NorthCairnsLowerCapeYorkCSSC_25139, description: `North Cairns & Lower Cape York CSSC`, shortDescription: `North Cairns & Lower Cape York CSSC` },
	{ code: DepartmentUnit.NorthCoastRegion_9267089, description: `North Coast Region`, shortDescription: `North Coast Region` },
	{ code: DepartmentUnit.NorthCoastRegion_9267096, description: `North Coast Region`, shortDescription: `North Coast Region` },
	{ code: DepartmentUnit.NorthCoastRegionalIntakeService_9249082, description: `North Coast Regional Intake Service`, shortDescription: `North Coast Regional Intake Service` },
	{ code: DepartmentUnit.NorthCoastRegionalOffice_9267101, description: `North Coast Regional Office`, shortDescription: `North Coast Regional Office` },
	{ code: DepartmentUnit.NorthQueensland_40006, description: `North Queensland`, shortDescription: `North Queensland` },
	{ code: DepartmentUnit.NorthQueenslandRegion_9267087, description: `North Queensland Region`, shortDescription: `North Queensland Region` },
	{ code: DepartmentUnit.NorthQueenslandRegion_9267094, description: `North Queensland Region`, shortDescription: `North Queensland Region` },
	{ code: DepartmentUnit.NorthQueenslandRegionalIntakeService_9249083, description: `North Queensland Regional Intake Service`, shortDescription: `North Queensland Regional Intake Service` },
	{ code: DepartmentUnit.NorthQueenslandRegionalOffice_9267116, description: `North Queensland Regional Office`, shortDescription: `North Queensland Regional Office` },
	{ code: DepartmentUnit.NorthernOther_22099, description: `Northern Other`, shortDescription: `Northern Other` },
	{ code: DepartmentUnit.NorthernPSU_9185148, description: `Northern PSU`, shortDescription: `Northern PSU` },
	{ code: DepartmentUnit.NorthernQueenslandRegion_25142, description: `Northern Queensland Region`, shortDescription: `Northern Queensland Region` },
	{ code: DepartmentUnit.NorthernQueenslandRegion_9274984, description: `Northern Queensland Region`, shortDescription: `Northern Queensland Region` },
	{ code: DepartmentUnit.NorthernZone_22007, description: `Northern Zone`, shortDescription: `Northern Zone` },
	{ code: DepartmentUnit.NorthernZoneOffice_22068, description: `Northern Zone Office`, shortDescription: `Northern Zone Office` },
	{ code: DepartmentUnit.Nundah_21080, description: `Nundah`, shortDescription: `Nundah` },
	{ code: DepartmentUnit.OfficeoftheChildandFamilyOfficialSolicitor_25147, description: `Office of the Child and Family Official Solicitor`, shortDescription: `Office of the Child and Family Official Solicitor` },
	{ code: DepartmentUnit.OtherUnits_22008, description: `Other Units`, shortDescription: `Other Units` },
	{ code: DepartmentUnit.OtherWorkgroup_21081, description: `Other Workgroup`, shortDescription: `Other Workgroup` },
	{ code: DepartmentUnit.PalmIslandYouthJusticeServiceCentre_20208, description: `Palm Island Youth Justice Service Centre`, shortDescription: `Palm Island YJSC` },
	{ code: DepartmentUnit.PerformanceMonitoringEvaluation_21002, description: `Performance Monitoring & Evaluation`, shortDescription: `Performance Monitoring & Evaluation` },
	{ code: DepartmentUnit.PineRiversCSSC_22014, description: `Pine Rivers CSSC`, shortDescription: `Pine Rivers CSSC` },
	{ code: DepartmentUnit.PlacementServicesBrisbane_9267113, description: `Placement Services Brisbane`, shortDescription: `Placement Services Brisbane` },
	{ code: DepartmentUnit.PlacementServicesCentralQueensland_9267106, description: `Placement Services Central Queensland`, shortDescription: `Placement Services Central Queensland` },
	{ code: DepartmentUnit.PlacementServicesFarNorthQueensland_9267109, description: `Placement Services Far North Queensland`, shortDescription: `Placement Services Far North Queensland` },
	{ code: DepartmentUnit.PlacementServicesNorthCoast_9267100, description: `Placement Services North Coast`, shortDescription: `Placement Services North Coast` },
	{ code: DepartmentUnit.PlacementServicesNorthQueensland_9267115, description: `Placement Services North Queensland`, shortDescription: `Placement Services North Queensland` },
	{ code: DepartmentUnit.PlacementServicesSouthEast_9267104, description: `Placement Services South East`, shortDescription: `Placement Services South East` },
	{ code: DepartmentUnit.PlacementServicesSouthWest_9267111, description: `Placement Services South West`, shortDescription: `Placement Services South West` },
	{ code: DepartmentUnit.QualityStandardsAssessmentBranch_21001, description: `Quality Standards Assessment Branch`, shortDescription: `Quality Standards Assessment Branch` },
	{ code: DepartmentUnit.RedcliffeCSSC_22015, description: `Redcliffe CSSC`, shortDescription: `Redcliffe CSSC` },
	{ code: DepartmentUnit.RedlandsCSSC_22023, description: `Redlands CSSC`, shortDescription: `Redlands CSSC` },
	{ code: DepartmentUnit.RedlandsYouthJusticeServiceCentre_20219, description: `Redlands Youth Justice Service Centre`, shortDescription: `Redlands YJSC` },
	{ code: DepartmentUnit.RemoteYouthJusticeServiceCentre_20101, description: `Remote Youth Justice Service Centre`, shortDescription: `Remote YJSC` },
	{ code: DepartmentUnit.RighttoinformationInformationprivacyScreening_9267118, description: `Right to information, Information privacy & Screening`, shortDescription: `Right to information, Information privacy & Screening` },
	{ code: DepartmentUnit.RockhamptonCSSC_25135, description: `Rockhampton CSSC`, shortDescription: `Rockhampton CSSC` },
	{ code: DepartmentUnit.RockhamptonNorthCSSC_22071, description: `Rockhampton North CSSC`, shortDescription: `Rockhampton North CSSC` },
	{ code: DepartmentUnit.RockhamptonRegionalServiceCentre_20004, description: `Rockhampton Regional Service Centre`, shortDescription: `Rockhampton RSC` },
	{ code: DepartmentUnit.RockhamptonSouthCSSC_22033, description: `Rockhampton South CSSC`, shortDescription: `Rockhampton South CSSC` },
	{ code: DepartmentUnit.RockhamptonYouthJusticeConferencing_20305, description: `Rockhampton Youth Justice Conferencing`, shortDescription: `Rockhampton YJ Conferencing` },
	{ code: DepartmentUnit.RockhamptonYouthJusticeServiceCentre_20105, description: `Rockhampton Youth Justice Service Centre`, shortDescription: `Rockhampton YJSC` },
	{ code: DepartmentUnit.RomaCSSC_22042, description: `Roma CSSC`, shortDescription: `Roma CSSC` },
	{ code: DepartmentUnit.RomaYouthJusticeService_20216, description: `Roma Youth Justice Service`, shortDescription: `Roma YJS` },
	{ code: DepartmentUnit.SACS_22060, description: `SACS`, shortDescription: `SACS` },
	{ code: DepartmentUnit.ServiceSupport_21007, description: `Service Support`, shortDescription: `Service Support` },
	{ code: DepartmentUnit.SexualAbuseCounsellingService_21006, description: `Sexual Abuse Counselling Service`, shortDescription: `Sexual Abuse Counselling Service` },
	{ code: DepartmentUnit.SouthBurnettCSSC_22016, description: `South Burnett CSSC`, shortDescription: `South Burnett CSSC` },
	{ code: DepartmentUnit.SouthEastRegion_25146, description: `South East Region`, shortDescription: `South East Region` },
	{ code: DepartmentUnit.SouthEastRegion_9267091, description: `South East Region`, shortDescription: `South East Region` },
	{ code: DepartmentUnit.SouthEastRegion_9267098, description: `South East Region`, shortDescription: `South East Region` },
	{ code: DepartmentUnit.SouthEastRegion_9274988, description: `South East Region`, shortDescription: `South East Region` },
	{ code: DepartmentUnit.SouthEastRegionalIntakeService_9249084, description: `South East Regional Intake Service`, shortDescription: `South East Regional Intake Service` },
	{ code: DepartmentUnit.SouthEastRegionalOffice_9267105, description: `South East Regional Office`, shortDescription: `South East Regional Office` },
	{ code: DepartmentUnit.SouthWestConferencing_20307, description: `South West Conferencing`, shortDescription: `South West Conferencing` },
	{ code: DepartmentUnit.SouthWestRegion_25145, description: `South West Region`, shortDescription: `South West Region` },
	{ code: DepartmentUnit.SouthWestRegion_9267092, description: `South West Region`, shortDescription: `South West Region` },
	{ code: DepartmentUnit.SouthWestRegion_9267099, description: `South West Region`, shortDescription: `South West Region` },
	{ code: DepartmentUnit.SouthWestRegion_9274987, description: `South West Region`, shortDescription: `South West Region` },
	{ code: DepartmentUnit.SouthWestRegionalIntakeService_9249085, description: `South West Regional Intake Service`, shortDescription: `South West Regional Intake Service` },
	{ code: DepartmentUnit.SouthWestRegionalOffice_9267112, description: `South West Regional Office`, shortDescription: `South West Regional Office` },
	{ code: DepartmentUnit.SpringfieldCSSC_25132, description: `Springfield CSSC`, shortDescription: `Springfield CSSC` },
	{ code: DepartmentUnit.StonesCornerCSSC_22024, description: `Stones Corner CSSC`, shortDescription: `Stones Corner CSSC` },
	{ code: DepartmentUnit.StrathpineCSSC_25134, description: `Strathpine CSSC`, shortDescription: `Strathpine CSSC` },
	{ code: DepartmentUnit.SunshineCoast_40012, description: `Sunshine Coast`, shortDescription: `Sunshine Coast` },
	{ code: DepartmentUnit.SunshineCoastNorthCSSC_22017, description: `Sunshine Coast North CSSC`, shortDescription: `Sunshine Coast North CSSC` },
	{ code: DepartmentUnit.SunshineCoastRegionalServiceCentre_20009, description: `Sunshine Coast Regional Service Centre`, shortDescription: `Sunshine Coast RSC` },
	{ code: DepartmentUnit.SunshineCoastSouthCSSC_22018, description: `Sunshine Coast South CSSC`, shortDescription: `Sunshine Coast South CSSC` },
	{ code: DepartmentUnit.SunshineCoastYouthJusticeServiceCentre_20113, description: `Sunshine Coast Youth Justice Service Centre`, shortDescription: `Sunshine Coast YJSC` },
	{ code: DepartmentUnit.ThuringowaCSSC_22052, description: `Thuringowa CSSC`, shortDescription: `Thuringowa CSSC` },
	{ code: DepartmentUnit.ThursdayIslandYouthJusticeServiceCentre_20201, description: `Thursday Island Youth Justice Service Centre`, shortDescription: `Thursday Island YJSC` },
	{ code: DepartmentUnit.ToBeAdvised_21082, description: `To Be Advised`, shortDescription: `To Be Advised` },
	{ code: DepartmentUnit.Toowong_21083, description: `Toowong`, shortDescription: `Toowong` },
	{ code: DepartmentUnit.ToowoombaSouthWestRegIntakeTeam_21092, description: `Toowoomba & South West Reg. Intake Team`, shortDescription: `Toowoomba & South West Reg. Intake Team` },
	{ code: DepartmentUnit.ToowoombaNorthCSSC_22043, description: `Toowoomba North CSSC`, shortDescription: `Toowoomba North CSSC` },
	{ code: DepartmentUnit.ToowoombaRegionalServiceCentre_20006, description: `Toowoomba Regional Service Centre`, shortDescription: `Toowoomba RSC` },
	{ code: DepartmentUnit.ToowoombaSouthCSSC_22044, description: `Toowoomba South CSSC`, shortDescription: `Toowoomba South CSSC` },
	{ code: DepartmentUnit.ToowoombaYouthJusticeServiceCentre_20107, description: `Toowoomba Youth Justice Service Centre`, shortDescription: `Toowoomba YJSC` },
	{ code: DepartmentUnit.TownsvilleCSSC_9267117, description: `Townsville CSSC`, shortDescription: `Townsville CSSC` },
	{ code: DepartmentUnit.TownsvilleNorthCSSC_22053, description: `Townsville North CSSC`, shortDescription: `Townsville North CSSC` },
	{ code: DepartmentUnit.TownsvilleNorthYouthJusticeServiceCentre_20103, description: `Townsville North Youth Justice Service Centre`, shortDescription: `Townsville North YJSC` },
	{ code: DepartmentUnit.TownsvilleRegionalServiceCentre_20002, description: `Townsville Regional Service Centre`, shortDescription: `Townsville RSC` },
	{ code: DepartmentUnit.TownsvilleSouthCSSC_22054, description: `Townsville South CSSC`, shortDescription: `Townsville South CSSC` },
	{ code: DepartmentUnit.TownsvilleSouthYouthJusticeServiceCentre_20221, description: `Townsville South Youth Justice Service Centre`, shortDescription: `Townsville South YJSC` },
	{ code: DepartmentUnit.TownsvilleYouthJusticeConferencing_20303, description: `Townsville Youth Justice Conferencing`, shortDescription: `Townsville YJ Conferencing` },
	{ code: DepartmentUnit.Unknown_21084, description: `Unknown`, shortDescription: `Unknown` },
	{ code: DepartmentUnit.Warwick_21085, description: `Warwick`, shortDescription: `Warwick` },
	{ code: DepartmentUnit.WatchhouseResponseTeam_20222, description: `Watchhouse Response Team`, shortDescription: `Watchhouse Response Team` },
	{ code: DepartmentUnit.WeipaYouthJusticeServiceCentre_20203, description: `Weipa Youth Justice Service Centre`, shortDescription: `Weipa YJSC` },
	{ code: DepartmentUnit.Westbrook_21086, description: `Westbrook`, shortDescription: `Westbrook` },
	{ code: DepartmentUnit.WesternDistrictsYouthJusticeServiceCentre_20218, description: `Western Districts Youth Justice Service Centre`, shortDescription: `Western Districts YJSC` },
	{ code: DepartmentUnit.WesternDownsInvestigationAssessmentCSSC_25131, description: `Western Downs Investigation & Assessment CSSC`, shortDescription: `Western Downs Investigation & Assessment CSSC` },
	{ code: DepartmentUnit.WideBayYouthJusticeService_22100, description: `Wide Bay Youth Justice Service`, shortDescription: `Wide Bay YJS` },
	{ code: DepartmentUnit.WideBayBurnett_40009, description: `Wide Bay/Burnett`, shortDescription: `Wide Bay/Burnett` },
	{ code: DepartmentUnit.Woodridge_21087, description: `Woodridge`, shortDescription: `Woodridge` },
	{ code: DepartmentUnit.WoodridgeCSSC_22050, description: `Woodridge CSSC`, shortDescription: `Woodridge CSSC` },
	{ code: DepartmentUnit.Woolloongabba_21088, description: `Woolloongabba`, shortDescription: `Woolloongabba` },
	{ code: DepartmentUnit.WynnumCSSC_22025, description: `Wynnum CSSC`, shortDescription: `Wynnum CSSC` },
	{ code: DepartmentUnit.YJOtherUnits_40001, description: `YJ - Other Units`, shortDescription: `YJ - Other Units` },
	{ code: DepartmentUnit.YouthJusticeandCourtSupport_20601, description: `Youth Justice and Court Support`, shortDescription: `YJ and Court Support` },
	{ code: DepartmentUnit.YJChildSafetyAfterHoursServiceCentre_40000, description: `YJ Child Safety After Hours Service Centre`, shortDescription: `YJ CSAHSC` },
	{ code: DepartmentUnit.YJDITO_40015, description: `YJ DITO`, shortDescription: `YJ DITO` },
	{ code: DepartmentUnit.YJInformationSystems_20501, description: `YJ Information Systems`, shortDescription: `YJ Information Systems` },
	{ code: DepartmentUnit.YJServiceDeliveryCentralOffice_20401, description: `YJ Service Delivery (Central Office)`, shortDescription: `YJ Service Delivery (Central Office)` },
	{ code: DepartmentUnit.YJServiceDesk_40014, description: `YJ Service Desk`, shortDescription: `YJ Service Desk` },
	{ code: DepartmentUnit.YouthDetentionCentres_40016, description: `Youth Detention Centres`, shortDescription: `Youth Detention Centres` }
];

export function getDepartmentUnitGroupDescriptor(DepartmentUnitCode: string) : IDepartmentUnitGroupDescriptor {
	for(var i = 0; i < DepartmentUnitGroup.length; i++)
	{
		if(DepartmentUnitGroup[i].code == DepartmentUnitCode)
		{
			return DepartmentUnitGroup[i];
		}
	}
	return null;
}


export enum DepartmentUnitCode {
	Courtservice_15 = "15",
	ChildSafetyServiceCentre_6 = "6",
	Datamanagement_16 = "16",
	Department_7 = "7",
	Other_99 = "99",
	Regionaloffice_9 = "9",
	Youthdetentioncentre_14 = "14",
	Youthdetentionoperation_13 = "13",
	Youthjusticecentraloffice_8 = "8",
	Youthjusticeconferencing_12 = "12",
	Youthjusticeservice_11 = "11",
	Youthjusticeservicecentre_10 = "10",
	Zone_5 = "5"
}

export interface IDepartmentUnitCodeGroupDescriptor { code: DepartmentUnitCode; description: string; shortDescription: string; }
export var DepartmentUnitCodeGroup: IDepartmentUnitCodeGroupDescriptor[] = [
	{ code: DepartmentUnitCode.Courtservice_15, description: `Court service`, shortDescription: `Court service` },
	{ code: DepartmentUnitCode.ChildSafetyServiceCentre_6, description: `Child Safety Service Centre`, shortDescription: `CSSC` },
	{ code: DepartmentUnitCode.Datamanagement_16, description: `Data management`, shortDescription: `Data management` },
	{ code: DepartmentUnitCode.Department_7, description: `Department`, shortDescription: `Department` },
	{ code: DepartmentUnitCode.Other_99, description: `Other`, shortDescription: `Other` },
	{ code: DepartmentUnitCode.Regionaloffice_9, description: `Regional office`, shortDescription: `Regional office` },
	{ code: DepartmentUnitCode.Youthdetentioncentre_14, description: `Youth detention centre`, shortDescription: `Youth detention centre` },
	{ code: DepartmentUnitCode.Youthdetentionoperation_13, description: `Youth detention operation`, shortDescription: `Youth detention operation` },
	{ code: DepartmentUnitCode.Youthjusticecentraloffice_8, description: `Youth justice central office`, shortDescription: `Youth justice central office` },
	{ code: DepartmentUnitCode.Youthjusticeconferencing_12, description: `Youth justice conferencing`, shortDescription: `Youth justice conferencing` },
	{ code: DepartmentUnitCode.Youthjusticeservice_11, description: `Youth justice service`, shortDescription: `Youth justice service` },
	{ code: DepartmentUnitCode.Youthjusticeservicecentre_10, description: `Youth justice service centre`, shortDescription: `Youth justice service centre` },
	{ code: DepartmentUnitCode.Zone_5, description: `Zone`, shortDescription: `Zone` }
];

export function getDepartmentUnitCodeGroupDescriptor(DepartmentUnitCodeCode: string) : IDepartmentUnitCodeGroupDescriptor {
	for(var i = 0; i < DepartmentUnitCodeGroup.length; i++)
	{
		if(DepartmentUnitCodeGroup[i].code == DepartmentUnitCodeCode)
		{
			return DepartmentUnitCodeGroup[i];
		}
	}
	return null;
}


export enum EventRole {
	Allegedpersonresponsible_10048 = "10048",
	Anotherentitystaffmember_20000 = "20000",
	Approvedcarer_10045 = "10045",
	Approvedcarerentity_10061 = "10061",
	Approvedfostercarer_20001 = "20001",
	Aunt_20002 = "20002",
	Brother_20004 = "20004",
	Brotherinlaw_20003 = "20003",
	Carerapplicant_10003 = "10003",
	Carerentity_10054 = "10054",
	Carerschild_20005 = "20005",
	Carersrelative_20008 = "20008",
	Childofsubjectchild_20006 = "20006",
	Childscommunity_20047 = "20047",
	Contact_10001 = "10001",
	Cousin_20007 = "20007",
	Departmentalcareservice_10062 = "10062",
	Detentioncentre_10056 = "10056",
	Father_20009 = "20009",
	Fatherspartner_20010 = "20010",
	FosterCarer_10008 = "10008",
	Friend_20011 = "20011",
	Grandfather_20013 = "20013",
	Grandmother_20014 = "20014",
	Greataunt_20012 = "20012",
	Greatuncle_20016 = "20016",
	Guardian_10009 = "10009",
	Halfbrother_20017 = "20017",
	Halfsister_20018 = "20018",
	Carerhouseholdmember_10004 = "10004",
	Kinshipcarer_10010 = "10010",
	Licensedcareservicepersonnel_10007 = "10007",
	LCSAnotherentity_10053 = "10053",
	Limitedapprovalcarer_10040 = "10040",
	LimitedApprovalCarer_10021 = "10021",
	LimitedApprovalProvisionallyApprovedCarer_20019 = "20019",
	Linkedsubjectchild_10058 = "10058",
	Longtermguardian_20048 = "20048",
	Mother_20022 = "20022",
	Motherspartner_20023 = "20023",
	Neighbour_20024 = "20024",
	Nephew_20025 = "20025",
	Niece_20026 = "20026",
	Norelationship_20027 = "20027",
	Nosubjectchildinvolved_20028 = "20028",
	NotSpecified_0 = "0",
	Otheradult_10046 = "10046",
	Otherchild_10047 = "10047",
	Otheroutofhomecarer_20046 = "20046",
	Otherrelative_20030 = "20030",
	Parent_10014 = "10014",
	Partner_20031 = "20031",
	Permanentguardian_20049 = "20049",
	Personresponsible_10049 = "10049",
	Preapplicant_10002 = "10002",
	Provisionallyapprovedcarer_10038 = "10038",
	ProvisionallyApprovedCarer_10011 = "10011",
	RelativeCarerandorApprovedKinshipCarer_20032 = "20032",
	RelativeCarer_10012 = "10012",
	Residentialcarehouseparentcarer_20034 = "20034",
	Residentialcareotheremployee_20033 = "20033",
	Siblingwheregenderunknown_20038 = "20038",
	Sister_20040 = "20040",
	Sisterinlaw_20039 = "20039",
	Staffmember_10059 = "10059",
	Stepbrother_20035 = "20035",
	Stepchild_20043 = "20043",
	Stepfather_20037 = "20037",
	Stepmother_20041 = "20041",
	Stepsister_20042 = "20042",
	Subjectchild_10013 = "10013",
	Uncle_20044 = "20044",
	Unknown_20045 = "20045",
	Watchhouse_10057 = "10057",
	Youngperson_10055 = "10055"
}

export interface IEventRoleGroupDescriptor { code: EventRole; description: string; shortDescription: string; }
export var EventRoleGroup: IEventRoleGroupDescriptor[] = [
	{ code: EventRole.Allegedpersonresponsible_10048, description: `Alleged person responsible`, shortDescription: `Alleged person responsible` },
	{ code: EventRole.Anotherentitystaffmember_20000, description: `Another entity staff member`, shortDescription: `Another entity staff member` },
	{ code: EventRole.Approvedcarer_10045, description: `Approved carer`, shortDescription: `Approved carer` },
	{ code: EventRole.Approvedcarerentity_10061, description: `Approved carer entity`, shortDescription: `Approved carer entity` },
	{ code: EventRole.Approvedfostercarer_20001, description: `Approved foster carer`, shortDescription: `Approved foster carer` },
	{ code: EventRole.Aunt_20002, description: `Aunt`, shortDescription: `Aunt` },
	{ code: EventRole.Brother_20004, description: `Brother`, shortDescription: `Brother` },
	{ code: EventRole.Brotherinlaw_20003, description: `Brother-in-law`, shortDescription: `Brother-in-law` },
	{ code: EventRole.Carerapplicant_10003, description: `Carer applicant`, shortDescription: `Carer applicant` },
	{ code: EventRole.Carerentity_10054, description: `Carer entity`, shortDescription: `Carer entity` },
	{ code: EventRole.Carerschild_20005, description: `Carer's child`, shortDescription: `Carer's child` },
	{ code: EventRole.Carersrelative_20008, description: `Carer's relative`, shortDescription: `Carer's relative` },
	{ code: EventRole.Childofsubjectchild_20006, description: `Child of subject child`, shortDescription: `Child of subject child` },
	{ code: EventRole.Childscommunity_20047, description: `Childs community`, shortDescription: `Childs community` },
	{ code: EventRole.Contact_10001, description: `Contact`, shortDescription: `Contact` },
	{ code: EventRole.Cousin_20007, description: `Cousin`, shortDescription: `Cousin` },
	{ code: EventRole.Departmentalcareservice_10062, description: `Departmental care service`, shortDescription: `Departmental care service` },
	{ code: EventRole.Detentioncentre_10056, description: `Detention centre`, shortDescription: `Detention centre` },
	{ code: EventRole.Father_20009, description: `Father`, shortDescription: `Father` },
	{ code: EventRole.Fatherspartner_20010, description: `Father's partner`, shortDescription: `Father's partner` },
	{ code: EventRole.FosterCarer_10008, description: `Foster Carer`, shortDescription: `Foster Carer` },
	{ code: EventRole.Friend_20011, description: `Friend`, shortDescription: `Friend` },
	{ code: EventRole.Grandfather_20013, description: `Grandfather`, shortDescription: `Grandfather` },
	{ code: EventRole.Grandmother_20014, description: `Grandmother`, shortDescription: `Grandmother` },
	{ code: EventRole.Greataunt_20012, description: `Great aunt`, shortDescription: `Great aunt` },
	{ code: EventRole.Greatuncle_20016, description: `Great uncle`, shortDescription: `Great uncle` },
	{ code: EventRole.Guardian_10009, description: `Guardian`, shortDescription: `Guardian` },
	{ code: EventRole.Halfbrother_20017, description: `Half-brother`, shortDescription: `Half-brother` },
	{ code: EventRole.Halfsister_20018, description: `Half-sister`, shortDescription: `Half-sister` },
	{ code: EventRole.Carerhouseholdmember_10004, description: `Carer household member`, shortDescription: `Household member` },
	{ code: EventRole.Kinshipcarer_10010, description: `Kinship carer`, shortDescription: `Kinship carer` },
	{ code: EventRole.Licensedcareservicepersonnel_10007, description: `Licensed care service personnel`, shortDescription: `LCS Personnel` },
	{ code: EventRole.LCSAnotherentity_10053, description: `LCS/Another entity`, shortDescription: `LCS/Another entity` },
	{ code: EventRole.Limitedapprovalcarer_10040, description: `Limited approval carer`, shortDescription: `Limited Approval` },
	{ code: EventRole.LimitedApprovalCarer_10021, description: `Limited Approval Carer`, shortDescription: `Limited Approval Carer` },
	{ code: EventRole.LimitedApprovalProvisionallyApprovedCarer_20019, description: `Limited Approval/Provisionally Approved Carer`, shortDescription: `Limited/Provisional Carer` },
	{ code: EventRole.Linkedsubjectchild_10058, description: `Linked subject child`, shortDescription: `Linked subject child` },
	{ code: EventRole.Longtermguardian_20048, description: `Long-term guardian`, shortDescription: `Long-term guardian` },
	{ code: EventRole.Mother_20022, description: `Mother`, shortDescription: `Mother` },
	{ code: EventRole.Motherspartner_20023, description: `Mother's partner`, shortDescription: `Mother's partner` },
	{ code: EventRole.Neighbour_20024, description: `Neighbour`, shortDescription: `Neighbour` },
	{ code: EventRole.Nephew_20025, description: `Nephew`, shortDescription: `Nephew` },
	{ code: EventRole.Niece_20026, description: `Niece`, shortDescription: `Niece` },
	{ code: EventRole.Norelationship_20027, description: `No relationship`, shortDescription: `No relationship` },
	{ code: EventRole.Nosubjectchildinvolved_20028, description: `No subject child involved`, shortDescription: `No subject child involved` },
	{ code: EventRole.NotSpecified_0, description: `NotSpecified`, shortDescription: `NotSpecified` },
	{ code: EventRole.Otheradult_10046, description: `Other adult`, shortDescription: `Other adult` },
	{ code: EventRole.Otherchild_10047, description: `Other child`, shortDescription: `Other child` },
	{ code: EventRole.Otheroutofhomecarer_20046, description: `Other out-of-home carer`, shortDescription: `Other out-of-home carer` },
	{ code: EventRole.Otherrelative_20030, description: `Other relative`, shortDescription: `Other relative` },
	{ code: EventRole.Parent_10014, description: `Parent`, shortDescription: `Parent` },
	{ code: EventRole.Partner_20031, description: `Partner`, shortDescription: `Partner` },
	{ code: EventRole.Permanentguardian_20049, description: `Permanent guardian`, shortDescription: `Permanent guardian` },
	{ code: EventRole.Personresponsible_10049, description: `Person responsible`, shortDescription: `Person responsible` },
	{ code: EventRole.Preapplicant_10002, description: `Pre-applicant`, shortDescription: `Pre-applicant` },
	{ code: EventRole.Provisionallyapprovedcarer_10038, description: `Provisionally approved carer`, shortDescription: `Provisional Approval` },
	{ code: EventRole.ProvisionallyApprovedCarer_10011, description: `Provisionally Approved Carer`, shortDescription: `Provisionally Approved Carer` },
	{ code: EventRole.RelativeCarerandorApprovedKinshipCarer_20032, description: `Relative Carer and/or Approved Kinship Carer`, shortDescription: `Relative and/or Kinship carer` },
	{ code: EventRole.RelativeCarer_10012, description: `Relative Carer`, shortDescription: `Relative Carer` },
	{ code: EventRole.Residentialcarehouseparentcarer_20034, description: `Residential care - house-parent/carer`, shortDescription: `Residential care - carer` },
	{ code: EventRole.Residentialcareotheremployee_20033, description: `Residential care - other employee`, shortDescription: `Residential care employee` },
	{ code: EventRole.Siblingwheregenderunknown_20038, description: `Sibling (where gender unknown)`, shortDescription: `Sibling (where gender unknown)` },
	{ code: EventRole.Sister_20040, description: `Sister`, shortDescription: `Sister` },
	{ code: EventRole.Sisterinlaw_20039, description: `Sister-in-law`, shortDescription: `Sister-in-law` },
	{ code: EventRole.Staffmember_10059, description: `Staff member`, shortDescription: `Staff member` },
	{ code: EventRole.Stepbrother_20035, description: `Stepbrother`, shortDescription: `Stepbrother` },
	{ code: EventRole.Stepchild_20043, description: `Stepchild`, shortDescription: `Stepchild` },
	{ code: EventRole.Stepfather_20037, description: `Stepfather`, shortDescription: `Stepfather` },
	{ code: EventRole.Stepmother_20041, description: `Stepmother`, shortDescription: `Stepmother` },
	{ code: EventRole.Stepsister_20042, description: `Stepsister`, shortDescription: `Stepsister` },
	{ code: EventRole.Subjectchild_10013, description: `Subject child`, shortDescription: `Subject child` },
	{ code: EventRole.Uncle_20044, description: `Uncle`, shortDescription: `Uncle` },
	{ code: EventRole.Unknown_20045, description: `Unknown`, shortDescription: `Unknown` },
	{ code: EventRole.Watchhouse_10057, description: `Watchhouse`, shortDescription: `Watchhouse` },
	{ code: EventRole.Youngperson_10055, description: `Young person`, shortDescription: `Young person` }
];

export function getEventRoleGroupDescriptor(EventRoleCode: string) : IEventRoleGroupDescriptor {
	for(var i = 0; i < EventRoleGroup.length; i++)
	{
		if(EventRoleGroup[i].code == EventRoleCode)
		{
			return EventRoleGroup[i];
		}
	}
	return null;
}


export enum EventStatus {
	Closed_7 = "7",
	Deleted_8 = "8",
	Inactive_9 = "9",
	Notstarted_5 = "5",
	Open_6 = "6",
	ReOpened_99 = "99"
}

export interface IEventStatusGroupDescriptor { code: EventStatus; description: string; shortDescription: string; }
export var EventStatusGroup: IEventStatusGroupDescriptor[] = [
	{ code: EventStatus.Closed_7, description: `Closed`, shortDescription: `Closed` },
	{ code: EventStatus.Deleted_8, description: `Deleted`, shortDescription: `Deleted` },
	{ code: EventStatus.Inactive_9, description: `Inactive`, shortDescription: `Inactive` },
	{ code: EventStatus.Notstarted_5, description: `Not started`, shortDescription: `Not started` },
	{ code: EventStatus.Open_6, description: `Open`, shortDescription: `Open` },
	{ code: EventStatus.ReOpened_99, description: `ReOpened`, shortDescription: `ReOpened` }
];

export function getEventStatusGroupDescriptor(EventStatusCode: string) : IEventStatusGroupDescriptor {
	for(var i = 0; i < EventStatusGroup.length; i++)
	{
		if(EventStatusGroup[i].code == EventStatusCode)
		{
			return EventStatusGroup[i];
		}
	}
	return null;
}


export enum EventType {
	Application_17001 = "17001",
	Arrest_16500 = "16500",
	Bail_17007 = "17007",
	CareService_17100 = "17100",
	Caseplan_17000 = "17000",
	Childprotectionorder_15700 = "15700",
	Courtassessmentorder_15200 = "15200",
	CPHistory_100009 = "100009",
	Suiciderisk_16000 = "16000",
	Detention_16100 = "16100",
	Detention_17008 = "17008",
	Escapealert_16400 = "16400",
	HarmreportIA_100013 = "100013",
	Intake_15300 = "15300",
	Intake_17005 = "17005",
	Investigationassessment_15500 = "15500",
	Life_17002 = "17002",
	Matterofconcern_15400 = "15400",
	MOCIA_15600 = "15600",
	MonitorandSupport_100011 = "100011",
	Movement_16600 = "16600",
	Ongoingintervention_15800 = "15800",
	Placement_15900 = "15900",
	Presentencereport_17004 = "17004",
	Standardsofcare_100012 = "100012",
	Support_17006 = "17006",
	Temporaryassessmentorder_15100 = "15100",
	Temporarycustodyorder_100010 = "100010",
	Transfer_17003 = "17003",
	Watchhouse_16200 = "16200",
	Watchhouse_17009 = "17009",
	Suiciderisk_16300 = "16300"
}

export interface IEventTypeGroupDescriptor { code: EventType; description: string; shortDescription: string; }
export var EventTypeGroup: IEventTypeGroupDescriptor[] = [
	{ code: EventType.Application_17001, description: `Application`, shortDescription: `Application` },
	{ code: EventType.Arrest_16500, description: `Arrest`, shortDescription: `Arrest` },
	{ code: EventType.Bail_17007, description: `Bail`, shortDescription: `Bail` },
	{ code: EventType.CareService_17100, description: `Care Service`, shortDescription: `Care Service` },
	{ code: EventType.Caseplan_17000, description: `Case plan`, shortDescription: `Case plan` },
	{ code: EventType.Childprotectionorder_15700, description: `Child protection order`, shortDescription: `Child protection order` },
	{ code: EventType.Courtassessmentorder_15200, description: `Court assessment order`, shortDescription: `Court assessment order` },
	{ code: EventType.CPHistory_100009, description: `CP History`, shortDescription: `CP History` },
	{ code: EventType.Suiciderisk_16000, description: `Suicide risk`, shortDescription: `CP Suicide risk` },
	{ code: EventType.Detention_16100, description: `Detention`, shortDescription: `Detention` },
	{ code: EventType.Detention_17008, description: `Detention`, shortDescription: `Detention` },
	{ code: EventType.Escapealert_16400, description: `Escape alert`, shortDescription: `Escape alert` },
	{ code: EventType.HarmreportIA_100013, description: `Harm report - I&A`, shortDescription: `Harm report - I&A` },
	{ code: EventType.Intake_15300, description: `Intake`, shortDescription: `Intake` },
	{ code: EventType.Intake_17005, description: `Intake`, shortDescription: `Intake` },
	{ code: EventType.Investigationassessment_15500, description: `Investigation & assessment`, shortDescription: `Investigation & assessment` },
	{ code: EventType.Life_17002, description: `Life`, shortDescription: `Life` },
	{ code: EventType.Matterofconcern_15400, description: `Matter of concern`, shortDescription: `Matter of concern` },
	{ code: EventType.MOCIA_15600, description: `MOC - I&A`, shortDescription: `MOC - I&A` },
	{ code: EventType.MonitorandSupport_100011, description: `Monitor and Support`, shortDescription: `Monitor and Support` },
	{ code: EventType.Movement_16600, description: `Movement`, shortDescription: `Movement` },
	{ code: EventType.Ongoingintervention_15800, description: `Ongoing intervention`, shortDescription: `Ongoing intervention` },
	{ code: EventType.Placement_15900, description: `Placement`, shortDescription: `Placement` },
	{ code: EventType.Presentencereport_17004, description: `Pre-sentence report`, shortDescription: `Pre-sentence report` },
	{ code: EventType.Standardsofcare_100012, description: `Standards of care`, shortDescription: `Standards of care` },
	{ code: EventType.Support_17006, description: `Support`, shortDescription: `Support` },
	{ code: EventType.Temporaryassessmentorder_15100, description: `Temporary assessment order`, shortDescription: `Temporary assessment order` },
	{ code: EventType.Temporarycustodyorder_100010, description: `Temporary custody order`, shortDescription: `Temporary custody order` },
	{ code: EventType.Transfer_17003, description: `Transfer`, shortDescription: `Transfer` },
	{ code: EventType.Watchhouse_16200, description: `Watchhouse`, shortDescription: `Watchhouse` },
	{ code: EventType.Watchhouse_17009, description: `Watchhouse`, shortDescription: `Watchhouse` },
	{ code: EventType.Suiciderisk_16300, description: `Suicide risk`, shortDescription: `YJ Suicide risk` }
];

export function getEventTypeGroupDescriptor(EventTypeCode: string) : IEventTypeGroupDescriptor {
	for(var i = 0; i < EventTypeGroup.length; i++)
	{
		if(EventTypeGroup[i].code == EventTypeCode)
		{
			return EventTypeGroup[i];
		}
	}
	return null;
}


export enum FormInstanceStatus {
	Approved_12 = "12",
	Archived_11 = "11",
	Attached_23 = "23",
	Awaitingapproval_7 = "7",
	AwaitingAuthorisation_20 = "20",
	AwaitingCompletion_17 = "17",
	AwaitingCourtServicesReview_19 = "19",
	Awaitingqualityassurancereview_24 = "24",
	AwaitingReview_18 = "18",
	Cannotbecompleted_9 = "9",
	ClosedbyMigration_14 = "14",
	Complete_8 = "8",
	Completed_22 = "22",
	Inprogress_6 = "6",
	New_5 = "5",
	Notapplicable_10 = "10",
	NotApproved_21 = "21",
	Reopened_16 = "16",
	Superceded_15 = "15",
	Workrequired_13 = "13"
}

export interface IFormInstanceStatusGroupDescriptor { code: FormInstanceStatus; description: string; shortDescription: string; }
export var FormInstanceStatusGroup: IFormInstanceStatusGroupDescriptor[] = [
	{ code: FormInstanceStatus.Approved_12, description: `Approved`, shortDescription: `Approved` },
	{ code: FormInstanceStatus.Archived_11, description: `Archived`, shortDescription: `Archived` },
	{ code: FormInstanceStatus.Attached_23, description: `Attached`, shortDescription: `Attached` },
	{ code: FormInstanceStatus.Awaitingapproval_7, description: `Awaiting approval`, shortDescription: `Awaiting approval` },
	{ code: FormInstanceStatus.AwaitingAuthorisation_20, description: `Awaiting Authorisation`, shortDescription: `Awaiting Authorisation` },
	{ code: FormInstanceStatus.AwaitingCompletion_17, description: `Awaiting Completion`, shortDescription: `Awaiting Completion` },
	{ code: FormInstanceStatus.AwaitingCourtServicesReview_19, description: `Awaiting Court Services Review`, shortDescription: `Awaiting Court Services Review` },
	{ code: FormInstanceStatus.Awaitingqualityassurancereview_24, description: `Awaiting quality assurance review`, shortDescription: `Awaiting QA review` },
	{ code: FormInstanceStatus.AwaitingReview_18, description: `Awaiting Review`, shortDescription: `Awaiting Review` },
	{ code: FormInstanceStatus.Cannotbecompleted_9, description: `Cannot be completed`, shortDescription: `Cannot be completed` },
	{ code: FormInstanceStatus.ClosedbyMigration_14, description: `Closed by Migration`, shortDescription: `Closed by Migration` },
	{ code: FormInstanceStatus.Complete_8, description: `Complete`, shortDescription: `Complete` },
	{ code: FormInstanceStatus.Completed_22, description: `Completed`, shortDescription: `Completed` },
	{ code: FormInstanceStatus.Inprogress_6, description: `In progress`, shortDescription: `In progress` },
	{ code: FormInstanceStatus.New_5, description: `New`, shortDescription: `New` },
	{ code: FormInstanceStatus.Notapplicable_10, description: `Not applicable`, shortDescription: `Not applicable` },
	{ code: FormInstanceStatus.NotApproved_21, description: `Not Approved`, shortDescription: `Not Approved` },
	{ code: FormInstanceStatus.Reopened_16, description: `Reopened`, shortDescription: `Reopened` },
	{ code: FormInstanceStatus.Superceded_15, description: `Superceded`, shortDescription: `Superceded` },
	{ code: FormInstanceStatus.Workrequired_13, description: `Work required`, shortDescription: `Work required` }
];

export function getFormInstanceStatusGroupDescriptor(FormInstanceStatusCode: string) : IFormInstanceStatusGroupDescriptor {
	for(var i = 0; i < FormInstanceStatusGroup.length; i++)
	{
		if(FormInstanceStatusGroup[i].code == FormInstanceStatusCode)
		{
			return FormInstanceStatusGroup[i];
		}
	}
	return null;
}


export enum FormSetFormTypeUsage {
	AssessmentandoutcomeActionplan_15606 = "15606",
	AssessmentandoutcomeActionplan_100146 = "100146",
	AssessmentandoutcomeASCresponse_310191 = "310191",
	AssessmentandoutcomeAssessmentoutcome_15510 = "15510",
	AssessmentandoutcomeAssessmentoutcome_18019 = "18019",
	AssessmentandoutcomeAssessmentoutcome_31011 = "31011",
	AssessmentandoutcomeAssessmentoutcome_100125 = "100125",
	AssessmentandoutcomeCasenote_10014 = "10014",
	AssessmentandoutcomeCasenote_10018 = "10018",
	AssessmentandoutcomeClosureform_15515 = "15515",
	AssessmentandoutcomeClosureform_15611 = "15611",
	AssessmentandoutcomeClosureform_30019 = "30019",
	AssessmentandoutcomeClosureform_31000 = "31000",
	AssessmentandoutcomeClosureform_420015 = "420015",
	AssessmentandoutcomeClosureform_420016 = "420016",
	AssessmentandoutcomeClosureofMOCIA_15610 = "15610",
	AssessmentandoutcomeCPcasenote_30014 = "30014",
	AssessmentandoutcomeCPcasenote_115908 = "115908",
	AssessmentandoutcomeCPcasenote_115912 = "115912",
	AssessmentandoutcomeCPcasenote_3000016 = "3000016",
	AssessmentandoutcomeCPcasenote_3000020 = "3000020",
	AssessmentandoutcomeFamilyriskevaluation_15509 = "15509",
	AssessmentandoutcomeFamilyriskevaluation_18003 = "18003",
	AssessmentandoutcomeFamilyriskevaluation_31014 = "31014",
	AssessmentandoutcomeFamilyriskevaluation_100123 = "100123",
	AssessmentandoutcomeFamilyriskevaluation_100139 = "100139",
	AssessmentandoutcomeFamilyriskevaluation_310181 = "310181",
	AssessmentandoutcomeHarmreportassessmentoutcome_30015 = "30015",
	AssessmentandoutcomeHarmreportactionplan_30016 = "30016",
	AssessmentandoutcomeIndependententity_300251 = "300251",
	AssessmentandoutcomeInformationProvision_15607 = "15607",
	AssessmentandoutcomeInformationprovision_30017 = "30017",
	AssessmentandoutcomeInformationProvision_115991 = "115991",
	AssessmentandoutcomeInformationprovisiontoparents_15511 = "15511",
	AssessmentandoutcomeInformationprovisiontoparents_31012 = "31012",
	AssessmentandoutcomeInformationprovisiontoparents_100126 = "100126",
	AssessmentandoutcomeMOCAssessmentoutcome_15605 = "15605",
	AssessmentandoutcomeMOCAssessmentoutcome_18013 = "18013",
	AssessmentandoutcomeRecognisedentityparticipation_15512 = "15512",
	AssessmentandoutcomeRecognisedentityparticipation_15608 = "15608",
	AssessmentandoutcomeRecognisedentityparticipation_18009 = "18009",
	AssessmentandoutcomeRecognisedentityparticipation_18011 = "18011",
	AssessmentandoutcomeRecognisedentityparticipation_30018 = "30018",
	AssessmentandoutcomeRecognisedentityparticipation_30022 = "30022",
	AssessmentandoutcomeSafetyassessment_15508 = "15508",
	AssessmentandoutcomeSafetyassessment_18001 = "18001",
	AssessmentandoutcomeSafetyassessment_31006 = "31006",
	BailprogramAttachment_115938 = "115938",
	BailprogramConditionalbailprogramcompletion_115936 = "115936",
	BailprogramConditionalbailprogramcompletion_405301 = "405301",
	BailprogramConditionalbailprogramreferralproposal_115933 = "115933",
	BailprogramConditionalbailprogramreferralproposal_405401 = "405401",
	BailprogramConditionalbailprogramreferralproposaloutcome_115934 = "115934",
	BailprogramConditionalbailprogramreferralproposaloutcome_405101 = "405101",
	BailprogramTransitionreferral_115935 = "115935",
	BailprogramTransitionreferral_405201 = "405201",
	BailprogramYJcasenote_115937 = "115937",
	BailprogramYJcasenote_1000180 = "1000180",
	BailprogramYJcasenote_4101036 = "4101036",
	BailsupportAttachment_115942 = "115942",
	BailsupportBailsupportcompletion_115940 = "115940",
	BailsupportBailsupportcompletion_405501 = "405501",
	BailsupportBailsupportreferral_115939 = "115939",
	BailsupportBailsupportreferral_405601 = "405601",
	BailsupportYJcasenote_115941 = "115941",
	BailsupportYJcasenote_1000181 = "1000181",
	BailsupportYJcasenote_4101037 = "4101037",
	CourtassessmentordersExtendvaryrevokeCasenote_10003 = "10003",
	CourtassessmentordersExtendvaryrevokeClosureform_15220 = "15220",
	CourtassessmentordersExtendvaryrevokeClosureform_420012 = "420012",
	CourtassessmentordersExtendvaryrevokeClosureofCAO_15218 = "15218",
	CourtassessmentordersExtendvaryrevokeCPcasenote_115897 = "115897",
	CourtassessmentordersExtendvaryrevokeCPcasenote_300005 = "300005",
	CourtassessmentordersExtendvaryrevokeForm17_15217 = "15217",
	CourtassessmentordersExtendvaryrevokeForm6ApplicationtoextendvaryrevokeaCAO_15208 = "15208",
	CourtassessmentordersExtendvaryrevokeForm6COFfinalorder_15209 = "15209",
	CourtassessmentordersExtendvaryrevokeForm6COFfinalorder_100096 = "100096",
	CourtassessmentordersExtendvaryrevokeForm9COFadjournmentorder_15206 = "15206",
	CourtassessmentordersExtendvaryrevokeForm9COFadjournmentorder_100099 = "100099",
	CaseclosureCaseclosure_115816 = "115816",
	CaseclosureCaseclosure_400601 = "400601",
	CaseclosureYJcasenote_115824 = "115824",
	CaseclosureYJcasenote_1000161 = "1000161",
	CaseclosureYJcasenote_4101017 = "4101017",
	CasereviewsCasereviewYDC_115814 = "115814",
	CasereviewsCasereviewYDC_1000139 = "1000139",
	CasereviewsCasereviewYDC_1000201 = "1000201",
	CasereviewsCasereviewYJSC_115826 = "115826",
	CasereviewsCasereviewYJSC_1000140 = "1000140",
	CasereviewsCasereviewYJSC_1000202 = "1000202",
	CasereviewsYJcasenote_115822 = "115822",
	CasereviewsYJcasenote_1000159 = "1000159",
	CasereviewsYJcasenote_4101015 = "4101015",
	CasereviewsYouthJusticecasereview_400401 = "400401",
	ChildprotectionordersCasenote_10019 = "10019",
	ChildprotectionordersClosureform_15714 = "15714",
	ChildprotectionordersClosureform_420017 = "420017",
	ChildprotectionordersClosureofCPO_15713 = "15713",
	ChildprotectionordersCPcasenote_115913 = "115913",
	ChildprotectionordersCPcasenote_3000021 = "3000021",
	ChildprotectionordersForm10ApplicationforaCPO_15700 = "15700",
	ChildprotectionordersForm10COFfinalorder_15701 = "15701",
	ChildprotectionordersForm10COFfinalorder_100122 = "100122",
	ChildprotectionordersForm11ApplicationtoextendvaryrevokeaCPO_15702 = "15702",
	ChildprotectionordersForm11ApplicationtoextendvaryrevokeaCPO_100121 = "100121",
	ChildprotectionordersForm11COFfinalorder_15703 = "15703",
	ChildprotectionordersForm11COFfinalorder_100128 = "100128",
	ChildprotectionordersForm12ApplicationtorevokemakeanotherCPO_15704 = "15704",
	ChildprotectionordersForm12COFfinalorder_15705 = "15705",
	ChildprotectionordersForm12COFfinalorder_100109 = "100109",
	ChildprotectionordersForm16COFadjournmentorder_15710 = "15710",
	ChildprotectionordersForm16COFadjournmentorder_100124 = "100124",
	ChildprotectionordersForm17_15706 = "15706",
	ChildprotectionordersForm44COFtransitionorder_100129 = "100129",
	ClosureAttachment_115848 = "115848",
	ClosureAttachment_115867 = "115867",
	ClosureAttachment_115888 = "115888",
	ClosureAttachment_115945 = "115945",
	ClosureClosureform_16451 = "16451",
	ClosureClosureform_16505 = "16505",
	ClosureClosureform_16604 = "16604",
	ClosureClosureform_115846 = "115846",
	ClosureClosureform_115852 = "115852",
	ClosureClosureform_115865 = "115865",
	ClosureClosureform_115886 = "115886",
	ClosureClosureform_115943 = "115943",
	ClosureClosureform_410151 = "410151",
	ClosureClosureform_410152 = "410152",
	ClosureClosureform_460004 = "460004",
	ClosureClosureform_4101057 = "4101057",
	ClosureClosureform_4101058 = "4101058",
	ClosureClosureform_4200111 = "4200111",
	ClosureClosureform_4200112 = "4200112",
	ClosureClosureform_4200113 = "4200113",
	ClosureClosureform_4200114 = "4200114",
	ClosureClosureform_4200115 = "4200115",
	ClosureClosureform_4200116 = "4200116",
	ClosureClosureform_4200117 = "4200117",
	ClosureClosureform_4200119 = "4200119",
	ClosureClosureform_4200120 = "4200120",
	ClosureClosureform_4200121 = "4200121",
	ClosureClosureform_4200122 = "4200122",
	ClosureYJcasenote_16452 = "16452",
	ClosureYJcasenote_16510 = "16510",
	ClosureYJcasenote_16609 = "16609",
	ClosureYJcasenote_115847 = "115847",
	ClosureYJcasenote_115853 = "115853",
	ClosureYJcasenote_115866 = "115866",
	ClosureYJcasenote_115887 = "115887",
	ClosureYJcasenote_115944 = "115944",
	ClosureYJcasenote_410103 = "410103",
	ClosureYJcasenote_410108 = "410108",
	ClosureYJcasenote_1000147 = "1000147",
	ClosureYJcasenote_1000152 = "1000152",
	ClosureYJcasenote_1000156 = "1000156",
	ClosureYJcasenote_1000168 = "1000168",
	ClosureYJcasenote_1000170 = "1000170",
	ClosureYJcasenote_1000174 = "1000174",
	ClosureYJcasenote_1000177 = "1000177",
	ClosureYJcasenote_1000182 = "1000182",
	ClosureYJcasenote_4101012 = "4101012",
	ClosureYJcasenote_4101024 = "4101024",
	ClosureYJcasenote_4101026 = "4101026",
	ClosureYJcasenote_4101030 = "4101030",
	ClosureYJcasenote_4101033 = "4101033",
	ClosureYJcasenote_4101038 = "4101038",
	ComplaintandsummonsAttachment_115833 = "115833",
	ComplaintandsummonsIssuecomplaintandsummons_100066 = "100066",
	ComplaintandsummonsIssuecomplaintandsummons_115830 = "115830",
	ComplaintandsummonsIssueComplaintandSummons_115987 = "115987",
	ComplaintandsummonsIssueComplaintandSummons_415201 = "415201",
	ComplaintandsummonsOathofservice_115831 = "115831",
	ComplaintandsummonsOathofservice_416101 = "416101",
	ComplaintandsummonsYJcasenote_115832 = "115832",
	ComplaintandsummonsYJcasenote_1000164 = "1000164",
	ComplaintandsummonsYJcasenote_4101020 = "4101020",
	ConsolidatedviewsCPcasenote_115950 = "115950",
	ConsolidatedviewsCPcasenote_115951 = "115951",
	ConsolidatedviewsCPcasenote_115952 = "115952",
	ConsolidatedviewsCPcasenote_115953 = "115953",
	ConsolidatedviewsCPcasenote_115954 = "115954",
	ConsolidatedviewsCPcasenote_115955 = "115955",
	ConsolidatedviewsCPcasenote_115956 = "115956",
	ConsolidatedviewsCPcasenote_115957 = "115957",
	ConsolidatedviewsCPcasenote_115958 = "115958",
	ConsolidatedviewsCPcasenote_115959 = "115959",
	ConsolidatedviewsCPcasenote_115963 = "115963",
	ConsolidatedviewsCPcasenote_115981 = "115981",
	ConsolidatedviewsCPcasenote_116004 = "116004",
	ConsolidatedviewsCPcasenote_3000036 = "3000036",
	ConsolidatedviewsCPcasenote_3000037 = "3000037",
	ConsolidatedviewsCPcasenote_3000038 = "3000038",
	ConsolidatedviewsCPcasenote_3000039 = "3000039",
	ConsolidatedviewsCPcasenote_3000040 = "3000040",
	ConsolidatedviewsCPcasenote_3000041 = "3000041",
	ConsolidatedviewsCPcasenote_3000042 = "3000042",
	ConsolidatedviewsCPcasenote_3000043 = "3000043",
	ConsolidatedviewsCPcasenote_3000044 = "3000044",
	ConsolidatedviewsCPcasenote_3000045 = "3000045",
	ConsolidatedviewsCPcasenote_3000046 = "3000046",
	ConsolidatedviewsCPcasenote_3000049 = "3000049",
	ConsolidatedviewsCPcasenote_3000053 = "3000053",
	ConsolidatedviewsYJcasenote_115960 = "115960",
	ConsolidatedviewsYJcasenote_115961 = "115961",
	ConsolidatedviewsYJcasenote_115962 = "115962",
	ConsolidatedviewsYJcasenote_115964 = "115964",
	ConsolidatedviewsYJcasenote_115965 = "115965",
	ConsolidatedviewsYJcasenote_115966 = "115966",
	ConsolidatedviewsYJcasenote_115967 = "115967",
	ConsolidatedviewsYJcasenote_115969 = "115969",
	ConsolidatedviewsYJcasenote_115970 = "115970",
	ConsolidatedviewsYJcasenote_115971 = "115971",
	ConsolidatedviewsYJcasenote_115972 = "115972",
	ConsolidatedviewsYJcasenote_115973 = "115973",
	ConsolidatedviewsYJcasenote_115974 = "115974",
	ConsolidatedviewsYJcasenote_115975 = "115975",
	ConsolidatedviewsYJcasenote_115980 = "115980",
	ConsolidatedviewsYJcasenote_1000183 = "1000183",
	ConsolidatedviewsYJcasenote_1000184 = "1000184",
	ConsolidatedviewsYJcasenote_1000185 = "1000185",
	ConsolidatedviewsYJcasenote_1000186 = "1000186",
	ConsolidatedviewsYJcasenote_1000187 = "1000187",
	ConsolidatedviewsYJcasenote_1000188 = "1000188",
	ConsolidatedviewsYJcasenote_1000189 = "1000189",
	ConsolidatedviewsYJcasenote_1000190 = "1000190",
	ConsolidatedviewsYJcasenote_1000191 = "1000191",
	ConsolidatedviewsYJcasenote_1000192 = "1000192",
	ConsolidatedviewsYJcasenote_1000193 = "1000193",
	ConsolidatedviewsYJcasenote_1000194 = "1000194",
	ConsolidatedviewsYJcasenote_1000195 = "1000195",
	ConsolidatedviewsYJcasenote_1000196 = "1000196",
	ConsolidatedviewsYJcasenote_1000199 = "1000199",
	ConsolidatedviewsYJcasenote_4101039 = "4101039",
	ConsolidatedviewsYJcasenote_4101040 = "4101040",
	ConsolidatedviewsYJcasenote_4101041 = "4101041",
	ConsolidatedviewsYJcasenote_4101042 = "4101042",
	ConsolidatedviewsYJcasenote_4101043 = "4101043",
	ConsolidatedviewsYJcasenote_4101044 = "4101044",
	ConsolidatedviewsYJcasenote_4101045 = "4101045",
	ConsolidatedviewsYJcasenote_4101046 = "4101046",
	ConsolidatedviewsYJcasenote_4101047 = "4101047",
	ConsolidatedviewsYJcasenote_4101048 = "4101048",
	ConsolidatedviewsYJcasenote_4101049 = "4101049",
	ConsolidatedviewsYJcasenote_4101050 = "4101050",
	ConsolidatedviewsYJcasenote_4101051 = "4101051",
	ConsolidatedviewsYJcasenote_4101052 = "4101052",
	ConsolidatedviewsYJcasenote_4101055 = "4101055",
	CourtassessmentordersCasenote_10002 = "10002",
	CourtassessmentordersClosureform_15219 = "15219",
	CourtassessmentordersClosureform_420011 = "420011",
	CourtassessmentordersClosureofCAO_15216 = "15216",
	CourtassessmentordersCPcasenote_115896 = "115896",
	CourtassessmentordersCPcasenote_300004 = "300004",
	CourtassessmentordersForm17_15202 = "15202",
	CourtassessmentordersForm5ApplicationforaCAO_15200 = "15200",
	CourtassessmentordersForm5COFfinalorder_15201 = "15201",
	CourtassessmentordersForm5COFfinalorder_100095 = "100095",
	CourtassessmentordersForm9COFadjournmentorder_15203 = "15203",
	CourtassessmentordersForm9COFadjournmentorder_1000200 = "1000200",
	ClosureCasenote_10024 = "10024",
	ClosureCPcasenote_115918 = "115918",
	ClosureCPcasenote_3000026 = "3000026",
	ClosureClosureform_16001 = "16001",
	ClosureClosureform_440011 = "440011",
	ClosureYJcasenote_115977 = "115977",
	ClosureYJcasenote_1000198 = "1000198",
	ClosureYJcasenote_4101054 = "4101054",
	SuicideCasenote_10023 = "10023",
	SuicideCPcasenote_115917 = "115917",
	SuicideCPcasenote_3000025 = "3000025",
	SuicideMediumtolongtermsuicideriskmanagementplan_16007 = "16007",
	SuicideMediumtolongtermsuicideriskmanagementplan_440002 = "440002",
	SuicideSuicideriskalertSR1_16000 = "16000",
	SuicideSuicideriskalertSR1_16008 = "16008",
	SuicideSuicideriskalertSR1_345201 = "345201",
	SuicideYJcasenote_115976 = "115976",
	SuicideYJcasenote_1000197 = "1000197",
	SuicideYJcasenote_4101053 = "4101053",
	CPCRAssessmentActionActionplan_15410 = "15410",
	CPCRAssessmentActionActionplan_100145 = "100145",
	CPCRAssessmentActionAssessmentplan_15409 = "15409",
	CPCRAssessmentActionAssessmentplan_100135 = "100135",
	CPCRAssessmentActionCasenote_10027 = "10027",
	CPCRAssessmentActionClosureform_15411 = "15411",
	CPCRAssessmentActionClosureform_420014 = "420014",
	CPCRAssessmentActionCPcasenote_115921 = "115921",
	CPCRAssessmentActionCPcasenote_3000028 = "3000028",
	DecisionmakingAdditionalnotifiedconcerns_15304 = "15304",
	DecisionmakingAdditionalnotifiedconcerns_18005 = "18005",
	DecisionmakingAdditionalnotifiedconcerns_31015 = "31015",
	DecisionmakingAdditionalnotifiedconcerns_100090 = "100090",
	DecisionmakingAdditionalnotifiedconcerns_100147 = "100147",
	DecisionmakingCasenote_10006 = "10006",
	DecisionmakingCPcasenote_115900 = "115900",
	DecisionmakingCPcasenote_300008 = "300008",
	DecisionmakingRecognisedentityparticipation_15313 = "15313",
	DecisionmakingRecognisedentityparticipation_18007 = "18007",
	DecisionmakingRecognisedentityparticipation_30021 = "30021",
	DecisionmakingScreeningcriteriaResponsepriority_31008 = "31008",
	DecisionmakingScreeningcriteriaResponsepriority_15303 = "15303",
	DecisionmakingScreeningcriteriaResponsepriority_18004 = "18004",
	AdministrationCasenote_10030 = "10030",
	AdministrationClosureform_16101 = "16101",
	AdministrationClosureform_420019 = "420019",
	AdministrationCPcasenote_115922 = "115922",
	AdministrationCPcasenote_3000029 = "3000029",
	AdministrationClosureofdetention_16100 = "16100",
	DepartmentalhistoryCasenote_10005 = "10005",
	DepartmentalhistoryCasenote_10009 = "10009",
	DepartmentalhistoryCasenote_10012 = "10012",
	DepartmentalhistoryCasenote_10016 = "10016",
	DepartmentalhistoryCasenote_10032 = "10032",
	DepartmentalhistoryCasenote_10033 = "10033",
	DepartmentalhistoryCasenote_10034 = "10034",
	DepartmentalhistoryCasenote_10035 = "10035",
	DepartmentalhistoryCasenote_10036 = "10036",
	DepartmentalhistoryCPcasenote_30000 = "30000",
	DepartmentalhistoryCPcasenote_30008 = "30008",
	DepartmentalhistoryCPcasenote_115889 = "115889",
	DepartmentalhistoryCPcasenote_115899 = "115899",
	DepartmentalhistoryCPcasenote_115903 = "115903",
	DepartmentalhistoryCPcasenote_115906 = "115906",
	DepartmentalhistoryCPcasenote_115910 = "115910",
	DepartmentalhistoryCPcasenote_115924 = "115924",
	DepartmentalhistoryCPcasenote_115925 = "115925",
	DepartmentalhistoryCPcasenote_115926 = "115926",
	DepartmentalhistoryCPcasenote_115927 = "115927",
	DepartmentalhistoryCPcasenote_115928 = "115928",
	DepartmentalhistoryCPcasenote_116000 = "116000",
	DepartmentalhistoryCPcasenote_300001 = "300001",
	DepartmentalhistoryCPcasenote_300007 = "300007",
	DepartmentalhistoryCPcasenote_3000011 = "3000011",
	DepartmentalhistoryCPcasenote_3000014 = "3000014",
	DepartmentalhistoryCPcasenote_3000018 = "3000018",
	DepartmentalhistoryCPcasenote_3000031 = "3000031",
	DepartmentalhistoryCPcasenote_3000032 = "3000032",
	DepartmentalhistoryCPcasenote_3000033 = "3000033",
	DepartmentalhistoryCPcasenote_3000034 = "3000034",
	DepartmentalhistoryCPcasenote_3000035 = "3000035",
	DepartmentalhistoryCPcasenote_3000050 = "3000050",
	DepartmentalhistoryDepartmentalhistory_15302 = "15302",
	DepartmentalhistoryDepartmentalhistory_15404 = "15404",
	DepartmentalhistoryDepartmentalhistory_15513 = "15513",
	DepartmentalhistoryDepartmentalhistory_15601 = "15601",
	EscapeEscapealert_16400 = "16400",
	EscapeEscapealert_420001 = "420001",
	EscapeYJcasenote_16401 = "16401",
	EscapeYJcasenote_410102 = "410102",
	EscapeYJcasenote_1000146 = "1000146",
	FormsandattachmentsYJcasenote_115820 = "115820",
	FormsandattachmentsYJcasenote_1000157 = "1000157",
	FormsandattachmentsYJcasenote_4101013 = "4101013",
	FormsandattachmentsYoungpersonsummary_115812 = "115812",
	FormsandattachmentsYoungpersonsummary_400703 = "400703",
	FormsandattachmentsYoungpersonsummary_460011 = "460011",
	IndividualdocumentsCasemanagement_15807 = "15807",
	IndividualdocumentsCasenote_10021 = "10021",
	IndividualdocumentsCaseplan_15800 = "15800",
	IndividualdocumentsCaseplan_17800 = "17800",
	IndividualdocumentsCaseplan_100064 = "100064",
	IndividualdocumentsCaseplan_100137 = "100137",
	IndividualdocumentsCaseplan_100141 = "100141",
	IndividualdocumentsCaseplan_325301 = "325301",
	IndividualdocumentsCaseplan_325311 = "325311",
	IndividualdocumentsChildstrengthsandneedsassessment_15810 = "15810",
	IndividualdocumentsChildstrengthsandneedsassessment_18014 = "18014",
	IndividualdocumentsChildstrengthsandneedsassessment_100143 = "100143",
	IndividualdocumentsChildstrengthsandneedsassessment_325901 = "325901",
	IndividualdocumentsClosureform_15812 = "15812",
	IndividualdocumentsClosureform_420018 = "420018",
	IndividualdocumentsClosureform_4101056 = "4101056",
	IndividualdocumentsClosureofongoingintervention_15811 = "15811",
	IndividualdocumentsCPcasenote_115915 = "115915",
	IndividualdocumentsCPcasenote_3000023 = "3000023",
	IndividualdocumentsIndependententity_300252 = "300252",
	IndividualdocumentsLTGtosuitablepersonCaseplan_100133 = "100133",
	IndividualdocumentsLTGtosuitablepersonCaseplan_100142 = "100142",
	IndividualdocumentsLTGtosuitablepersonCaseplan_325501 = "325501",
	IndividualdocumentsLTGtosuitablepersonContactandreviewreport_100132 = "100132",
	IndividualdocumentsLTGtosuitablepersonContactandreviewreport_325201 = "325201",
	IndividualdocumentsPCOCaseplan_327501 = "327501",
	IndividualdocumentsRecognisedentityparticipation_15808 = "15808",
	IndividualdocumentsRecognisedentityparticipation_18010 = "18010",
	IndividualdocumentsRecognisedentityparticipation_300201 = "300201",
	IndividualdocumentsReviewreport_15809 = "15809",
	IndividualdocumentsReviewreport_17806 = "17806",
	IndividualdocumentsReviewreport_100081 = "100081",
	IndividualdocumentsReviewreport_100159 = "100159",
	IndividualdocumentsReviewreport_327201 = "327201",
	IndividualdocumentsReviewreport_327211 = "327211",
	IndividualdocumentsTransitionplan_100134 = "100134",
	IndividualdocumentsTransitionplan_327301 = "327301",
	InformationgatheringandinterviewsCasenote_10013 = "10013",
	InformationgatheringandinterviewsCPcasenote_115907 = "115907",
	InformationgatheringandinterviewsCPcasenote_3000015 = "3000015",
	InformationgatheringandinterviewsInformationgathering_15503 = "15503",
	InformationgatheringandinterviewsInformationgathering_31005 = "31005",
	InformationgatheringandinterviewsRecordofinterviews_15504 = "15504",
	InformationgatheringandinterviewsRecordofinterviewsuseofpowers_31013 = "31013",
	InformationgatheringandinterviewsRecordofinterviewsuseofpowers_100131 = "100131",
	InformationgatheringandinterviewsRecordofuseofpowers_15506 = "15506",
	InformationgatheringandinterviewsCasenote_10017 = "10017",
	InformationgatheringandinterviewsCPcasenote_30012 = "30012",
	InformationgatheringandinterviewsCPcasenote_115911 = "115911",
	InformationgatheringandinterviewsCPcasenote_3000019 = "3000019",
	InformationgatheringandinterviewsRecordofchecksandconsultation_15602 = "15602",
	InformationgatheringandinterviewsRecordofinterviews_15603 = "15603",
	InformationgatheringandinterviewsRecordofinterviews_18012 = "18012",
	InformationgatheringandinterviewsRecordofinterviews_30013 = "30013",
	InformationgatheringDecisionmakingAdditionalnotifiedconcerns_15403 = "15403",
	InformationgatheringDecisionmakingAdditionalnotifiedconcerns_100098 = "100098",
	InformationgatheringDecisionmakingAdditionalnotifiedconcerns_100150 = "100150",
	InformationgatheringDecisionmakingCasenote_10008 = "10008",
	InformationgatheringDecisionmakingConcernsconsultationandresponsedecision_30002 = "30002",
	InformationgatheringDecisionmakingCPcasenote_30001 = "30001",
	InformationgatheringDecisionmakingCPcasenote_115902 = "115902",
	InformationgatheringDecisionmakingCPcasenote_3000010 = "3000010",
	InformationgatheringDecisionmakingRecognisedentityparticipation_15402 = "15402",
	InformationgatheringDecisionmakingRecognisedentityparticipation_18008 = "18008",
	InformationgatheringDecisionmakingRecognisedentityparticipation_30003 = "30003",
	InformationgatheringDecisionmakingRecordofchecksandconsultation_15401 = "15401",
	InformationgatheringDecisionmakingRecordofconcerns_15400 = "15400",
	InformationgatheringDecisionmakingRecordofconcerns_100097 = "100097",
	InformationgatheringDecisionmakingRecordofinterviews_18020 = "18020",
	InformationgatheringCasenote_10004 = "10004",
	InformationgatheringCPcasenote_115898 = "115898",
	InformationgatheringCPcasenote_300006 = "300006",
	InformationgatheringIntake_310201 = "310201",
	InformationgatheringPrenotificationchecks_15301 = "15301",
	InformationgatheringPrenotificationchecks_31001 = "31001",
	InformationgatheringRecognisedentityparticipation_15312 = "15312",
	InformationgatheringRecognisedentityparticipation_18006 = "18006",
	InformationgatheringRecognisedentityparticipation_30020 = "30020",
	InformationgatheringRecordofconcerns_15300 = "15300",
	InformationgatheringRecordofconcerns_31010 = "31010",
	InformationgatheringRecordofconcerns_100065 = "100065",
	InformationgatheringRecordofconcerns_100092 = "100092",
	InterviewAttachment_115878 = "115878",
	InterviewDrugtestingconditioninformation_115875 = "115875",
	InterviewDrugtestingconditioninformation_410071 = "410071",
	InterviewHealthchecklist_115872 = "115872",
	InterviewHealthchecklist_410081 = "410081",
	InterviewInformationtoparentsorcarer_100155 = "100155",
	InterviewInformationtoparentsorcarer_115873 = "115873",
	InterviewInformationtoparentsorcarer_115985 = "115985",
	InterviewInformationtoparentsorcarer_410091 = "410091",
	InterviewInitialinterviewYDC_115871 = "115871",
	InterviewInitialinterviewYDC_410051 = "410051",
	InterviewInitialinterviewYJSC_100152 = "100152",
	InterviewInitialinterviewYJSC_100158 = "100158",
	InterviewInitialinterviewYJSC_115870 = "115870",
	InterviewInitialinterviewYJSC_410041 = "410041",
	InterviewSupervisedreleaseorder_115874 = "115874",
	InterviewSupervisedreleaseorder_115982 = "115982",
	InterviewSupervisedreleaseorder_410441 = "410441",
	InterviewYJcasenote_115877 = "115877",
	InterviewYJcasenote_1000175 = "1000175",
	InterviewYJcasenote_4101031 = "4101031",
	InvestigationandassessmentplanningAdditionalnotifiedconcerns_15500 = "15500",
	InvestigationandassessmentplanningAdditionalnotifiedconcerns_18000 = "18000",
	InvestigationandassessmentplanningAdditionalnotifiedconcerns_31016 = "31016",
	InvestigationandassessmentplanningAdditionalnotifiedconcerns_100130 = "100130",
	InvestigationandassessmentplanningAdditionalnotifiedconcerns_100136 = "100136",
	InvestigationandassessmentplanningAdditionalnotifiedconcerns_100148 = "100148",
	InvestigationandassessmentplanningCasenote_10011 = "10011",
	InvestigationandassessmentplanningCPcasenote_115905 = "115905",
	InvestigationandassessmentplanningCPcasenote_3000013 = "3000013",
	InvestigationandassessmentplanningInvestigationandassessmentplan_15501 = "15501",
	InvestigationandassessmentplanningNotifiedconcernsResponsepriority_15514 = "15514",
	InvestigationandassessmentplanningNotifiedconcernsResponsepriority_31017 = "31017",
	InvestigationandassessmentplanningNotifiedconcernsResponsepriority_100149 = "100149",
	InvestigationandassessmentplanningRecordofactionsmobilefamily_15502 = "15502",
	InvestigationandassessmentplanningRecordofactionsmobilefamily_31004 = "31004",
	InvestigationandassessmentplanningAdditionalnotifiedconcerns_15604 = "15604",
	InvestigationandassessmentplanningAdditionalnotifiedconcerns_30011 = "30011",
	InvestigationandassessmentplanningAdditionalnotifiedconcerns_100151 = "100151",
	InvestigationandassessmentplanningAdditionalnotifiedconcerns_115990 = "115990",
	InvestigationandassessmentplanningCasenote_10015 = "10015",
	InvestigationandassessmentplanningCPcasenote_30009 = "30009",
	InvestigationandassessmentplanningCPcasenote_115909 = "115909",
	InvestigationandassessmentplanningCPcasenote_3000017 = "3000017",
	InvestigationandassessmentplanningHarmreportIAplan_30010 = "30010",
	InvestigationandassessmentplanningMOCInvestigationandassessmentplan_15600 = "15600",
	LegacyFormsAdditionalnotifiedconcerns_115304 = "115304",
	LegacyFormsAdditionalnotifiedconcerns_115403 = "115403",
	LegacyFormsAdditionalnotifiedconcerns_115500 = "115500",
	LegacyFormsAdditionalnotifiedconcerns_115604 = "115604",
	LegacyFormsCPSAssessmentofProtectiveNeeds_20000 = "20000",
	LegacyFormsCPSAssessmentofProtectiveNeedsPriorVersion_20001 = "20001",
	LegacyFormsCPSAssessmentofProtectiveNeeds_20002 = "20002",
	LegacyFormsCPSAssessmentofProtectiveNeedsPriorVersion_20003 = "20003",
	LegacyFormsCPSAuthoritytoCareforChild_20009 = "20009",
	LegacyFormsCPSApplicationCAO_20034 = "20034",
	LegacyFormsCPSApplicationCAOPriorVersion_20035 = "20035",
	LegacyFormsCPSApplicationCAOExtend_20072 = "20072",
	LegacyFormsCPSApplicationCAOExtendPriorVersion_20076 = "20076",
	LegacyFormsCPSApplicationCAOExtendVary_20074 = "20074",
	LegacyFormsCPSApplicationCAOExtendVaryPriorVersion_20078 = "20078",
	LegacyFormsCPSLettertoParentsCAO_20036 = "20036",
	LegacyFormsCPSLettertoParentsCAOPriorVersion_20037 = "20037",
	LegacyFormsCPSApplicationCAORevoke_20075 = "20075",
	LegacyFormsCPSApplicationCAORevokePriorVersion_20079 = "20079",
	LegacyFormsCPSApplicationCAOVary_20073 = "20073",
	LegacyFormsCPSApplicationCAOVaryPriorVersion_20077 = "20077",
	LegacyFormsCPSCaseManagement_20040 = "20040",
	LegacyFormsCasemanagement_115809 = "115809",
	LegacyFormsCasenote_10025 = "10025",
	LegacyFormsCPSCaseNote_20010 = "20010",
	LegacyFormsCPSCaseNotePriorVersion_20011 = "20011",
	LegacyFormsCPSCaseNote_20012 = "20012",
	LegacyFormsCPSCaseNotePriorVersion_20013 = "20013",
	LegacyFormsCPSCasePlan_20041 = "20041",
	LegacyFormsCPSCasePlanPriorVersion_20043 = "20043",
	LegacyFormsCPSCasePlanOutcomeAction_20044 = "20044",
	LegacyFormsCPSCaseWork_20045 = "20045",
	LegacyFormsCPSChildConcernReport_20024 = "20024",
	LegacyFormsCPSChildConcernReportPriorVersion_20025 = "20025",
	LegacyFormsChildstrengthsandneedsassessment_115810 = "115810",
	LegacyFormsClosureofongoingintervention_115811 = "115811",
	LegacyFormsApplicationforCourtOrder_20097 = "20097",
	LegacyFormsCourtOutcome_20098 = "20098",
	LegacyFormsCOF_20047 = "20047",
	LegacyFormsCPSChildPlacementConcernReport_20029 = "20029",
	LegacyFormsCPSChildPlacementConcernReportPriorVersion_20030 = "20030",
	LegacyFormsCPSApplicationCPO_20048 = "20048",
	LegacyFormsCPSApplicationCPOPriorVersion_20049 = "20049",
	LegacyFormsCPSApplicationCPOExtend_20080 = "20080",
	LegacyFormsCPSApplicationCPOExtendPriorVersion_20085 = "20085",
	LegacyFormsCPSApplicationCPOExtendVary_20082 = "20082",
	LegacyFormsCPSApplicationCPOExtendVaryPriorVersion_20087 = "20087",
	LegacyFormsCPSLettertoParentsCPO_20050 = "20050",
	LegacyFormsCPSLettertoParentsCPOPriorVersion_20051 = "20051",
	LegacyFormsCPSApplicationCPORevoke_20083 = "20083",
	LegacyFormsCPSApplicationCPORevokePriorVersion_20088 = "20088",
	LegacyFormsCPSApplicationCPORevokeNewOrder_20084 = "20084",
	LegacyFormsCPSApplicationCPORevokeNewOrderPriorVer_20089 = "20089",
	LegacyFormsCPSApplicationCPOVary_20081 = "20081",
	LegacyFormsCPSApplicationCPOVaryPriorVersion_20086 = "20086",
	LegacyFormsCPSExternalFileReference_20026 = "20026",
	LegacyFormsFamilyreunificationassessment_115804 = "115804",
	LegacyFormsFamilyreunificationassessment_327402 = "327402",
	LegacyFormsFamilyreunificationassessment_327412 = "327412",
	LegacyFormsFamilyreunificationassessment_327422 = "327422",
	LegacyFormsFamilyriskevaluation_115509 = "115509",
	LegacyFormsFamilyriskreevaluationforinhomecases_115803 = "115803",
	LegacyFormsCPSInitialAssessment_20031 = "20031",
	LegacyFormsCPSInitialAssessmentPriorVersion_20032 = "20032",
	LegacyFormsCPSInitialAssessmentPriorVersion_20090 = "20090",
	LegacyFormsCPSIntake_20022 = "20022",
	LegacyFormsCPSIntakePriorVersion_20023 = "20023",
	LegacyFormsCPSInvestigationandAssessment_20033 = "20033",
	LegacyFormsCPSInvestigationandAssessmentPriorVersion_20071 = "20071",
	LegacyFormsCPSActionPlanMOCCPCR_20055 = "20055",
	LegacyFormsCPSActionPlanMOCCPCRPriorVersion_20056 = "20056",
	LegacyFormsCPSActionPlanMOCIA_20091 = "20091",
	LegacyFormsCPSActionPlanMOCIAPriorVersion_20092 = "20092",
	LegacyFormsCPSAssessmentPlanMOCCPCR_20057 = "20057",
	LegacyFormsCPSAssessmentPlanMOCCPCRPriorVersion_20058 = "20058",
	LegacyFormsCPSAssessmentPlanMOCIA_20093 = "20093",
	LegacyFormsCPSAssessmentPlanMOCIAPriorVersion_20094 = "20094",
	LegacyFormsCPSNotification_20027 = "20027",
	LegacyFormsCPSNotificationPriorVersion_20028 = "20028",
	LegacyFormsParentalstrengthsneedsassessmentreassessment_115801 = "115801",
	LegacyFormsParentalstrengthsneedsassessmentreassessment_326002 = "326002",
	LegacyFormsParentalstrengthsneedsassessmentreassessment_1100063 = "1100063",
	LegacyFormsCPSPlacementAgreement_20008 = "20008",
	LegacyFormsPre1997Assessment_20095 = "20095",
	LegacyFormsPre1997Notification_20096 = "20096",
	LegacyFormsCPSRecordofFamilyMeeting_20004 = "20004",
	LegacyFormsCPSRecordofFamilyMeetingPriorVersion_20005 = "20005",
	LegacyFormsCPSRecordofFamilyMeeting_20006 = "20006",
	LegacyFormsCPSRecordofFamilyMeetingPriorVersion_20007 = "20007",
	LegacyFormsCPSReviewReport_20061 = "20061",
	LegacyFormsCPSReviewReportPriorVersion_20062 = "20062",
	LegacyFormsCPSReviewReportOutcomeAction_20064 = "20064",
	LegacyFormsCPSSafePlaceMovementRecord_20014 = "20014",
	LegacyFormsSafetyassessment_115508 = "115508",
	LegacyFormsScreeningcriteriaResponsepriority_115303 = "115303",
	LegacyFormsCPSApplicationTAO_20015 = "20015",
	LegacyFormsCPSApplicationTAOPriorVersion_20016 = "20016",
	LegacyFormsCPSOrderTAO_20019 = "20019",
	LegacyFormsCPSApplicationTAOExtend_20017 = "20017",
	LegacyFormsCPSApplicationTAOExtendPriorVersion_20018 = "20018",
	LegacyFormsCPSOrderTAOExtend_20020 = "20020",
	LegacyFormsCPSTAOExtSummary_20070 = "20070",
	LegacyFormsCPSTAOSummary_20021 = "20021",
	LifeCourtCaseNotes_115995 = "115995",
	LifeCourtCaseNotes_410201 = "410201",
	LifeYJcasenote_115932 = "115932",
	LifeYJcasenote_1000179 = "1000179",
	LifeYJcasenote_4101035 = "4101035",
	OtherapplicationsAttachment_115845 = "115845",
	OtherapplicationsTransfertoprison_115843 = "115843",
	OtherapplicationsTransferToPrison_115994 = "115994",
	OtherapplicationsVaryOrRevokeATransferOrder_115996 = "115996",
	OtherapplicationsYJcasenote_115844 = "115844",
	OtherapplicationsYJcasenote_1000167 = "1000167",
	OtherapplicationsYJcasenote_4101023 = "4101023",
	PlacementAboriginalTorresStraitIslanderchildplacement_360111 = "360111",
	PlacementAssessmentofplacementinformationtoparents_15901 = "15901",
	PlacementAssessmentofplacementinformationtoparents_100111 = "100111",
	PlacementAssessmentofplacementinformationtoparents_330011 = "330011",
	PlacementAuthoritytocare_15900 = "15900",
	PlacementAuthoritytocare_18017 = "18017",
	PlacementAuthoritytocare_100060 = "100060",
	PlacementAuthoritytocare_100119 = "100119",
	PlacementAuthoritytocare_330001 = "330001",
	PlacementCareagreement_15903 = "15903",
	PlacementCareagreement_100062 = "100062",
	PlacementCareagreement_100110 = "100110",
	PlacementCareagreement_330031 = "330031",
	PlacementCasenote_10022 = "10022",
	PlacementClosureofplacement_15907 = "15907",
	PlacementClosureofplacement_100140 = "100140",
	PlacementClosureofplacement_100160 = "100160",
	PlacementClosureofplacement_330051 = "330051",
	PlacementCPcasenote_115916 = "115916",
	PlacementCPcasenote_3000024 = "3000024",
	PlacementIndependententity_300253 = "300253",
	PlacementPlacementagreement_15902 = "15902",
	PlacementPlacementagreement_18016 = "18016",
	PlacementPlacementagreement_100061 = "100061",
	PlacementPlacementagreement_100118 = "100118",
	PlacementPlacementagreement_330021 = "330021",
	PlacementRecognisedentityparticipationCPP_15904 = "15904",
	PlacementRecognisedentityparticipationCPP_100138 = "100138",
	PlacementRecognisedentityparticipationCPP_360101 = "360101",
	PresentencereportAddendumpresentencereport_100154 = "100154",
	PresentencereportAddendumpresentencereport_100157 = "100157",
	PresentencereportAddendumpresentencereport_115855 = "115855",
	PresentencereportAttachment_115857 = "115857",
	PresentencereportPresentencereport_100153 = "100153",
	PresentencereportPresentencereport_100156 = "100156",
	PresentencereportPresentencereport_115854 = "115854",
	PresentencereportPresentencereport_480021 = "480021",
	PresentencereportYJcasenote_115856 = "115856",
	PresentencereportYJcasenote_1000171 = "1000171",
	PresentencereportYJcasenote_4101027 = "4101027",
	PreviouscaseplansAssessment_115819 = "115819",
	PreviouscaseplansAssessment_400702 = "400702",
	PreviouscaseplansInterventionplan_115817 = "115817",
	PreviouscaseplansYJcasenote_115825 = "115825",
	PreviouscaseplansYJcasenote_1000162 = "1000162",
	PreviouscaseplansYJcasenote_4101018 = "4101018",
	ProgramproposalAttachment_115861 = "115861",
	ProgramproposalConditionalreleaseorderprogramproposal_115858 = "115858",
	ProgramproposalConditionalreleaseorderprogramproposal_480001 = "480001",
	ProgramproposalIntensivesupervisionorderprogramproposal_115859 = "115859",
	ProgramproposalIntensivesupervisionorderprogramproposal_480011 = "480011",
	ProgramproposalYJcasenote_115860 = "115860",
	ProgramproposalYJcasenote_1000172 = "1000172",
	ProgramproposalYJcasenote_4101028 = "4101028",
	RecordofadviceofarrestNoticetoappearRecordofadviceofarrest_16501 = "16501",
	RecordofadviceofarrestNoticetoappearRecordofadviceofarrest_450001 = "450001",
	RecordofadviceofarrestNoticetoappearRecordofadviceofarrest_450041 = "450041",
	RecordofadviceofarrestNoticetoappearYJcasenote_16506 = "16506",
	RecordofadviceofarrestNoticetoappearYJcasenote_410104 = "410104",
	RecordofadviceofarrestNoticetoappearYJcasenote_1000148 = "1000148",
	ReferralAfterhoursreferral_115883 = "115883",
	ReferralAfterhoursreferral_410131 = "410131",
	ReferralAttachment_115864 = "115864",
	ReferralAttachment_115885 = "115885",
	ReferralCommunityserviceorderreferral_115880 = "115880",
	ReferralCommunityserviceorderreferral_410061 = "410061",
	ReferralGriffithreferral_115862 = "115862",
	ReferralGriffithreferral_400802 = "400802",
	ReferralGriffithreferral_1000144 = "1000144",
	ReferralISOconferencereferral_115881 = "115881",
	ReferralISOconferencereferral_410111 = "410111",
	ReferralProgramreferral_410161 = "410161",
	ReferralsAfterhoursreferral_115893 = "115893",
	ReferralsAfterhoursreferral_410132 = "410132",
	ReferralsGriffithreferral_115813 = "115813",
	ReferralsGriffithreferral_400801 = "400801",
	ReferralsGriffithreferral_1000143 = "1000143",
	ReferralsYDCtherapeuticprogramsreferral_115894 = "115894",
	ReferralsYDCtherapeuticprogramsreferral_410122 = "410122",
	ReferralsYJcasenote_115821 = "115821",
	ReferralsYJcasenote_1000158 = "1000158",
	ReferralsYJcasenote_4101014 = "4101014",
	ReferralsYouthworkersupportreferral_115892 = "115892",
	ReferralsYouthworkersupportreferral_115984 = "115984",
	ReferralsYouthworkersupportreferral_410142 = "410142",
	ReferralYDCtherapeuticprogramsreferral_115882 = "115882",
	ReferralYDCtherapeuticprogramsreferral_410121 = "410121",
	ReferralYJcasenote_115863 = "115863",
	ReferralYJcasenote_115884 = "115884",
	ReferralYJcasenote_1000173 = "1000173",
	ReferralYJcasenote_1000176 = "1000176",
	ReferralYJcasenote_4101029 = "4101029",
	ReferralYJcasenote_4101032 = "4101032",
	ReferralYouthworkersupportreferral_115879 = "115879",
	ReferralYouthworkersupportreferral_115983 = "115983",
	ReferralYouthworkersupportreferral_410141 = "410141",
	RegionalDirectorsconversationRegionalDirectorsconversation_450031 = "450031",
	RegionalDirectorsconversationRegionalDirectorsconversation_450032 = "450032",
	RegionalDirectorsconversationRegionalExecutiveDirectorsconversation_16504 = "16504",
	RegionalDirectorsconversationRegionalExecutiveDirectorsconversation_16603 = "16603",
	RegionalDirectorsconversationYJcasenote_16509 = "16509",
	RegionalDirectorsconversationYJcasenote_16608 = "16608",
	RegionalDirectorsconversationYJcasenote_410107 = "410107",
	RegionalDirectorsconversationYJcasenote_1000151 = "1000151",
	RegionalDirectorsconversationYJcasenote_1000155 = "1000155",
	RegionalDirectorsconversationYJcasenote_4101011 = "4101011",
	ResponseCasenote_10007 = "10007",
	ResponseCasenote_10010 = "10010",
	ResponseChildconcernreport_15306 = "15306",
	ResponseChildconcernreport_31002 = "31002",
	ResponseChildplacementconcernreport_15406 = "15406",
	ResponseChildplacementconcernreport_100100 = "100100",
	ResponseClosureform_15314 = "15314",
	ResponseClosureform_30007 = "30007",
	ResponseClosureform_30999 = "30999",
	ResponseClosureform_420013 = "420013",
	ResponseCPcasenote_30004 = "30004",
	ResponseCPcasenote_115901 = "115901",
	ResponseCPcasenote_115904 = "115904",
	ResponseCPcasenote_300009 = "300009",
	ResponseCPcasenote_3000012 = "3000012",
	ResponseHarmreport_30006 = "30006",
	ResponseMOCNotification_15405 = "15405",
	ResponseNotification_15305 = "15305",
	ResponseNotification_31009 = "31009",
	ResponseNotification_100091 = "100091",
	ResponseSafeplacemovementrecord_15307 = "15307",
	ResponseSafeplacemovementrecord_31003 = "31003",
	ResponseStandardsofcarereviewreport_30005 = "30005",
	RiskassessmentsAssessment_115815 = "115815",
	RiskassessmentsAssessment_400701 = "400701",
	RiskassessmentsYJcasenote_115823 = "115823",
	RiskassessmentsYJcasenote_1000160 = "1000160",
	RiskassessmentsYJcasenote_4101016 = "4101016",
	ShareddocumentsCasenote_10020 = "10020",
	ShareddocumentsCPcasenote_115914 = "115914",
	ShareddocumentsCPcasenote_3000022 = "3000022",
	ShareddocumentsFamilyreunificationassessment_15804 = "15804",
	ShareddocumentsFamilyreunificationassessment_327401 = "327401",
	ShareddocumentsFamilyreunificationassessment_327411 = "327411",
	ShareddocumentsFamilyreunificationassessment_327421 = "327421",
	ShareddocumentsFamilyriskreevaluationforinhomecases_15803 = "15803",
	ShareddocumentsFamilyriskreevaluationforinhomecases_18015 = "18015",
	ShareddocumentsFamilyriskreevaluationforinhomecases_100144 = "100144",
	ShareddocumentsFamilyriskreevaluationforinhomecases_327001 = "327001",
	ShareddocumentsParentalstrengthsneedsassessmentreassessment_15801 = "15801",
	ShareddocumentsParentalstrengthsneedsassessmentreassessment_100063 = "100063",
	ShareddocumentsParentalstrengthsneedsassessmentreassessment_326001 = "326001",
	ShareddocumentsRecordofafamilygroupmeeting_15806 = "15806",
	ShareddocumentsRecordofafamilygroupmeeting_327101 = "327101",
	ShareddocumentsReferraltoaconvenorforafamilygroupmeeting_15805 = "15805",
	ShareddocumentsSafetyassessment_15802 = "15802",
	ShareddocumentsSafetyassessment_18002 = "18002",
	ShareddocumentsSafetyassessment_310061 = "310061",
	SupportClosureform_115890 = "115890",
	SupportClosureform_4200118 = "4200118",
	SupportCPcasenote_115891 = "115891",
	SupportCPcasenote_300002 = "300002",
	TemporaryassessmentordersExtendvaryCasenote_10026 = "10026",
	TemporaryassessmentordersExtendvaryClosureofTAO_15109 = "15109",
	TemporaryassessmentordersExtendvaryCPcasenote_115920 = "115920",
	TemporaryassessmentordersExtendvaryCPcasenote_3000027 = "3000027",
	TemporaryassessmentordersExtendvaryForm17_15108 = "15108",
	TemporaryassessmentordersExtendvaryForm2ApplicationtoextendvaryaTAO_15101 = "15101",
	TemporaryassessmentordersExtendvaryForm2ApplicationtoextendvaryaTAO_100105 = "100105",
	TemporaryassessmentordersExtendvaryForm2TAOoutcomeformTOF_15106 = "15106",
	TemporaryassessmentordersExtendvaryForm2TAOoutcomeformTOF_100103 = "100103",
	TemporarycustodyordersExtendvaryClosureofTCO_100120 = "100120",
	TemporarycustodyordersExtendvaryCPcasenote_116002 = "116002",
	TemporarycustodyordersExtendvaryCPcasenote_3000052 = "3000052",
	TemporarycustodyordersExtendvaryForm17_15205 = "15205",
	TemporarycustodyordersExtendvaryForm41ApplicationtoextendvaryaTCO_100116 = "100116",
	TemporarycustodyordersExtendvaryForm41TCOoutcomeformTCOF_100117 = "100117",
	TemporaryassessmentordersCasenote_10001 = "10001",
	TemporaryassessmentordersClosureofTAO_15107 = "15107",
	TemporaryassessmentordersCPcasenote_115895 = "115895",
	TemporaryassessmentordersCPcasenote_300003 = "300003",
	TemporaryassessmentordersForm1ApplicationforaTAO_15100 = "15100",
	TemporaryassessmentordersForm1ApplicationforaTAO_18018 = "18018",
	TemporaryassessmentordersForm1ApplicationforaTAO_100101 = "100101",
	TemporaryassessmentordersForm1TAOoutcomeformTOF_15105 = "15105",
	TemporaryassessmentordersForm1TAOoutcomeformTOF_100102 = "100102",
	TemporaryassessmentordersForm17_15102 = "15102",
	TemporarycustodyordersClosureofTCO_100107 = "100107",
	TemporarycustodyordersCPcasenote_116001 = "116001",
	TemporarycustodyordersCPcasenote_3000051 = "3000051",
	TemporarycustodyordersForm17_15204 = "15204",
	TemporarycustodyordersForm39ApplicationforaTCO_100104 = "100104",
	TemporarycustodyordersForm39TCOoutcomeformTCOF_100106 = "100106",
	TransferTransfersummaryinformalinterstateoverseas_115850 = "115850",
	TransferTransfersummaryinformalinterstateoverseas_460001 = "460001",
	TransferTransfersummaryinformalinterstateoverseas_1000141 = "1000141",
	TransferYJcasenote_115851 = "115851",
	TransferYJcasenote_1000169 = "1000169",
	TransferYJcasenote_4101025 = "4101025",
	TransferYoungpersonsummary_115849 = "115849",
	TransferYoungpersonsummary_460012 = "460012",
	VaryorderAttachment_115842 = "115842",
	VaryorderOathofservice_416102 = "416102",
	VaryorderVarydetentionorderintheinterestsofjustice_115840 = "115840",
	VaryorderVarydetentionorderintheinterestsofjustice_415601 = "415601",
	VaryorderVarydetentionorderintheinterestsofjustice_1000142 = "1000142",
	VaryorderVaryorderbyconsent_115838 = "115838",
	VaryorderVaryorderbyconsent_115993 = "115993",
	VaryorderVaryorderbyconsent_415701 = "415701",
	VaryorderVaryorderintheinterestsofjustice_115839 = "115839",
	VaryorderVaryorderintheinterestsofjustice_115992 = "115992",
	VaryorderVaryorderintheinterestsofjustice_415801 = "415801",
	VaryorderYJcasenote_115841 = "115841",
	VaryorderYJcasenote_1000166 = "1000166",
	VaryorderYJcasenote_4101022 = "4101022",
	WarningletterAttachment_115829 = "115829",
	WarningletterIssuewarningletter_100067 = "100067",
	WarningletterIssuewarningletter_115827 = "115827",
	WarningletterIssueWarningLetter_115986 = "115986",
	WarningletterIssueWarningLetter_415301 = "415301",
	WarningletterYJcasenote_115828 = "115828",
	WarningletterYJcasenote_1000163 = "1000163",
	WarningletterYJcasenote_4101019 = "4101019",
	WarrantAttachment_115837 = "115837",
	WarrantComplaint_115835 = "115835",
	WarrantComplaint_115989 = "115989",
	WarrantComplaint_415101 = "415101",
	WarrantIssuewarrant_100068 = "100068",
	WarrantIssuewarrant_115834 = "115834",
	WarrantIssueWarrant_115988 = "115988",
	WarrantIssueWarrant_415401 = "415401",
	WarrantYJcasenote_115836 = "115836",
	WarrantYJcasenote_1000165 = "1000165",
	WarrantYJcasenote_4101021 = "4101021",
	WatchhouseTransportWatchhousedetentiondetails_16502 = "16502",
	WatchhouseTransportWatchhousedetentiondetails_16513 = "16513",
	WatchhouseTransportWatchhousedetentiondetails_16601 = "16601",
	WatchhouseTransportWatchhousedetentiondetails_16612 = "16612",
	WatchhouseTransportWatchhousedetentiondetails_450021 = "450021",
	WatchhouseTransportWatchhousedetentiondetails_450022 = "450022",
	WatchhouseTransportYJcasenote_16507 = "16507",
	WatchhouseTransportYJcasenote_16605 = "16605",
	WatchhouseTransportYJcasenote_410105 = "410105",
	WatchhouseTransportYJcasenote_410109 = "410109",
	WatchhouseTransportYJcasenote_1000149 = "1000149",
	WatchhouseTransportYJcasenote_1000153 = "1000153",
	AdministrationCasenote_10031 = "10031",
	AdministrationClosureform_16201 = "16201",
	AdministrationClosureform_4200110 = "4200110",
	AdministrationCPcasenote_115923 = "115923",
	AdministrationCPcasenote_3000030 = "3000030",
	AdministrationClosureofwatchhouse_16200 = "16200",
	YouthdetentioncentreTransportDetentionclosure_16606 = "16606",
	YouthdetentioncentreTransportDetentionclosure_16611 = "16611",
	YouthdetentioncentreTransportDetentionclosure_470001 = "470001",
	YouthdetentioncentreTransportDetentionclosure_1000138 = "1000138",
	YouthdetentioncentreTransportYDCdetentiondetails_16503 = "16503",
	YouthdetentioncentreTransportYDCdetentiondetails_16511 = "16511",
	YouthdetentioncentreTransportYDCdetentiondetails_16512 = "16512",
	YouthdetentioncentreTransportYDCdetentiondetails_16514 = "16514",
	YouthdetentioncentreTransportYDCdetentiondetails_16602 = "16602",
	YouthdetentioncentreTransportYDCdetentiondetails_450011 = "450011",
	YouthdetentioncentreTransportYDCdetentiondetails_450012 = "450012",
	YouthdetentioncentreTransportYDCdetentiondetails_1000137 = "1000137",
	YouthdetentioncentreTransportYJcasenote_16508 = "16508",
	YouthdetentioncentreTransportYJcasenote_16607 = "16607",
	YouthdetentioncentreTransportYJcasenote_410106 = "410106",
	YouthdetentioncentreTransportYJcasenote_1000150 = "1000150",
	YouthdetentioncentreTransportYJcasenote_1000154 = "1000154",
	YouthdetentioncentreTransportYJcasenote_4101010 = "4101010",
	ClosureCasenote_10029 = "10029",
	ClosureCPcasenote_115979 = "115979",
	ClosureCPcasenote_3000048 = "3000048",
	ClosureClosureform_16004 = "16004",
	ClosureClosureform_440012 = "440012",
	ClosureYJcasenote_16005 = "16005",
	ClosureYJcasenote_410101 = "410101",
	ClosureYJcasenote_1000145 = "1000145",
	SuicideriskCasenote_10028 = "10028",
	SuicideriskCasenote_16003 = "16003",
	SuicideriskCPcasenote_115978 = "115978",
	SuicideriskCPcasenote_3000047 = "3000047",
	SuicideriskMediumtolongtermsuicideriskmanagementplan_16006 = "16006",
	SuicideriskMediumtolongtermsuicideriskmanagementplan_440001 = "440001",
	SuicideriskSuicideriskalertSR1_16002 = "16002",
	SuicideriskSuicideriskalertSR1_115929 = "115929",
	SuicideriskSuicideriskalertSR1_345202 = "345202",
	SuicideriskYJcasenote_115930 = "115930",
	SuicideriskYJcasenote_1000178 = "1000178",
	SuicideriskYJcasenote_4101034 = "4101034",
	SuicideriskYJCcasenote_345301 = "345301",
	SuicideriskYJCcasenote_1000203 = "1000203"
}

export interface IFormSetFormTypeUsageGroupDescriptor { code: FormSetFormTypeUsage; description: string; shortDescription: string; }
export var FormSetFormTypeUsageGroup: IFormSetFormTypeUsageGroupDescriptor[] = [
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeActionplan_15606, description: `Assessment and outcome-Action plan`, shortDescription: `Assessment and outcome-Action plan` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeActionplan_100146, description: `Assessment and outcome-Action plan`, shortDescription: `Assessment and outcome-Action plan` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeASCresponse_310191, description: `Assessment and outcome-ASC response`, shortDescription: `Assessment and outcome-ASC response` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeAssessmentoutcome_15510, description: `Assessment and outcome-Assessment & outcome`, shortDescription: `Assessment and outcome-Assessment & outcome` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeAssessmentoutcome_18019, description: `Assessment and outcome-Assessment & outcome`, shortDescription: `Assessment and outcome-Assessment & outcome` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeAssessmentoutcome_31011, description: `Assessment and outcome-Assessment & outcome`, shortDescription: `Assessment and outcome-Assessment & outcome` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeAssessmentoutcome_100125, description: `Assessment and outcome-Assessment & outcome`, shortDescription: `Assessment and outcome-Assessment & outcome` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeCasenote_10014, description: `Assessment and outcome-Case note`, shortDescription: `Assessment and outcome-Case note` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeCasenote_10018, description: `Assessment and outcome-Case note`, shortDescription: `Assessment and outcome-Case note` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeClosureform_15515, description: `Assessment and outcome-Closure form`, shortDescription: `Assessment and outcome-Closure form` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeClosureform_15611, description: `Assessment and outcome-Closure form`, shortDescription: `Assessment and outcome-Closure form` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeClosureform_30019, description: `Assessment and outcome-Closure form`, shortDescription: `Assessment and outcome-Closure form` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeClosureform_31000, description: `Assessment and outcome-Closure form`, shortDescription: `Assessment and outcome-Closure form` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeClosureform_420015, description: `Assessment and outcome-Closure form`, shortDescription: `Assessment and outcome-Closure form` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeClosureform_420016, description: `Assessment and outcome-Closure form`, shortDescription: `Assessment and outcome-Closure form` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeClosureofMOCIA_15610, description: `Assessment and outcome-Closure of MOC - I&A`, shortDescription: `Assessment and outcome-Closure of MOC - I&A` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeCPcasenote_30014, description: `Assessment and outcome-CP case note`, shortDescription: `Assessment and outcome-CP case note` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeCPcasenote_115908, description: `Assessment and outcome-CP case note`, shortDescription: `Assessment and outcome-CP case note` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeCPcasenote_115912, description: `Assessment and outcome-CP case note`, shortDescription: `Assessment and outcome-CP case note` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeCPcasenote_3000016, description: `Assessment and outcome-CP case note`, shortDescription: `Assessment and outcome-CP case note` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeCPcasenote_3000020, description: `Assessment and outcome-CP case note`, shortDescription: `Assessment and outcome-CP case note` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeFamilyriskevaluation_15509, description: `Assessment and outcome-Family risk evaluation`, shortDescription: `Assessment and outcome-Family risk evaluation` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeFamilyriskevaluation_18003, description: `Assessment and outcome-Family risk evaluation`, shortDescription: `Assessment and outcome-Family risk evaluation` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeFamilyriskevaluation_31014, description: `Assessment and outcome-Family risk evaluation`, shortDescription: `Assessment and outcome-Family risk evaluation` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeFamilyriskevaluation_100123, description: `Assessment and outcome-Family risk evaluation`, shortDescription: `Assessment and outcome-Family risk evaluation` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeFamilyriskevaluation_100139, description: `Assessment and outcome-Family risk evaluation`, shortDescription: `Assessment and outcome-Family risk evaluation` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeFamilyriskevaluation_310181, description: `Assessment and outcome-Family risk evaluation`, shortDescription: `Assessment and outcome-Family risk evaluation` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeHarmreportassessmentoutcome_30015, description: `Assessment and outcome-Harm report - assessment & outcome`, shortDescription: `Assessment and outcome-Harm report - assessment & outcome` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeHarmreportactionplan_30016, description: `Assessment and outcome-Harm report action plan`, shortDescription: `Assessment and outcome-Harm report action plan` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeIndependententity_300251, description: `Assessment and outcome-Independent entity`, shortDescription: `Assessment and outcome-Independent entity` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeInformationProvision_15607, description: `Assessment and outcome-Information Provision`, shortDescription: `Assessment and outcome-Information Provision` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeInformationprovision_30017, description: `Assessment and outcome-Information provision`, shortDescription: `Assessment and outcome-Information provision` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeInformationProvision_115991, description: `Assessment and outcome-Information Provision`, shortDescription: `Assessment and outcome-Information Provision` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeInformationprovisiontoparents_15511, description: `Assessment and outcome-Information provision to parents`, shortDescription: `Assessment and outcome-Information provision to parents` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeInformationprovisiontoparents_31012, description: `Assessment and outcome-Information provision to parents`, shortDescription: `Assessment and outcome-Information provision to parents` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeInformationprovisiontoparents_100126, description: `Assessment and outcome-Information provision to parents`, shortDescription: `Assessment and outcome-Information provision to parents` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeMOCAssessmentoutcome_15605, description: `Assessment and outcome-MOC Assessment & outcome`, shortDescription: `Assessment and outcome-MOC Assessment & outcome` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeMOCAssessmentoutcome_18013, description: `Assessment and outcome-MOC Assessment & outcome`, shortDescription: `Assessment and outcome-MOC Assessment & outcome` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeRecognisedentityparticipation_15512, description: `Assessment and outcome-Recognised entity participation`, shortDescription: `Assessment and outcome-Recognised entity participation` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeRecognisedentityparticipation_15608, description: `Assessment and outcome-Recognised entity participation`, shortDescription: `Assessment and outcome-Recognised entity participation` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeRecognisedentityparticipation_18009, description: `Assessment and outcome-Recognised entity participation`, shortDescription: `Assessment and outcome-Recognised entity participation` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeRecognisedentityparticipation_18011, description: `Assessment and outcome-Recognised entity participation`, shortDescription: `Assessment and outcome-Recognised entity participation` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeRecognisedentityparticipation_30018, description: `Assessment and outcome-Recognised entity participation`, shortDescription: `Assessment and outcome-Recognised entity participation` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeRecognisedentityparticipation_30022, description: `Assessment and outcome-Recognised entity participation`, shortDescription: `Assessment and outcome-Recognised entity participation` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeSafetyassessment_15508, description: `Assessment and outcome-Safety assessment`, shortDescription: `Assessment and outcome-Safety assessment` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeSafetyassessment_18001, description: `Assessment and outcome-Safety assessment`, shortDescription: `Assessment and outcome-Safety assessment` },
	{ code: FormSetFormTypeUsage.AssessmentandoutcomeSafetyassessment_31006, description: `Assessment and outcome-Safety assessment`, shortDescription: `Assessment and outcome-Safety assessment` },
	{ code: FormSetFormTypeUsage.BailprogramAttachment_115938, description: `Bail program-Attachment`, shortDescription: `Bail program-Attachment` },
	{ code: FormSetFormTypeUsage.BailprogramConditionalbailprogramcompletion_115936, description: `Bail program-Conditional bail program completion`, shortDescription: `Bail program-CBP completion` },
	{ code: FormSetFormTypeUsage.BailprogramConditionalbailprogramcompletion_405301, description: `Bail program-Conditional bail program completion`, shortDescription: `Bail program-CBP completion` },
	{ code: FormSetFormTypeUsage.BailprogramConditionalbailprogramreferralproposal_115933, description: `Bail program-Conditional bail program referral/proposal`, shortDescription: `Bail program-CBP referral/proposal` },
	{ code: FormSetFormTypeUsage.BailprogramConditionalbailprogramreferralproposal_405401, description: `Bail program-Conditional bail program referral/proposal`, shortDescription: `Bail program-CBP referral/proposal` },
	{ code: FormSetFormTypeUsage.BailprogramConditionalbailprogramreferralproposaloutcome_115934, description: `Bail program-Conditional bail program referral/proposal outcome`, shortDescription: `Bail program-CBP referral/proposal outcome` },
	{ code: FormSetFormTypeUsage.BailprogramConditionalbailprogramreferralproposaloutcome_405101, description: `Bail program-Conditional bail program referral/proposal outcome`, shortDescription: `Bail program-CBP referral/proposal outcome` },
	{ code: FormSetFormTypeUsage.BailprogramTransitionreferral_115935, description: `Bail program-Transition referral`, shortDescription: `Bail program-Transition referral` },
	{ code: FormSetFormTypeUsage.BailprogramTransitionreferral_405201, description: `Bail program-Transition referral`, shortDescription: `Bail program-Transition referral` },
	{ code: FormSetFormTypeUsage.BailprogramYJcasenote_115937, description: `Bail program-YJ case note`, shortDescription: `Bail program-YJ case note` },
	{ code: FormSetFormTypeUsage.BailprogramYJcasenote_1000180, description: `Bail program-YJ case note`, shortDescription: `Bail program-YJ case note` },
	{ code: FormSetFormTypeUsage.BailprogramYJcasenote_4101036, description: `Bail program-YJ case note`, shortDescription: `Bail program-YJ case note` },
	{ code: FormSetFormTypeUsage.BailsupportAttachment_115942, description: `Bail support-Attachment`, shortDescription: `Bail support-Attachment` },
	{ code: FormSetFormTypeUsage.BailsupportBailsupportcompletion_115940, description: `Bail support-Bail support completion`, shortDescription: `Bail support-Bail support completion` },
	{ code: FormSetFormTypeUsage.BailsupportBailsupportcompletion_405501, description: `Bail support-Bail support completion`, shortDescription: `Bail support-Bail support completion` },
	{ code: FormSetFormTypeUsage.BailsupportBailsupportreferral_115939, description: `Bail support-Bail support referral`, shortDescription: `Bail support-Bail support referral` },
	{ code: FormSetFormTypeUsage.BailsupportBailsupportreferral_405601, description: `Bail support-Bail support referral`, shortDescription: `Bail support-Bail support referral` },
	{ code: FormSetFormTypeUsage.BailsupportYJcasenote_115941, description: `Bail support-YJ case note`, shortDescription: `Bail support-YJ case note` },
	{ code: FormSetFormTypeUsage.BailsupportYJcasenote_1000181, description: `Bail support-YJ case note`, shortDescription: `Bail support-YJ case note` },
	{ code: FormSetFormTypeUsage.BailsupportYJcasenote_4101037, description: `Bail support-YJ case note`, shortDescription: `Bail support-YJ case note` },
	{ code: FormSetFormTypeUsage.CourtassessmentordersExtendvaryrevokeCasenote_10003, description: `Court assessment orders - Extend/vary/revoke-Case note`, shortDescription: `CAO - Extend/vary/revoke-Case note` },
	{ code: FormSetFormTypeUsage.CourtassessmentordersExtendvaryrevokeClosureform_15220, description: `Court assessment orders - Extend/vary/revoke-Closure form`, shortDescription: `CAO - Extend/vary/revoke-Closure form` },
	{ code: FormSetFormTypeUsage.CourtassessmentordersExtendvaryrevokeClosureform_420012, description: `Court assessment orders - Extend/vary/revoke-Closure form`, shortDescription: `CAO - Extend/vary/revoke-Closure form` },
	{ code: FormSetFormTypeUsage.CourtassessmentordersExtendvaryrevokeClosureofCAO_15218, description: `Court assessment orders - Extend/vary/revoke-Closure of CAO`, shortDescription: `CAO - Extend/vary/revoke-Closure of CAO` },
	{ code: FormSetFormTypeUsage.CourtassessmentordersExtendvaryrevokeCPcasenote_115897, description: `Court assessment orders - Extend/vary/revoke-CP case note`, shortDescription: `CAO - Extend/vary/revoke-CP case note` },
	{ code: FormSetFormTypeUsage.CourtassessmentordersExtendvaryrevokeCPcasenote_300005, description: `Court assessment orders - Extend/vary/revoke-CP case note`, shortDescription: `CAO - Extend/vary/revoke-CP case note` },
	{ code: FormSetFormTypeUsage.CourtassessmentordersExtendvaryrevokeForm17_15217, description: `Court assessment orders - Extend/vary/revoke-Form 17`, shortDescription: `CAO - Extend/vary/revoke-Form 17` },
	{ code: FormSetFormTypeUsage.CourtassessmentordersExtendvaryrevokeForm6ApplicationtoextendvaryrevokeaCAO_15208, description: `Court assessment orders - Extend/vary/revoke-Form 6 - Application to extend/vary/revoke a CAO`, shortDescription: `CAO - Extend/vary/revoke-Form 6` },
	{ code: FormSetFormTypeUsage.CourtassessmentordersExtendvaryrevokeForm6COFfinalorder_15209, description: `Court assessment orders - Extend/vary/revoke-Form 6 - COF - final order`, shortDescription: `CAO - Extend/vary/revoke-Form 6 - COF` },
	{ code: FormSetFormTypeUsage.CourtassessmentordersExtendvaryrevokeForm6COFfinalorder_100096, description: `Court assessment orders - Extend/vary/revoke-Form 6 - COF - final order`, shortDescription: `CAO - Extend/vary/revoke-Form 6 - COF` },
	{ code: FormSetFormTypeUsage.CourtassessmentordersExtendvaryrevokeForm9COFadjournmentorder_15206, description: `Court assessment orders - Extend/vary/revoke-Form 9 - COF - adjournment order`, shortDescription: `CAO - Extend/vary/revoke-Form 9 - COF` },
	{ code: FormSetFormTypeUsage.CourtassessmentordersExtendvaryrevokeForm9COFadjournmentorder_100099, description: `Court assessment orders - Extend/vary/revoke-Form 9 - COF - adjournment order`, shortDescription: `CAO - Extend/vary/revoke-Form 9 - COF` },
	{ code: FormSetFormTypeUsage.CaseclosureCaseclosure_115816, description: `Case closure-Case closure`, shortDescription: `Case closure-Case closure` },
	{ code: FormSetFormTypeUsage.CaseclosureCaseclosure_400601, description: `Case closure-Case closure`, shortDescription: `Case closure-Case closure` },
	{ code: FormSetFormTypeUsage.CaseclosureYJcasenote_115824, description: `Case closure-YJ case note`, shortDescription: `Case closure-YJ case note` },
	{ code: FormSetFormTypeUsage.CaseclosureYJcasenote_1000161, description: `Case closure-YJ case note`, shortDescription: `Case closure-YJ case note` },
	{ code: FormSetFormTypeUsage.CaseclosureYJcasenote_4101017, description: `Case closure-YJ case note`, shortDescription: `Case closure-YJ case note` },
	{ code: FormSetFormTypeUsage.CasereviewsCasereviewYDC_115814, description: `Case reviews-Case review - YDC`, shortDescription: `Case reviews-Case review - YDC` },
	{ code: FormSetFormTypeUsage.CasereviewsCasereviewYDC_1000139, description: `Case reviews-Case review - YDC`, shortDescription: `Case reviews-Case review - YDC` },
	{ code: FormSetFormTypeUsage.CasereviewsCasereviewYDC_1000201, description: `Case reviews-Case review - YDC`, shortDescription: `Case reviews-Case review - YDC` },
	{ code: FormSetFormTypeUsage.CasereviewsCasereviewYJSC_115826, description: `Case reviews-Case review - YJSC`, shortDescription: `Case reviews-Case review - YJSC` },
	{ code: FormSetFormTypeUsage.CasereviewsCasereviewYJSC_1000140, description: `Case reviews-Case review - YJSC`, shortDescription: `Case reviews-Case review - YJSC` },
	{ code: FormSetFormTypeUsage.CasereviewsCasereviewYJSC_1000202, description: `Case reviews-Case review - YJSC`, shortDescription: `Case reviews-Case review - YJSC` },
	{ code: FormSetFormTypeUsage.CasereviewsYJcasenote_115822, description: `Case reviews-YJ case note`, shortDescription: `Case reviews-YJ case note` },
	{ code: FormSetFormTypeUsage.CasereviewsYJcasenote_1000159, description: `Case reviews-YJ case note`, shortDescription: `Case reviews-YJ case note` },
	{ code: FormSetFormTypeUsage.CasereviewsYJcasenote_4101015, description: `Case reviews-YJ case note`, shortDescription: `Case reviews-YJ case note` },
	{ code: FormSetFormTypeUsage.CasereviewsYouthJusticecasereview_400401, description: `Case reviews-Youth Justice case review`, shortDescription: `Case reviews-Youth Justice case review` },
	{ code: FormSetFormTypeUsage.ChildprotectionordersCasenote_10019, description: `Child protection orders-Case note`, shortDescription: `Child protection orders-Case note` },
	{ code: FormSetFormTypeUsage.ChildprotectionordersClosureform_15714, description: `Child protection orders-Closure form`, shortDescription: `Child protection orders-Closure form` },
	{ code: FormSetFormTypeUsage.ChildprotectionordersClosureform_420017, description: `Child protection orders-Closure form`, shortDescription: `Child protection orders-Closure form` },
	{ code: FormSetFormTypeUsage.ChildprotectionordersClosureofCPO_15713, description: `Child protection orders-Closure of CPO`, shortDescription: `Child protection orders-Closure of CPO` },
	{ code: FormSetFormTypeUsage.ChildprotectionordersCPcasenote_115913, description: `Child protection orders-CP case note`, shortDescription: `Child protection orders-CP case note` },
	{ code: FormSetFormTypeUsage.ChildprotectionordersCPcasenote_3000021, description: `Child protection orders-CP case note`, shortDescription: `Child protection orders-CP case note` },
	{ code: FormSetFormTypeUsage.ChildprotectionordersForm10ApplicationforaCPO_15700, description: `Child protection orders-Form 10 - Application for a CPO`, shortDescription: `Child protection orders-Form 10` },
	{ code: FormSetFormTypeUsage.ChildprotectionordersForm10COFfinalorder_15701, description: `Child protection orders-Form 10 - COF - final order`, shortDescription: `Child protection orders-Form 10 - COF` },
	{ code: FormSetFormTypeUsage.ChildprotectionordersForm10COFfinalorder_100122, description: `Child protection orders-Form 10 - COF - final order`, shortDescription: `Child protection orders-Form 10 - COF` },
	{ code: FormSetFormTypeUsage.ChildprotectionordersForm11ApplicationtoextendvaryrevokeaCPO_15702, description: `Child protection orders-Form 11 - Application to extend/vary/revoke a CPO`, shortDescription: `Child protection orders-Form 11` },
	{ code: FormSetFormTypeUsage.ChildprotectionordersForm11ApplicationtoextendvaryrevokeaCPO_100121, description: `Child protection orders-Form 11 - Application to extend/vary/revoke a CPO`, shortDescription: `Child protection orders-Form 11` },
	{ code: FormSetFormTypeUsage.ChildprotectionordersForm11COFfinalorder_15703, description: `Child protection orders-Form 11 - COF - final order`, shortDescription: `Child protection orders-Form 11 - COF` },
	{ code: FormSetFormTypeUsage.ChildprotectionordersForm11COFfinalorder_100128, description: `Child protection orders-Form 11 - COF - final order`, shortDescription: `Child protection orders-Form 11 - COF` },
	{ code: FormSetFormTypeUsage.ChildprotectionordersForm12ApplicationtorevokemakeanotherCPO_15704, description: `Child protection orders-Form 12 - Application to revoke & make another CPO`, shortDescription: `Child protection orders-Form 12` },
	{ code: FormSetFormTypeUsage.ChildprotectionordersForm12COFfinalorder_15705, description: `Child protection orders-Form 12 - COF - final order`, shortDescription: `Child protection orders-Form 12 - COF` },
	{ code: FormSetFormTypeUsage.ChildprotectionordersForm12COFfinalorder_100109, description: `Child protection orders-Form 12 - COF - final order`, shortDescription: `Child protection orders-Form 12 - COF` },
	{ code: FormSetFormTypeUsage.ChildprotectionordersForm16COFadjournmentorder_15710, description: `Child protection orders-Form 16 - COF - adjournment order`, shortDescription: `Child protection orders-Form 16 - COF` },
	{ code: FormSetFormTypeUsage.ChildprotectionordersForm16COFadjournmentorder_100124, description: `Child protection orders-Form 16 - COF - adjournment order`, shortDescription: `Child protection orders-Form 16 - COF` },
	{ code: FormSetFormTypeUsage.ChildprotectionordersForm17_15706, description: `Child protection orders-Form 17`, shortDescription: `Child protection orders-Form 17` },
	{ code: FormSetFormTypeUsage.ChildprotectionordersForm44COFtransitionorder_100129, description: `Child protection orders-Form 44 - COF - transition order`, shortDescription: `Child protection orders-Form 44 - COF` },
	{ code: FormSetFormTypeUsage.ClosureAttachment_115848, description: `Closure-Attachment`, shortDescription: `Closure-Attachment` },
	{ code: FormSetFormTypeUsage.ClosureAttachment_115867, description: `Closure-Attachment`, shortDescription: `Closure-Attachment` },
	{ code: FormSetFormTypeUsage.ClosureAttachment_115888, description: `Closure-Attachment`, shortDescription: `Closure-Attachment` },
	{ code: FormSetFormTypeUsage.ClosureAttachment_115945, description: `Closure-Attachment`, shortDescription: `Closure-Attachment` },
	{ code: FormSetFormTypeUsage.ClosureClosureform_16451, description: `Closure-Closure form`, shortDescription: `Closure-Closure form` },
	{ code: FormSetFormTypeUsage.ClosureClosureform_16505, description: `Closure-Closure form`, shortDescription: `Closure-Closure form` },
	{ code: FormSetFormTypeUsage.ClosureClosureform_16604, description: `Closure-Closure form`, shortDescription: `Closure-Closure form` },
	{ code: FormSetFormTypeUsage.ClosureClosureform_115846, description: `Closure-Closure form`, shortDescription: `Closure-Closure form` },
	{ code: FormSetFormTypeUsage.ClosureClosureform_115852, description: `Closure-Closure form`, shortDescription: `Closure-Closure form` },
	{ code: FormSetFormTypeUsage.ClosureClosureform_115865, description: `Closure-Closure form`, shortDescription: `Closure-Closure form` },
	{ code: FormSetFormTypeUsage.ClosureClosureform_115886, description: `Closure-Closure form`, shortDescription: `Closure-Closure form` },
	{ code: FormSetFormTypeUsage.ClosureClosureform_115943, description: `Closure-Closure form`, shortDescription: `Closure-Closure form` },
	{ code: FormSetFormTypeUsage.ClosureClosureform_410151, description: `Closure-Closure form`, shortDescription: `Closure-Closure form` },
	{ code: FormSetFormTypeUsage.ClosureClosureform_410152, description: `Closure-Closure form`, shortDescription: `Closure-Closure form` },
	{ code: FormSetFormTypeUsage.ClosureClosureform_460004, description: `Closure-Closure form`, shortDescription: `Closure-Closure form` },
	{ code: FormSetFormTypeUsage.ClosureClosureform_4101057, description: `Closure-Closure form`, shortDescription: `Closure-Closure form` },
	{ code: FormSetFormTypeUsage.ClosureClosureform_4101058, description: `Closure-Closure form`, shortDescription: `Closure-Closure form` },
	{ code: FormSetFormTypeUsage.ClosureClosureform_4200111, description: `Closure-Closure form`, shortDescription: `Closure-Closure form` },
	{ code: FormSetFormTypeUsage.ClosureClosureform_4200112, description: `Closure-Closure form`, shortDescription: `Closure-Closure form` },
	{ code: FormSetFormTypeUsage.ClosureClosureform_4200113, description: `Closure-Closure form`, shortDescription: `Closure-Closure form` },
	{ code: FormSetFormTypeUsage.ClosureClosureform_4200114, description: `Closure-Closure form`, shortDescription: `Closure-Closure form` },
	{ code: FormSetFormTypeUsage.ClosureClosureform_4200115, description: `Closure-Closure form`, shortDescription: `Closure-Closure form` },
	{ code: FormSetFormTypeUsage.ClosureClosureform_4200116, description: `Closure-Closure form`, shortDescription: `Closure-Closure form` },
	{ code: FormSetFormTypeUsage.ClosureClosureform_4200117, description: `Closure-Closure form`, shortDescription: `Closure-Closure form` },
	{ code: FormSetFormTypeUsage.ClosureClosureform_4200119, description: `Closure-Closure form`, shortDescription: `Closure-Closure form` },
	{ code: FormSetFormTypeUsage.ClosureClosureform_4200120, description: `Closure-Closure form`, shortDescription: `Closure-Closure form` },
	{ code: FormSetFormTypeUsage.ClosureClosureform_4200121, description: `Closure-Closure form`, shortDescription: `Closure-Closure form` },
	{ code: FormSetFormTypeUsage.ClosureClosureform_4200122, description: `Closure-Closure form`, shortDescription: `Closure-Closure form` },
	{ code: FormSetFormTypeUsage.ClosureYJcasenote_16452, description: `Closure-YJ case note`, shortDescription: `Closure-YJ case note` },
	{ code: FormSetFormTypeUsage.ClosureYJcasenote_16510, description: `Closure-YJ case note`, shortDescription: `Closure-YJ case note` },
	{ code: FormSetFormTypeUsage.ClosureYJcasenote_16609, description: `Closure-YJ case note`, shortDescription: `Closure-YJ case note` },
	{ code: FormSetFormTypeUsage.ClosureYJcasenote_115847, description: `Closure-YJ case note`, shortDescription: `Closure-YJ case note` },
	{ code: FormSetFormTypeUsage.ClosureYJcasenote_115853, description: `Closure-YJ case note`, shortDescription: `Closure-YJ case note` },
	{ code: FormSetFormTypeUsage.ClosureYJcasenote_115866, description: `Closure-YJ case note`, shortDescription: `Closure-YJ case note` },
	{ code: FormSetFormTypeUsage.ClosureYJcasenote_115887, description: `Closure-YJ case note`, shortDescription: `Closure-YJ case note` },
	{ code: FormSetFormTypeUsage.ClosureYJcasenote_115944, description: `Closure-YJ case note`, shortDescription: `Closure-YJ case note` },
	{ code: FormSetFormTypeUsage.ClosureYJcasenote_410103, description: `Closure-YJ case note`, shortDescription: `Closure-YJ case note` },
	{ code: FormSetFormTypeUsage.ClosureYJcasenote_410108, description: `Closure-YJ case note`, shortDescription: `Closure-YJ case note` },
	{ code: FormSetFormTypeUsage.ClosureYJcasenote_1000147, description: `Closure-YJ case note`, shortDescription: `Closure-YJ case note` },
	{ code: FormSetFormTypeUsage.ClosureYJcasenote_1000152, description: `Closure-YJ case note`, shortDescription: `Closure-YJ case note` },
	{ code: FormSetFormTypeUsage.ClosureYJcasenote_1000156, description: `Closure-YJ case note`, shortDescription: `Closure-YJ case note` },
	{ code: FormSetFormTypeUsage.ClosureYJcasenote_1000168, description: `Closure-YJ case note`, shortDescription: `Closure-YJ case note` },
	{ code: FormSetFormTypeUsage.ClosureYJcasenote_1000170, description: `Closure-YJ case note`, shortDescription: `Closure-YJ case note` },
	{ code: FormSetFormTypeUsage.ClosureYJcasenote_1000174, description: `Closure-YJ case note`, shortDescription: `Closure-YJ case note` },
	{ code: FormSetFormTypeUsage.ClosureYJcasenote_1000177, description: `Closure-YJ case note`, shortDescription: `Closure-YJ case note` },
	{ code: FormSetFormTypeUsage.ClosureYJcasenote_1000182, description: `Closure-YJ case note`, shortDescription: `Closure-YJ case note` },
	{ code: FormSetFormTypeUsage.ClosureYJcasenote_4101012, description: `Closure-YJ case note`, shortDescription: `Closure-YJ case note` },
	{ code: FormSetFormTypeUsage.ClosureYJcasenote_4101024, description: `Closure-YJ case note`, shortDescription: `Closure-YJ case note` },
	{ code: FormSetFormTypeUsage.ClosureYJcasenote_4101026, description: `Closure-YJ case note`, shortDescription: `Closure-YJ case note` },
	{ code: FormSetFormTypeUsage.ClosureYJcasenote_4101030, description: `Closure-YJ case note`, shortDescription: `Closure-YJ case note` },
	{ code: FormSetFormTypeUsage.ClosureYJcasenote_4101033, description: `Closure-YJ case note`, shortDescription: `Closure-YJ case note` },
	{ code: FormSetFormTypeUsage.ClosureYJcasenote_4101038, description: `Closure-YJ case note`, shortDescription: `Closure-YJ case note` },
	{ code: FormSetFormTypeUsage.ComplaintandsummonsAttachment_115833, description: `Complaint and summons-Attachment`, shortDescription: `Complaint and summons-Attachment` },
	{ code: FormSetFormTypeUsage.ComplaintandsummonsIssuecomplaintandsummons_100066, description: `Complaint and summons-Issue complaint and summons`, shortDescription: `Complaint and summons-Issue complaint and summons` },
	{ code: FormSetFormTypeUsage.ComplaintandsummonsIssuecomplaintandsummons_115830, description: `Complaint and summons-Issue complaint and summons`, shortDescription: `Complaint and summons-Issue complaint and summons` },
	{ code: FormSetFormTypeUsage.ComplaintandsummonsIssueComplaintandSummons_115987, description: `Complaint and summons-Issue Complaint and Summons`, shortDescription: `Complaint and summons-Issue Complaint and Summons` },
	{ code: FormSetFormTypeUsage.ComplaintandsummonsIssueComplaintandSummons_415201, description: `Complaint and summons-Issue Complaint and Summons`, shortDescription: `Complaint and summons-Issue Complaint and Summons` },
	{ code: FormSetFormTypeUsage.ComplaintandsummonsOathofservice_115831, description: `Complaint and summons-Oath of service`, shortDescription: `Complaint and summons-Oath of service` },
	{ code: FormSetFormTypeUsage.ComplaintandsummonsOathofservice_416101, description: `Complaint and summons-Oath of service`, shortDescription: `Complaint and summons-Oath of service` },
	{ code: FormSetFormTypeUsage.ComplaintandsummonsYJcasenote_115832, description: `Complaint and summons-YJ case note`, shortDescription: `Complaint and summons-YJ case note` },
	{ code: FormSetFormTypeUsage.ComplaintandsummonsYJcasenote_1000164, description: `Complaint and summons-YJ case note`, shortDescription: `Complaint and summons-YJ case note` },
	{ code: FormSetFormTypeUsage.ComplaintandsummonsYJcasenote_4101020, description: `Complaint and summons-YJ case note`, shortDescription: `Complaint and summons-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsCPcasenote_115950, description: `Consolidated views-CP case note`, shortDescription: `Consolidated views-CP case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsCPcasenote_115951, description: `Consolidated views-CP case note`, shortDescription: `Consolidated views-CP case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsCPcasenote_115952, description: `Consolidated views-CP case note`, shortDescription: `Consolidated views-CP case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsCPcasenote_115953, description: `Consolidated views-CP case note`, shortDescription: `Consolidated views-CP case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsCPcasenote_115954, description: `Consolidated views-CP case note`, shortDescription: `Consolidated views-CP case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsCPcasenote_115955, description: `Consolidated views-CP case note`, shortDescription: `Consolidated views-CP case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsCPcasenote_115956, description: `Consolidated views-CP case note`, shortDescription: `Consolidated views-CP case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsCPcasenote_115957, description: `Consolidated views-CP case note`, shortDescription: `Consolidated views-CP case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsCPcasenote_115958, description: `Consolidated views-CP case note`, shortDescription: `Consolidated views-CP case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsCPcasenote_115959, description: `Consolidated views-CP case note`, shortDescription: `Consolidated views-CP case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsCPcasenote_115963, description: `Consolidated views-CP case note`, shortDescription: `Consolidated views-CP case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsCPcasenote_115981, description: `Consolidated views-CP case note`, shortDescription: `Consolidated views-CP case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsCPcasenote_116004, description: `Consolidated views-CP case note`, shortDescription: `Consolidated views-CP case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsCPcasenote_3000036, description: `Consolidated views-CP case note`, shortDescription: `Consolidated views-CP case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsCPcasenote_3000037, description: `Consolidated views-CP case note`, shortDescription: `Consolidated views-CP case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsCPcasenote_3000038, description: `Consolidated views-CP case note`, shortDescription: `Consolidated views-CP case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsCPcasenote_3000039, description: `Consolidated views-CP case note`, shortDescription: `Consolidated views-CP case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsCPcasenote_3000040, description: `Consolidated views-CP case note`, shortDescription: `Consolidated views-CP case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsCPcasenote_3000041, description: `Consolidated views-CP case note`, shortDescription: `Consolidated views-CP case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsCPcasenote_3000042, description: `Consolidated views-CP case note`, shortDescription: `Consolidated views-CP case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsCPcasenote_3000043, description: `Consolidated views-CP case note`, shortDescription: `Consolidated views-CP case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsCPcasenote_3000044, description: `Consolidated views-CP case note`, shortDescription: `Consolidated views-CP case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsCPcasenote_3000045, description: `Consolidated views-CP case note`, shortDescription: `Consolidated views-CP case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsCPcasenote_3000046, description: `Consolidated views-CP case note`, shortDescription: `Consolidated views-CP case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsCPcasenote_3000049, description: `Consolidated views-CP case note`, shortDescription: `Consolidated views-CP case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsCPcasenote_3000053, description: `Consolidated views-CP case note`, shortDescription: `Consolidated views-CP case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_115960, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_115961, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_115962, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_115964, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_115965, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_115966, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_115967, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_115969, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_115970, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_115971, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_115972, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_115973, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_115974, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_115975, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_115980, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_1000183, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_1000184, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_1000185, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_1000186, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_1000187, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_1000188, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_1000189, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_1000190, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_1000191, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_1000192, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_1000193, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_1000194, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_1000195, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_1000196, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_1000199, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_4101039, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_4101040, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_4101041, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_4101042, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_4101043, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_4101044, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_4101045, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_4101046, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_4101047, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_4101048, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_4101049, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_4101050, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_4101051, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_4101052, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.ConsolidatedviewsYJcasenote_4101055, description: `Consolidated views-YJ case note`, shortDescription: `Consolidated views-YJ case note` },
	{ code: FormSetFormTypeUsage.CourtassessmentordersCasenote_10002, description: `Court assessment orders-Case note`, shortDescription: `Court assessment orders-Case note` },
	{ code: FormSetFormTypeUsage.CourtassessmentordersClosureform_15219, description: `Court assessment orders-Closure form`, shortDescription: `Court assessment orders-Closure form` },
	{ code: FormSetFormTypeUsage.CourtassessmentordersClosureform_420011, description: `Court assessment orders-Closure form`, shortDescription: `Court assessment orders-Closure form` },
	{ code: FormSetFormTypeUsage.CourtassessmentordersClosureofCAO_15216, description: `Court assessment orders-Closure of CAO`, shortDescription: `Court assessment orders-Closure of CAO` },
	{ code: FormSetFormTypeUsage.CourtassessmentordersCPcasenote_115896, description: `Court assessment orders-CP case note`, shortDescription: `Court assessment orders-CP case note` },
	{ code: FormSetFormTypeUsage.CourtassessmentordersCPcasenote_300004, description: `Court assessment orders-CP case note`, shortDescription: `Court assessment orders-CP case note` },
	{ code: FormSetFormTypeUsage.CourtassessmentordersForm17_15202, description: `Court assessment orders-Form 17`, shortDescription: `Court assessment orders-Form 17` },
	{ code: FormSetFormTypeUsage.CourtassessmentordersForm5ApplicationforaCAO_15200, description: `Court assessment orders-Form 5 - Application for a CAO`, shortDescription: `Court assessment orders-Form 5` },
	{ code: FormSetFormTypeUsage.CourtassessmentordersForm5COFfinalorder_15201, description: `Court assessment orders-Form 5 - COF - final order`, shortDescription: `Court assessment orders-Form 5 - COF` },
	{ code: FormSetFormTypeUsage.CourtassessmentordersForm5COFfinalorder_100095, description: `Court assessment orders-Form 5 - COF - final order`, shortDescription: `Court assessment orders-Form 5 - COF` },
	{ code: FormSetFormTypeUsage.CourtassessmentordersForm9COFadjournmentorder_15203, description: `Court assessment orders-Form 9 - COF - adjournment order`, shortDescription: `Court assessment orders-Form 9 - COF` },
	{ code: FormSetFormTypeUsage.CourtassessmentordersForm9COFadjournmentorder_1000200, description: `Court assessment orders-Form 9 - COF - adjournment order`, shortDescription: `Court assessment orders-Form 9 - COF` },
	{ code: FormSetFormTypeUsage.ClosureCasenote_10024, description: `Closure-Case note`, shortDescription: `CP Suicide closure-Case note` },
	{ code: FormSetFormTypeUsage.ClosureCPcasenote_115918, description: `Closure-CP case note`, shortDescription: `CP Suicide closure-CP case note` },
	{ code: FormSetFormTypeUsage.ClosureCPcasenote_3000026, description: `Closure-CP case note`, shortDescription: `CP Suicide closure-CP case note` },
	{ code: FormSetFormTypeUsage.ClosureClosureform_16001, description: `Closure-Closure form`, shortDescription: `CP Suicide closure-Suicide risk closure form` },
	{ code: FormSetFormTypeUsage.ClosureClosureform_440011, description: `Closure-Closure form`, shortDescription: `CP Suicide closure-Suicide risk closure form` },
	{ code: FormSetFormTypeUsage.ClosureYJcasenote_115977, description: `Closure-YJ case note`, shortDescription: `CP Suicide closure-YJ case note` },
	{ code: FormSetFormTypeUsage.ClosureYJcasenote_1000198, description: `Closure-YJ case note`, shortDescription: `CP Suicide closure-YJ case note` },
	{ code: FormSetFormTypeUsage.ClosureYJcasenote_4101054, description: `Closure-YJ case note`, shortDescription: `CP Suicide closure-YJ case note` },
	{ code: FormSetFormTypeUsage.SuicideCasenote_10023, description: `Suicide-Case note`, shortDescription: `CP Suicide-Case note` },
	{ code: FormSetFormTypeUsage.SuicideCPcasenote_115917, description: `Suicide-CP case note`, shortDescription: `CP Suicide-CP case note` },
	{ code: FormSetFormTypeUsage.SuicideCPcasenote_3000025, description: `Suicide-CP case note`, shortDescription: `CP Suicide-CP case note` },
	{ code: FormSetFormTypeUsage.SuicideMediumtolongtermsuicideriskmanagementplan_16007, description: `Suicide-Medium to long term suicide risk management plan`, shortDescription: `CP Suicide-SR1 medium to long term plan` },
	{ code: FormSetFormTypeUsage.SuicideMediumtolongtermsuicideriskmanagementplan_440002, description: `Suicide-Medium to long term suicide risk management plan`, shortDescription: `CP Suicide-SR1 medium to long term plan` },
	{ code: FormSetFormTypeUsage.SuicideSuicideriskalertSR1_16000, description: `Suicide-Suicide risk alert (SR1)`, shortDescription: `CP Suicide-Suicide risk alert (SR1)` },
	{ code: FormSetFormTypeUsage.SuicideSuicideriskalertSR1_16008, description: `Suicide-Suicide risk alert (SR1)`, shortDescription: `CP Suicide-Suicide risk alert (SR1)` },
	{ code: FormSetFormTypeUsage.SuicideSuicideriskalertSR1_345201, description: `Suicide-Suicide risk alert (SR1)`, shortDescription: `CP Suicide-Suicide risk alert (SR1)` },
	{ code: FormSetFormTypeUsage.SuicideYJcasenote_115976, description: `Suicide-YJ case note`, shortDescription: `CP Suicide-YJ case note` },
	{ code: FormSetFormTypeUsage.SuicideYJcasenote_1000197, description: `Suicide-YJ case note`, shortDescription: `CP Suicide-YJ case note` },
	{ code: FormSetFormTypeUsage.SuicideYJcasenote_4101053, description: `Suicide-YJ case note`, shortDescription: `CP Suicide-YJ case note` },
	{ code: FormSetFormTypeUsage.CPCRAssessmentActionActionplan_15410, description: `CPCR Assessment/Action-Action plan`, shortDescription: `CPCR Assessment/Action-Action plan` },
	{ code: FormSetFormTypeUsage.CPCRAssessmentActionActionplan_100145, description: `CPCR Assessment/Action-Action plan`, shortDescription: `CPCR Assessment/Action-Action plan` },
	{ code: FormSetFormTypeUsage.CPCRAssessmentActionAssessmentplan_15409, description: `CPCR Assessment/Action-Assessment plan`, shortDescription: `CPCR Assessment/Action-Assessment plan` },
	{ code: FormSetFormTypeUsage.CPCRAssessmentActionAssessmentplan_100135, description: `CPCR Assessment/Action-Assessment plan`, shortDescription: `CPCR Assessment/Action-Assessment plan` },
	{ code: FormSetFormTypeUsage.CPCRAssessmentActionCasenote_10027, description: `CPCR Assessment/Action-Case note`, shortDescription: `CPCR Assessment/Action-Case note` },
	{ code: FormSetFormTypeUsage.CPCRAssessmentActionClosureform_15411, description: `CPCR Assessment/Action-Closure form`, shortDescription: `CPCR Assessment/Action-Closure form` },
	{ code: FormSetFormTypeUsage.CPCRAssessmentActionClosureform_420014, description: `CPCR Assessment/Action-Closure form`, shortDescription: `CPCR Assessment/Action-Closure form` },
	{ code: FormSetFormTypeUsage.CPCRAssessmentActionCPcasenote_115921, description: `CPCR Assessment/Action-CP case note`, shortDescription: `CPCR Assessment/Action-CP case note` },
	{ code: FormSetFormTypeUsage.CPCRAssessmentActionCPcasenote_3000028, description: `CPCR Assessment/Action-CP case note`, shortDescription: `CPCR Assessment/Action-CP case note` },
	{ code: FormSetFormTypeUsage.DecisionmakingAdditionalnotifiedconcerns_15304, description: `Decision making-Additional notified concerns`, shortDescription: `Decision making-Additional notified concerns` },
	{ code: FormSetFormTypeUsage.DecisionmakingAdditionalnotifiedconcerns_18005, description: `Decision making-Additional notified concerns`, shortDescription: `Decision making-Additional notified concerns` },
	{ code: FormSetFormTypeUsage.DecisionmakingAdditionalnotifiedconcerns_31015, description: `Decision making-Additional notified concerns`, shortDescription: `Decision making-Additional notified concerns` },
	{ code: FormSetFormTypeUsage.DecisionmakingAdditionalnotifiedconcerns_100090, description: `Decision making-Additional notified concerns`, shortDescription: `Decision making-Additional notified concerns` },
	{ code: FormSetFormTypeUsage.DecisionmakingAdditionalnotifiedconcerns_100147, description: `Decision making-Additional notified concerns`, shortDescription: `Decision making-Additional notified concerns` },
	{ code: FormSetFormTypeUsage.DecisionmakingCasenote_10006, description: `Decision making-Case note`, shortDescription: `Decision making-Case note` },
	{ code: FormSetFormTypeUsage.DecisionmakingCPcasenote_115900, description: `Decision making-CP case note`, shortDescription: `Decision making-CP case note` },
	{ code: FormSetFormTypeUsage.DecisionmakingCPcasenote_300008, description: `Decision making-CP case note`, shortDescription: `Decision making-CP case note` },
	{ code: FormSetFormTypeUsage.DecisionmakingRecognisedentityparticipation_15313, description: `Decision making-Recognised entity participation`, shortDescription: `Decision making-Recognised entity participation` },
	{ code: FormSetFormTypeUsage.DecisionmakingRecognisedentityparticipation_18007, description: `Decision making-Recognised entity participation`, shortDescription: `Decision making-Recognised entity participation` },
	{ code: FormSetFormTypeUsage.DecisionmakingRecognisedentityparticipation_30021, description: `Decision making-Recognised entity participation`, shortDescription: `Decision making-Recognised entity participation` },
	{ code: FormSetFormTypeUsage.DecisionmakingScreeningcriteriaResponsepriority_31008, description: `Decision making-Screening criteria/Response priority`, shortDescription: `Decision making-Screening criteria/Response` },
	{ code: FormSetFormTypeUsage.DecisionmakingScreeningcriteriaResponsepriority_15303, description: `Decision making-Screening criteria/Response priority`, shortDescription: `Decision making-Screening criteria/Response priority` },
	{ code: FormSetFormTypeUsage.DecisionmakingScreeningcriteriaResponsepriority_18004, description: `Decision making-Screening criteria/Response priority`, shortDescription: `Decision making-Screening criteria/Response priority` },
	{ code: FormSetFormTypeUsage.AdministrationCasenote_10030, description: `Administration-Case note`, shortDescription: `Den Administration-Case note` },
	{ code: FormSetFormTypeUsage.AdministrationClosureform_16101, description: `Administration-Closure form`, shortDescription: `Den Administration-Closure form` },
	{ code: FormSetFormTypeUsage.AdministrationClosureform_420019, description: `Administration-Closure form`, shortDescription: `Den Administration-Closure form` },
	{ code: FormSetFormTypeUsage.AdministrationCPcasenote_115922, description: `Administration-CP case note`, shortDescription: `Den Administration-CP case note` },
	{ code: FormSetFormTypeUsage.AdministrationCPcasenote_3000029, description: `Administration-CP case note`, shortDescription: `Den Administration-CP case note` },
	{ code: FormSetFormTypeUsage.AdministrationClosureofdetention_16100, description: `Administration-Closure of detention`, shortDescription: `Den Administration-Detention closure` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCasenote_10005, description: `Departmental history-Case note`, shortDescription: `Departmental history-Case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCasenote_10009, description: `Departmental history-Case note`, shortDescription: `Departmental history-Case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCasenote_10012, description: `Departmental history-Case note`, shortDescription: `Departmental history-Case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCasenote_10016, description: `Departmental history-Case note`, shortDescription: `Departmental history-Case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCasenote_10032, description: `Departmental history-Case note`, shortDescription: `Departmental history-Case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCasenote_10033, description: `Departmental history-Case note`, shortDescription: `Departmental history-Case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCasenote_10034, description: `Departmental history-Case note`, shortDescription: `Departmental history-Case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCasenote_10035, description: `Departmental history-Case note`, shortDescription: `Departmental history-Case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCasenote_10036, description: `Departmental history-Case note`, shortDescription: `Departmental history-Case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCPcasenote_30000, description: `Departmental history-CP case note`, shortDescription: `Departmental history-CP case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCPcasenote_30008, description: `Departmental history-CP case note`, shortDescription: `Departmental history-CP case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCPcasenote_115889, description: `Departmental history-CP case note`, shortDescription: `Departmental history-CP case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCPcasenote_115899, description: `Departmental history-CP case note`, shortDescription: `Departmental history-CP case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCPcasenote_115903, description: `Departmental history-CP case note`, shortDescription: `Departmental history-CP case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCPcasenote_115906, description: `Departmental history-CP case note`, shortDescription: `Departmental history-CP case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCPcasenote_115910, description: `Departmental history-CP case note`, shortDescription: `Departmental history-CP case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCPcasenote_115924, description: `Departmental history-CP case note`, shortDescription: `Departmental history-CP case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCPcasenote_115925, description: `Departmental history-CP case note`, shortDescription: `Departmental history-CP case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCPcasenote_115926, description: `Departmental history-CP case note`, shortDescription: `Departmental history-CP case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCPcasenote_115927, description: `Departmental history-CP case note`, shortDescription: `Departmental history-CP case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCPcasenote_115928, description: `Departmental history-CP case note`, shortDescription: `Departmental history-CP case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCPcasenote_116000, description: `Departmental history-CP case note`, shortDescription: `Departmental history-CP case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCPcasenote_300001, description: `Departmental history-CP case note`, shortDescription: `Departmental history-CP case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCPcasenote_300007, description: `Departmental history-CP case note`, shortDescription: `Departmental history-CP case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCPcasenote_3000011, description: `Departmental history-CP case note`, shortDescription: `Departmental history-CP case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCPcasenote_3000014, description: `Departmental history-CP case note`, shortDescription: `Departmental history-CP case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCPcasenote_3000018, description: `Departmental history-CP case note`, shortDescription: `Departmental history-CP case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCPcasenote_3000031, description: `Departmental history-CP case note`, shortDescription: `Departmental history-CP case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCPcasenote_3000032, description: `Departmental history-CP case note`, shortDescription: `Departmental history-CP case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCPcasenote_3000033, description: `Departmental history-CP case note`, shortDescription: `Departmental history-CP case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCPcasenote_3000034, description: `Departmental history-CP case note`, shortDescription: `Departmental history-CP case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCPcasenote_3000035, description: `Departmental history-CP case note`, shortDescription: `Departmental history-CP case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryCPcasenote_3000050, description: `Departmental history-CP case note`, shortDescription: `Departmental history-CP case note` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryDepartmentalhistory_15302, description: `Departmental history-Departmental history`, shortDescription: `Departmental history-Departmental history` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryDepartmentalhistory_15404, description: `Departmental history-Departmental history`, shortDescription: `Departmental history-Departmental history` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryDepartmentalhistory_15513, description: `Departmental history-Departmental history`, shortDescription: `Departmental history-Departmental history` },
	{ code: FormSetFormTypeUsage.DepartmentalhistoryDepartmentalhistory_15601, description: `Departmental history-Departmental history`, shortDescription: `Departmental history-Departmental history` },
	{ code: FormSetFormTypeUsage.EscapeEscapealert_16400, description: `Escape-Escape alert`, shortDescription: `Escape-Escape alert` },
	{ code: FormSetFormTypeUsage.EscapeEscapealert_420001, description: `Escape-Escape alert`, shortDescription: `Escape-Escape alert` },
	{ code: FormSetFormTypeUsage.EscapeYJcasenote_16401, description: `Escape-YJ case note`, shortDescription: `Escape-YJ case note` },
	{ code: FormSetFormTypeUsage.EscapeYJcasenote_410102, description: `Escape-YJ case note`, shortDescription: `Escape-YJ case note` },
	{ code: FormSetFormTypeUsage.EscapeYJcasenote_1000146, description: `Escape-YJ case note`, shortDescription: `Escape-YJ case note` },
	{ code: FormSetFormTypeUsage.FormsandattachmentsYJcasenote_115820, description: `Forms and attachments-YJ case note`, shortDescription: `Forms and attachments-YJ case note` },
	{ code: FormSetFormTypeUsage.FormsandattachmentsYJcasenote_1000157, description: `Forms and attachments-YJ case note`, shortDescription: `Forms and attachments-YJ case note` },
	{ code: FormSetFormTypeUsage.FormsandattachmentsYJcasenote_4101013, description: `Forms and attachments-YJ case note`, shortDescription: `Forms and attachments-YJ case note` },
	{ code: FormSetFormTypeUsage.FormsandattachmentsYoungpersonsummary_115812, description: `Forms and attachments-Young person summary`, shortDescription: `Forms and attachments-Young person summary` },
	{ code: FormSetFormTypeUsage.FormsandattachmentsYoungpersonsummary_400703, description: `Forms and attachments-Young person summary`, shortDescription: `Forms and attachments-Young person summary` },
	{ code: FormSetFormTypeUsage.FormsandattachmentsYoungpersonsummary_460011, description: `Forms and attachments-Young person summary`, shortDescription: `Forms and attachments-Young person summary` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsCasemanagement_15807, description: `Individual documents-Case management`, shortDescription: `Individual documents-Case management` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsCasenote_10021, description: `Individual documents-Case note`, shortDescription: `Individual documents-Case note` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsCaseplan_15800, description: `Individual documents-Case plan`, shortDescription: `Individual documents-Case plan` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsCaseplan_17800, description: `Individual documents-Case plan`, shortDescription: `Individual documents-Case plan` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsCaseplan_100064, description: `Individual documents-Case plan`, shortDescription: `Individual documents-Case plan` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsCaseplan_100137, description: `Individual documents-Case plan`, shortDescription: `Individual documents-Case plan` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsCaseplan_100141, description: `Individual documents-Case plan`, shortDescription: `Individual documents-Case plan` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsCaseplan_325301, description: `Individual documents-Case plan`, shortDescription: `Individual documents-Case plan` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsCaseplan_325311, description: `Individual documents-Case plan`, shortDescription: `Individual documents-Case plan` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsChildstrengthsandneedsassessment_15810, description: `Individual documents-Child strengths and needs assessment`, shortDescription: `Individual documents-Child strengths and needs assessment` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsChildstrengthsandneedsassessment_18014, description: `Individual documents-Child strengths and needs assessment`, shortDescription: `Individual documents-Child strengths and needs assessment` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsChildstrengthsandneedsassessment_100143, description: `Individual documents-Child strengths and needs assessment`, shortDescription: `Individual documents-Child strengths and needs assessment` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsChildstrengthsandneedsassessment_325901, description: `Individual documents-Child strengths and needs assessment`, shortDescription: `Individual documents-Child strengths and needs assessment` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsClosureform_15812, description: `Individual documents-Closure form`, shortDescription: `Individual documents-Closure form` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsClosureform_420018, description: `Individual documents-Closure form`, shortDescription: `Individual documents-Closure form` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsClosureform_4101056, description: `Individual documents-Closure form`, shortDescription: `Individual documents-Closure form` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsClosureofongoingintervention_15811, description: `Individual documents-Closure of ongoing intervention`, shortDescription: `Individual documents-Closure of OI` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsCPcasenote_115915, description: `Individual documents-CP case note`, shortDescription: `Individual documents-CP case note` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsCPcasenote_3000023, description: `Individual documents-CP case note`, shortDescription: `Individual documents-CP case note` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsIndependententity_300252, description: `Individual documents-Independent entity`, shortDescription: `Individual documents-Independent entity` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsLTGtosuitablepersonCaseplan_100133, description: `Individual documents-LTG to suitable person - Case plan`, shortDescription: `Individual documents-LTG - Case plan` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsLTGtosuitablepersonCaseplan_100142, description: `Individual documents-LTG to suitable person - Case plan`, shortDescription: `Individual documents-LTG - Case plan` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsLTGtosuitablepersonCaseplan_325501, description: `Individual documents-LTG to suitable person - Case plan`, shortDescription: `Individual documents-LTG - Case plan` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsLTGtosuitablepersonContactandreviewreport_100132, description: `Individual documents-LTG to suitable person - Contact and review report`, shortDescription: `Individual documents-LTG - Contact and review` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsLTGtosuitablepersonContactandreviewreport_325201, description: `Individual documents-LTG to suitable person - Contact and review report`, shortDescription: `Individual documents-LTG - Contact and review` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsPCOCaseplan_327501, description: `Individual documents-PCO - Case plan`, shortDescription: `Individual documents-PCO - Case plan` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsRecognisedentityparticipation_15808, description: `Individual documents-Recognised entity participation`, shortDescription: `Individual documents-Recognised entity participation` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsRecognisedentityparticipation_18010, description: `Individual documents-Recognised entity participation`, shortDescription: `Individual documents-Recognised entity participation` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsRecognisedentityparticipation_300201, description: `Individual documents-Recognised entity participation`, shortDescription: `Individual documents-Recognised entity participation` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsReviewreport_15809, description: `Individual documents-Review report`, shortDescription: `Individual documents-Review report` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsReviewreport_17806, description: `Individual documents-Review report`, shortDescription: `Individual documents-Review report` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsReviewreport_100081, description: `Individual documents-Review report`, shortDescription: `Individual documents-Review report` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsReviewreport_100159, description: `Individual documents-Review report`, shortDescription: `Individual documents-Review report` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsReviewreport_327201, description: `Individual documents-Review report`, shortDescription: `Individual documents-Review report` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsReviewreport_327211, description: `Individual documents-Review report`, shortDescription: `Individual documents-Review report` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsTransitionplan_100134, description: `Individual documents-Transition plan`, shortDescription: `Individual documents-Transition plan` },
	{ code: FormSetFormTypeUsage.IndividualdocumentsTransitionplan_327301, description: `Individual documents-Transition plan`, shortDescription: `Individual documents-Transition plan` },
	{ code: FormSetFormTypeUsage.InformationgatheringandinterviewsCasenote_10013, description: `Information gathering and interviews-Case note`, shortDescription: `Information gathering and inte-Case note` },
	{ code: FormSetFormTypeUsage.InformationgatheringandinterviewsCPcasenote_115907, description: `Information gathering and interviews-CP case note`, shortDescription: `Information gathering and inte-CP case note` },
	{ code: FormSetFormTypeUsage.InformationgatheringandinterviewsCPcasenote_3000015, description: `Information gathering and interviews-CP case note`, shortDescription: `Information gathering and inte-CP case note` },
	{ code: FormSetFormTypeUsage.InformationgatheringandinterviewsInformationgathering_15503, description: `Information gathering and interviews-Information gathering`, shortDescription: `Information gathering and inte-Information gathering` },
	{ code: FormSetFormTypeUsage.InformationgatheringandinterviewsInformationgathering_31005, description: `Information gathering and interviews-Information gathering`, shortDescription: `Information gathering and inte-Information gathering` },
	{ code: FormSetFormTypeUsage.InformationgatheringandinterviewsRecordofinterviews_15504, description: `Information gathering and interviews-Record of interviews`, shortDescription: `Information gathering and inte-Record of interviews` },
	{ code: FormSetFormTypeUsage.InformationgatheringandinterviewsRecordofinterviewsuseofpowers_31013, description: `Information gathering and interviews-Record of interviews / use of powers`, shortDescription: `Information gathering and inte-Record of interviews & powers` },
	{ code: FormSetFormTypeUsage.InformationgatheringandinterviewsRecordofinterviewsuseofpowers_100131, description: `Information gathering and interviews-Record of interviews / use of powers`, shortDescription: `Information gathering and inte-Record of interviews & powers` },
	{ code: FormSetFormTypeUsage.InformationgatheringandinterviewsRecordofuseofpowers_15506, description: `Information gathering and interviews-Record of use of powers`, shortDescription: `Information gathering and inte-Record of use of powers` },
	{ code: FormSetFormTypeUsage.InformationgatheringandinterviewsCasenote_10017, description: `Information gathering and interviews-Case note`, shortDescription: `Information gathering and-Case note` },
	{ code: FormSetFormTypeUsage.InformationgatheringandinterviewsCPcasenote_30012, description: `Information gathering and interviews-CP case note`, shortDescription: `Information gathering and-CP case note` },
	{ code: FormSetFormTypeUsage.InformationgatheringandinterviewsCPcasenote_115911, description: `Information gathering and interviews-CP case note`, shortDescription: `Information gathering and-CP case note` },
	{ code: FormSetFormTypeUsage.InformationgatheringandinterviewsCPcasenote_3000019, description: `Information gathering and interviews-CP case note`, shortDescription: `Information gathering and-CP case note` },
	{ code: FormSetFormTypeUsage.InformationgatheringandinterviewsRecordofchecksandconsultation_15602, description: `Information gathering and interviews-Record of checks and consultation`, shortDescription: `Information gathering and-Record of checks` },
	{ code: FormSetFormTypeUsage.InformationgatheringandinterviewsRecordofinterviews_15603, description: `Information gathering and interviews-Record of interviews`, shortDescription: `Information gathering and-Record of interviews` },
	{ code: FormSetFormTypeUsage.InformationgatheringandinterviewsRecordofinterviews_18012, description: `Information gathering and interviews-Record of interviews`, shortDescription: `Information gathering and-Record of interviews` },
	{ code: FormSetFormTypeUsage.InformationgatheringandinterviewsRecordofinterviews_30013, description: `Information gathering and interviews-Record of interviews`, shortDescription: `Information gathering and-Record of interviews` },
	{ code: FormSetFormTypeUsage.InformationgatheringDecisionmakingAdditionalnotifiedconcerns_15403, description: `Information gathering/Decision making-Additional notified concerns`, shortDescription: `Information gathering/Decision-Additional notified concerns` },
	{ code: FormSetFormTypeUsage.InformationgatheringDecisionmakingAdditionalnotifiedconcerns_100098, description: `Information gathering/Decision making-Additional notified concerns`, shortDescription: `Information gathering/Decision-Additional notified concerns` },
	{ code: FormSetFormTypeUsage.InformationgatheringDecisionmakingAdditionalnotifiedconcerns_100150, description: `Information gathering/Decision making-Additional notified concerns`, shortDescription: `Information gathering/Decision-Additional notified concerns` },
	{ code: FormSetFormTypeUsage.InformationgatheringDecisionmakingCasenote_10008, description: `Information gathering/Decision making-Case note`, shortDescription: `Information gathering/Decision-Case note` },
	{ code: FormSetFormTypeUsage.InformationgatheringDecisionmakingConcernsconsultationandresponsedecision_30002, description: `Information gathering/Decision making-Concerns, consultation and response decision`, shortDescription: `Information gathering/Decision-Concerns, consultation and response decision` },
	{ code: FormSetFormTypeUsage.InformationgatheringDecisionmakingCPcasenote_30001, description: `Information gathering/Decision making-CP case note`, shortDescription: `Information gathering/Decision-CP case note` },
	{ code: FormSetFormTypeUsage.InformationgatheringDecisionmakingCPcasenote_115902, description: `Information gathering/Decision making-CP case note`, shortDescription: `Information gathering/Decision-CP case note` },
	{ code: FormSetFormTypeUsage.InformationgatheringDecisionmakingCPcasenote_3000010, description: `Information gathering/Decision making-CP case note`, shortDescription: `Information gathering/Decision-CP case note` },
	{ code: FormSetFormTypeUsage.InformationgatheringDecisionmakingRecognisedentityparticipation_15402, description: `Information gathering/Decision making-Recognised entity participation`, shortDescription: `Information gathering/Decision-Recognised entity participation` },
	{ code: FormSetFormTypeUsage.InformationgatheringDecisionmakingRecognisedentityparticipation_18008, description: `Information gathering/Decision making-Recognised entity participation`, shortDescription: `Information gathering/Decision-Recognised entity participation` },
	{ code: FormSetFormTypeUsage.InformationgatheringDecisionmakingRecognisedentityparticipation_30003, description: `Information gathering/Decision making-Recognised entity participation`, shortDescription: `Information gathering/Decision-Recognised entity participation` },
	{ code: FormSetFormTypeUsage.InformationgatheringDecisionmakingRecordofchecksandconsultation_15401, description: `Information gathering/Decision making-Record of checks and consultation`, shortDescription: `Information gathering/Decision-Record of checks` },
	{ code: FormSetFormTypeUsage.InformationgatheringDecisionmakingRecordofconcerns_15400, description: `Information gathering/Decision making-Record of concerns`, shortDescription: `Information gathering/Decision-Record of concerns` },
	{ code: FormSetFormTypeUsage.InformationgatheringDecisionmakingRecordofconcerns_100097, description: `Information gathering/Decision making-Record of concerns`, shortDescription: `Information gathering/Decision-Record of concerns` },
	{ code: FormSetFormTypeUsage.InformationgatheringDecisionmakingRecordofinterviews_18020, description: `Information gathering/Decision making-Record of interviews`, shortDescription: `Information gathering/Decision-Record of interviews` },
	{ code: FormSetFormTypeUsage.InformationgatheringCasenote_10004, description: `Information gathering-Case note`, shortDescription: `Information gathering-Case note` },
	{ code: FormSetFormTypeUsage.InformationgatheringCPcasenote_115898, description: `Information gathering-CP case note`, shortDescription: `Information gathering-CP case note` },
	{ code: FormSetFormTypeUsage.InformationgatheringCPcasenote_300006, description: `Information gathering-CP case note`, shortDescription: `Information gathering-CP case note` },
	{ code: FormSetFormTypeUsage.InformationgatheringIntake_310201, description: `Information gathering-Intake`, shortDescription: `Information gathering-Intake` },
	{ code: FormSetFormTypeUsage.InformationgatheringPrenotificationchecks_15301, description: `Information gathering-Pre-notification checks`, shortDescription: `Information gathering-Pre-notification checks` },
	{ code: FormSetFormTypeUsage.InformationgatheringPrenotificationchecks_31001, description: `Information gathering-Pre-notification checks`, shortDescription: `Information gathering-Pre-notification checks` },
	{ code: FormSetFormTypeUsage.InformationgatheringRecognisedentityparticipation_15312, description: `Information gathering-Recognised entity participation`, shortDescription: `Information gathering-Recognised entity participation` },
	{ code: FormSetFormTypeUsage.InformationgatheringRecognisedentityparticipation_18006, description: `Information gathering-Recognised entity participation`, shortDescription: `Information gathering-Recognised entity participation` },
	{ code: FormSetFormTypeUsage.InformationgatheringRecognisedentityparticipation_30020, description: `Information gathering-Recognised entity participation`, shortDescription: `Information gathering-Recognised entity participation` },
	{ code: FormSetFormTypeUsage.InformationgatheringRecordofconcerns_15300, description: `Information gathering-Record of concerns`, shortDescription: `Information gathering-Record of concerns` },
	{ code: FormSetFormTypeUsage.InformationgatheringRecordofconcerns_31010, description: `Information gathering-Record of concerns`, shortDescription: `Information gathering-Record of concerns` },
	{ code: FormSetFormTypeUsage.InformationgatheringRecordofconcerns_100065, description: `Information gathering-Record of concerns`, shortDescription: `Information gathering-Record of concerns` },
	{ code: FormSetFormTypeUsage.InformationgatheringRecordofconcerns_100092, description: `Information gathering-Record of concerns`, shortDescription: `Information gathering-Record of concerns` },
	{ code: FormSetFormTypeUsage.InterviewAttachment_115878, description: `Interview-Attachment`, shortDescription: `Interview-Attachment` },
	{ code: FormSetFormTypeUsage.InterviewDrugtestingconditioninformation_115875, description: `Interview-Drug testing condition information`, shortDescription: `Interview-Drug testing condition` },
	{ code: FormSetFormTypeUsage.InterviewDrugtestingconditioninformation_410071, description: `Interview-Drug testing condition information`, shortDescription: `Interview-Drug testing condition` },
	{ code: FormSetFormTypeUsage.InterviewHealthchecklist_115872, description: `Interview-Health checklist`, shortDescription: `Interview-Health checklist` },
	{ code: FormSetFormTypeUsage.InterviewHealthchecklist_410081, description: `Interview-Health checklist`, shortDescription: `Interview-Health checklist` },
	{ code: FormSetFormTypeUsage.InterviewInformationtoparentsorcarer_100155, description: `Interview-Information to parent/s or carer`, shortDescription: `Interview-Information to parent/s` },
	{ code: FormSetFormTypeUsage.InterviewInformationtoparentsorcarer_115873, description: `Interview-Information to parent/s or carer`, shortDescription: `Interview-Information to parent/s` },
	{ code: FormSetFormTypeUsage.InterviewInformationtoparentsorcarer_115985, description: `Interview-Information to parent/s or carer`, shortDescription: `Interview-Information to parent/s` },
	{ code: FormSetFormTypeUsage.InterviewInformationtoparentsorcarer_410091, description: `Interview-Information to parent/s or carer`, shortDescription: `Interview-Information to parent/s` },
	{ code: FormSetFormTypeUsage.InterviewInitialinterviewYDC_115871, description: `Interview-Initial interview - YDC`, shortDescription: `Interview-Initial interview - YDC` },
	{ code: FormSetFormTypeUsage.InterviewInitialinterviewYDC_410051, description: `Interview-Initial interview - YDC`, shortDescription: `Interview-Initial interview - YDC` },
	{ code: FormSetFormTypeUsage.InterviewInitialinterviewYJSC_100152, description: `Interview-Initial interview - YJSC`, shortDescription: `Interview-Initial interview - YJSC` },
	{ code: FormSetFormTypeUsage.InterviewInitialinterviewYJSC_100158, description: `Interview-Initial interview - YJSC`, shortDescription: `Interview-Initial interview - YJSC` },
	{ code: FormSetFormTypeUsage.InterviewInitialinterviewYJSC_115870, description: `Interview-Initial interview - YJSC`, shortDescription: `Interview-Initial interview - YJSC` },
	{ code: FormSetFormTypeUsage.InterviewInitialinterviewYJSC_410041, description: `Interview-Initial interview - YJSC`, shortDescription: `Interview-Initial interview - YJSC` },
	{ code: FormSetFormTypeUsage.InterviewSupervisedreleaseorder_115874, description: `Interview-Supervised release order`, shortDescription: `Interview-Supervised release order` },
	{ code: FormSetFormTypeUsage.InterviewSupervisedreleaseorder_115982, description: `Interview-Supervised release order`, shortDescription: `Interview-Supervised release order` },
	{ code: FormSetFormTypeUsage.InterviewSupervisedreleaseorder_410441, description: `Interview-Supervised release order`, shortDescription: `Interview-Supervised release order` },
	{ code: FormSetFormTypeUsage.InterviewYJcasenote_115877, description: `Interview-YJ case note`, shortDescription: `Interview-YJ case note` },
	{ code: FormSetFormTypeUsage.InterviewYJcasenote_1000175, description: `Interview-YJ case note`, shortDescription: `Interview-YJ case note` },
	{ code: FormSetFormTypeUsage.InterviewYJcasenote_4101031, description: `Interview-YJ case note`, shortDescription: `Interview-YJ case note` },
	{ code: FormSetFormTypeUsage.InvestigationandassessmentplanningAdditionalnotifiedconcerns_15500, description: `Investigation and assessment planning-Additional notified concerns`, shortDescription: `Investigation and assessment p-Additional notified concerns` },
	{ code: FormSetFormTypeUsage.InvestigationandassessmentplanningAdditionalnotifiedconcerns_18000, description: `Investigation and assessment planning-Additional notified concerns`, shortDescription: `Investigation and assessment p-Additional notified concerns` },
	{ code: FormSetFormTypeUsage.InvestigationandassessmentplanningAdditionalnotifiedconcerns_31016, description: `Investigation and assessment planning-Additional notified concerns`, shortDescription: `Investigation and assessment p-Additional notified concerns` },
	{ code: FormSetFormTypeUsage.InvestigationandassessmentplanningAdditionalnotifiedconcerns_100130, description: `Investigation and assessment planning-Additional notified concerns`, shortDescription: `Investigation and assessment p-Additional notified concerns` },
	{ code: FormSetFormTypeUsage.InvestigationandassessmentplanningAdditionalnotifiedconcerns_100136, description: `Investigation and assessment planning-Additional notified concerns`, shortDescription: `Investigation and assessment p-Additional notified concerns` },
	{ code: FormSetFormTypeUsage.InvestigationandassessmentplanningAdditionalnotifiedconcerns_100148, description: `Investigation and assessment planning-Additional notified concerns`, shortDescription: `Investigation and assessment p-Additional notified concerns` },
	{ code: FormSetFormTypeUsage.InvestigationandassessmentplanningCasenote_10011, description: `Investigation and assessment planning-Case note`, shortDescription: `Investigation and assessment p-Case note` },
	{ code: FormSetFormTypeUsage.InvestigationandassessmentplanningCPcasenote_115905, description: `Investigation and assessment planning-CP case note`, shortDescription: `Investigation and assessment p-CP case note` },
	{ code: FormSetFormTypeUsage.InvestigationandassessmentplanningCPcasenote_3000013, description: `Investigation and assessment planning-CP case note`, shortDescription: `Investigation and assessment p-CP case note` },
	{ code: FormSetFormTypeUsage.InvestigationandassessmentplanningInvestigationandassessmentplan_15501, description: `Investigation and assessment planning-Investigation and assessment plan`, shortDescription: `Investigation and assessment p-Investigation plan` },
	{ code: FormSetFormTypeUsage.InvestigationandassessmentplanningNotifiedconcernsResponsepriority_15514, description: `Investigation and assessment planning-Notified concerns / Response priority`, shortDescription: `Investigation and assessment p-Notified concerns` },
	{ code: FormSetFormTypeUsage.InvestigationandassessmentplanningNotifiedconcernsResponsepriority_31017, description: `Investigation and assessment planning-Notified concerns / Response priority`, shortDescription: `Investigation and assessment p-Notified concerns` },
	{ code: FormSetFormTypeUsage.InvestigationandassessmentplanningNotifiedconcernsResponsepriority_100149, description: `Investigation and assessment planning-Notified concerns / Response priority`, shortDescription: `Investigation and assessment p-Notified concerns` },
	{ code: FormSetFormTypeUsage.InvestigationandassessmentplanningRecordofactionsmobilefamily_15502, description: `Investigation and assessment planning-Record of actions - mobile family`, shortDescription: `Investigation and assessment p-Record of actions` },
	{ code: FormSetFormTypeUsage.InvestigationandassessmentplanningRecordofactionsmobilefamily_31004, description: `Investigation and assessment planning-Record of actions - mobile family`, shortDescription: `Investigation and assessment p-Record of actions` },
	{ code: FormSetFormTypeUsage.InvestigationandassessmentplanningAdditionalnotifiedconcerns_15604, description: `Investigation and assessment planning-Additional notified concerns`, shortDescription: `Investigation planning-Additional notified concerns` },
	{ code: FormSetFormTypeUsage.InvestigationandassessmentplanningAdditionalnotifiedconcerns_30011, description: `Investigation and assessment planning-Additional notified concerns`, shortDescription: `Investigation planning-Additional notified concerns` },
	{ code: FormSetFormTypeUsage.InvestigationandassessmentplanningAdditionalnotifiedconcerns_100151, description: `Investigation and assessment planning-Additional notified concerns`, shortDescription: `Investigation planning-Additional notified concerns` },
	{ code: FormSetFormTypeUsage.InvestigationandassessmentplanningAdditionalnotifiedconcerns_115990, description: `Investigation and assessment planning-Additional notified concerns`, shortDescription: `Investigation planning-Additional notified concerns` },
	{ code: FormSetFormTypeUsage.InvestigationandassessmentplanningCasenote_10015, description: `Investigation and assessment planning-Case note`, shortDescription: `Investigation planning-Case note` },
	{ code: FormSetFormTypeUsage.InvestigationandassessmentplanningCPcasenote_30009, description: `Investigation and assessment planning-CP case note`, shortDescription: `Investigation planning-CP case note` },
	{ code: FormSetFormTypeUsage.InvestigationandassessmentplanningCPcasenote_115909, description: `Investigation and assessment planning-CP case note`, shortDescription: `Investigation planning-CP case note` },
	{ code: FormSetFormTypeUsage.InvestigationandassessmentplanningCPcasenote_3000017, description: `Investigation and assessment planning-CP case note`, shortDescription: `Investigation planning-CP case note` },
	{ code: FormSetFormTypeUsage.InvestigationandassessmentplanningHarmreportIAplan_30010, description: `Investigation and assessment planning-Harm report - I&A plan`, shortDescription: `Investigation planning-Harm report - I&A plan` },
	{ code: FormSetFormTypeUsage.InvestigationandassessmentplanningMOCInvestigationandassessmentplan_15600, description: `Investigation and assessment planning-MOC Investigation and assessment plan`, shortDescription: `Investigation planning-MOC Invest & assess plan` },
	{ code: FormSetFormTypeUsage.LegacyFormsAdditionalnotifiedconcerns_115304, description: `Legacy Forms-Additional notified concerns`, shortDescription: `Legacy Forms-Additional notified concerns` },
	{ code: FormSetFormTypeUsage.LegacyFormsAdditionalnotifiedconcerns_115403, description: `Legacy Forms-Additional notified concerns`, shortDescription: `Legacy Forms-Additional notified concerns` },
	{ code: FormSetFormTypeUsage.LegacyFormsAdditionalnotifiedconcerns_115500, description: `Legacy Forms-Additional notified concerns`, shortDescription: `Legacy Forms-Additional notified concerns` },
	{ code: FormSetFormTypeUsage.LegacyFormsAdditionalnotifiedconcerns_115604, description: `Legacy Forms-Additional notified concerns`, shortDescription: `Legacy Forms-Additional notified concerns` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSAssessmentofProtectiveNeeds_20000, description: `Legacy Forms-CPS Assessment of Protective Needs`, shortDescription: `Legacy Forms-APN` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSAssessmentofProtectiveNeedsPriorVersion_20001, description: `Legacy Forms-CPS Assessment of Protective Needs (Prior Version)`, shortDescription: `Legacy Forms-APN` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSAssessmentofProtectiveNeeds_20002, description: `Legacy Forms-CPS Assessment of Protective Needs`, shortDescription: `Legacy Forms-APN` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSAssessmentofProtectiveNeedsPriorVersion_20003, description: `Legacy Forms-CPS Assessment of Protective Needs (Prior Version)`, shortDescription: `Legacy Forms-APN` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSAuthoritytoCareforChild_20009, description: `Legacy Forms-CPS Authority to Care for Child`, shortDescription: `Legacy Forms-Authority to care` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSApplicationCAO_20034, description: `Legacy Forms-CPS Application CAO`, shortDescription: `Legacy Forms-CAO` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSApplicationCAOPriorVersion_20035, description: `Legacy Forms-CPS Application CAO (Prior Version)`, shortDescription: `Legacy Forms-CAO` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSApplicationCAOExtend_20072, description: `Legacy Forms-CPS Application CAO (Extend)`, shortDescription: `Legacy Forms-CAO - extend` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSApplicationCAOExtendPriorVersion_20076, description: `Legacy Forms-CPS Application CAO (Extend) (Prior Version)`, shortDescription: `Legacy Forms-CAO - extend` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSApplicationCAOExtendVary_20074, description: `Legacy Forms-CPS Application CAO (Extend&Vary)`, shortDescription: `Legacy Forms-CAO - extend and vary` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSApplicationCAOExtendVaryPriorVersion_20078, description: `Legacy Forms-CPS Application CAO (Extend&Vary) (Prior Version)`, shortDescription: `Legacy Forms-CAO - extend and vary` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSLettertoParentsCAO_20036, description: `Legacy Forms-CPS Letter to Parents CAO`, shortDescription: `Legacy Forms-CAO - letter` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSLettertoParentsCAOPriorVersion_20037, description: `Legacy Forms-CPS Letter to Parents CAO (Prior Version)`, shortDescription: `Legacy Forms-CAO - letter` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSApplicationCAORevoke_20075, description: `Legacy Forms-CPS Application CAO (Revoke)`, shortDescription: `Legacy Forms-CAO - revoke` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSApplicationCAORevokePriorVersion_20079, description: `Legacy Forms-CPS Application CAO (Revoke) (Prior Version)`, shortDescription: `Legacy Forms-CAO - revoke` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSApplicationCAOVary_20073, description: `Legacy Forms-CPS Application CAO (Vary)`, shortDescription: `Legacy Forms-CAO - vary` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSApplicationCAOVaryPriorVersion_20077, description: `Legacy Forms-CPS Application CAO (Vary) (Prior Version)`, shortDescription: `Legacy Forms-CAO - vary` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSCaseManagement_20040, description: `Legacy Forms-CPS Case Management`, shortDescription: `Legacy Forms-Case management` },
	{ code: FormSetFormTypeUsage.LegacyFormsCasemanagement_115809, description: `Legacy Forms-Case management`, shortDescription: `Legacy Forms-Case management` },
	{ code: FormSetFormTypeUsage.LegacyFormsCasenote_10025, description: `Legacy Forms-Case note`, shortDescription: `Legacy Forms-Case note` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSCaseNote_20010, description: `Legacy Forms-CPS Case Note`, shortDescription: `Legacy Forms-Case notes` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSCaseNotePriorVersion_20011, description: `Legacy Forms-CPS Case Note (Prior Version)`, shortDescription: `Legacy Forms-Case notes` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSCaseNote_20012, description: `Legacy Forms-CPS Case Note`, shortDescription: `Legacy Forms-Case notes` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSCaseNotePriorVersion_20013, description: `Legacy Forms-CPS Case Note (Prior Version)`, shortDescription: `Legacy Forms-Case notes` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSCasePlan_20041, description: `Legacy Forms-CPS Case Plan`, shortDescription: `Legacy Forms-Case plan` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSCasePlanPriorVersion_20043, description: `Legacy Forms-CPS Case Plan (Prior Version)`, shortDescription: `Legacy Forms-Case plan` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSCasePlanOutcomeAction_20044, description: `Legacy Forms-CPS Case Plan Outcome&Action`, shortDescription: `Legacy Forms-Case plan` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSCaseWork_20045, description: `Legacy Forms-CPS Case Work`, shortDescription: `Legacy Forms-Case work` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSChildConcernReport_20024, description: `Legacy Forms-CPS Child Concern Report`, shortDescription: `Legacy Forms-CCR` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSChildConcernReportPriorVersion_20025, description: `Legacy Forms-CPS Child Concern Report (Prior Version)`, shortDescription: `Legacy Forms-CCR` },
	{ code: FormSetFormTypeUsage.LegacyFormsChildstrengthsandneedsassessment_115810, description: `Legacy Forms-Child strengths and needs assessment`, shortDescription: `Legacy Forms-Child strengths and needs assessment` },
	{ code: FormSetFormTypeUsage.LegacyFormsClosureofongoingintervention_115811, description: `Legacy Forms-Closure of ongoing intervention`, shortDescription: `Legacy Forms-Closure of OI` },
	{ code: FormSetFormTypeUsage.LegacyFormsApplicationforCourtOrder_20097, description: `Legacy Forms-Application for Court Order`, shortDescription: `Legacy Forms-Court Application` },
	{ code: FormSetFormTypeUsage.LegacyFormsCourtOutcome_20098, description: `Legacy Forms-Court Outcome`, shortDescription: `Legacy Forms-Court Outcome` },
	{ code: FormSetFormTypeUsage.LegacyFormsCOF_20047, description: `Legacy Forms-COF`, shortDescription: `Legacy Forms-Court outcome form` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSChildPlacementConcernReport_20029, description: `Legacy Forms-CPS Child Placement Concern Report`, shortDescription: `Legacy Forms-CPCR` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSChildPlacementConcernReportPriorVersion_20030, description: `Legacy Forms-CPS Child Placement Concern Report (Prior Version)`, shortDescription: `Legacy Forms-CPCR` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSApplicationCPO_20048, description: `Legacy Forms-CPS Application CPO`, shortDescription: `Legacy Forms-CPO` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSApplicationCPOPriorVersion_20049, description: `Legacy Forms-CPS Application CPO (Prior Version)`, shortDescription: `Legacy Forms-CPO` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSApplicationCPOExtend_20080, description: `Legacy Forms-CPS Application CPO (Extend)`, shortDescription: `Legacy Forms-CPO - extend` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSApplicationCPOExtendPriorVersion_20085, description: `Legacy Forms-CPS Application CPO (Extend) (Prior Version)`, shortDescription: `Legacy Forms-CPO - extend` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSApplicationCPOExtendVary_20082, description: `Legacy Forms-CPS Application CPO (Extend&Vary)`, shortDescription: `Legacy Forms-CPO - extend and vary` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSApplicationCPOExtendVaryPriorVersion_20087, description: `Legacy Forms-CPS Application CPO (Extend&Vary) (Prior Version)`, shortDescription: `Legacy Forms-CPO - extend and vary` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSLettertoParentsCPO_20050, description: `Legacy Forms-CPS Letter to Parents CPO`, shortDescription: `Legacy Forms-CPO - letter` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSLettertoParentsCPOPriorVersion_20051, description: `Legacy Forms-CPS Letter to Parents CPO (Prior Version)`, shortDescription: `Legacy Forms-CPO - letter` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSApplicationCPORevoke_20083, description: `Legacy Forms-CPS Application CPO (Revoke)`, shortDescription: `Legacy Forms-CPO - revoke` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSApplicationCPORevokePriorVersion_20088, description: `Legacy Forms-CPS Application CPO (Revoke) (Prior Version)`, shortDescription: `Legacy Forms-CPO - revoke` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSApplicationCPORevokeNewOrder_20084, description: `Legacy Forms-CPS Application CPO (Revoke&New Order)`, shortDescription: `Legacy Forms-CPO - revoke and make another` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSApplicationCPORevokeNewOrderPriorVer_20089, description: `Legacy Forms-CPS Application CPO (Revoke&New Order) (Prior Ver)`, shortDescription: `Legacy Forms-CPO - revoke and make another` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSApplicationCPOVary_20081, description: `Legacy Forms-CPS Application CPO (Vary)`, shortDescription: `Legacy Forms-CPO - vary` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSApplicationCPOVaryPriorVersion_20086, description: `Legacy Forms-CPS Application CPO (Vary) (Prior Version)`, shortDescription: `Legacy Forms-CPO - vary` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSExternalFileReference_20026, description: `Legacy Forms-CPS External File Reference`, shortDescription: `Legacy Forms-External file reference` },
	{ code: FormSetFormTypeUsage.LegacyFormsFamilyreunificationassessment_115804, description: `Legacy Forms-Family reunification assessment`, shortDescription: `Legacy Forms-Family reunification` },
	{ code: FormSetFormTypeUsage.LegacyFormsFamilyreunificationassessment_327402, description: `Legacy Forms-Family reunification assessment`, shortDescription: `Legacy Forms-Family reunification` },
	{ code: FormSetFormTypeUsage.LegacyFormsFamilyreunificationassessment_327412, description: `Legacy Forms-Family reunification assessment`, shortDescription: `Legacy Forms-Family reunification` },
	{ code: FormSetFormTypeUsage.LegacyFormsFamilyreunificationassessment_327422, description: `Legacy Forms-Family reunification assessment`, shortDescription: `Legacy Forms-Family reunification` },
	{ code: FormSetFormTypeUsage.LegacyFormsFamilyriskevaluation_115509, description: `Legacy Forms-Family risk evaluation`, shortDescription: `Legacy Forms-Family risk evaluation` },
	{ code: FormSetFormTypeUsage.LegacyFormsFamilyriskreevaluationforinhomecases_115803, description: `Legacy Forms-Family risk re-evaluation for in home cases`, shortDescription: `Legacy Forms-Family risk re-evaluation for in home cases` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSInitialAssessment_20031, description: `Legacy Forms-CPS Initial Assessment`, shortDescription: `Legacy Forms-Initial assessment` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSInitialAssessmentPriorVersion_20032, description: `Legacy Forms-CPS Initial Assessment (Prior Version)`, shortDescription: `Legacy Forms-Initial assessment` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSInitialAssessmentPriorVersion_20090, description: `Legacy Forms-CPS Initial Assessment (Prior Version)`, shortDescription: `Legacy Forms-Initial assessment` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSIntake_20022, description: `Legacy Forms-CPS Intake`, shortDescription: `Legacy Forms-Intake` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSIntakePriorVersion_20023, description: `Legacy Forms-CPS Intake (Prior Version)`, shortDescription: `Legacy Forms-Intake` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSInvestigationandAssessment_20033, description: `Legacy Forms-CPS Investigation and Assessment`, shortDescription: `Legacy Forms-Investigation and assessment` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSInvestigationandAssessmentPriorVersion_20071, description: `Legacy Forms-CPS Investigation and Assessment (Prior Version)`, shortDescription: `Legacy Forms-Investigation and assessment` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSActionPlanMOCCPCR_20055, description: `Legacy Forms-CPS Action Plan MOC CPCR`, shortDescription: `Legacy Forms-MOC action plan CPCR` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSActionPlanMOCCPCRPriorVersion_20056, description: `Legacy Forms-CPS Action Plan MOC CPCR (Prior Version)`, shortDescription: `Legacy Forms-MOC action plan CPCR` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSActionPlanMOCIA_20091, description: `Legacy Forms-CPS Action Plan MOC I&A`, shortDescription: `Legacy Forms-MOC action plan I&A` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSActionPlanMOCIAPriorVersion_20092, description: `Legacy Forms-CPS Action Plan MOC I&A (Prior Version)`, shortDescription: `Legacy Forms-MOC action plan I&A` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSAssessmentPlanMOCCPCR_20057, description: `Legacy Forms-CPS Assessment Plan MOC CPCR`, shortDescription: `Legacy Forms-MOC assessment plan CPCR` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSAssessmentPlanMOCCPCRPriorVersion_20058, description: `Legacy Forms-CPS Assessment Plan MOC CPCR (Prior Version)`, shortDescription: `Legacy Forms-MOC assessment plan CPCR` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSAssessmentPlanMOCIA_20093, description: `Legacy Forms-CPS Assessment Plan MOC I&A`, shortDescription: `Legacy Forms-MOC assessment plan I&A` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSAssessmentPlanMOCIAPriorVersion_20094, description: `Legacy Forms-CPS Assessment Plan MOC I&A (Prior Version)`, shortDescription: `Legacy Forms-MOC assessment plan I&A` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSNotification_20027, description: `Legacy Forms-CPS Notification`, shortDescription: `Legacy Forms-Notification` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSNotificationPriorVersion_20028, description: `Legacy Forms-CPS Notification (Prior Version)`, shortDescription: `Legacy Forms-Notification` },
	{ code: FormSetFormTypeUsage.LegacyFormsParentalstrengthsneedsassessmentreassessment_115801, description: `Legacy Forms-Parental strengths & needs assessment/reassessment`, shortDescription: `Legacy Forms-Parental strengths and needs assessment` },
	{ code: FormSetFormTypeUsage.LegacyFormsParentalstrengthsneedsassessmentreassessment_326002, description: `Legacy Forms-Parental strengths & needs assessment/reassessment`, shortDescription: `Legacy Forms-Parental strengths and needs assessment` },
	{ code: FormSetFormTypeUsage.LegacyFormsParentalstrengthsneedsassessmentreassessment_1100063, description: `Legacy Forms-Parental strengths & needs assessment/reassessment`, shortDescription: `Legacy Forms-Parental strengths and needs assessment` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSPlacementAgreement_20008, description: `Legacy Forms-CPS Placement Agreement`, shortDescription: `Legacy Forms-Placement agreement` },
	{ code: FormSetFormTypeUsage.LegacyFormsPre1997Assessment_20095, description: `Legacy Forms-Pre-1997 - Assessment`, shortDescription: `Legacy Forms-Pre-1997 Assessment` },
	{ code: FormSetFormTypeUsage.LegacyFormsPre1997Notification_20096, description: `Legacy Forms-Pre-1997 - Notification`, shortDescription: `Legacy Forms-Pre-1997 Notification` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSRecordofFamilyMeeting_20004, description: `Legacy Forms-CPS Record of Family Meeting`, shortDescription: `Legacy Forms-Record of family meeting` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSRecordofFamilyMeetingPriorVersion_20005, description: `Legacy Forms-CPS Record of Family Meeting (Prior Version)`, shortDescription: `Legacy Forms-Record of family meeting` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSRecordofFamilyMeeting_20006, description: `Legacy Forms-CPS Record of Family Meeting`, shortDescription: `Legacy Forms-Record of family meeting` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSRecordofFamilyMeetingPriorVersion_20007, description: `Legacy Forms-CPS Record of Family Meeting (Prior Version)`, shortDescription: `Legacy Forms-Record of family meeting` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSReviewReport_20061, description: `Legacy Forms-CPS Review Report`, shortDescription: `Legacy Forms-Review report` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSReviewReportPriorVersion_20062, description: `Legacy Forms-CPS Review Report (Prior Version)`, shortDescription: `Legacy Forms-Review report` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSReviewReportOutcomeAction_20064, description: `Legacy Forms-CPS Review Report Outcome&Action`, shortDescription: `Legacy Forms-Review report` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSSafePlaceMovementRecord_20014, description: `Legacy Forms-CPS Safe Place Movement Record`, shortDescription: `Legacy Forms-Safe place movement record` },
	{ code: FormSetFormTypeUsage.LegacyFormsSafetyassessment_115508, description: `Legacy Forms-Safety assessment`, shortDescription: `Legacy Forms-Safety assessment` },
	{ code: FormSetFormTypeUsage.LegacyFormsScreeningcriteriaResponsepriority_115303, description: `Legacy Forms-Screening criteria/Response priority`, shortDescription: `Legacy Forms-Screening criteria/Response priority` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSApplicationTAO_20015, description: `Legacy Forms-CPS Application TAO`, shortDescription: `Legacy Forms-TAO` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSApplicationTAOPriorVersion_20016, description: `Legacy Forms-CPS Application TAO (Prior Version)`, shortDescription: `Legacy Forms-TAO` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSOrderTAO_20019, description: `Legacy Forms-CPS Order TAO`, shortDescription: `Legacy Forms-TAO - draft order` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSApplicationTAOExtend_20017, description: `Legacy Forms-CPS Application TAO (Extend)`, shortDescription: `Legacy Forms-TAO - extend` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSApplicationTAOExtendPriorVersion_20018, description: `Legacy Forms-CPS Application TAO (Extend) (Prior Version)`, shortDescription: `Legacy Forms-TAO - extend` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSOrderTAOExtend_20020, description: `Legacy Forms-CPS Order TAO (Extend)`, shortDescription: `Legacy Forms-TAO - extend draft order` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSTAOExtSummary_20070, description: `Legacy Forms-CPS TAO (Ext) Summary`, shortDescription: `Legacy Forms-TAO - extend outcome summary` },
	{ code: FormSetFormTypeUsage.LegacyFormsCPSTAOSummary_20021, description: `Legacy Forms-CPS TAO Summary`, shortDescription: `Legacy Forms-TAO - outcome summary` },
	{ code: FormSetFormTypeUsage.LifeCourtCaseNotes_115995, description: `Life-Court Case Notes`, shortDescription: `Life-Court Case Notes` },
	{ code: FormSetFormTypeUsage.LifeCourtCaseNotes_410201, description: `Life-Court Case Notes`, shortDescription: `Life-Court Case Notes` },
	{ code: FormSetFormTypeUsage.LifeYJcasenote_115932, description: `Life-YJ case note`, shortDescription: `Life-YJ case note` },
	{ code: FormSetFormTypeUsage.LifeYJcasenote_1000179, description: `Life-YJ case note`, shortDescription: `Life-YJ case note` },
	{ code: FormSetFormTypeUsage.LifeYJcasenote_4101035, description: `Life-YJ case note`, shortDescription: `Life-YJ case note` },
	{ code: FormSetFormTypeUsage.OtherapplicationsAttachment_115845, description: `Other applications-Attachment`, shortDescription: `Other applications-Attachment` },
	{ code: FormSetFormTypeUsage.OtherapplicationsTransfertoprison_115843, description: `Other applications-Transfer to prison`, shortDescription: `Other applications-Transfer to prison` },
	{ code: FormSetFormTypeUsage.OtherapplicationsTransferToPrison_115994, description: `Other applications-Transfer To Prison`, shortDescription: `Other applications-Transfer To Prison` },
	{ code: FormSetFormTypeUsage.OtherapplicationsVaryOrRevokeATransferOrder_115996, description: `Other applications-Vary Or Revoke A Transfer Order`, shortDescription: `Other applications-VaryRevokeTransfer` },
	{ code: FormSetFormTypeUsage.OtherapplicationsYJcasenote_115844, description: `Other applications-YJ case note`, shortDescription: `Other applications-YJ case note` },
	{ code: FormSetFormTypeUsage.OtherapplicationsYJcasenote_1000167, description: `Other applications-YJ case note`, shortDescription: `Other applications-YJ case note` },
	{ code: FormSetFormTypeUsage.OtherapplicationsYJcasenote_4101023, description: `Other applications-YJ case note`, shortDescription: `Other applications-YJ case note` },
	{ code: FormSetFormTypeUsage.PlacementAboriginalTorresStraitIslanderchildplacement_360111, description: `Placement-Aboriginal/Torres Strait Islander child placement`, shortDescription: `Placement-Aboriginal/Torres Strait Islander child placement` },
	{ code: FormSetFormTypeUsage.PlacementAssessmentofplacementinformationtoparents_15901, description: `Placement-Assessment of placement information to parents`, shortDescription: `Placement-Assessment of placement information to parents` },
	{ code: FormSetFormTypeUsage.PlacementAssessmentofplacementinformationtoparents_100111, description: `Placement-Assessment of placement information to parents`, shortDescription: `Placement-Assessment of placement information to parents` },
	{ code: FormSetFormTypeUsage.PlacementAssessmentofplacementinformationtoparents_330011, description: `Placement-Assessment of placement information to parents`, shortDescription: `Placement-Assessment of placement information to parents` },
	{ code: FormSetFormTypeUsage.PlacementAuthoritytocare_15900, description: `Placement-Authority to care`, shortDescription: `Placement-Authority to care` },
	{ code: FormSetFormTypeUsage.PlacementAuthoritytocare_18017, description: `Placement-Authority to care`, shortDescription: `Placement-Authority to care` },
	{ code: FormSetFormTypeUsage.PlacementAuthoritytocare_100060, description: `Placement-Authority to care`, shortDescription: `Placement-Authority to care` },
	{ code: FormSetFormTypeUsage.PlacementAuthoritytocare_100119, description: `Placement-Authority to care`, shortDescription: `Placement-Authority to care` },
	{ code: FormSetFormTypeUsage.PlacementAuthoritytocare_330001, description: `Placement-Authority to care`, shortDescription: `Placement-Authority to care` },
	{ code: FormSetFormTypeUsage.PlacementCareagreement_15903, description: `Placement-Care agreement`, shortDescription: `Placement-Care agreement` },
	{ code: FormSetFormTypeUsage.PlacementCareagreement_100062, description: `Placement-Care agreement`, shortDescription: `Placement-Care agreement` },
	{ code: FormSetFormTypeUsage.PlacementCareagreement_100110, description: `Placement-Care agreement`, shortDescription: `Placement-Care agreement` },
	{ code: FormSetFormTypeUsage.PlacementCareagreement_330031, description: `Placement-Care agreement`, shortDescription: `Placement-Care agreement` },
	{ code: FormSetFormTypeUsage.PlacementCasenote_10022, description: `Placement-Case note`, shortDescription: `Placement-Case note` },
	{ code: FormSetFormTypeUsage.PlacementClosureofplacement_15907, description: `Placement-Closure of placement`, shortDescription: `Placement-Closure of placement` },
	{ code: FormSetFormTypeUsage.PlacementClosureofplacement_100140, description: `Placement-Closure of placement`, shortDescription: `Placement-Closure of placement` },
	{ code: FormSetFormTypeUsage.PlacementClosureofplacement_100160, description: `Placement-Closure of placement`, shortDescription: `Placement-Closure of placement` },
	{ code: FormSetFormTypeUsage.PlacementClosureofplacement_330051, description: `Placement-Closure of placement`, shortDescription: `Placement-Closure of placement` },
	{ code: FormSetFormTypeUsage.PlacementCPcasenote_115916, description: `Placement-CP case note`, shortDescription: `Placement-CP case note` },
	{ code: FormSetFormTypeUsage.PlacementCPcasenote_3000024, description: `Placement-CP case note`, shortDescription: `Placement-CP case note` },
	{ code: FormSetFormTypeUsage.PlacementIndependententity_300253, description: `Placement-Independent entity`, shortDescription: `Placement-Independent entity` },
	{ code: FormSetFormTypeUsage.PlacementPlacementagreement_15902, description: `Placement-Placement agreement`, shortDescription: `Placement-Placement agreement` },
	{ code: FormSetFormTypeUsage.PlacementPlacementagreement_18016, description: `Placement-Placement agreement`, shortDescription: `Placement-Placement agreement` },
	{ code: FormSetFormTypeUsage.PlacementPlacementagreement_100061, description: `Placement-Placement agreement`, shortDescription: `Placement-Placement agreement` },
	{ code: FormSetFormTypeUsage.PlacementPlacementagreement_100118, description: `Placement-Placement agreement`, shortDescription: `Placement-Placement agreement` },
	{ code: FormSetFormTypeUsage.PlacementPlacementagreement_330021, description: `Placement-Placement agreement`, shortDescription: `Placement-Placement agreement` },
	{ code: FormSetFormTypeUsage.PlacementRecognisedentityparticipationCPP_15904, description: `Placement-Recognised entity participation/CPP`, shortDescription: `Placement-Recognised entity participation/CPP` },
	{ code: FormSetFormTypeUsage.PlacementRecognisedentityparticipationCPP_100138, description: `Placement-Recognised entity participation/CPP`, shortDescription: `Placement-Recognised entity participation/CPP` },
	{ code: FormSetFormTypeUsage.PlacementRecognisedentityparticipationCPP_360101, description: `Placement-Recognised entity participation/CPP`, shortDescription: `Placement-Recognised entity participation/CPP` },
	{ code: FormSetFormTypeUsage.PresentencereportAddendumpresentencereport_100154, description: `Pre-sentence report-Addendum pre-sentence report`, shortDescription: `Pre-sentence report-Addendum pre-sentence report` },
	{ code: FormSetFormTypeUsage.PresentencereportAddendumpresentencereport_100157, description: `Pre-sentence report-Addendum pre-sentence report`, shortDescription: `Pre-sentence report-Addendum pre-sentence report` },
	{ code: FormSetFormTypeUsage.PresentencereportAddendumpresentencereport_115855, description: `Pre-sentence report-Addendum pre-sentence report`, shortDescription: `Pre-sentence report-Addendum pre-sentence report` },
	{ code: FormSetFormTypeUsage.PresentencereportAttachment_115857, description: `Pre-sentence report-Attachment`, shortDescription: `Pre-sentence report-Attachment` },
	{ code: FormSetFormTypeUsage.PresentencereportPresentencereport_100153, description: `Pre-sentence report-Pre-sentence report`, shortDescription: `Pre-sentence report-Pre-sentence report` },
	{ code: FormSetFormTypeUsage.PresentencereportPresentencereport_100156, description: `Pre-sentence report-Pre-sentence report`, shortDescription: `Pre-sentence report-Pre-sentence report` },
	{ code: FormSetFormTypeUsage.PresentencereportPresentencereport_115854, description: `Pre-sentence report-Pre-sentence report`, shortDescription: `Pre-sentence report-Pre-sentence report` },
	{ code: FormSetFormTypeUsage.PresentencereportPresentencereport_480021, description: `Pre-sentence report-Pre-sentence report`, shortDescription: `Pre-sentence report-Pre-sentence report` },
	{ code: FormSetFormTypeUsage.PresentencereportYJcasenote_115856, description: `Pre-sentence report-YJ case note`, shortDescription: `Pre-sentence report-YJ case note` },
	{ code: FormSetFormTypeUsage.PresentencereportYJcasenote_1000171, description: `Pre-sentence report-YJ case note`, shortDescription: `Pre-sentence report-YJ case note` },
	{ code: FormSetFormTypeUsage.PresentencereportYJcasenote_4101027, description: `Pre-sentence report-YJ case note`, shortDescription: `Pre-sentence report-YJ case note` },
	{ code: FormSetFormTypeUsage.PreviouscaseplansAssessment_115819, description: `Previous case plans-Assessment`, shortDescription: `Previous case plans-Assessment` },
	{ code: FormSetFormTypeUsage.PreviouscaseplansAssessment_400702, description: `Previous case plans-Assessment`, shortDescription: `Previous case plans-Assessment` },
	{ code: FormSetFormTypeUsage.PreviouscaseplansInterventionplan_115817, description: `Previous case plans-Intervention plan`, shortDescription: `Previous case plans-Intervention plan` },
	{ code: FormSetFormTypeUsage.PreviouscaseplansYJcasenote_115825, description: `Previous case plans-YJ case note`, shortDescription: `Previous case plans-YJ case note` },
	{ code: FormSetFormTypeUsage.PreviouscaseplansYJcasenote_1000162, description: `Previous case plans-YJ case note`, shortDescription: `Previous case plans-YJ case note` },
	{ code: FormSetFormTypeUsage.PreviouscaseplansYJcasenote_4101018, description: `Previous case plans-YJ case note`, shortDescription: `Previous case plans-YJ case note` },
	{ code: FormSetFormTypeUsage.ProgramproposalAttachment_115861, description: `Program proposal-Attachment`, shortDescription: `Program proposal-Attachment` },
	{ code: FormSetFormTypeUsage.ProgramproposalConditionalreleaseorderprogramproposal_115858, description: `Program proposal-Conditional release order program proposal`, shortDescription: `Program proposal-Conditional release order` },
	{ code: FormSetFormTypeUsage.ProgramproposalConditionalreleaseorderprogramproposal_480001, description: `Program proposal-Conditional release order program proposal`, shortDescription: `Program proposal-Conditional release order` },
	{ code: FormSetFormTypeUsage.ProgramproposalIntensivesupervisionorderprogramproposal_115859, description: `Program proposal-Intensive supervision order program proposal`, shortDescription: `Program proposal-Intensive supervision order` },
	{ code: FormSetFormTypeUsage.ProgramproposalIntensivesupervisionorderprogramproposal_480011, description: `Program proposal-Intensive supervision order program proposal`, shortDescription: `Program proposal-Intensive supervision order` },
	{ code: FormSetFormTypeUsage.ProgramproposalYJcasenote_115860, description: `Program proposal-YJ case note`, shortDescription: `Program proposal-YJ case note` },
	{ code: FormSetFormTypeUsage.ProgramproposalYJcasenote_1000172, description: `Program proposal-YJ case note`, shortDescription: `Program proposal-YJ case note` },
	{ code: FormSetFormTypeUsage.ProgramproposalYJcasenote_4101028, description: `Program proposal-YJ case note`, shortDescription: `Program proposal-YJ case note` },
	{ code: FormSetFormTypeUsage.RecordofadviceofarrestNoticetoappearRecordofadviceofarrest_16501, description: `Record of advice of arrest / Notice to appear-Record of advice of arrest`, shortDescription: `RcrdOfAdvceOfArrst/NtceToAppr-Record of advice of arrest` },
	{ code: FormSetFormTypeUsage.RecordofadviceofarrestNoticetoappearRecordofadviceofarrest_450001, description: `Record of advice of arrest / Notice to appear-Record of advice of arrest`, shortDescription: `RcrdOfAdvceOfArrst/NtceToAppr-Record of advice of arrest` },
	{ code: FormSetFormTypeUsage.RecordofadviceofarrestNoticetoappearRecordofadviceofarrest_450041, description: `Record of advice of arrest / Notice to appear-Record of advice of arrest`, shortDescription: `RcrdOfAdvceOfArrst/NtceToAppr-Record of advice of arrest` },
	{ code: FormSetFormTypeUsage.RecordofadviceofarrestNoticetoappearYJcasenote_16506, description: `Record of advice of arrest / Notice to appear-YJ case note`, shortDescription: `RcrdOfAdvceOfArrst/NtceToAppr-YJ case note` },
	{ code: FormSetFormTypeUsage.RecordofadviceofarrestNoticetoappearYJcasenote_410104, description: `Record of advice of arrest / Notice to appear-YJ case note`, shortDescription: `RcrdOfAdvceOfArrst/NtceToAppr-YJ case note` },
	{ code: FormSetFormTypeUsage.RecordofadviceofarrestNoticetoappearYJcasenote_1000148, description: `Record of advice of arrest / Notice to appear-YJ case note`, shortDescription: `RcrdOfAdvceOfArrst/NtceToAppr-YJ case note` },
	{ code: FormSetFormTypeUsage.ReferralAfterhoursreferral_115883, description: `Referral-After hours referral`, shortDescription: `Referral-After hours referral` },
	{ code: FormSetFormTypeUsage.ReferralAfterhoursreferral_410131, description: `Referral-After hours referral`, shortDescription: `Referral-After hours referral` },
	{ code: FormSetFormTypeUsage.ReferralAttachment_115864, description: `Referral-Attachment`, shortDescription: `Referral-Attachment` },
	{ code: FormSetFormTypeUsage.ReferralAttachment_115885, description: `Referral-Attachment`, shortDescription: `Referral-Attachment` },
	{ code: FormSetFormTypeUsage.ReferralCommunityserviceorderreferral_115880, description: `Referral-Community service order referral`, shortDescription: `Referral-Community service referral` },
	{ code: FormSetFormTypeUsage.ReferralCommunityserviceorderreferral_410061, description: `Referral-Community service order referral`, shortDescription: `Referral-Community service referral` },
	{ code: FormSetFormTypeUsage.ReferralGriffithreferral_115862, description: `Referral-Griffith referral`, shortDescription: `Referral-Griffith referral` },
	{ code: FormSetFormTypeUsage.ReferralGriffithreferral_400802, description: `Referral-Griffith referral`, shortDescription: `Referral-Griffith referral` },
	{ code: FormSetFormTypeUsage.ReferralGriffithreferral_1000144, description: `Referral-Griffith referral`, shortDescription: `Referral-Griffith referral` },
	{ code: FormSetFormTypeUsage.ReferralISOconferencereferral_115881, description: `Referral-ISO conference referral`, shortDescription: `Referral-ISO conference referral` },
	{ code: FormSetFormTypeUsage.ReferralISOconferencereferral_410111, description: `Referral-ISO conference referral`, shortDescription: `Referral-ISO conference referral` },
	{ code: FormSetFormTypeUsage.ReferralProgramreferral_410161, description: `Referral-Program referral`, shortDescription: `Referral-Program referral` },
	{ code: FormSetFormTypeUsage.ReferralsAfterhoursreferral_115893, description: `Referrals-After hours referral`, shortDescription: `Referrals-After hours referral` },
	{ code: FormSetFormTypeUsage.ReferralsAfterhoursreferral_410132, description: `Referrals-After hours referral`, shortDescription: `Referrals-After hours referral` },
	{ code: FormSetFormTypeUsage.ReferralsGriffithreferral_115813, description: `Referrals-Griffith referral`, shortDescription: `Referrals-Griffith referral` },
	{ code: FormSetFormTypeUsage.ReferralsGriffithreferral_400801, description: `Referrals-Griffith referral`, shortDescription: `Referrals-Griffith referral` },
	{ code: FormSetFormTypeUsage.ReferralsGriffithreferral_1000143, description: `Referrals-Griffith referral`, shortDescription: `Referrals-Griffith referral` },
	{ code: FormSetFormTypeUsage.ReferralsYDCtherapeuticprogramsreferral_115894, description: `Referrals-YDC therapeutic programs referral`, shortDescription: `Referrals-YDC therapeutic programs referral` },
	{ code: FormSetFormTypeUsage.ReferralsYDCtherapeuticprogramsreferral_410122, description: `Referrals-YDC therapeutic programs referral`, shortDescription: `Referrals-YDC therapeutic programs referral` },
	{ code: FormSetFormTypeUsage.ReferralsYJcasenote_115821, description: `Referrals-YJ case note`, shortDescription: `Referrals-YJ case note` },
	{ code: FormSetFormTypeUsage.ReferralsYJcasenote_1000158, description: `Referrals-YJ case note`, shortDescription: `Referrals-YJ case note` },
	{ code: FormSetFormTypeUsage.ReferralsYJcasenote_4101014, description: `Referrals-YJ case note`, shortDescription: `Referrals-YJ case note` },
	{ code: FormSetFormTypeUsage.ReferralsYouthworkersupportreferral_115892, description: `Referrals-Youth worker support referral`, shortDescription: `Referrals-Youth worker support referral` },
	{ code: FormSetFormTypeUsage.ReferralsYouthworkersupportreferral_115984, description: `Referrals-Youth worker support referral`, shortDescription: `Referrals-Youth worker support referral` },
	{ code: FormSetFormTypeUsage.ReferralsYouthworkersupportreferral_410142, description: `Referrals-Youth worker support referral`, shortDescription: `Referrals-Youth worker support referral` },
	{ code: FormSetFormTypeUsage.ReferralYDCtherapeuticprogramsreferral_115882, description: `Referral-YDC therapeutic programs referral`, shortDescription: `Referral-YDC therapeutic programs referral` },
	{ code: FormSetFormTypeUsage.ReferralYDCtherapeuticprogramsreferral_410121, description: `Referral-YDC therapeutic programs referral`, shortDescription: `Referral-YDC therapeutic programs referral` },
	{ code: FormSetFormTypeUsage.ReferralYJcasenote_115863, description: `Referral-YJ case note`, shortDescription: `Referral-YJ case note` },
	{ code: FormSetFormTypeUsage.ReferralYJcasenote_115884, description: `Referral-YJ case note`, shortDescription: `Referral-YJ case note` },
	{ code: FormSetFormTypeUsage.ReferralYJcasenote_1000173, description: `Referral-YJ case note`, shortDescription: `Referral-YJ case note` },
	{ code: FormSetFormTypeUsage.ReferralYJcasenote_1000176, description: `Referral-YJ case note`, shortDescription: `Referral-YJ case note` },
	{ code: FormSetFormTypeUsage.ReferralYJcasenote_4101029, description: `Referral-YJ case note`, shortDescription: `Referral-YJ case note` },
	{ code: FormSetFormTypeUsage.ReferralYJcasenote_4101032, description: `Referral-YJ case note`, shortDescription: `Referral-YJ case note` },
	{ code: FormSetFormTypeUsage.ReferralYouthworkersupportreferral_115879, description: `Referral-Youth worker support referral`, shortDescription: `Referral-Youth worker support referral` },
	{ code: FormSetFormTypeUsage.ReferralYouthworkersupportreferral_115983, description: `Referral-Youth worker support referral`, shortDescription: `Referral-Youth worker support referral` },
	{ code: FormSetFormTypeUsage.ReferralYouthworkersupportreferral_410141, description: `Referral-Youth worker support referral`, shortDescription: `Referral-Youth worker support referral` },
	{ code: FormSetFormTypeUsage.RegionalDirectorsconversationRegionalDirectorsconversation_450031, description: `Regional Director's conversation-Regional Director's conversation`, shortDescription: `Regional Dir Conv-Regional Dir Conv` },
	{ code: FormSetFormTypeUsage.RegionalDirectorsconversationRegionalDirectorsconversation_450032, description: `Regional Director's conversation-Regional Director's conversation`, shortDescription: `Regional Dir Conv-Regional Dir Conv` },
	{ code: FormSetFormTypeUsage.RegionalDirectorsconversationRegionalExecutiveDirectorsconversation_16504, description: `Regional Director's conversation-Regional Executive Director's conversation`, shortDescription: `Regional Dir Conv-Regional Exec Dir Conv` },
	{ code: FormSetFormTypeUsage.RegionalDirectorsconversationRegionalExecutiveDirectorsconversation_16603, description: `Regional Director's conversation-Regional Executive Director's conversation`, shortDescription: `Regional Dir Conv-Regional Exec Dir Conv` },
	{ code: FormSetFormTypeUsage.RegionalDirectorsconversationYJcasenote_16509, description: `Regional Director's conversation-YJ case note`, shortDescription: `Regional Dir Conv-YJ case note` },
	{ code: FormSetFormTypeUsage.RegionalDirectorsconversationYJcasenote_16608, description: `Regional Director's conversation-YJ case note`, shortDescription: `Regional Dir Conv-YJ case note` },
	{ code: FormSetFormTypeUsage.RegionalDirectorsconversationYJcasenote_410107, description: `Regional Director's conversation-YJ case note`, shortDescription: `Regional Dir Conv-YJ case note` },
	{ code: FormSetFormTypeUsage.RegionalDirectorsconversationYJcasenote_1000151, description: `Regional Director's conversation-YJ case note`, shortDescription: `Regional Dir Conv-YJ case note` },
	{ code: FormSetFormTypeUsage.RegionalDirectorsconversationYJcasenote_1000155, description: `Regional Director's conversation-YJ case note`, shortDescription: `Regional Dir Conv-YJ case note` },
	{ code: FormSetFormTypeUsage.RegionalDirectorsconversationYJcasenote_4101011, description: `Regional Director's conversation-YJ case note`, shortDescription: `Regional Dir Conv-YJ case note` },
	{ code: FormSetFormTypeUsage.ResponseCasenote_10007, description: `Response-Case note`, shortDescription: `Response-Case note` },
	{ code: FormSetFormTypeUsage.ResponseCasenote_10010, description: `Response-Case note`, shortDescription: `Response-Case note` },
	{ code: FormSetFormTypeUsage.ResponseChildconcernreport_15306, description: `Response-Child concern report`, shortDescription: `Response-Child concern report` },
	{ code: FormSetFormTypeUsage.ResponseChildconcernreport_31002, description: `Response-Child concern report`, shortDescription: `Response-Child concern report` },
	{ code: FormSetFormTypeUsage.ResponseChildplacementconcernreport_15406, description: `Response-Child placement concern report`, shortDescription: `Response-Child placement concern` },
	{ code: FormSetFormTypeUsage.ResponseChildplacementconcernreport_100100, description: `Response-Child placement concern report`, shortDescription: `Response-Child placement concern report` },
	{ code: FormSetFormTypeUsage.ResponseClosureform_15314, description: `Response-Closure form`, shortDescription: `Response-Closure form` },
	{ code: FormSetFormTypeUsage.ResponseClosureform_30007, description: `Response-Closure form`, shortDescription: `Response-Closure form` },
	{ code: FormSetFormTypeUsage.ResponseClosureform_30999, description: `Response-Closure form`, shortDescription: `Response-Closure form` },
	{ code: FormSetFormTypeUsage.ResponseClosureform_420013, description: `Response-Closure form`, shortDescription: `Response-Closure form` },
	{ code: FormSetFormTypeUsage.ResponseCPcasenote_30004, description: `Response-CP case note`, shortDescription: `Response-CP case note` },
	{ code: FormSetFormTypeUsage.ResponseCPcasenote_115901, description: `Response-CP case note`, shortDescription: `Response-CP case note` },
	{ code: FormSetFormTypeUsage.ResponseCPcasenote_115904, description: `Response-CP case note`, shortDescription: `Response-CP case note` },
	{ code: FormSetFormTypeUsage.ResponseCPcasenote_300009, description: `Response-CP case note`, shortDescription: `Response-CP case note` },
	{ code: FormSetFormTypeUsage.ResponseCPcasenote_3000012, description: `Response-CP case note`, shortDescription: `Response-CP case note` },
	{ code: FormSetFormTypeUsage.ResponseHarmreport_30006, description: `Response-Harm report`, shortDescription: `Response-Harm report` },
	{ code: FormSetFormTypeUsage.ResponseMOCNotification_15405, description: `Response-MOC Notification`, shortDescription: `Response-MOC Notification` },
	{ code: FormSetFormTypeUsage.ResponseNotification_15305, description: `Response-Notification`, shortDescription: `Response-Notification` },
	{ code: FormSetFormTypeUsage.ResponseNotification_31009, description: `Response-Notification`, shortDescription: `Response-Notification` },
	{ code: FormSetFormTypeUsage.ResponseNotification_100091, description: `Response-Notification`, shortDescription: `Response-Notification` },
	{ code: FormSetFormTypeUsage.ResponseSafeplacemovementrecord_15307, description: `Response-Safe place movement record`, shortDescription: `Response-Safe place movement record` },
	{ code: FormSetFormTypeUsage.ResponseSafeplacemovementrecord_31003, description: `Response-Safe place movement record`, shortDescription: `Response-Safe place movement record` },
	{ code: FormSetFormTypeUsage.ResponseStandardsofcarereviewreport_30005, description: `Response-Standards of care review report`, shortDescription: `Response-Standards of care review report` },
	{ code: FormSetFormTypeUsage.RiskassessmentsAssessment_115815, description: `Risk assessments-Assessment`, shortDescription: `Risk assessments-Assessment` },
	{ code: FormSetFormTypeUsage.RiskassessmentsAssessment_400701, description: `Risk assessments-Assessment`, shortDescription: `Risk assessments-Assessment` },
	{ code: FormSetFormTypeUsage.RiskassessmentsYJcasenote_115823, description: `Risk assessments-YJ case note`, shortDescription: `Risk assessments-YJ case note` },
	{ code: FormSetFormTypeUsage.RiskassessmentsYJcasenote_1000160, description: `Risk assessments-YJ case note`, shortDescription: `Risk assessments-YJ case note` },
	{ code: FormSetFormTypeUsage.RiskassessmentsYJcasenote_4101016, description: `Risk assessments-YJ case note`, shortDescription: `Risk assessments-YJ case note` },
	{ code: FormSetFormTypeUsage.ShareddocumentsCasenote_10020, description: `Shared documents-Case note`, shortDescription: `Shared documents-Case note` },
	{ code: FormSetFormTypeUsage.ShareddocumentsCPcasenote_115914, description: `Shared documents-CP case note`, shortDescription: `Shared documents-CP case note` },
	{ code: FormSetFormTypeUsage.ShareddocumentsCPcasenote_3000022, description: `Shared documents-CP case note`, shortDescription: `Shared documents-CP case note` },
	{ code: FormSetFormTypeUsage.ShareddocumentsFamilyreunificationassessment_15804, description: `Shared documents-Family reunification assessment`, shortDescription: `Shared documents-Family reunification` },
	{ code: FormSetFormTypeUsage.ShareddocumentsFamilyreunificationassessment_327401, description: `Shared documents-Family reunification assessment`, shortDescription: `Shared documents-Family reunification` },
	{ code: FormSetFormTypeUsage.ShareddocumentsFamilyreunificationassessment_327411, description: `Shared documents-Family reunification assessment`, shortDescription: `Shared documents-Family reunification` },
	{ code: FormSetFormTypeUsage.ShareddocumentsFamilyreunificationassessment_327421, description: `Shared documents-Family reunification assessment`, shortDescription: `Shared documents-Family reunification` },
	{ code: FormSetFormTypeUsage.ShareddocumentsFamilyriskreevaluationforinhomecases_15803, description: `Shared documents-Family risk re-evaluation for in home cases`, shortDescription: `Shared documents-Family risk re-evaluation for in home cases` },
	{ code: FormSetFormTypeUsage.ShareddocumentsFamilyriskreevaluationforinhomecases_18015, description: `Shared documents-Family risk re-evaluation for in home cases`, shortDescription: `Shared documents-Family risk re-evaluation for in home cases` },
	{ code: FormSetFormTypeUsage.ShareddocumentsFamilyriskreevaluationforinhomecases_100144, description: `Shared documents-Family risk re-evaluation for in home cases`, shortDescription: `Shared documents-Family risk re-evaluation for in home cases` },
	{ code: FormSetFormTypeUsage.ShareddocumentsFamilyriskreevaluationforinhomecases_327001, description: `Shared documents-Family risk re-evaluation for in home cases`, shortDescription: `Shared documents-Family risk re-evaluation for in home cases` },
	{ code: FormSetFormTypeUsage.ShareddocumentsParentalstrengthsneedsassessmentreassessment_15801, description: `Shared documents-Parental strengths & needs assessment/reassessment`, shortDescription: `Shared documents-Parental strengths and needs assessment` },
	{ code: FormSetFormTypeUsage.ShareddocumentsParentalstrengthsneedsassessmentreassessment_100063, description: `Shared documents-Parental strengths & needs assessment/reassessment`, shortDescription: `Shared documents-Parental strengths and needs assessment` },
	{ code: FormSetFormTypeUsage.ShareddocumentsParentalstrengthsneedsassessmentreassessment_326001, description: `Shared documents-Parental strengths & needs assessment/reassessment`, shortDescription: `Shared documents-Parental strengths and needs assessment` },
	{ code: FormSetFormTypeUsage.ShareddocumentsRecordofafamilygroupmeeting_15806, description: `Shared documents-Record of a family group meeting`, shortDescription: `Shared documents-Record of FGM` },
	{ code: FormSetFormTypeUsage.ShareddocumentsRecordofafamilygroupmeeting_327101, description: `Shared documents-Record of a family group meeting`, shortDescription: `Shared documents-Record of FGM` },
	{ code: FormSetFormTypeUsage.ShareddocumentsReferraltoaconvenorforafamilygroupmeeting_15805, description: `Shared documents-Referral to a convenor for a family group meeting`, shortDescription: `Shared documents-Referral to FGM convenor` },
	{ code: FormSetFormTypeUsage.ShareddocumentsSafetyassessment_15802, description: `Shared documents-Safety assessment`, shortDescription: `Shared documents-Safety assessment` },
	{ code: FormSetFormTypeUsage.ShareddocumentsSafetyassessment_18002, description: `Shared documents-Safety assessment`, shortDescription: `Shared documents-Safety assessment` },
	{ code: FormSetFormTypeUsage.ShareddocumentsSafetyassessment_310061, description: `Shared documents-Safety assessment`, shortDescription: `Shared documents-Safety assessment` },
	{ code: FormSetFormTypeUsage.SupportClosureform_115890, description: `Support-Closure form`, shortDescription: `Support-Closure form` },
	{ code: FormSetFormTypeUsage.SupportClosureform_4200118, description: `Support-Closure form`, shortDescription: `Support-Closure form` },
	{ code: FormSetFormTypeUsage.SupportCPcasenote_115891, description: `Support-CP case note`, shortDescription: `Support-CP case note` },
	{ code: FormSetFormTypeUsage.SupportCPcasenote_300002, description: `Support-CP case note`, shortDescription: `Support-CP case note` },
	{ code: FormSetFormTypeUsage.TemporaryassessmentordersExtendvaryCasenote_10026, description: `Temporary assessment orders - Extend/vary-Case note`, shortDescription: `TAO - Extend/vary-Case note` },
	{ code: FormSetFormTypeUsage.TemporaryassessmentordersExtendvaryClosureofTAO_15109, description: `Temporary assessment orders - Extend/vary-Closure of TAO`, shortDescription: `TAO - Extend/vary-Closure of TAO` },
	{ code: FormSetFormTypeUsage.TemporaryassessmentordersExtendvaryCPcasenote_115920, description: `Temporary assessment orders - Extend/vary-CP case note`, shortDescription: `TAO - Extend/vary-CP case note` },
	{ code: FormSetFormTypeUsage.TemporaryassessmentordersExtendvaryCPcasenote_3000027, description: `Temporary assessment orders - Extend/vary-CP case note`, shortDescription: `TAO - Extend/vary-CP case note` },
	{ code: FormSetFormTypeUsage.TemporaryassessmentordersExtendvaryForm17_15108, description: `Temporary assessment orders - Extend/vary-Form 17`, shortDescription: `TAO - Extend/vary-Form 17` },
	{ code: FormSetFormTypeUsage.TemporaryassessmentordersExtendvaryForm2ApplicationtoextendvaryaTAO_15101, description: `Temporary assessment orders - Extend/vary-Form 2 - Application to extend/vary a TAO`, shortDescription: `TAO - Extend/vary-Form 2` },
	{ code: FormSetFormTypeUsage.TemporaryassessmentordersExtendvaryForm2ApplicationtoextendvaryaTAO_100105, description: `Temporary assessment orders - Extend/vary-Form 2 - Application to extend/vary a TAO`, shortDescription: `TAO - Extend/vary-Form 2` },
	{ code: FormSetFormTypeUsage.TemporaryassessmentordersExtendvaryForm2TAOoutcomeformTOF_15106, description: `Temporary assessment orders - Extend/vary-Form 2 - TAO outcome form (TOF)`, shortDescription: `TAO - Extend/vary-Form 2 TOF` },
	{ code: FormSetFormTypeUsage.TemporaryassessmentordersExtendvaryForm2TAOoutcomeformTOF_100103, description: `Temporary assessment orders - Extend/vary-Form 2 - TAO outcome form (TOF)`, shortDescription: `TAO - Extend/vary-Form 2 TOF` },
	{ code: FormSetFormTypeUsage.TemporarycustodyordersExtendvaryClosureofTCO_100120, description: `Temporary custody orders - Extend/vary-Closure of TCO`, shortDescription: `TCO - Extend/vary-Closure of TCO` },
	{ code: FormSetFormTypeUsage.TemporarycustodyordersExtendvaryCPcasenote_116002, description: `Temporary custody orders - Extend/vary-CP case note`, shortDescription: `TCO - Extend/vary-CP case note` },
	{ code: FormSetFormTypeUsage.TemporarycustodyordersExtendvaryCPcasenote_3000052, description: `Temporary custody orders - Extend/vary-CP case note`, shortDescription: `TCO - Extend/vary-CP case note` },
	{ code: FormSetFormTypeUsage.TemporarycustodyordersExtendvaryForm17_15205, description: `Temporary custody orders - Extend/vary-Form 17`, shortDescription: `TCO - Extend/vary-Form 17` },
	{ code: FormSetFormTypeUsage.TemporarycustodyordersExtendvaryForm41ApplicationtoextendvaryaTCO_100116, description: `Temporary custody orders - Extend/vary-Form 41 - Application to extend/vary a TCO`, shortDescription: `TCO - Extend/vary-Form 41` },
	{ code: FormSetFormTypeUsage.TemporarycustodyordersExtendvaryForm41TCOoutcomeformTCOF_100117, description: `Temporary custody orders - Extend/vary-Form 41 - TCO outcome form (TCOF)`, shortDescription: `TCO - Extend/vary-Form 41 TCOF` },
	{ code: FormSetFormTypeUsage.TemporaryassessmentordersCasenote_10001, description: `Temporary assessment orders-Case note`, shortDescription: `Temporary assessment orders-Case note` },
	{ code: FormSetFormTypeUsage.TemporaryassessmentordersClosureofTAO_15107, description: `Temporary assessment orders-Closure of TAO`, shortDescription: `Temporary assessment orders-Closure of TAO` },
	{ code: FormSetFormTypeUsage.TemporaryassessmentordersCPcasenote_115895, description: `Temporary assessment orders-CP case note`, shortDescription: `Temporary assessment orders-CP case note` },
	{ code: FormSetFormTypeUsage.TemporaryassessmentordersCPcasenote_300003, description: `Temporary assessment orders-CP case note`, shortDescription: `Temporary assessment orders-CP case note` },
	{ code: FormSetFormTypeUsage.TemporaryassessmentordersForm1ApplicationforaTAO_15100, description: `Temporary assessment orders-Form 1 - Application for a TAO`, shortDescription: `Temporary assessment orders-Form 1` },
	{ code: FormSetFormTypeUsage.TemporaryassessmentordersForm1ApplicationforaTAO_18018, description: `Temporary assessment orders-Form 1 - Application for a TAO`, shortDescription: `Temporary assessment orders-Form 1` },
	{ code: FormSetFormTypeUsage.TemporaryassessmentordersForm1ApplicationforaTAO_100101, description: `Temporary assessment orders-Form 1 - Application for a TAO`, shortDescription: `Temporary assessment orders-Form 1` },
	{ code: FormSetFormTypeUsage.TemporaryassessmentordersForm1TAOoutcomeformTOF_15105, description: `Temporary assessment orders-Form 1 - TAO outcome form (TOF)`, shortDescription: `Temporary assessment orders-Form 1 TOF` },
	{ code: FormSetFormTypeUsage.TemporaryassessmentordersForm1TAOoutcomeformTOF_100102, description: `Temporary assessment orders-Form 1 - TAO outcome form (TOF)`, shortDescription: `Temporary assessment orders-Form 1 TOF` },
	{ code: FormSetFormTypeUsage.TemporaryassessmentordersForm17_15102, description: `Temporary assessment orders-Form 17`, shortDescription: `Temporary assessment orders-Form 17` },
	{ code: FormSetFormTypeUsage.TemporarycustodyordersClosureofTCO_100107, description: `Temporary custody orders-Closure of TCO`, shortDescription: `Temporary custody orders-Closure of TCO` },
	{ code: FormSetFormTypeUsage.TemporarycustodyordersCPcasenote_116001, description: `Temporary custody orders-CP case note`, shortDescription: `Temporary custody orders-CP case note` },
	{ code: FormSetFormTypeUsage.TemporarycustodyordersCPcasenote_3000051, description: `Temporary custody orders-CP case note`, shortDescription: `Temporary custody orders-CP case note` },
	{ code: FormSetFormTypeUsage.TemporarycustodyordersForm17_15204, description: `Temporary custody orders-Form 17`, shortDescription: `Temporary custody orders-Form 17` },
	{ code: FormSetFormTypeUsage.TemporarycustodyordersForm39ApplicationforaTCO_100104, description: `Temporary custody orders-Form 39 - Application for a TCO`, shortDescription: `Temporary custody orders-Form 39 Application for a TCO` },
	{ code: FormSetFormTypeUsage.TemporarycustodyordersForm39TCOoutcomeformTCOF_100106, description: `Temporary custody orders-Form 39 - TCO outcome form (TCOF)`, shortDescription: `Temporary custody orders-Form 39 Temporary custody order outcome` },
	{ code: FormSetFormTypeUsage.TransferTransfersummaryinformalinterstateoverseas_115850, description: `Transfer-Transfer summary (informal interstate / overseas)`, shortDescription: `Transfer-Transfer summary (IOT)` },
	{ code: FormSetFormTypeUsage.TransferTransfersummaryinformalinterstateoverseas_460001, description: `Transfer-Transfer summary (informal interstate / overseas)`, shortDescription: `Transfer-Transfer summary (IOT)` },
	{ code: FormSetFormTypeUsage.TransferTransfersummaryinformalinterstateoverseas_1000141, description: `Transfer-Transfer summary (informal interstate / overseas)`, shortDescription: `Transfer-Transfer summary (IOT)` },
	{ code: FormSetFormTypeUsage.TransferYJcasenote_115851, description: `Transfer-YJ case note`, shortDescription: `Transfer-YJ case note` },
	{ code: FormSetFormTypeUsage.TransferYJcasenote_1000169, description: `Transfer-YJ case note`, shortDescription: `Transfer-YJ case note` },
	{ code: FormSetFormTypeUsage.TransferYJcasenote_4101025, description: `Transfer-YJ case note`, shortDescription: `Transfer-YJ case note` },
	{ code: FormSetFormTypeUsage.TransferYoungpersonsummary_115849, description: `Transfer-Young person summary`, shortDescription: `Transfer-Young person summary` },
	{ code: FormSetFormTypeUsage.TransferYoungpersonsummary_460012, description: `Transfer-Young person summary`, shortDescription: `Transfer-Young person summary` },
	{ code: FormSetFormTypeUsage.VaryorderAttachment_115842, description: `Vary order-Attachment`, shortDescription: `Vary order-Attachment` },
	{ code: FormSetFormTypeUsage.VaryorderOathofservice_416102, description: `Vary order-Oath of service`, shortDescription: `Vary order-Oath of service` },
	{ code: FormSetFormTypeUsage.VaryorderVarydetentionorderintheinterestsofjustice_115840, description: `Vary order-Vary detention order in the interests of justice`, shortDescription: `Vary order-Vary detention order in the interests of justice` },
	{ code: FormSetFormTypeUsage.VaryorderVarydetentionorderintheinterestsofjustice_415601, description: `Vary order-Vary detention order in the interests of justice`, shortDescription: `Vary order-Vary detention order in the interests of justice` },
	{ code: FormSetFormTypeUsage.VaryorderVarydetentionorderintheinterestsofjustice_1000142, description: `Vary order-Vary detention order in the interests of justice`, shortDescription: `Vary order-Vary detention order in the interests of justice` },
	{ code: FormSetFormTypeUsage.VaryorderVaryorderbyconsent_115838, description: `Vary order-Vary order by consent`, shortDescription: `Vary order-Vary order by consent` },
	{ code: FormSetFormTypeUsage.VaryorderVaryorderbyconsent_115993, description: `Vary order-Vary order by consent`, shortDescription: `Vary order-Vary order by consent` },
	{ code: FormSetFormTypeUsage.VaryorderVaryorderbyconsent_415701, description: `Vary order-Vary order by consent`, shortDescription: `Vary order-Vary order by consent` },
	{ code: FormSetFormTypeUsage.VaryorderVaryorderintheinterestsofjustice_115839, description: `Vary order-Vary order in the interests of justice`, shortDescription: `Vary order-Vary order in the interests of justice` },
	{ code: FormSetFormTypeUsage.VaryorderVaryorderintheinterestsofjustice_115992, description: `Vary order-Vary order in the interests of justice`, shortDescription: `Vary order-Vary order in the interests of justice` },
	{ code: FormSetFormTypeUsage.VaryorderVaryorderintheinterestsofjustice_415801, description: `Vary order-Vary order in the interests of justice`, shortDescription: `Vary order-Vary order in the interests of justice` },
	{ code: FormSetFormTypeUsage.VaryorderYJcasenote_115841, description: `Vary order-YJ case note`, shortDescription: `Vary order-YJ case note` },
	{ code: FormSetFormTypeUsage.VaryorderYJcasenote_1000166, description: `Vary order-YJ case note`, shortDescription: `Vary order-YJ case note` },
	{ code: FormSetFormTypeUsage.VaryorderYJcasenote_4101022, description: `Vary order-YJ case note`, shortDescription: `Vary order-YJ case note` },
	{ code: FormSetFormTypeUsage.WarningletterAttachment_115829, description: `Warning letter-Attachment`, shortDescription: `Warning letter-Attachment` },
	{ code: FormSetFormTypeUsage.WarningletterIssuewarningletter_100067, description: `Warning letter-Issue warning letter`, shortDescription: `Warning letter-Issue warning letter` },
	{ code: FormSetFormTypeUsage.WarningletterIssuewarningletter_115827, description: `Warning letter-Issue warning letter`, shortDescription: `Warning letter-Issue warning letter` },
	{ code: FormSetFormTypeUsage.WarningletterIssueWarningLetter_115986, description: `Warning letter-Issue Warning Letter`, shortDescription: `Warning letter-Issue Warning Letter` },
	{ code: FormSetFormTypeUsage.WarningletterIssueWarningLetter_415301, description: `Warning letter-Issue Warning Letter`, shortDescription: `Warning letter-Issue Warning Letter` },
	{ code: FormSetFormTypeUsage.WarningletterYJcasenote_115828, description: `Warning letter-YJ case note`, shortDescription: `Warning letter-YJ case note` },
	{ code: FormSetFormTypeUsage.WarningletterYJcasenote_1000163, description: `Warning letter-YJ case note`, shortDescription: `Warning letter-YJ case note` },
	{ code: FormSetFormTypeUsage.WarningletterYJcasenote_4101019, description: `Warning letter-YJ case note`, shortDescription: `Warning letter-YJ case note` },
	{ code: FormSetFormTypeUsage.WarrantAttachment_115837, description: `Warrant-Attachment`, shortDescription: `Warrant-Attachment` },
	{ code: FormSetFormTypeUsage.WarrantComplaint_115835, description: `Warrant-Complaint`, shortDescription: `Warrant-Complaint` },
	{ code: FormSetFormTypeUsage.WarrantComplaint_115989, description: `Warrant-Complaint`, shortDescription: `Warrant-Complaint` },
	{ code: FormSetFormTypeUsage.WarrantComplaint_415101, description: `Warrant-Complaint`, shortDescription: `Warrant-Complaint` },
	{ code: FormSetFormTypeUsage.WarrantIssuewarrant_100068, description: `Warrant-Issue warrant`, shortDescription: `Warrant-Issue warrant` },
	{ code: FormSetFormTypeUsage.WarrantIssuewarrant_115834, description: `Warrant-Issue warrant`, shortDescription: `Warrant-Issue warrant` },
	{ code: FormSetFormTypeUsage.WarrantIssueWarrant_115988, description: `Warrant-Issue Warrant`, shortDescription: `Warrant-Issue Warrant` },
	{ code: FormSetFormTypeUsage.WarrantIssueWarrant_415401, description: `Warrant-Issue Warrant`, shortDescription: `Warrant-Issue Warrant` },
	{ code: FormSetFormTypeUsage.WarrantYJcasenote_115836, description: `Warrant-YJ case note`, shortDescription: `Warrant-YJ case note` },
	{ code: FormSetFormTypeUsage.WarrantYJcasenote_1000165, description: `Warrant-YJ case note`, shortDescription: `Warrant-YJ case note` },
	{ code: FormSetFormTypeUsage.WarrantYJcasenote_4101021, description: `Warrant-YJ case note`, shortDescription: `Warrant-YJ case note` },
	{ code: FormSetFormTypeUsage.WatchhouseTransportWatchhousedetentiondetails_16502, description: `Watchhouse / Transport-Watchhouse detention details`, shortDescription: `Watchhouse / Transport-Watchhouse detention details` },
	{ code: FormSetFormTypeUsage.WatchhouseTransportWatchhousedetentiondetails_16513, description: `Watchhouse / Transport-Watchhouse detention details`, shortDescription: `Watchhouse / Transport-Watchhouse detention details` },
	{ code: FormSetFormTypeUsage.WatchhouseTransportWatchhousedetentiondetails_16601, description: `Watchhouse / Transport-Watchhouse detention details`, shortDescription: `Watchhouse / Transport-Watchhouse detention details` },
	{ code: FormSetFormTypeUsage.WatchhouseTransportWatchhousedetentiondetails_16612, description: `Watchhouse / Transport-Watchhouse detention details`, shortDescription: `Watchhouse / Transport-Watchhouse detention details` },
	{ code: FormSetFormTypeUsage.WatchhouseTransportWatchhousedetentiondetails_450021, description: `Watchhouse / Transport-Watchhouse detention details`, shortDescription: `Watchhouse / Transport-Watchhouse detention details` },
	{ code: FormSetFormTypeUsage.WatchhouseTransportWatchhousedetentiondetails_450022, description: `Watchhouse / Transport-Watchhouse detention details`, shortDescription: `Watchhouse / Transport-Watchhouse detention details` },
	{ code: FormSetFormTypeUsage.WatchhouseTransportYJcasenote_16507, description: `Watchhouse / Transport-YJ case note`, shortDescription: `Watchhouse / Transport-YJ case note` },
	{ code: FormSetFormTypeUsage.WatchhouseTransportYJcasenote_16605, description: `Watchhouse / Transport-YJ case note`, shortDescription: `Watchhouse / Transport-YJ case note` },
	{ code: FormSetFormTypeUsage.WatchhouseTransportYJcasenote_410105, description: `Watchhouse / Transport-YJ case note`, shortDescription: `Watchhouse / Transport-YJ case note` },
	{ code: FormSetFormTypeUsage.WatchhouseTransportYJcasenote_410109, description: `Watchhouse / Transport-YJ case note`, shortDescription: `Watchhouse / Transport-YJ case note` },
	{ code: FormSetFormTypeUsage.WatchhouseTransportYJcasenote_1000149, description: `Watchhouse / Transport-YJ case note`, shortDescription: `Watchhouse / Transport-YJ case note` },
	{ code: FormSetFormTypeUsage.WatchhouseTransportYJcasenote_1000153, description: `Watchhouse / Transport-YJ case note`, shortDescription: `Watchhouse / Transport-YJ case note` },
	{ code: FormSetFormTypeUsage.AdministrationCasenote_10031, description: `Administration-Case note`, shortDescription: `WH Administration-Case note` },
	{ code: FormSetFormTypeUsage.AdministrationClosureform_16201, description: `Administration-Closure form`, shortDescription: `WH Administration-Closure form` },
	{ code: FormSetFormTypeUsage.AdministrationClosureform_4200110, description: `Administration-Closure form`, shortDescription: `WH Administration-Closure form` },
	{ code: FormSetFormTypeUsage.AdministrationCPcasenote_115923, description: `Administration-CP case note`, shortDescription: `WH Administration-CP case note` },
	{ code: FormSetFormTypeUsage.AdministrationCPcasenote_3000030, description: `Administration-CP case note`, shortDescription: `WH Administration-CP case note` },
	{ code: FormSetFormTypeUsage.AdministrationClosureofwatchhouse_16200, description: `Administration-Closure of watchhouse`, shortDescription: `WH Administration-Watchhouse closure` },
	{ code: FormSetFormTypeUsage.YouthdetentioncentreTransportDetentionclosure_16606, description: `Youth detention centre / Transport-Detention closure`, shortDescription: `YDC / Transport-Detention closure` },
	{ code: FormSetFormTypeUsage.YouthdetentioncentreTransportDetentionclosure_16611, description: `Youth detention centre / Transport-Detention closure`, shortDescription: `YDC / Transport-Detention closure` },
	{ code: FormSetFormTypeUsage.YouthdetentioncentreTransportDetentionclosure_470001, description: `Youth detention centre / Transport-Detention closure`, shortDescription: `YDC / Transport-Detention closure` },
	{ code: FormSetFormTypeUsage.YouthdetentioncentreTransportDetentionclosure_1000138, description: `Youth detention centre / Transport-Detention closure`, shortDescription: `YDC / Transport-Detention closure` },
	{ code: FormSetFormTypeUsage.YouthdetentioncentreTransportYDCdetentiondetails_16503, description: `Youth detention centre / Transport-YDC detention details`, shortDescription: `YDC / Transport-YDC detention details` },
	{ code: FormSetFormTypeUsage.YouthdetentioncentreTransportYDCdetentiondetails_16511, description: `Youth detention centre / Transport-YDC detention details`, shortDescription: `YDC / Transport-YDC detention details` },
	{ code: FormSetFormTypeUsage.YouthdetentioncentreTransportYDCdetentiondetails_16512, description: `Youth detention centre / Transport-YDC detention details`, shortDescription: `YDC / Transport-YDC detention details` },
	{ code: FormSetFormTypeUsage.YouthdetentioncentreTransportYDCdetentiondetails_16514, description: `Youth detention centre / Transport-YDC detention details`, shortDescription: `YDC / Transport-YDC detention details` },
	{ code: FormSetFormTypeUsage.YouthdetentioncentreTransportYDCdetentiondetails_16602, description: `Youth detention centre / Transport-YDC detention details`, shortDescription: `YDC / Transport-YDC detention details` },
	{ code: FormSetFormTypeUsage.YouthdetentioncentreTransportYDCdetentiondetails_450011, description: `Youth detention centre / Transport-YDC detention details`, shortDescription: `YDC / Transport-YDC detention details` },
	{ code: FormSetFormTypeUsage.YouthdetentioncentreTransportYDCdetentiondetails_450012, description: `Youth detention centre / Transport-YDC detention details`, shortDescription: `YDC / Transport-YDC detention details` },
	{ code: FormSetFormTypeUsage.YouthdetentioncentreTransportYDCdetentiondetails_1000137, description: `Youth detention centre / Transport-YDC detention details`, shortDescription: `YDC / Transport-YDC detention details` },
	{ code: FormSetFormTypeUsage.YouthdetentioncentreTransportYJcasenote_16508, description: `Youth detention centre / Transport-YJ case note`, shortDescription: `YDC / Transport-YJ case note` },
	{ code: FormSetFormTypeUsage.YouthdetentioncentreTransportYJcasenote_16607, description: `Youth detention centre / Transport-YJ case note`, shortDescription: `YDC / Transport-YJ case note` },
	{ code: FormSetFormTypeUsage.YouthdetentioncentreTransportYJcasenote_410106, description: `Youth detention centre / Transport-YJ case note`, shortDescription: `YDC / Transport-YJ case note` },
	{ code: FormSetFormTypeUsage.YouthdetentioncentreTransportYJcasenote_1000150, description: `Youth detention centre / Transport-YJ case note`, shortDescription: `YDC / Transport-YJ case note` },
	{ code: FormSetFormTypeUsage.YouthdetentioncentreTransportYJcasenote_1000154, description: `Youth detention centre / Transport-YJ case note`, shortDescription: `YDC / Transport-YJ case note` },
	{ code: FormSetFormTypeUsage.YouthdetentioncentreTransportYJcasenote_4101010, description: `Youth detention centre / Transport-YJ case note`, shortDescription: `YDC / Transport-YJ case note` },
	{ code: FormSetFormTypeUsage.ClosureCasenote_10029, description: `Closure-Case note`, shortDescription: `YJ Suicide closure-Case note` },
	{ code: FormSetFormTypeUsage.ClosureCPcasenote_115979, description: `Closure-CP case note`, shortDescription: `YJ Suicide closure-CP case note` },
	{ code: FormSetFormTypeUsage.ClosureCPcasenote_3000048, description: `Closure-CP case note`, shortDescription: `YJ Suicide closure-CP case note` },
	{ code: FormSetFormTypeUsage.ClosureClosureform_16004, description: `Closure-Closure form`, shortDescription: `YJ Suicide closure-Suicide risk closure form` },
	{ code: FormSetFormTypeUsage.ClosureClosureform_440012, description: `Closure-Closure form`, shortDescription: `YJ Suicide closure-Suicide risk closure form` },
	{ code: FormSetFormTypeUsage.ClosureYJcasenote_16005, description: `Closure-YJ case note`, shortDescription: `YJ Suicide closure-YJ case note` },
	{ code: FormSetFormTypeUsage.ClosureYJcasenote_410101, description: `Closure-YJ case note`, shortDescription: `YJ Suicide closure-YJ case note` },
	{ code: FormSetFormTypeUsage.ClosureYJcasenote_1000145, description: `Closure-YJ case note`, shortDescription: `YJ Suicide closure-YJ case note` },
	{ code: FormSetFormTypeUsage.SuicideriskCasenote_10028, description: `Suicide risk-Case note`, shortDescription: `YJ Suicide-Case note` },
	{ code: FormSetFormTypeUsage.SuicideriskCasenote_16003, description: `Suicide risk-Case note`, shortDescription: `YJ Suicide-Case note` },
	{ code: FormSetFormTypeUsage.SuicideriskCPcasenote_115978, description: `Suicide risk-CP case note`, shortDescription: `YJ Suicide-CP case note` },
	{ code: FormSetFormTypeUsage.SuicideriskCPcasenote_3000047, description: `Suicide risk-CP case note`, shortDescription: `YJ Suicide-CP case note` },
	{ code: FormSetFormTypeUsage.SuicideriskMediumtolongtermsuicideriskmanagementplan_16006, description: `Suicide risk-Medium to long term suicide risk management plan`, shortDescription: `YJ Suicide-SR1 medium to long term plan` },
	{ code: FormSetFormTypeUsage.SuicideriskMediumtolongtermsuicideriskmanagementplan_440001, description: `Suicide risk-Medium to long term suicide risk management plan`, shortDescription: `YJ Suicide-SR1 medium to long term plan` },
	{ code: FormSetFormTypeUsage.SuicideriskSuicideriskalertSR1_16002, description: `Suicide risk-Suicide risk alert (SR1)`, shortDescription: `YJ Suicide-Suicide risk alert (SR1)` },
	{ code: FormSetFormTypeUsage.SuicideriskSuicideriskalertSR1_115929, description: `Suicide risk-Suicide risk alert (SR1)`, shortDescription: `YJ Suicide-Suicide risk alert (SR1)` },
	{ code: FormSetFormTypeUsage.SuicideriskSuicideriskalertSR1_345202, description: `Suicide risk-Suicide risk alert (SR1)`, shortDescription: `YJ Suicide-Suicide risk alert (SR1)` },
	{ code: FormSetFormTypeUsage.SuicideriskYJcasenote_115930, description: `Suicide risk-YJ case note`, shortDescription: `YJ Suicide-YJ case note` },
	{ code: FormSetFormTypeUsage.SuicideriskYJcasenote_1000178, description: `Suicide risk-YJ case note`, shortDescription: `YJ Suicide-YJ case note` },
	{ code: FormSetFormTypeUsage.SuicideriskYJcasenote_4101034, description: `Suicide risk-YJ case note`, shortDescription: `YJ Suicide-YJ case note` },
	{ code: FormSetFormTypeUsage.SuicideriskYJCcasenote_345301, description: `Suicide risk-YJC case note`, shortDescription: `YJ Suicide-YJC case note` },
	{ code: FormSetFormTypeUsage.SuicideriskYJCcasenote_1000203, description: `Suicide risk-YJC case note`, shortDescription: `YJ Suicide-YJC case note` }
];

export function getFormSetFormTypeUsageGroupDescriptor(FormSetFormTypeUsageCode: string) : IFormSetFormTypeUsageGroupDescriptor {
	for(var i = 0; i < FormSetFormTypeUsageGroup.length; i++)
	{
		if(FormSetFormTypeUsageGroup[i].code == FormSetFormTypeUsageCode)
		{
			return FormSetFormTypeUsageGroup[i];
		}
	}
	return null;
}


export enum FormType {
	AboriginalTorresStraitIslanderchildplacement_36011 = "36011",
	Actionplan_15406 = "15406",
	Actionplan_15603 = "15603",
	Actionplan_100145 = "100145",
	Actionplan_100146 = "100146",
	Addendumpresentencereport_100034 = "100034",
	Addendumpresentencereport_100154 = "100154",
	Addendumpresentencereport_100157 = "100157",
	Additionalnotifiedconcerns_15303 = "15303",
	Additionalnotifiedconcerns_15402 = "15402",
	Additionalnotifiedconcerns_15509 = "15509",
	Additionalnotifiedconcerns_15601 = "15601",
	Additionalnotifiedconcerns_18101 = "18101",
	Additionalnotifiedconcerns_18201 = "18201",
	Additionalnotifiedconcerns_30070 = "30070",
	Additionalnotifiedconcerns_31015 = "31015",
	Additionalnotifiedconcerns_31016 = "31016",
	Additionalnotifiedconcerns_100090 = "100090",
	Additionalnotifiedconcerns_100093 = "100093",
	Additionalnotifiedconcerns_100098 = "100098",
	Additionalnotifiedconcerns_100129 = "100129",
	Additionalnotifiedconcerns_100136 = "100136",
	Additionalnotifiedconcerns_100147 = "100147",
	Additionalnotifiedconcerns_100148 = "100148",
	Additionalnotifiedconcerns_100150 = "100150",
	Additionalnotifiedconcerns_100151 = "100151",
	Afterhoursreferral_41013 = "41013",
	Afterhoursreferral_100048 = "100048",
	CPSAssessmentofProtectiveNeeds_20000 = "20000",
	CPSAssessmentofProtectiveNeedsPriorVersion_20001 = "20001",
	CPSAssessmentofProtectiveNeeds_20002 = "20002",
	CPSAssessmentofProtectiveNeedsPriorVersion_20003 = "20003",
	ASCresponse_31019 = "31019",
	Assessment_40070 = "40070",
	Assessment_100014 = "100014",
	Assessmentoutcome_15507 = "15507",
	Assessmentoutcome_18100 = "18100",
	Assessmentoutcome_31011 = "31011",
	Assessmentoutcome_100125 = "100125",
	Assessmentofplacementinformationtoparents_15901 = "15901",
	Assessmentofplacementinformationtoparents_33001 = "33001",
	Assessmentofplacementinformationtoparents_100111 = "100111",
	Assessmentplan_15405 = "15405",
	Assessmentplan_100135 = "100135",
	Attachment_100022 = "100022",
	Authoritytocare_15900 = "15900",
	Authoritytocare_18401 = "18401",
	CPSAuthoritytoCareforChild_20009 = "20009",
	Authoritytocare_33000 = "33000",
	Authoritytocare_100060 = "100060",
	Authoritytocare_100119 = "100119",
	Bailsupportcompletion_40550 = "40550",
	Bailsupportcompletion_100054 = "100054",
	Bailsupportreferral_40560 = "40560",
	Bailsupportreferral_100053 = "100053",
	CPSApplicationCAO_20034 = "20034",
	CPSApplicationCAOPriorVersion_20035 = "20035",
	CPSApplicationCAOExtend_20072 = "20072",
	CPSApplicationCAOExtendPriorVersion_20076 = "20076",
	CPSApplicationCAOExtendVary_20074 = "20074",
	CPSApplicationCAOExtendVaryPriorVersion_20078 = "20078",
	CPSLettertoParentsCAO_20036 = "20036",
	CPSLettertoParentsCAOPriorVersion_20037 = "20037",
	CPSApplicationCAORevoke_20075 = "20075",
	CPSApplicationCAORevokePriorVersion_20079 = "20079",
	CPSApplicationCAOVary_20073 = "20073",
	CPSApplicationCAOVaryPriorVersion_20077 = "20077",
	Careagreement_15903 = "15903",
	Careagreement_33003 = "33003",
	Careagreement_100062 = "100062",
	Careagreement_100110 = "100110",
	Caseclosure_40060 = "40060",
	Caseclosure_100015 = "100015",
	Casemanagement_15809 = "15809",
	CPSCaseManagement_20040 = "20040",
	Casenote_100000 = "100000",
	CPSCaseNote_20010 = "20010",
	CPSCaseNotePriorVersion_20011 = "20011",
	CPSCaseNote_20012 = "20012",
	CPSCaseNotePriorVersion_20013 = "20013",
	Caseplan_15800 = "15800",
	Caseplan_17800 = "17800",
	CPSCasePlan_20041 = "20041",
	CPSCasePlanPriorVersion_20043 = "20043",
	CPSCasePlanOutcomeAction_20044 = "20044",
	Caseplan_32530 = "32530",
	Caseplan_32531 = "32531",
	Caseplan_100064 = "100064",
	Caseplan_100137 = "100137",
	Caseplan_100141 = "100141",
	CasereviewYDC_100013 = "100013",
	CasereviewYDC_1000139 = "1000139",
	CasereviewYDC_1000146 = "1000146",
	CasereviewYJSC_100019 = "100019",
	CasereviewYJSC_1000140 = "1000140",
	CasereviewYJSC_1000147 = "1000147",
	CPSCaseWork_20045 = "20045",
	Conditionalbailprogramcompletion_40530 = "40530",
	Conditionalbailprogramcompletion_100052 = "100052",
	Conditionalbailprogramreferralproposal_40540 = "40540",
	Conditionalbailprogramreferralproposal_100049 = "100049",
	Conditionalbailprogramreferralproposaloutcome_40510 = "40510",
	Conditionalbailprogramreferralproposaloutcome_100050 = "100050",
	CPSChildConcernReport_20024 = "20024",
	CPSChildConcernReportPriorVersion_20025 = "20025",
	Childconcernreport_15305 = "15305",
	Childconcernreport_31002 = "31002",
	Childplacementconcernreport_15404 = "15404",
	Childplacementconcernreport_100100 = "100100",
	Childstrengthsandneedsassessment_15807 = "15807",
	Childstrengthsandneedsassessment_18300 = "18300",
	Childstrengthsandneedsassessment_32590 = "32590",
	Childstrengthsandneedsassessment_100143 = "100143",
	Closureform_15007 = "15007",
	Closureform_30050 = "30050",
	Closureform_30120 = "30120",
	Closureform_31000 = "31000",
	Closureform_41015 = "41015",
	Closureform_42001 = "42001",
	ClosureofCAO_15207 = "15207",
	ClosureofCPO_15709 = "15709",
	ClosureofMOCIA_15606 = "15606",
	Closureofongoingintervention_15808 = "15808",
	Closureofplacement_15905 = "15905",
	Closureofplacement_33005 = "33005",
	Closureofplacement_100140 = "100140",
	Closureofplacement_100160 = "100160",
	ClosureofTAO_15100 = "15100",
	ClosureofTCO_100107 = "100107",
	Communityserviceorderreferral_41006 = "41006",
	Communityserviceorderreferral_100045 = "100045",
	Complaint_41510 = "41510",
	Complaint_100026 = "100026",
	Complaint_100075 = "100075",
	Concernsconsultationandresponsedecision_30010 = "30010",
	Conditionalreleaseorderprogramproposal_48000 = "48000",
	Conditionalreleaseorderprogramproposal_100035 = "100035",
	ApplicationforCourtOrder_20097 = "20097",
	CourtCaseNotes_41020 = "41020",
	CourtCaseNotes_100079 = "100079",
	CourtOutcome_20098 = "20098",
	COF_20047 = "20047",
	CPcasenote_30000 = "30000",
	CPcasenote_100001 = "100001",
	CPSChildPlacementConcernReport_20029 = "20029",
	CPSChildPlacementConcernReportPriorVersion_20030 = "20030",
	CPSApplicationCPO_20048 = "20048",
	CPSApplicationCPOPriorVersion_20049 = "20049",
	CPSApplicationCPOExtend_20080 = "20080",
	CPSApplicationCPOExtendPriorVersion_20085 = "20085",
	CPSApplicationCPOExtendVary_20082 = "20082",
	CPSApplicationCPOExtendVaryPriorVersion_20087 = "20087",
	CPSLettertoParentsCPO_20050 = "20050",
	CPSLettertoParentsCPOPriorVersion_20051 = "20051",
	CPSApplicationCPORevoke_20083 = "20083",
	CPSApplicationCPORevokePriorVersion_20088 = "20088",
	CPSApplicationCPORevokeNewOrder_20084 = "20084",
	CPSApplicationCPORevokeNewOrderPriorVer_20089 = "20089",
	CPSApplicationCPOVary_20081 = "20081",
	CPSApplicationCPOVaryPriorVersion_20086 = "20086",
	Form16InterimOrder_15705 = "15705",
	LettertoParentsOrder_15707 = "15707",
	Departmentalhistory_15003 = "15003",
	Closureofdetention_16100 = "16100",
	Detentionclosure_16601 = "16601",
	Detentionclosure_16603 = "16603",
	Detentionclosure_47000 = "47000",
	Detentionclosure_1000138 = "1000138",
	Drugtestingconditioninformation_41007 = "41007",
	Drugtestingconditioninformation_100043 = "100043",
	Escapealert_16400 = "16400",
	Escapealert_42000 = "42000",
	CPSExternalFileReference_20026 = "20026",
	Familyreunificationassessment_15803 = "15803",
	Familyreunificationassessment_32740 = "32740",
	Familyreunificationassessment_32741 = "32741",
	Familyreunificationassessment_32742 = "32742",
	Familyriskevaluation_15506 = "15506",
	Familyriskevaluation_18102 = "18102",
	Familyriskevaluation_31014 = "31014",
	Familyriskevaluation_31018 = "31018",
	Familyriskevaluation_100123 = "100123",
	Familyriskevaluation_100139 = "100139",
	Familyriskreevaluationforinhomecases_15802 = "15802",
	Familyriskreevaluationforinhomecases_18301 = "18301",
	Familyriskreevaluationforinhomecases_32700 = "32700",
	Familyriskreevaluationforinhomecases_100144 = "100144",
	Form1ApplicationforaTAO_15101 = "15101",
	Form1ApplicationforaTAO_18500 = "18500",
	Form1ApplicationforaTAO_100101 = "100101",
	Form1TAOoutcomeformTOF_15103 = "15103",
	Form1TAOoutcomeformTOF_100102 = "100102",
	Form10ApplicationforaCPO_15700 = "15700",
	Form10COFfinalorder_15701 = "15701",
	Form10COFfinalorder_100121 = "100121",
	Form11ApplicationtoextendvaryrevokeaCPO_15702 = "15702",
	Form11ApplicationtoextendvaryrevokeaCPO_100120 = "100120",
	Form11COFfinalorder_15703 = "15703",
	Form11COFfinalorder_100127 = "100127",
	Form12ApplicationtorevokemakeanotherCPO_15704 = "15704",
	Form12COFfinalorder_15708 = "15708",
	Form12COFfinalorder_100109 = "100109",
	Form16COFadjournmentorder_15706 = "15706",
	Form16COFadjournmentorder_100124 = "100124",
	Form17_15002 = "15002",
	Form2ApplicationtoextendvaryaTAO_15102 = "15102",
	Form2ApplicationtoextendvaryaTAO_100105 = "100105",
	Form2TAOoutcomeformTOF_15104 = "15104",
	Form2TAOoutcomeformTOF_100103 = "100103",
	Form25_15000 = "15000",
	Form26_15001 = "15001",
	Form39ApplicationforaTCO_100104 = "100104",
	Form39TCOoutcomeformTCOF_100106 = "100106",
	Form41ApplicationtoextendvaryaTCO_100112 = "100112",
	Form41TCOoutcomeformTCOF_100117 = "100117",
	Form44COFtransitionorder_100128 = "100128",
	Form5ApplicationforaCAO_15200 = "15200",
	Form5COFfinalorder_15201 = "15201",
	Form5COFfinalorder_100095 = "100095",
	Form6ApplicationtoextendvaryrevokeaCAO_15202 = "15202",
	Form6COFfinalorder_15203 = "15203",
	Form6COFfinalorder_100096 = "100096",
	Form9COFadjournmentorder_15205 = "15205",
	Form9COFadjournmentorder_100099 = "100099",
	Griffithreferral_40080 = "40080",
	Griffithreferral_100037 = "100037",
	Griffithreferral_1000143 = "1000143",
	Harmreport_30040 = "30040",
	Harmreportassessmentoutcome_30090 = "30090",
	HarmreportIAplan_30060 = "30060",
	Harmreportactionplan_30100 = "30100",
	Healthchecklist_41008 = "41008",
	Healthchecklist_100040 = "100040",
	Independententity_30025 = "30025",
	Informationgathering_15503 = "15503",
	Informationgathering_31005 = "31005",
	InformationProvision_15604 = "15604",
	Informationprovision_30110 = "30110",
	InformationProvision_100094 = "100094",
	Informationprovisiontoparents_15508 = "15508",
	Informationprovisiontoparents_31012 = "31012",
	Informationprovisiontoparents_100126 = "100126",
	Informationtoparentsorcarer_41009 = "41009",
	Informationtoparentsorcarer_100041 = "100041",
	Informationtoparentsorcarer_100071 = "100071",
	Informationtoparentsorcarer_100155 = "100155",
	CPSInitialAssessment_20031 = "20031",
	CPSInitialAssessmentPriorVersion_20032 = "20032",
	CPSInitialAssessmentPriorVersion_20090 = "20090",
	InitialinterviewYDC_41005 = "41005",
	InitialinterviewYDC_100039 = "100039",
	InitialinterviewYJSC_41004 = "41004",
	InitialinterviewYJSC_100038 = "100038",
	InitialinterviewYJSC_100152 = "100152",
	InitialinterviewYJSC_100158 = "100158",
	CPSIntake_20022 = "20022",
	CPSIntakePriorVersion_20023 = "20023",
	Intake_31020 = "31020",
	Intensivesupervisionorderprogramproposal_48001 = "48001",
	Intensivesupervisionorderprogramproposal_100036 = "100036",
	Interventionplan_100016 = "100016",
	CPSInvestigationandAssessment_20033 = "20033",
	CPSInvestigationandAssessmentPriorVersion_20071 = "20071",
	Investigationandassessmentplan_15501 = "15501",
	ISOconferencereferral_41011 = "41011",
	ISOconferencereferral_100046 = "100046",
	IssueComplaintandSummons_41520 = "41520",
	Issuecomplaintandsummons_100023 = "100023",
	Issuecomplaintandsummons_100066 = "100066",
	IssueComplaintandSummons_100073 = "100073",
	IssueWarningLetter_41530 = "41530",
	Issuewarningletter_100021 = "100021",
	Issuewarningletter_100067 = "100067",
	IssueWarningLetter_100072 = "100072",
	IssueWarrant_41540 = "41540",
	Issuewarrant_100025 = "100025",
	Issuewarrant_100068 = "100068",
	IssueWarrant_100074 = "100074",
	LettertoParentsOrder_15206 = "15206",
	LTGtosuitablepersonCaseplan_32550 = "32550",
	LTGtosuitablepersonCaseplan_100133 = "100133",
	LTGtosuitablepersonCaseplan_100142 = "100142",
	LTGtosuitablepersonContactandreviewreport_32520 = "32520",
	LTGtosuitablepersonContactandreviewreport_100132 = "100132",
	CPSActionPlanMOCCPCR_20055 = "20055",
	CPSActionPlanMOCCPCRPriorVersion_20056 = "20056",
	CPSActionPlanMOCIA_20091 = "20091",
	CPSActionPlanMOCIAPriorVersion_20092 = "20092",
	MOCAssessmentoutcome_15602 = "15602",
	MOCAssessmentoutcome_18601 = "18601",
	CPSAssessmentPlanMOCCPCR_20057 = "20057",
	CPSAssessmentPlanMOCCPCRPriorVersion_20058 = "20058",
	CPSAssessmentPlanMOCIA_20093 = "20093",
	CPSAssessmentPlanMOCIAPriorVersion_20094 = "20094",
	MOCInvestigationandassessmentplan_15600 = "15600",
	MOCNotification_15403 = "15403",
	Notification_15304 = "15304",
	CPSNotification_20027 = "20027",
	CPSNotificationPriorVersion_20028 = "20028",
	Notification_31009 = "31009",
	Notification_100091 = "100091",
	NotifiedconcernsResponsepriority_15500 = "15500",
	NotifiedconcernsResponsepriority_31017 = "31017",
	NotifiedconcernsResponsepriority_100149 = "100149",
	Oathofservice_41610 = "41610",
	Oathofservice_100024 = "100024",
	Parentalstrengthsneedsassessmentreassessment_15801 = "15801",
	Parentalstrengthsneedsassessmentreassessment_32600 = "32600",
	Parentalstrengthsneedsassessmentreassessment_100063 = "100063",
	Placementagreement_15902 = "15902",
	Placementagreement_18400 = "18400",
	CPSPlacementAgreement_20008 = "20008",
	Placementagreement_33002 = "33002",
	Placementagreement_100061 = "100061",
	Placementagreement_100118 = "100118",
	Pre1997Assessment_20095 = "20095",
	Pre1997Notification_20096 = "20096",
	Prenotificationchecks_15301 = "15301",
	Prenotificationchecks_31001 = "31001",
	Presentencereport_48002 = "48002",
	Presentencereport_100033 = "100033",
	Presentencereport_100153 = "100153",
	Presentencereport_100156 = "100156",
	Programreferral_41016 = "41016",
	Recognisedentityparticipation_15005 = "15005",
	Recognisedentityparticipation_18001 = "18001",
	Recognisedentityparticipation_30020 = "30020",
	RecognisedentityparticipationCPP_15907 = "15907",
	RecognisedentityparticipationCPP_36010 = "36010",
	RecognisedentityparticipationCPP_100138 = "100138",
	Recordofactionsmobilefamily_15502 = "15502",
	Recordofactionsmobilefamily_31004 = "31004",
	Recordofadviceofarrest_16501 = "16501",
	Recordofadviceofarrest_45000 = "45000",
	Recordofadviceofarrest_45004 = "45004",
	Recordofchecksandconsultation_15006 = "15006",
	Recordofchecksandconsultation_15607 = "15607",
	Recordofconcerns_15300 = "15300",
	Recordofconcerns_15400 = "15400",
	Recordofconcerns_31010 = "31010",
	Recordofconcerns_100065 = "100065",
	Recordofconcerns_100092 = "100092",
	Recordofconcerns_100097 = "100097",
	CPSRecordofFamilyMeeting_20004 = "20004",
	CPSRecordofFamilyMeetingPriorVersion_20005 = "20005",
	CPSRecordofFamilyMeeting_20006 = "20006",
	CPSRecordofFamilyMeetingPriorVersion_20007 = "20007",
	Recordofafamilygroupmeeting_15805 = "15805",
	Recordofafamilygroupmeeting_32710 = "32710",
	Recordofinterviews_15504 = "15504",
	Recordofinterviews_15605 = "15605",
	Recordofinterviews_18600 = "18600",
	Recordofinterviews_30080 = "30080",
	Recordofinterviewsuseofpowers_31013 = "31013",
	Recordofinterviewsuseofpowers_100131 = "100131",
	Recordofuseofpowers_15505 = "15505",
	Referraltoaconvenorforafamilygroupmeeting_15804 = "15804",
	RegionalDirectorsconversation_45003 = "45003",
	RegionalExecutiveDirectorsconversation_16504 = "16504",
	Reviewreport_15806 = "15806",
	Reviewreport_17806 = "17806",
	CPSReviewReport_20061 = "20061",
	CPSReviewReportPriorVersion_20062 = "20062",
	CPSReviewReportOutcomeAction_20064 = "20064",
	Reviewreport_32720 = "32720",
	Reviewreport_32721 = "32721",
	Reviewreport_100081 = "100081",
	Reviewreport_100159 = "100159",
	Safeplacemovementrecord_15306 = "15306",
	CPSSafePlaceMovementRecord_20014 = "20014",
	Safeplacemovementrecord_31003 = "31003",
	Safetyassessment_15004 = "15004",
	Safetyassessment_18000 = "18000",
	Safetyassessment_31006 = "31006",
	ScreeningcriteriaResponsepriority_31008 = "31008",
	ScreeningcriteriaResponsepriority_15302 = "15302",
	ScreeningcriteriaResponsepriority_18200 = "18200",
	Mediumtolongtermsuicideriskmanagementplan_16002 = "16002",
	Mediumtolongtermsuicideriskmanagementplan_44000 = "44000",
	Standardsofcarereviewreport_30030 = "30030",
	SuicideriskalertSR1_16000 = "16000",
	SuicideriskalertSR1_16003 = "16003",
	SuicideriskalertSR1_34520 = "34520",
	Closureform_16001 = "16001",
	Closureform_44001 = "44001",
	Supervisedreleaseorder_41044 = "41044",
	Supervisedreleaseorder_100042 = "100042",
	Supervisedreleaseorder_100069 = "100069",
	CPSApplicationTAO_20015 = "20015",
	CPSApplicationTAOPriorVersion_20016 = "20016",
	CPSOrderTAO_20019 = "20019",
	CPSApplicationTAOExtend_20017 = "20017",
	CPSApplicationTAOExtendPriorVersion_20018 = "20018",
	CPSOrderTAOExtend_20020 = "20020",
	CPSTAOExtSummary_20070 = "20070",
	CPSTAOSummary_20021 = "20021",
	Transfersummaryinformalinterstateoverseas_46000 = "46000",
	Transfersummaryinformalinterstateoverseas_100032 = "100032",
	Transfersummaryinformalinterstateoverseas_1000141 = "1000141",
	Transfertoprison_100030 = "100030",
	TransferToPrison_100078 = "100078",
	Transitionplan_32730 = "32730",
	Transitionplan_100134 = "100134",
	Transitionreferral_40520 = "40520",
	Transitionreferral_100051 = "100051",
	Varydetentionorderintheinterestsofjustice_41560 = "41560",
	Varydetentionorderintheinterestsofjustice_100029 = "100029",
	Varydetentionorderintheinterestsofjustice_1000142 = "1000142",
	Varyorderbyconsent_41570 = "41570",
	Varyorderbyconsent_100027 = "100027",
	Varyorderbyconsent_100076 = "100076",
	Varyorderintheinterestsofjustice_41580 = "41580",
	Varyorderintheinterestsofjustice_100028 = "100028",
	Varyorderintheinterestsofjustice_100077 = "100077",
	VaryOrRevokeATransferOrder_100080 = "100080",
	Closureofwatchhouse_16200 = "16200",
	Watchhousedetentiondetails_16502 = "16502",
	Watchhousedetentiondetails_16506 = "16506",
	Watchhousedetentiondetails_45002 = "45002",
	YDCdetentiondetails_16503 = "16503",
	YDCdetentiondetails_16505 = "16505",
	YDCdetentiondetails_45001 = "45001",
	YDCdetentiondetails_1000137 = "1000137",
	YDCtherapeuticprogramsreferral_41012 = "41012",
	YDCtherapeuticprogramsreferral_100047 = "100047",
	YJcasenote_41010 = "41010",
	YJcasenote_100010 = "100010",
	YJcasenote_1000145 = "1000145",
	YJCcasenote_34530 = "34530",
	YJCcasenote_1000148 = "1000148",
	Youngpersonsummary_46001 = "46001",
	Youngpersonsummary_100011 = "100011",
	Youngpersonsummary_100031 = "100031",
	YouthJusticecasereview_40040 = "40040",
	Youthworkersupportreferral_41014 = "41014",
	Youthworkersupportreferral_100044 = "100044",
	Youthworkersupportreferral_100070 = "100070"
}

export interface IFormTypeGroupDescriptor { code: FormType; description: string; shortDescription: string; }
export var FormTypeGroup: IFormTypeGroupDescriptor[] = [
	{ code: FormType.AboriginalTorresStraitIslanderchildplacement_36011, description: `Aboriginal/Torres Strait Islander child placement`, shortDescription: `Aboriginal/Torres Strait Islander child placement` },
	{ code: FormType.Actionplan_15406, description: `Action plan`, shortDescription: `Action plan` },
	{ code: FormType.Actionplan_15603, description: `Action plan`, shortDescription: `Action plan` },
	{ code: FormType.Actionplan_100145, description: `Action plan`, shortDescription: `Action plan` },
	{ code: FormType.Actionplan_100146, description: `Action plan`, shortDescription: `Action plan` },
	{ code: FormType.Addendumpresentencereport_100034, description: `Addendum pre-sentence report`, shortDescription: `Addendum pre-sentence report` },
	{ code: FormType.Addendumpresentencereport_100154, description: `Addendum pre-sentence report`, shortDescription: `Addendum pre-sentence report` },
	{ code: FormType.Addendumpresentencereport_100157, description: `Addendum pre-sentence report`, shortDescription: `Addendum pre-sentence report` },
	{ code: FormType.Additionalnotifiedconcerns_15303, description: `Additional notified concerns`, shortDescription: `Additional notified concerns` },
	{ code: FormType.Additionalnotifiedconcerns_15402, description: `Additional notified concerns`, shortDescription: `Additional notified concerns` },
	{ code: FormType.Additionalnotifiedconcerns_15509, description: `Additional notified concerns`, shortDescription: `Additional notified concerns` },
	{ code: FormType.Additionalnotifiedconcerns_15601, description: `Additional notified concerns`, shortDescription: `Additional notified concerns` },
	{ code: FormType.Additionalnotifiedconcerns_18101, description: `Additional notified concerns`, shortDescription: `Additional notified concerns` },
	{ code: FormType.Additionalnotifiedconcerns_18201, description: `Additional notified concerns`, shortDescription: `Additional notified concerns` },
	{ code: FormType.Additionalnotifiedconcerns_30070, description: `Additional notified concerns`, shortDescription: `Additional notified concerns` },
	{ code: FormType.Additionalnotifiedconcerns_31015, description: `Additional notified concerns`, shortDescription: `Additional notified concerns` },
	{ code: FormType.Additionalnotifiedconcerns_31016, description: `Additional notified concerns`, shortDescription: `Additional notified concerns` },
	{ code: FormType.Additionalnotifiedconcerns_100090, description: `Additional notified concerns`, shortDescription: `Additional notified concerns` },
	{ code: FormType.Additionalnotifiedconcerns_100093, description: `Additional notified concerns`, shortDescription: `Additional notified concerns` },
	{ code: FormType.Additionalnotifiedconcerns_100098, description: `Additional notified concerns`, shortDescription: `Additional notified concerns` },
	{ code: FormType.Additionalnotifiedconcerns_100129, description: `Additional notified concerns`, shortDescription: `Additional notified concerns` },
	{ code: FormType.Additionalnotifiedconcerns_100136, description: `Additional notified concerns`, shortDescription: `Additional notified concerns` },
	{ code: FormType.Additionalnotifiedconcerns_100147, description: `Additional notified concerns`, shortDescription: `Additional notified concerns` },
	{ code: FormType.Additionalnotifiedconcerns_100148, description: `Additional notified concerns`, shortDescription: `Additional notified concerns` },
	{ code: FormType.Additionalnotifiedconcerns_100150, description: `Additional notified concerns`, shortDescription: `Additional notified concerns` },
	{ code: FormType.Additionalnotifiedconcerns_100151, description: `Additional notified concerns`, shortDescription: `Additional notified concerns` },
	{ code: FormType.Afterhoursreferral_41013, description: `After hours referral`, shortDescription: `After hours referral` },
	{ code: FormType.Afterhoursreferral_100048, description: `After hours referral`, shortDescription: `After hours referral` },
	{ code: FormType.CPSAssessmentofProtectiveNeeds_20000, description: `CPS Assessment of Protective Needs`, shortDescription: `APN` },
	{ code: FormType.CPSAssessmentofProtectiveNeedsPriorVersion_20001, description: `CPS Assessment of Protective Needs (Prior Version)`, shortDescription: `APN` },
	{ code: FormType.CPSAssessmentofProtectiveNeeds_20002, description: `CPS Assessment of Protective Needs`, shortDescription: `APN` },
	{ code: FormType.CPSAssessmentofProtectiveNeedsPriorVersion_20003, description: `CPS Assessment of Protective Needs (Prior Version)`, shortDescription: `APN` },
	{ code: FormType.ASCresponse_31019, description: `ASC response`, shortDescription: `ASC response` },
	{ code: FormType.Assessment_40070, description: `Assessment`, shortDescription: `Assessment` },
	{ code: FormType.Assessment_100014, description: `Assessment`, shortDescription: `Assessment` },
	{ code: FormType.Assessmentoutcome_15507, description: `Assessment & outcome`, shortDescription: `Assessment & outcome` },
	{ code: FormType.Assessmentoutcome_18100, description: `Assessment & outcome`, shortDescription: `Assessment & outcome` },
	{ code: FormType.Assessmentoutcome_31011, description: `Assessment & outcome`, shortDescription: `Assessment & outcome` },
	{ code: FormType.Assessmentoutcome_100125, description: `Assessment & outcome`, shortDescription: `Assessment & outcome` },
	{ code: FormType.Assessmentofplacementinformationtoparents_15901, description: `Assessment of placement information to parents`, shortDescription: `Assessment of placement information to parents` },
	{ code: FormType.Assessmentofplacementinformationtoparents_33001, description: `Assessment of placement information to parents`, shortDescription: `Assessment of placement information to parents` },
	{ code: FormType.Assessmentofplacementinformationtoparents_100111, description: `Assessment of placement information to parents`, shortDescription: `Assessment of placement information to parents` },
	{ code: FormType.Assessmentplan_15405, description: `Assessment plan`, shortDescription: `Assessment plan` },
	{ code: FormType.Assessmentplan_100135, description: `Assessment plan`, shortDescription: `Assessment plan` },
	{ code: FormType.Attachment_100022, description: `Attachment`, shortDescription: `Attachment` },
	{ code: FormType.Authoritytocare_15900, description: `Authority to care`, shortDescription: `Authority to care` },
	{ code: FormType.Authoritytocare_18401, description: `Authority to care`, shortDescription: `Authority to care` },
	{ code: FormType.CPSAuthoritytoCareforChild_20009, description: `CPS Authority to Care for Child`, shortDescription: `Authority to care` },
	{ code: FormType.Authoritytocare_33000, description: `Authority to care`, shortDescription: `Authority to care` },
	{ code: FormType.Authoritytocare_100060, description: `Authority to care`, shortDescription: `Authority to care` },
	{ code: FormType.Authoritytocare_100119, description: `Authority to care`, shortDescription: `Authority to care` },
	{ code: FormType.Bailsupportcompletion_40550, description: `Bail support completion`, shortDescription: `Bail support completion` },
	{ code: FormType.Bailsupportcompletion_100054, description: `Bail support completion`, shortDescription: `Bail support completion` },
	{ code: FormType.Bailsupportreferral_40560, description: `Bail support referral`, shortDescription: `Bail support referral` },
	{ code: FormType.Bailsupportreferral_100053, description: `Bail support referral`, shortDescription: `Bail support referral` },
	{ code: FormType.CPSApplicationCAO_20034, description: `CPS Application CAO`, shortDescription: `CAO` },
	{ code: FormType.CPSApplicationCAOPriorVersion_20035, description: `CPS Application CAO (Prior Version)`, shortDescription: `CAO` },
	{ code: FormType.CPSApplicationCAOExtend_20072, description: `CPS Application CAO (Extend)`, shortDescription: `CAO - extend` },
	{ code: FormType.CPSApplicationCAOExtendPriorVersion_20076, description: `CPS Application CAO (Extend) (Prior Version)`, shortDescription: `CAO - extend` },
	{ code: FormType.CPSApplicationCAOExtendVary_20074, description: `CPS Application CAO (Extend&Vary)`, shortDescription: `CAO - extend and vary` },
	{ code: FormType.CPSApplicationCAOExtendVaryPriorVersion_20078, description: `CPS Application CAO (Extend&Vary) (Prior Version)`, shortDescription: `CAO - extend and vary` },
	{ code: FormType.CPSLettertoParentsCAO_20036, description: `CPS Letter to Parents CAO`, shortDescription: `CAO - letter` },
	{ code: FormType.CPSLettertoParentsCAOPriorVersion_20037, description: `CPS Letter to Parents CAO (Prior Version)`, shortDescription: `CAO - letter` },
	{ code: FormType.CPSApplicationCAORevoke_20075, description: `CPS Application CAO (Revoke)`, shortDescription: `CAO - revoke` },
	{ code: FormType.CPSApplicationCAORevokePriorVersion_20079, description: `CPS Application CAO (Revoke) (Prior Version)`, shortDescription: `CAO - revoke` },
	{ code: FormType.CPSApplicationCAOVary_20073, description: `CPS Application CAO (Vary)`, shortDescription: `CAO - vary` },
	{ code: FormType.CPSApplicationCAOVaryPriorVersion_20077, description: `CPS Application CAO (Vary) (Prior Version)`, shortDescription: `CAO - vary` },
	{ code: FormType.Careagreement_15903, description: `Care agreement`, shortDescription: `Care agreement` },
	{ code: FormType.Careagreement_33003, description: `Care agreement`, shortDescription: `Care agreement` },
	{ code: FormType.Careagreement_100062, description: `Care agreement`, shortDescription: `Care agreement` },
	{ code: FormType.Careagreement_100110, description: `Care agreement`, shortDescription: `Care agreement` },
	{ code: FormType.Caseclosure_40060, description: `Case closure`, shortDescription: `Case closure` },
	{ code: FormType.Caseclosure_100015, description: `Case closure`, shortDescription: `Case closure` },
	{ code: FormType.Casemanagement_15809, description: `Case management`, shortDescription: `Case management` },
	{ code: FormType.CPSCaseManagement_20040, description: `CPS Case Management`, shortDescription: `Case management` },
	{ code: FormType.Casenote_100000, description: `Case note`, shortDescription: `Case note` },
	{ code: FormType.CPSCaseNote_20010, description: `CPS Case Note`, shortDescription: `Case notes` },
	{ code: FormType.CPSCaseNotePriorVersion_20011, description: `CPS Case Note (Prior Version)`, shortDescription: `Case notes` },
	{ code: FormType.CPSCaseNote_20012, description: `CPS Case Note`, shortDescription: `Case notes` },
	{ code: FormType.CPSCaseNotePriorVersion_20013, description: `CPS Case Note (Prior Version)`, shortDescription: `Case notes` },
	{ code: FormType.Caseplan_15800, description: `Case plan`, shortDescription: `Case plan` },
	{ code: FormType.Caseplan_17800, description: `Case plan`, shortDescription: `Case plan` },
	{ code: FormType.CPSCasePlan_20041, description: `CPS Case Plan`, shortDescription: `Case plan` },
	{ code: FormType.CPSCasePlanPriorVersion_20043, description: `CPS Case Plan (Prior Version)`, shortDescription: `Case plan` },
	{ code: FormType.CPSCasePlanOutcomeAction_20044, description: `CPS Case Plan Outcome&Action`, shortDescription: `Case plan` },
	{ code: FormType.Caseplan_32530, description: `Case plan`, shortDescription: `Case plan` },
	{ code: FormType.Caseplan_32531, description: `Case plan`, shortDescription: `Case plan` },
	{ code: FormType.Caseplan_100064, description: `Case plan`, shortDescription: `Case plan` },
	{ code: FormType.Caseplan_100137, description: `Case plan`, shortDescription: `Case plan` },
	{ code: FormType.Caseplan_100141, description: `Case plan`, shortDescription: `Case plan` },
	{ code: FormType.CasereviewYDC_100013, description: `Case review - YDC`, shortDescription: `Case review - YDC` },
	{ code: FormType.CasereviewYDC_1000139, description: `Case review - YDC`, shortDescription: `Case review - YDC` },
	{ code: FormType.CasereviewYDC_1000146, description: `Case review - YDC`, shortDescription: `Case review - YDC` },
	{ code: FormType.CasereviewYJSC_100019, description: `Case review - YJSC`, shortDescription: `Case review - YJSC` },
	{ code: FormType.CasereviewYJSC_1000140, description: `Case review - YJSC`, shortDescription: `Case review - YJSC` },
	{ code: FormType.CasereviewYJSC_1000147, description: `Case review - YJSC`, shortDescription: `Case review - YJSC` },
	{ code: FormType.CPSCaseWork_20045, description: `CPS Case Work`, shortDescription: `Case work` },
	{ code: FormType.Conditionalbailprogramcompletion_40530, description: `Conditional bail program completion`, shortDescription: `CBP completion` },
	{ code: FormType.Conditionalbailprogramcompletion_100052, description: `Conditional bail program completion`, shortDescription: `CBP completion` },
	{ code: FormType.Conditionalbailprogramreferralproposal_40540, description: `Conditional bail program referral/proposal`, shortDescription: `CBP referral/proposal` },
	{ code: FormType.Conditionalbailprogramreferralproposal_100049, description: `Conditional bail program referral/proposal`, shortDescription: `CBP referral/proposal` },
	{ code: FormType.Conditionalbailprogramreferralproposaloutcome_40510, description: `Conditional bail program referral/proposal outcome`, shortDescription: `CBP referral/proposal outcome` },
	{ code: FormType.Conditionalbailprogramreferralproposaloutcome_100050, description: `Conditional bail program referral/proposal outcome`, shortDescription: `CBP referral/proposal outcome` },
	{ code: FormType.CPSChildConcernReport_20024, description: `CPS Child Concern Report`, shortDescription: `CCR` },
	{ code: FormType.CPSChildConcernReportPriorVersion_20025, description: `CPS Child Concern Report (Prior Version)`, shortDescription: `CCR` },
	{ code: FormType.Childconcernreport_15305, description: `Child concern report`, shortDescription: `Child concern report` },
	{ code: FormType.Childconcernreport_31002, description: `Child concern report`, shortDescription: `Child concern report` },
	{ code: FormType.Childplacementconcernreport_15404, description: `Child placement concern report`, shortDescription: `Child placement concern` },
	{ code: FormType.Childplacementconcernreport_100100, description: `Child placement concern report`, shortDescription: `Child placement concern report` },
	{ code: FormType.Childstrengthsandneedsassessment_15807, description: `Child strengths and needs assessment`, shortDescription: `Child strengths and needs assessment` },
	{ code: FormType.Childstrengthsandneedsassessment_18300, description: `Child strengths and needs assessment`, shortDescription: `Child strengths and needs assessment` },
	{ code: FormType.Childstrengthsandneedsassessment_32590, description: `Child strengths and needs assessment`, shortDescription: `Child strengths and needs assessment` },
	{ code: FormType.Childstrengthsandneedsassessment_100143, description: `Child strengths and needs assessment`, shortDescription: `Child strengths and needs assessment` },
	{ code: FormType.Closureform_15007, description: `Closure form`, shortDescription: `Closure form` },
	{ code: FormType.Closureform_30050, description: `Closure form`, shortDescription: `Closure form` },
	{ code: FormType.Closureform_30120, description: `Closure form`, shortDescription: `Closure form` },
	{ code: FormType.Closureform_31000, description: `Closure form`, shortDescription: `Closure form` },
	{ code: FormType.Closureform_41015, description: `Closure form`, shortDescription: `Closure form` },
	{ code: FormType.Closureform_42001, description: `Closure form`, shortDescription: `Closure form` },
	{ code: FormType.ClosureofCAO_15207, description: `Closure of CAO`, shortDescription: `Closure of CAO` },
	{ code: FormType.ClosureofCPO_15709, description: `Closure of CPO`, shortDescription: `Closure of CPO` },
	{ code: FormType.ClosureofMOCIA_15606, description: `Closure of MOC - I&A`, shortDescription: `Closure of MOC - I&A` },
	{ code: FormType.Closureofongoingintervention_15808, description: `Closure of ongoing intervention`, shortDescription: `Closure of OI` },
	{ code: FormType.Closureofplacement_15905, description: `Closure of placement`, shortDescription: `Closure of placement` },
	{ code: FormType.Closureofplacement_33005, description: `Closure of placement`, shortDescription: `Closure of placement` },
	{ code: FormType.Closureofplacement_100140, description: `Closure of placement`, shortDescription: `Closure of placement` },
	{ code: FormType.Closureofplacement_100160, description: `Closure of placement`, shortDescription: `Closure of placement` },
	{ code: FormType.ClosureofTAO_15100, description: `Closure of TAO`, shortDescription: `Closure of TAO` },
	{ code: FormType.ClosureofTCO_100107, description: `Closure of TCO`, shortDescription: `Closure of TCO` },
	{ code: FormType.Communityserviceorderreferral_41006, description: `Community service order referral`, shortDescription: `Community service referral` },
	{ code: FormType.Communityserviceorderreferral_100045, description: `Community service order referral`, shortDescription: `Community service referral` },
	{ code: FormType.Complaint_41510, description: `Complaint`, shortDescription: `Complaint` },
	{ code: FormType.Complaint_100026, description: `Complaint`, shortDescription: `Complaint` },
	{ code: FormType.Complaint_100075, description: `Complaint`, shortDescription: `Complaint` },
	{ code: FormType.Concernsconsultationandresponsedecision_30010, description: `Concerns, consultation and response decision`, shortDescription: `Concerns, consultation and response decision` },
	{ code: FormType.Conditionalreleaseorderprogramproposal_48000, description: `Conditional release order program proposal`, shortDescription: `Conditional release order` },
	{ code: FormType.Conditionalreleaseorderprogramproposal_100035, description: `Conditional release order program proposal`, shortDescription: `Conditional release order` },
	{ code: FormType.ApplicationforCourtOrder_20097, description: `Application for Court Order`, shortDescription: `Court Application` },
	{ code: FormType.CourtCaseNotes_41020, description: `Court Case Notes`, shortDescription: `Court Case Notes` },
	{ code: FormType.CourtCaseNotes_100079, description: `Court Case Notes`, shortDescription: `Court Case Notes` },
	{ code: FormType.CourtOutcome_20098, description: `Court Outcome`, shortDescription: `Court Outcome` },
	{ code: FormType.COF_20047, description: `COF`, shortDescription: `Court outcome form` },
	{ code: FormType.CPcasenote_30000, description: `CP case note`, shortDescription: `CP case note` },
	{ code: FormType.CPcasenote_100001, description: `CP case note`, shortDescription: `CP case note` },
	{ code: FormType.CPSChildPlacementConcernReport_20029, description: `CPS Child Placement Concern Report`, shortDescription: `CPCR` },
	{ code: FormType.CPSChildPlacementConcernReportPriorVersion_20030, description: `CPS Child Placement Concern Report (Prior Version)`, shortDescription: `CPCR` },
	{ code: FormType.CPSApplicationCPO_20048, description: `CPS Application CPO`, shortDescription: `CPO` },
	{ code: FormType.CPSApplicationCPOPriorVersion_20049, description: `CPS Application CPO (Prior Version)`, shortDescription: `CPO` },
	{ code: FormType.CPSApplicationCPOExtend_20080, description: `CPS Application CPO (Extend)`, shortDescription: `CPO - extend` },
	{ code: FormType.CPSApplicationCPOExtendPriorVersion_20085, description: `CPS Application CPO (Extend) (Prior Version)`, shortDescription: `CPO - extend` },
	{ code: FormType.CPSApplicationCPOExtendVary_20082, description: `CPS Application CPO (Extend&Vary)`, shortDescription: `CPO - extend and vary` },
	{ code: FormType.CPSApplicationCPOExtendVaryPriorVersion_20087, description: `CPS Application CPO (Extend&Vary) (Prior Version)`, shortDescription: `CPO - extend and vary` },
	{ code: FormType.CPSLettertoParentsCPO_20050, description: `CPS Letter to Parents CPO`, shortDescription: `CPO - letter` },
	{ code: FormType.CPSLettertoParentsCPOPriorVersion_20051, description: `CPS Letter to Parents CPO (Prior Version)`, shortDescription: `CPO - letter` },
	{ code: FormType.CPSApplicationCPORevoke_20083, description: `CPS Application CPO (Revoke)`, shortDescription: `CPO - revoke` },
	{ code: FormType.CPSApplicationCPORevokePriorVersion_20088, description: `CPS Application CPO (Revoke) (Prior Version)`, shortDescription: `CPO - revoke` },
	{ code: FormType.CPSApplicationCPORevokeNewOrder_20084, description: `CPS Application CPO (Revoke&New Order)`, shortDescription: `CPO - revoke and make another` },
	{ code: FormType.CPSApplicationCPORevokeNewOrderPriorVer_20089, description: `CPS Application CPO (Revoke&New Order) (Prior Ver)`, shortDescription: `CPO - revoke and make another` },
	{ code: FormType.CPSApplicationCPOVary_20081, description: `CPS Application CPO (Vary)`, shortDescription: `CPO - vary` },
	{ code: FormType.CPSApplicationCPOVaryPriorVersion_20086, description: `CPS Application CPO (Vary) (Prior Version)`, shortDescription: `CPO - vary` },
	{ code: FormType.Form16InterimOrder_15705, description: `Form 16 - Interim Order`, shortDescription: `CPO Form 16` },
	{ code: FormType.LettertoParentsOrder_15707, description: `Letter to Parents - Order`, shortDescription: `CPO Letter to Parents` },
	{ code: FormType.Departmentalhistory_15003, description: `Departmental history`, shortDescription: `Departmental history` },
	{ code: FormType.Closureofdetention_16100, description: `Closure of detention`, shortDescription: `Detention closure` },
	{ code: FormType.Detentionclosure_16601, description: `Detention closure`, shortDescription: `Detention closure` },
	{ code: FormType.Detentionclosure_16603, description: `Detention closure`, shortDescription: `Detention closure` },
	{ code: FormType.Detentionclosure_47000, description: `Detention closure`, shortDescription: `Detention closure` },
	{ code: FormType.Detentionclosure_1000138, description: `Detention closure`, shortDescription: `Detention closure` },
	{ code: FormType.Drugtestingconditioninformation_41007, description: `Drug testing condition information`, shortDescription: `Drug testing condition` },
	{ code: FormType.Drugtestingconditioninformation_100043, description: `Drug testing condition information`, shortDescription: `Drug testing condition` },
	{ code: FormType.Escapealert_16400, description: `Escape alert`, shortDescription: `Escape alert` },
	{ code: FormType.Escapealert_42000, description: `Escape alert`, shortDescription: `Escape alert` },
	{ code: FormType.CPSExternalFileReference_20026, description: `CPS External File Reference`, shortDescription: `External file reference` },
	{ code: FormType.Familyreunificationassessment_15803, description: `Family reunification assessment`, shortDescription: `Family reunification` },
	{ code: FormType.Familyreunificationassessment_32740, description: `Family reunification assessment`, shortDescription: `Family reunification` },
	{ code: FormType.Familyreunificationassessment_32741, description: `Family reunification assessment`, shortDescription: `Family reunification` },
	{ code: FormType.Familyreunificationassessment_32742, description: `Family reunification assessment`, shortDescription: `Family reunification` },
	{ code: FormType.Familyriskevaluation_15506, description: `Family risk evaluation`, shortDescription: `Family risk evaluation` },
	{ code: FormType.Familyriskevaluation_18102, description: `Family risk evaluation`, shortDescription: `Family risk evaluation` },
	{ code: FormType.Familyriskevaluation_31014, description: `Family risk evaluation`, shortDescription: `Family risk evaluation` },
	{ code: FormType.Familyriskevaluation_31018, description: `Family risk evaluation`, shortDescription: `Family risk evaluation` },
	{ code: FormType.Familyriskevaluation_100123, description: `Family risk evaluation`, shortDescription: `Family risk evaluation` },
	{ code: FormType.Familyriskevaluation_100139, description: `Family risk evaluation`, shortDescription: `Family risk evaluation` },
	{ code: FormType.Familyriskreevaluationforinhomecases_15802, description: `Family risk re-evaluation for in home cases`, shortDescription: `Family risk re-evaluation for in home cases` },
	{ code: FormType.Familyriskreevaluationforinhomecases_18301, description: `Family risk re-evaluation for in home cases`, shortDescription: `Family risk re-evaluation for in home cases` },
	{ code: FormType.Familyriskreevaluationforinhomecases_32700, description: `Family risk re-evaluation for in home cases`, shortDescription: `Family risk re-evaluation for in home cases` },
	{ code: FormType.Familyriskreevaluationforinhomecases_100144, description: `Family risk re-evaluation for in home cases`, shortDescription: `Family risk re-evaluation for in home cases` },
	{ code: FormType.Form1ApplicationforaTAO_15101, description: `Form 1 - Application for a TAO`, shortDescription: `Form 1` },
	{ code: FormType.Form1ApplicationforaTAO_18500, description: `Form 1 - Application for a TAO`, shortDescription: `Form 1` },
	{ code: FormType.Form1ApplicationforaTAO_100101, description: `Form 1 - Application for a TAO`, shortDescription: `Form 1` },
	{ code: FormType.Form1TAOoutcomeformTOF_15103, description: `Form 1 - TAO outcome form (TOF)`, shortDescription: `Form 1 TOF` },
	{ code: FormType.Form1TAOoutcomeformTOF_100102, description: `Form 1 - TAO outcome form (TOF)`, shortDescription: `Form 1 TOF` },
	{ code: FormType.Form10ApplicationforaCPO_15700, description: `Form 10 - Application for a CPO`, shortDescription: `Form 10` },
	{ code: FormType.Form10COFfinalorder_15701, description: `Form 10 - COF - final order`, shortDescription: `Form 10 - COF` },
	{ code: FormType.Form10COFfinalorder_100121, description: `Form 10 - COF - final order`, shortDescription: `Form 10 - COF` },
	{ code: FormType.Form11ApplicationtoextendvaryrevokeaCPO_15702, description: `Form 11 - Application to extend/vary/revoke a CPO`, shortDescription: `Form 11` },
	{ code: FormType.Form11ApplicationtoextendvaryrevokeaCPO_100120, description: `Form 11 - Application to extend/vary/revoke a CPO`, shortDescription: `Form 11` },
	{ code: FormType.Form11COFfinalorder_15703, description: `Form 11 - COF - final order`, shortDescription: `Form 11 - COF` },
	{ code: FormType.Form11COFfinalorder_100127, description: `Form 11 - COF - final order`, shortDescription: `Form 11 - COF` },
	{ code: FormType.Form12ApplicationtorevokemakeanotherCPO_15704, description: `Form 12 - Application to revoke & make another CPO`, shortDescription: `Form 12` },
	{ code: FormType.Form12COFfinalorder_15708, description: `Form 12 - COF - final order`, shortDescription: `Form 12 - COF` },
	{ code: FormType.Form12COFfinalorder_100109, description: `Form 12 - COF - final order`, shortDescription: `Form 12 - COF` },
	{ code: FormType.Form16COFadjournmentorder_15706, description: `Form 16 - COF - adjournment order`, shortDescription: `Form 16 - COF` },
	{ code: FormType.Form16COFadjournmentorder_100124, description: `Form 16 - COF - adjournment order`, shortDescription: `Form 16 - COF` },
	{ code: FormType.Form17_15002, description: `Form 17`, shortDescription: `Form 17` },
	{ code: FormType.Form2ApplicationtoextendvaryaTAO_15102, description: `Form 2 - Application to extend/vary a TAO`, shortDescription: `Form 2` },
	{ code: FormType.Form2ApplicationtoextendvaryaTAO_100105, description: `Form 2 - Application to extend/vary a TAO`, shortDescription: `Form 2` },
	{ code: FormType.Form2TAOoutcomeformTOF_15104, description: `Form 2 - TAO outcome form (TOF)`, shortDescription: `Form 2 TOF` },
	{ code: FormType.Form2TAOoutcomeformTOF_100103, description: `Form 2 - TAO outcome form (TOF)`, shortDescription: `Form 2 TOF` },
	{ code: FormType.Form25_15000, description: `Form 25`, shortDescription: `Form 25` },
	{ code: FormType.Form26_15001, description: `Form 26`, shortDescription: `Form 26` },
	{ code: FormType.Form39ApplicationforaTCO_100104, description: `Form 39 - Application for a TCO`, shortDescription: `Form 39 Application for a TCO` },
	{ code: FormType.Form39TCOoutcomeformTCOF_100106, description: `Form 39 - TCO outcome form (TCOF)`, shortDescription: `Form 39 Temporary custody order outcome` },
	{ code: FormType.Form41ApplicationtoextendvaryaTCO_100112, description: `Form 41 - Application to extend/vary a TCO`, shortDescription: `Form 41` },
	{ code: FormType.Form41TCOoutcomeformTCOF_100117, description: `Form 41 - TCO outcome form (TCOF)`, shortDescription: `Form 41 TCOF` },
	{ code: FormType.Form44COFtransitionorder_100128, description: `Form 44 - COF - transition order`, shortDescription: `Form 44 - COF` },
	{ code: FormType.Form5ApplicationforaCAO_15200, description: `Form 5 - Application for a CAO`, shortDescription: `Form 5` },
	{ code: FormType.Form5COFfinalorder_15201, description: `Form 5 - COF - final order`, shortDescription: `Form 5 - COF` },
	{ code: FormType.Form5COFfinalorder_100095, description: `Form 5 - COF - final order`, shortDescription: `Form 5 - COF` },
	{ code: FormType.Form6ApplicationtoextendvaryrevokeaCAO_15202, description: `Form 6 - Application to extend/vary/revoke a CAO`, shortDescription: `Form 6` },
	{ code: FormType.Form6COFfinalorder_15203, description: `Form 6 - COF - final order`, shortDescription: `Form 6 - COF` },
	{ code: FormType.Form6COFfinalorder_100096, description: `Form 6 - COF - final order`, shortDescription: `Form 6 - COF` },
	{ code: FormType.Form9COFadjournmentorder_15205, description: `Form 9 - COF - adjournment order`, shortDescription: `Form 9 - COF` },
	{ code: FormType.Form9COFadjournmentorder_100099, description: `Form 9 - COF - adjournment order`, shortDescription: `Form 9 - COF` },
	{ code: FormType.Griffithreferral_40080, description: `Griffith referral`, shortDescription: `Griffith referral` },
	{ code: FormType.Griffithreferral_100037, description: `Griffith referral`, shortDescription: `Griffith referral` },
	{ code: FormType.Griffithreferral_1000143, description: `Griffith referral`, shortDescription: `Griffith referral` },
	{ code: FormType.Harmreport_30040, description: `Harm report`, shortDescription: `Harm report` },
	{ code: FormType.Harmreportassessmentoutcome_30090, description: `Harm report - assessment & outcome`, shortDescription: `Harm report - assessment & outcome` },
	{ code: FormType.HarmreportIAplan_30060, description: `Harm report - I&A plan`, shortDescription: `Harm report - I&A plan` },
	{ code: FormType.Harmreportactionplan_30100, description: `Harm report action plan`, shortDescription: `Harm report action plan` },
	{ code: FormType.Healthchecklist_41008, description: `Health checklist`, shortDescription: `Health checklist` },
	{ code: FormType.Healthchecklist_100040, description: `Health checklist`, shortDescription: `Health checklist` },
	{ code: FormType.Independententity_30025, description: `Independent entity`, shortDescription: `Independent entity` },
	{ code: FormType.Informationgathering_15503, description: `Information gathering`, shortDescription: `Information gathering` },
	{ code: FormType.Informationgathering_31005, description: `Information gathering`, shortDescription: `Information gathering` },
	{ code: FormType.InformationProvision_15604, description: `Information Provision`, shortDescription: `Information Provision` },
	{ code: FormType.Informationprovision_30110, description: `Information provision`, shortDescription: `Information provision` },
	{ code: FormType.InformationProvision_100094, description: `Information Provision`, shortDescription: `Information Provision` },
	{ code: FormType.Informationprovisiontoparents_15508, description: `Information provision to parents`, shortDescription: `Information provision to parents` },
	{ code: FormType.Informationprovisiontoparents_31012, description: `Information provision to parents`, shortDescription: `Information provision to parents` },
	{ code: FormType.Informationprovisiontoparents_100126, description: `Information provision to parents`, shortDescription: `Information provision to parents` },
	{ code: FormType.Informationtoparentsorcarer_41009, description: `Information to parent/s or carer`, shortDescription: `Information to parent/s` },
	{ code: FormType.Informationtoparentsorcarer_100041, description: `Information to parent/s or carer`, shortDescription: `Information to parent/s` },
	{ code: FormType.Informationtoparentsorcarer_100071, description: `Information to parent/s or carer`, shortDescription: `Information to parent/s` },
	{ code: FormType.Informationtoparentsorcarer_100155, description: `Information to parent/s or carer`, shortDescription: `Information to parent/s` },
	{ code: FormType.CPSInitialAssessment_20031, description: `CPS Initial Assessment`, shortDescription: `Initial assessment` },
	{ code: FormType.CPSInitialAssessmentPriorVersion_20032, description: `CPS Initial Assessment (Prior Version)`, shortDescription: `Initial assessment` },
	{ code: FormType.CPSInitialAssessmentPriorVersion_20090, description: `CPS Initial Assessment (Prior Version)`, shortDescription: `Initial assessment` },
	{ code: FormType.InitialinterviewYDC_41005, description: `Initial interview - YDC`, shortDescription: `Initial interview - YDC` },
	{ code: FormType.InitialinterviewYDC_100039, description: `Initial interview - YDC`, shortDescription: `Initial interview - YDC` },
	{ code: FormType.InitialinterviewYJSC_41004, description: `Initial interview - YJSC`, shortDescription: `Initial interview - YJSC` },
	{ code: FormType.InitialinterviewYJSC_100038, description: `Initial interview - YJSC`, shortDescription: `Initial interview - YJSC` },
	{ code: FormType.InitialinterviewYJSC_100152, description: `Initial interview - YJSC`, shortDescription: `Initial interview - YJSC` },
	{ code: FormType.InitialinterviewYJSC_100158, description: `Initial interview - YJSC`, shortDescription: `Initial interview - YJSC` },
	{ code: FormType.CPSIntake_20022, description: `CPS Intake`, shortDescription: `Intake` },
	{ code: FormType.CPSIntakePriorVersion_20023, description: `CPS Intake (Prior Version)`, shortDescription: `Intake` },
	{ code: FormType.Intake_31020, description: `Intake`, shortDescription: `Intake` },
	{ code: FormType.Intensivesupervisionorderprogramproposal_48001, description: `Intensive supervision order program proposal`, shortDescription: `Intensive supervision order` },
	{ code: FormType.Intensivesupervisionorderprogramproposal_100036, description: `Intensive supervision order program proposal`, shortDescription: `Intensive supervision order` },
	{ code: FormType.Interventionplan_100016, description: `Intervention plan`, shortDescription: `Intervention plan` },
	{ code: FormType.CPSInvestigationandAssessment_20033, description: `CPS Investigation and Assessment`, shortDescription: `Investigation and assessment` },
	{ code: FormType.CPSInvestigationandAssessmentPriorVersion_20071, description: `CPS Investigation and Assessment (Prior Version)`, shortDescription: `Investigation and assessment` },
	{ code: FormType.Investigationandassessmentplan_15501, description: `Investigation and assessment plan`, shortDescription: `Investigation plan` },
	{ code: FormType.ISOconferencereferral_41011, description: `ISO conference referral`, shortDescription: `ISO conference referral` },
	{ code: FormType.ISOconferencereferral_100046, description: `ISO conference referral`, shortDescription: `ISO conference referral` },
	{ code: FormType.IssueComplaintandSummons_41520, description: `Issue Complaint and Summons`, shortDescription: `Issue Complaint and Summons` },
	{ code: FormType.Issuecomplaintandsummons_100023, description: `Issue complaint and summons`, shortDescription: `Issue complaint and summons` },
	{ code: FormType.Issuecomplaintandsummons_100066, description: `Issue complaint and summons`, shortDescription: `Issue complaint and summons` },
	{ code: FormType.IssueComplaintandSummons_100073, description: `Issue Complaint and Summons`, shortDescription: `Issue Complaint and Summons` },
	{ code: FormType.IssueWarningLetter_41530, description: `Issue Warning Letter`, shortDescription: `Issue Warning Letter` },
	{ code: FormType.Issuewarningletter_100021, description: `Issue warning letter`, shortDescription: `Issue warning letter` },
	{ code: FormType.Issuewarningletter_100067, description: `Issue warning letter`, shortDescription: `Issue warning letter` },
	{ code: FormType.IssueWarningLetter_100072, description: `Issue Warning Letter`, shortDescription: `Issue Warning Letter` },
	{ code: FormType.IssueWarrant_41540, description: `Issue Warrant`, shortDescription: `Issue Warrant` },
	{ code: FormType.Issuewarrant_100025, description: `Issue warrant`, shortDescription: `Issue warrant` },
	{ code: FormType.Issuewarrant_100068, description: `Issue warrant`, shortDescription: `Issue warrant` },
	{ code: FormType.IssueWarrant_100074, description: `Issue Warrant`, shortDescription: `Issue Warrant` },
	{ code: FormType.LettertoParentsOrder_15206, description: `Letter to Parents - Order`, shortDescription: `Letter to Parents - Order` },
	{ code: FormType.LTGtosuitablepersonCaseplan_32550, description: `LTG to suitable person - Case plan`, shortDescription: `LTG - Case plan` },
	{ code: FormType.LTGtosuitablepersonCaseplan_100133, description: `LTG to suitable person - Case plan`, shortDescription: `LTG - Case plan` },
	{ code: FormType.LTGtosuitablepersonCaseplan_100142, description: `LTG to suitable person - Case plan`, shortDescription: `LTG - Case plan` },
	{ code: FormType.LTGtosuitablepersonContactandreviewreport_32520, description: `LTG to suitable person - Contact and review report`, shortDescription: `LTG - Contact and review` },
	{ code: FormType.LTGtosuitablepersonContactandreviewreport_100132, description: `LTG to suitable person - Contact and review report`, shortDescription: `LTG - Contact and review` },
	{ code: FormType.CPSActionPlanMOCCPCR_20055, description: `CPS Action Plan MOC CPCR`, shortDescription: `MOC action plan CPCR` },
	{ code: FormType.CPSActionPlanMOCCPCRPriorVersion_20056, description: `CPS Action Plan MOC CPCR (Prior Version)`, shortDescription: `MOC action plan CPCR` },
	{ code: FormType.CPSActionPlanMOCIA_20091, description: `CPS Action Plan MOC I&A`, shortDescription: `MOC action plan I&A` },
	{ code: FormType.CPSActionPlanMOCIAPriorVersion_20092, description: `CPS Action Plan MOC I&A (Prior Version)`, shortDescription: `MOC action plan I&A` },
	{ code: FormType.MOCAssessmentoutcome_15602, description: `MOC Assessment & outcome`, shortDescription: `MOC Assessment & outcome` },
	{ code: FormType.MOCAssessmentoutcome_18601, description: `MOC Assessment & outcome`, shortDescription: `MOC Assessment & outcome` },
	{ code: FormType.CPSAssessmentPlanMOCCPCR_20057, description: `CPS Assessment Plan MOC CPCR`, shortDescription: `MOC assessment plan CPCR` },
	{ code: FormType.CPSAssessmentPlanMOCCPCRPriorVersion_20058, description: `CPS Assessment Plan MOC CPCR (Prior Version)`, shortDescription: `MOC assessment plan CPCR` },
	{ code: FormType.CPSAssessmentPlanMOCIA_20093, description: `CPS Assessment Plan MOC I&A`, shortDescription: `MOC assessment plan I&A` },
	{ code: FormType.CPSAssessmentPlanMOCIAPriorVersion_20094, description: `CPS Assessment Plan MOC I&A (Prior Version)`, shortDescription: `MOC assessment plan I&A` },
	{ code: FormType.MOCInvestigationandassessmentplan_15600, description: `MOC Investigation and assessment plan`, shortDescription: `MOC Invest & assess plan` },
	{ code: FormType.MOCNotification_15403, description: `MOC Notification`, shortDescription: `MOC Notification` },
	{ code: FormType.Notification_15304, description: `Notification`, shortDescription: `Notification` },
	{ code: FormType.CPSNotification_20027, description: `CPS Notification`, shortDescription: `Notification` },
	{ code: FormType.CPSNotificationPriorVersion_20028, description: `CPS Notification (Prior Version)`, shortDescription: `Notification` },
	{ code: FormType.Notification_31009, description: `Notification`, shortDescription: `Notification` },
	{ code: FormType.Notification_100091, description: `Notification`, shortDescription: `Notification` },
	{ code: FormType.NotifiedconcernsResponsepriority_15500, description: `Notified concerns / Response priority`, shortDescription: `Notified concerns` },
	{ code: FormType.NotifiedconcernsResponsepriority_31017, description: `Notified concerns / Response priority`, shortDescription: `Notified concerns` },
	{ code: FormType.NotifiedconcernsResponsepriority_100149, description: `Notified concerns / Response priority`, shortDescription: `Notified concerns` },
	{ code: FormType.Oathofservice_41610, description: `Oath of service`, shortDescription: `Oath of service` },
	{ code: FormType.Oathofservice_100024, description: `Oath of service`, shortDescription: `Oath of service` },
	{ code: FormType.Parentalstrengthsneedsassessmentreassessment_15801, description: `Parental strengths & needs assessment/reassessment`, shortDescription: `Parental strengths and needs assessment` },
	{ code: FormType.Parentalstrengthsneedsassessmentreassessment_32600, description: `Parental strengths & needs assessment/reassessment`, shortDescription: `Parental strengths and needs assessment` },
	{ code: FormType.Parentalstrengthsneedsassessmentreassessment_100063, description: `Parental strengths & needs assessment/reassessment`, shortDescription: `Parental strengths and needs assessment` },
	{ code: FormType.Placementagreement_15902, description: `Placement agreement`, shortDescription: `Placement agreement` },
	{ code: FormType.Placementagreement_18400, description: `Placement agreement`, shortDescription: `Placement agreement` },
	{ code: FormType.CPSPlacementAgreement_20008, description: `CPS Placement Agreement`, shortDescription: `Placement agreement` },
	{ code: FormType.Placementagreement_33002, description: `Placement agreement`, shortDescription: `Placement agreement` },
	{ code: FormType.Placementagreement_100061, description: `Placement agreement`, shortDescription: `Placement agreement` },
	{ code: FormType.Placementagreement_100118, description: `Placement agreement`, shortDescription: `Placement agreement` },
	{ code: FormType.Pre1997Assessment_20095, description: `Pre-1997 - Assessment`, shortDescription: `Pre-1997 Assessment` },
	{ code: FormType.Pre1997Notification_20096, description: `Pre-1997 - Notification`, shortDescription: `Pre-1997 Notification` },
	{ code: FormType.Prenotificationchecks_15301, description: `Pre-notification checks`, shortDescription: `Pre-notification checks` },
	{ code: FormType.Prenotificationchecks_31001, description: `Pre-notification checks`, shortDescription: `Pre-notification checks` },
	{ code: FormType.Presentencereport_48002, description: `Pre-sentence report`, shortDescription: `Pre-sentence report` },
	{ code: FormType.Presentencereport_100033, description: `Pre-sentence report`, shortDescription: `Pre-sentence report` },
	{ code: FormType.Presentencereport_100153, description: `Pre-sentence report`, shortDescription: `Pre-sentence report` },
	{ code: FormType.Presentencereport_100156, description: `Pre-sentence report`, shortDescription: `Pre-sentence report` },
	{ code: FormType.Programreferral_41016, description: `Program referral`, shortDescription: `Program referral` },
	{ code: FormType.Recognisedentityparticipation_15005, description: `Recognised entity participation`, shortDescription: `Recognised entity participation` },
	{ code: FormType.Recognisedentityparticipation_18001, description: `Recognised entity participation`, shortDescription: `Recognised entity participation` },
	{ code: FormType.Recognisedentityparticipation_30020, description: `Recognised entity participation`, shortDescription: `Recognised entity participation` },
	{ code: FormType.RecognisedentityparticipationCPP_15907, description: `Recognised entity participation/CPP`, shortDescription: `Recognised entity participation/CPP` },
	{ code: FormType.RecognisedentityparticipationCPP_36010, description: `Recognised entity participation/CPP`, shortDescription: `Recognised entity participation/CPP` },
	{ code: FormType.RecognisedentityparticipationCPP_100138, description: `Recognised entity participation/CPP`, shortDescription: `Recognised entity participation/CPP` },
	{ code: FormType.Recordofactionsmobilefamily_15502, description: `Record of actions - mobile family`, shortDescription: `Record of actions` },
	{ code: FormType.Recordofactionsmobilefamily_31004, description: `Record of actions - mobile family`, shortDescription: `Record of actions` },
	{ code: FormType.Recordofadviceofarrest_16501, description: `Record of advice of arrest`, shortDescription: `Record of advice of arrest` },
	{ code: FormType.Recordofadviceofarrest_45000, description: `Record of advice of arrest`, shortDescription: `Record of advice of arrest` },
	{ code: FormType.Recordofadviceofarrest_45004, description: `Record of advice of arrest`, shortDescription: `Record of advice of arrest` },
	{ code: FormType.Recordofchecksandconsultation_15006, description: `Record of checks and consultation`, shortDescription: `Record of checks` },
	{ code: FormType.Recordofchecksandconsultation_15607, description: `Record of checks and consultation`, shortDescription: `Record of checks` },
	{ code: FormType.Recordofconcerns_15300, description: `Record of concerns`, shortDescription: `Record of concerns` },
	{ code: FormType.Recordofconcerns_15400, description: `Record of concerns`, shortDescription: `Record of concerns` },
	{ code: FormType.Recordofconcerns_31010, description: `Record of concerns`, shortDescription: `Record of concerns` },
	{ code: FormType.Recordofconcerns_100065, description: `Record of concerns`, shortDescription: `Record of concerns` },
	{ code: FormType.Recordofconcerns_100092, description: `Record of concerns`, shortDescription: `Record of concerns` },
	{ code: FormType.Recordofconcerns_100097, description: `Record of concerns`, shortDescription: `Record of concerns` },
	{ code: FormType.CPSRecordofFamilyMeeting_20004, description: `CPS Record of Family Meeting`, shortDescription: `Record of family meeting` },
	{ code: FormType.CPSRecordofFamilyMeetingPriorVersion_20005, description: `CPS Record of Family Meeting (Prior Version)`, shortDescription: `Record of family meeting` },
	{ code: FormType.CPSRecordofFamilyMeeting_20006, description: `CPS Record of Family Meeting`, shortDescription: `Record of family meeting` },
	{ code: FormType.CPSRecordofFamilyMeetingPriorVersion_20007, description: `CPS Record of Family Meeting (Prior Version)`, shortDescription: `Record of family meeting` },
	{ code: FormType.Recordofafamilygroupmeeting_15805, description: `Record of a family group meeting`, shortDescription: `Record of FGM` },
	{ code: FormType.Recordofafamilygroupmeeting_32710, description: `Record of a family group meeting`, shortDescription: `Record of FGM` },
	{ code: FormType.Recordofinterviews_15504, description: `Record of interviews`, shortDescription: `Record of interviews` },
	{ code: FormType.Recordofinterviews_15605, description: `Record of interviews`, shortDescription: `Record of interviews` },
	{ code: FormType.Recordofinterviews_18600, description: `Record of interviews`, shortDescription: `Record of interviews` },
	{ code: FormType.Recordofinterviews_30080, description: `Record of interviews`, shortDescription: `Record of interviews` },
	{ code: FormType.Recordofinterviewsuseofpowers_31013, description: `Record of interviews / use of powers`, shortDescription: `Record of interviews & powers` },
	{ code: FormType.Recordofinterviewsuseofpowers_100131, description: `Record of interviews / use of powers`, shortDescription: `Record of interviews & powers` },
	{ code: FormType.Recordofuseofpowers_15505, description: `Record of use of powers`, shortDescription: `Record of use of powers` },
	{ code: FormType.Referraltoaconvenorforafamilygroupmeeting_15804, description: `Referral to a convenor for a family group meeting`, shortDescription: `Referral to FGM convenor` },
	{ code: FormType.RegionalDirectorsconversation_45003, description: `Regional Director's conversation`, shortDescription: `Regional Dir Conv` },
	{ code: FormType.RegionalExecutiveDirectorsconversation_16504, description: `Regional Executive Director's conversation`, shortDescription: `Regional Exec Dir Conv` },
	{ code: FormType.Reviewreport_15806, description: `Review report`, shortDescription: `Review report` },
	{ code: FormType.Reviewreport_17806, description: `Review report`, shortDescription: `Review report` },
	{ code: FormType.CPSReviewReport_20061, description: `CPS Review Report`, shortDescription: `Review report` },
	{ code: FormType.CPSReviewReportPriorVersion_20062, description: `CPS Review Report (Prior Version)`, shortDescription: `Review report` },
	{ code: FormType.CPSReviewReportOutcomeAction_20064, description: `CPS Review Report Outcome&Action`, shortDescription: `Review report` },
	{ code: FormType.Reviewreport_32720, description: `Review report`, shortDescription: `Review report` },
	{ code: FormType.Reviewreport_32721, description: `Review report`, shortDescription: `Review report` },
	{ code: FormType.Reviewreport_100081, description: `Review report`, shortDescription: `Review report` },
	{ code: FormType.Reviewreport_100159, description: `Review report`, shortDescription: `Review report` },
	{ code: FormType.Safeplacemovementrecord_15306, description: `Safe place movement record`, shortDescription: `Safe place movement record` },
	{ code: FormType.CPSSafePlaceMovementRecord_20014, description: `CPS Safe Place Movement Record`, shortDescription: `Safe place movement record` },
	{ code: FormType.Safeplacemovementrecord_31003, description: `Safe place movement record`, shortDescription: `Safe place movement record` },
	{ code: FormType.Safetyassessment_15004, description: `Safety assessment`, shortDescription: `Safety assessment` },
	{ code: FormType.Safetyassessment_18000, description: `Safety assessment`, shortDescription: `Safety assessment` },
	{ code: FormType.Safetyassessment_31006, description: `Safety assessment`, shortDescription: `Safety assessment` },
	{ code: FormType.ScreeningcriteriaResponsepriority_31008, description: `Screening criteria/Response priority`, shortDescription: `Screening criteria/Response` },
	{ code: FormType.ScreeningcriteriaResponsepriority_15302, description: `Screening criteria/Response priority`, shortDescription: `Screening criteria/Response priority` },
	{ code: FormType.ScreeningcriteriaResponsepriority_18200, description: `Screening criteria/Response priority`, shortDescription: `Screening criteria/Response priority` },
	{ code: FormType.Mediumtolongtermsuicideriskmanagementplan_16002, description: `Medium to long term suicide risk management plan`, shortDescription: `SR1 medium to long term plan` },
	{ code: FormType.Mediumtolongtermsuicideriskmanagementplan_44000, description: `Medium to long term suicide risk management plan`, shortDescription: `SR1 medium to long term plan` },
	{ code: FormType.Standardsofcarereviewreport_30030, description: `Standards of care review report`, shortDescription: `Standards of care review report` },
	{ code: FormType.SuicideriskalertSR1_16000, description: `Suicide risk alert (SR1)`, shortDescription: `Suicide risk alert (SR1)` },
	{ code: FormType.SuicideriskalertSR1_16003, description: `Suicide risk alert (SR1)`, shortDescription: `Suicide risk alert (SR1)` },
	{ code: FormType.SuicideriskalertSR1_34520, description: `Suicide risk alert (SR1)`, shortDescription: `Suicide risk alert (SR1)` },
	{ code: FormType.Closureform_16001, description: `Closure form`, shortDescription: `Suicide risk closure form` },
	{ code: FormType.Closureform_44001, description: `Closure form`, shortDescription: `Suicide risk closure form` },
	{ code: FormType.Supervisedreleaseorder_41044, description: `Supervised release order`, shortDescription: `Supervised release order` },
	{ code: FormType.Supervisedreleaseorder_100042, description: `Supervised release order`, shortDescription: `Supervised release order` },
	{ code: FormType.Supervisedreleaseorder_100069, description: `Supervised release order`, shortDescription: `Supervised release order` },
	{ code: FormType.CPSApplicationTAO_20015, description: `CPS Application TAO`, shortDescription: `TAO` },
	{ code: FormType.CPSApplicationTAOPriorVersion_20016, description: `CPS Application TAO (Prior Version)`, shortDescription: `TAO` },
	{ code: FormType.CPSOrderTAO_20019, description: `CPS Order TAO`, shortDescription: `TAO - draft order` },
	{ code: FormType.CPSApplicationTAOExtend_20017, description: `CPS Application TAO (Extend)`, shortDescription: `TAO - extend` },
	{ code: FormType.CPSApplicationTAOExtendPriorVersion_20018, description: `CPS Application TAO (Extend) (Prior Version)`, shortDescription: `TAO - extend` },
	{ code: FormType.CPSOrderTAOExtend_20020, description: `CPS Order TAO (Extend)`, shortDescription: `TAO - extend draft order` },
	{ code: FormType.CPSTAOExtSummary_20070, description: `CPS TAO (Ext) Summary`, shortDescription: `TAO - extend outcome summary` },
	{ code: FormType.CPSTAOSummary_20021, description: `CPS TAO Summary`, shortDescription: `TAO - outcome summary` },
	{ code: FormType.Transfersummaryinformalinterstateoverseas_46000, description: `Transfer summary (informal interstate / overseas)`, shortDescription: `Transfer summary (IOT)` },
	{ code: FormType.Transfersummaryinformalinterstateoverseas_100032, description: `Transfer summary (informal interstate / overseas)`, shortDescription: `Transfer summary (IOT)` },
	{ code: FormType.Transfersummaryinformalinterstateoverseas_1000141, description: `Transfer summary (informal interstate / overseas)`, shortDescription: `Transfer summary (IOT)` },
	{ code: FormType.Transfertoprison_100030, description: `Transfer to prison`, shortDescription: `Transfer to prison` },
	{ code: FormType.TransferToPrison_100078, description: `Transfer To Prison`, shortDescription: `Transfer To Prison` },
	{ code: FormType.Transitionplan_32730, description: `Transition plan`, shortDescription: `Transition plan` },
	{ code: FormType.Transitionplan_100134, description: `Transition plan`, shortDescription: `Transition plan` },
	{ code: FormType.Transitionreferral_40520, description: `Transition referral`, shortDescription: `Transition referral` },
	{ code: FormType.Transitionreferral_100051, description: `Transition referral`, shortDescription: `Transition referral` },
	{ code: FormType.Varydetentionorderintheinterestsofjustice_41560, description: `Vary detention order in the interests of justice`, shortDescription: `Vary detention order in the interests of justice` },
	{ code: FormType.Varydetentionorderintheinterestsofjustice_100029, description: `Vary detention order in the interests of justice`, shortDescription: `Vary detention order in the interests of justice` },
	{ code: FormType.Varydetentionorderintheinterestsofjustice_1000142, description: `Vary detention order in the interests of justice`, shortDescription: `Vary detention order in the interests of justice` },
	{ code: FormType.Varyorderbyconsent_41570, description: `Vary order by consent`, shortDescription: `Vary order by consent` },
	{ code: FormType.Varyorderbyconsent_100027, description: `Vary order by consent`, shortDescription: `Vary order by consent` },
	{ code: FormType.Varyorderbyconsent_100076, description: `Vary order by consent`, shortDescription: `Vary order by consent` },
	{ code: FormType.Varyorderintheinterestsofjustice_41580, description: `Vary order in the interests of justice`, shortDescription: `Vary order in the interests of justice` },
	{ code: FormType.Varyorderintheinterestsofjustice_100028, description: `Vary order in the interests of justice`, shortDescription: `Vary order in the interests of justice` },
	{ code: FormType.Varyorderintheinterestsofjustice_100077, description: `Vary order in the interests of justice`, shortDescription: `Vary order in the interests of justice` },
	{ code: FormType.VaryOrRevokeATransferOrder_100080, description: `Vary Or Revoke A Transfer Order`, shortDescription: `VaryRevokeTransfer` },
	{ code: FormType.Closureofwatchhouse_16200, description: `Closure of watchhouse`, shortDescription: `Watchhouse closure` },
	{ code: FormType.Watchhousedetentiondetails_16502, description: `Watchhouse detention details`, shortDescription: `Watchhouse detention details` },
	{ code: FormType.Watchhousedetentiondetails_16506, description: `Watchhouse detention details`, shortDescription: `Watchhouse detention details` },
	{ code: FormType.Watchhousedetentiondetails_45002, description: `Watchhouse detention details`, shortDescription: `Watchhouse detention details` },
	{ code: FormType.YDCdetentiondetails_16503, description: `YDC detention details`, shortDescription: `YDC detention details` },
	{ code: FormType.YDCdetentiondetails_16505, description: `YDC detention details`, shortDescription: `YDC detention details` },
	{ code: FormType.YDCdetentiondetails_45001, description: `YDC detention details`, shortDescription: `YDC detention details` },
	{ code: FormType.YDCdetentiondetails_1000137, description: `YDC detention details`, shortDescription: `YDC detention details` },
	{ code: FormType.YDCtherapeuticprogramsreferral_41012, description: `YDC therapeutic programs referral`, shortDescription: `YDC therapeutic programs referral` },
	{ code: FormType.YDCtherapeuticprogramsreferral_100047, description: `YDC therapeutic programs referral`, shortDescription: `YDC therapeutic programs referral` },
	{ code: FormType.YJcasenote_41010, description: `YJ case note`, shortDescription: `YJ case note` },
	{ code: FormType.YJcasenote_100010, description: `YJ case note`, shortDescription: `YJ case note` },
	{ code: FormType.YJcasenote_1000145, description: `YJ case note`, shortDescription: `YJ case note` },
	{ code: FormType.YJCcasenote_34530, description: `YJC case note`, shortDescription: `YJC case note` },
	{ code: FormType.YJCcasenote_1000148, description: `YJC case note`, shortDescription: `YJC case note` },
	{ code: FormType.Youngpersonsummary_46001, description: `Young person summary`, shortDescription: `Young person summary` },
	{ code: FormType.Youngpersonsummary_100011, description: `Young person summary`, shortDescription: `Young person summary` },
	{ code: FormType.Youngpersonsummary_100031, description: `Young person summary`, shortDescription: `Young person summary` },
	{ code: FormType.YouthJusticecasereview_40040, description: `Youth Justice case review`, shortDescription: `Youth Justice case review` },
	{ code: FormType.Youthworkersupportreferral_41014, description: `Youth worker support referral`, shortDescription: `Youth worker support referral` },
	{ code: FormType.Youthworkersupportreferral_100044, description: `Youth worker support referral`, shortDescription: `Youth worker support referral` },
	{ code: FormType.Youthworkersupportreferral_100070, description: `Youth worker support referral`, shortDescription: `Youth worker support referral` }
];

export function getFormTypeGroupDescriptor(FormTypeCode: string) : IFormTypeGroupDescriptor {
	for(var i = 0; i < FormTypeGroup.length; i++)
	{
		if(FormTypeGroup[i].code == FormTypeCode)
		{
			return FormTypeGroup[i];
		}
	}
	return null;
}


export enum PlacementType {
	Absconded_6 = "6",
	Adultcorrectionalcentre_7 = "7",
	Adultcorrectionalcentre_17 = "17",
	Boardingschool_8 = "8",
	Boardingschool_18 = "18",
	Commercial_24 = "24",
	Detentioncentre_15 = "15",
	YouthDetentionCentre_29 = "29",
	Escape_19 = "19",
	HospitalMedicalcentre_20 = "20",
	HospitalMedicalfacility_9 = "9",
	Livingindependently_10 = "10",
	Livingindependently_28 = "28",
	MissingChild_31 = "31",
	Other_14 = "14",
	Other_23 = "23",
	Placedathome_11 = "11",
	Placedathome_27 = "27",
	Placedathomes822_25 = "25",
	Placementevent_5 = "5",
	Policetransit_26 = "26",
	Returnedhome_21 = "21",
	SAAPshelter_22 = "22",
	Supervisedaccommodation_32 = "32",
	Watchhouse_16 = "16",
	Watchhouse_30 = "30"
}

export interface IPlacementTypeGroupDescriptor { code: PlacementType; description: string; shortDescription: string; }
export var PlacementTypeGroup: IPlacementTypeGroupDescriptor[] = [
	{ code: PlacementType.Absconded_6, description: `Absconded`, shortDescription: `Absconded` },
	{ code: PlacementType.Adultcorrectionalcentre_7, description: `Adult correctional centre`, shortDescription: `Adult correctional centre` },
	{ code: PlacementType.Adultcorrectionalcentre_17, description: `Adult correctional centre`, shortDescription: `Adult correctional centre (YJ)` },
	{ code: PlacementType.Boardingschool_8, description: `Boarding school`, shortDescription: `Boarding school` },
	{ code: PlacementType.Boardingschool_18, description: `Boarding school`, shortDescription: `Boarding school (YJ)` },
	{ code: PlacementType.Commercial_24, description: `Commercial`, shortDescription: `Commercial` },
	{ code: PlacementType.Detentioncentre_15, description: `Detention centre`, shortDescription: `Detention centre event` },
	{ code: PlacementType.YouthDetentionCentre_29, description: `Youth Detention Centre`, shortDescription: `Detention location event` },
	{ code: PlacementType.Escape_19, description: `Escape`, shortDescription: `Escape` },
	{ code: PlacementType.HospitalMedicalcentre_20, description: `Hospital/Medical centre`, shortDescription: `Hospital/Medical centre` },
	{ code: PlacementType.HospitalMedicalfacility_9, description: `Hospital/Medical facility`, shortDescription: `Hospital/Medical facility` },
	{ code: PlacementType.Livingindependently_10, description: `Living independently`, shortDescription: `Living independently` },
	{ code: PlacementType.Livingindependently_28, description: `Living independently`, shortDescription: `Living independently` },
	{ code: PlacementType.MissingChild_31, description: `Missing Child`, shortDescription: `Missing Child` },
	{ code: PlacementType.Other_14, description: `Other`, shortDescription: `Other` },
	{ code: PlacementType.Other_23, description: `Other`, shortDescription: `Other (YJ)` },
	{ code: PlacementType.Placedathome_11, description: `Placed at home`, shortDescription: `Placed at home` },
	{ code: PlacementType.Placedathome_27, description: `Placed at home`, shortDescription: `Placed at home` },
	{ code: PlacementType.Placedathomes822_25, description: `Placed at home s 82 (2)`, shortDescription: `Placed at home s 82 (2)` },
	{ code: PlacementType.Placementevent_5, description: `Placement event`, shortDescription: `Placement event` },
	{ code: PlacementType.Policetransit_26, description: `Police transit`, shortDescription: `Police transit` },
	{ code: PlacementType.Returnedhome_21, description: `Returned home`, shortDescription: `Returned home` },
	{ code: PlacementType.SAAPshelter_22, description: `SAAP shelter`, shortDescription: `SAAP shelter` },
	{ code: PlacementType.Supervisedaccommodation_32, description: `Supervised accommodation`, shortDescription: `Supervised accommodation` },
	{ code: PlacementType.Watchhouse_16, description: `Watchhouse`, shortDescription: `Watchhouse event` },
	{ code: PlacementType.Watchhouse_30, description: `Watchhouse`, shortDescription: `Watchhouse location event` }
];

export function getPlacementTypeGroupDescriptor(PlacementTypeCode: string) : IPlacementTypeGroupDescriptor {
	for(var i = 0; i < PlacementTypeGroup.length; i++)
	{
		if(PlacementTypeGroup[i].code == PlacementTypeCode)
		{
			return PlacementTypeGroup[i];
		}
	}
	return null;
}
