<!-- Each 'driver' here is an instance of Api.RuleDriverResult -->
<div *ngFor="let driver of ruleResults" ng-if="ruleResults" class="mt-4">
  <div *ngIf="showRule(driver)">
    <div class="table-wrapper">
      <div class="table-heading table-alt-heading">
        <span [id]="getIdReference(driver.ruleName)">Rule - {{ driver.ruleName }}</span><span>{{ driver.currentValue }}</span>
      </div>
      <div *ngIf="driver.extraDetails" class="sub-heading-space">
        {{ driver.extraDetails }}
      </div>
      <!-- Each 'rule' is an instance of Api.RuleDriver -->
      <div class="row" *ngFor="let rule of driver.drivers" ng-if="driver.drivers">
        <div class="rule-column pr-1 content-shaded">
          <div class="table-heading table-alt-sub-heading">
            <span>{{ rule.displayName }}</span><span *ngIf="rule.ruleMet">Match</span><span *ngIf="!rule.ruleMet">Not Matched</span>
          </div>

          <div class="top-level">
            <div class="row">
              <div class="th rule-column"><span>Required</span></div>
              <div class="th rule-column"><span>Result</span></div>
              <div class="th rule-column"><span>Condition</span></div>
            </div>

            <!-- Each 'rule' is an instance of Api.RulePartDriver -->
            <div *ngFor="let ruleDriverPart of rule.ruleDrivers" ng-if="driver.ruleDrivers">
              <div class="row" *ngIf="ruleDriverPart.isFirstOptional">
                <div class="rule-column"><div class="emphasis">&nbsp;</div></div>
                <div class="rule-column spaced"><div class="emphasis">&nbsp;</div></div>
                <div class="rule-column">
                  <div class="emphasis">Only one of the below optional conditions needs to be met for this rule to be met.</div>
                </div>
              </div>
              <div class="row">
                <div class="rule-column">
                  <div class="has-text-centered">{{ ruleDriverPart.isRequired ? 'Y' : 'N' }}</div>
                </div>
                <div class="rule-column spaced">
                  <div [ngClass]="{'circle': true, 'green': ruleDriverPart.childMeetsCriteria, 'red': !ruleDriverPart.childMeetsCriteria && ruleDriverPart.isRequired, 'yellow': !ruleDriverPart.childMeetsCriteria && !ruleDriverPart.isRequired }"></div>
                </div>
                <div class="rule-column">
                  <div class="">{{ ruleDriverPart.displayName }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>