import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-rules-drivers',
  templateUrl: './view-rules-drivers.component.html',
  styleUrls: ['./view-rules-drivers.component.scss']
})
export class ViewRulesDriversComponent implements OnInit {
  @Input() ruleResults : Api.RuleDriverResult[];
  @Input() ruleDisplayType : number;

  constructor() { }

  ngOnInit() {
    this.sortRules();
    this.ruleResults.forEach(r => {
      r.drivers.forEach(d => {
        var haveSetFirstOptional = false;
        d.ruleDrivers.forEach(rd => {
          if(!rd.isRequired)
          {
            if(!haveSetFirstOptional)
            {
              rd.isFirstOptional = true;
              haveSetFirstOptional = true;
            }
          }
        });
      });
    });
  }

  sortRules()
  {
    this.ruleResults.forEach(r => {
      r.drivers.forEach(d => {
        d.ruleDrivers.sort((x: Api.RulePartDriver, y: Api.RulePartDriver) => {
          if(x.isRequired && !y.isRequired)
          {
            return -1;
          }
          else if(!x.isRequired && y.isRequired)
          {
            return 1;
          }
          else if(x.isRequired && y.isRequired)
          {
            return 1;
          }
          else
          {
            return 0;
          }
        });
      });
    });
  }

  showRule(rule: Api.RuleDriverResult) : boolean
  {
    return rule && rule.driverType == this.ruleDisplayType;
  }

  getIdReference(ruleName: string) : string {
    return ruleName.replace(/\s+/g, '');
  }
}
