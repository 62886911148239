import { Component, Input, DoCheck, HostBinding } from '@angular/core';
import { WorkTracker } from "./work-tracker.service";

@Component({
  selector: 'loading-pane',
  templateUrl: 'loading-pane.component.html',
  styleUrls: ['loading-pane.component.scss']
})
export class LoadingPaneComponent implements DoCheck {

  @HostBinding('class.loading') public loading: boolean;
  @HostBinding('attr.aria-busy') get ariaRole(): string {
    return (this.loading ? 'true' : null);
  }
  @HostBinding('attr.aria-live') get ariaLive(): string {
    return (this.loading ? 'polite' : null);
  }

  @Input()
  public tracker: WorkTracker;

  ngDoCheck(): void {
    this.loading = !!(this.tracker && !this.tracker.complete);
  }
}