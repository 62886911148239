<section>
  <div class="container main-content">
    <loading-pane [tracker]="tracker">
      <div [ngClass]="{ 'tile': largeView }">
        <div [ngClass]="{ 'tile': largeView, 'is-vertical': true, 'is-parent': true, 'is-12': true }">
          <article [ngClass]="{ 'tile': largeView, 'is-child': true, 'notification': largeView, 'box': largeView, 'main': true }">
            <div class="table-wrapper">
                <div class="table-heading">
                  <h1>Site Administration</h1>
                </div>
            </div>
          
            <div class="content-site">
              <form (ngSubmit)="onSubmit()" #cfgForm="ngForm">
                <div [ngClass]="{ 'tile': largeView, 'is-vertical': true,'is-child': true, 'box': largeView, 'is-12': true }">

                  <div class="nav-box-right">
                    <div>
                      <button class="button" (click)="sendToHomeVisitPriorityRules()">Home Visit Priority Rules</button>
                    </div>
                  </div>

                  <!-- START: Max Refresh Hits to ESB -->
                  <div class="form-group form-content columns">
                    <div class="column is-one-quarter">
                      <label class="form-label" for="maxRefreshHitsToESBPerMinute">Max Refresh Hits to ESB Per Minute</label>
                    </div>
                    <div class="column is-half box">
                      <input type="text" class="form-control ml" id="maxRefreshHitsToESBPerMinute"
                            required pattern="[0-9]*" 
                            [(ngModel)]="model.maxRefreshHitsToESBPerMinute" name="maxRefreshHitsToESBPerMinute"
                            #maxRefreshHitsToESBPerMinuteControl="ngModel">
                      <div class="help-tip">
                        <p>
                          This setting controls how frequently the TSS Refresh function can send requests to ESB to request data 
                          relating to an Event or Form in any given minute. Increasing this setting will hit ESB more. 
                          Example: Setting to 10 means we can send one request every 6 seconds to ESB. Setting to 100 is 1 
                          request every 0.6 seconds. 1000 = 1 per 0.06 seconds, and so on. This presumes we have data we 
                          wish to request, of course. This controls the 'maximum' throughput.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="is-full">
                    <div [hidden]="maxRefreshHitsToESBPerMinuteControl.valid || maxRefreshHitsToESBPerMinuteControl.pristine"
                          class="alert alert-danger is-12">
                      Max Refresh Hits to ESB Per Minute is required and must be a whole number.
                    </div>
                  </div>
                  <!-- END: Max Refresh Hits to ESB -->

                  <!-- START: Max Reclassify -->
                  <div class="form-group form-content columns">
                    <div class="column is-one-quarter">
                      <label class="form-label" for="maxItemsToReclassifyOnEachRun">Max Items To Reclassify On Each Run</label>
                    </div>
                    <div class="column is-half box">
                        <input type="text" class="form-control ml" id="maxItemsToReclassifyOnEachRun"
                                required pattern="[0-9]*"
                                [(ngModel)]="model.maxItemsToReclassifyOnEachRun" name="maxItemsToReclassifyOnEachRun"
                                #maxItemsToReclassifyOnEachRunControl="ngModel">
                        <div class="help-tip">
                          <p>This setting controls how many Events will be reclassified, at most, each minute by the TSS Classification function.</p>
                        </div>
                    </div>
                  </div>
                  <div class="is-full">
                    <div [hidden]="maxItemsToReclassifyOnEachRunControl.valid || maxItemsToReclassifyOnEachRunControl.pristine"
                          class="alert alert-danger">
                      Max Items to Reclassify Per Minute is required and must be a whole number.
                    </div>
                  </div>
                  <!-- END: Max Reclassify -->

                  <!-- START: Max Reprioritise -->
                  <div class="form-group form-content columns">
                    <div class="column is-one-quarter">
                      <label class="form-label" for="maxItemsToReprioritiseOnEachRun">Max Items To Reprioritise On Each Run</label>
                    </div>
                    <div class="column is-half box">
                      <input type="text" class="form-control ml" id="maxItemsToReprioritiseOnEachRun"
                                required pattern="[0-9]*"
                                [(ngModel)]="model.maxItemsToReprioritiseOnEachRun" name="maxItemsToReprioritiseOnEachRun"
                                #maxItemsToReprioritiseOnEachRunControl="ngModel">
                      <div class="help-tip">
                        <p>This setting controls how many Events will be reprioritised, at most, each minute by the TSS Prioritisation function.</p>
                      </div>
                    </div>
                  </div>
                  <div class="is-full">
                    <div [hidden]="maxItemsToReprioritiseOnEachRunControl.valid || maxItemsToReprioritiseOnEachRunControl.pristine"
                          class="alert alert-danger">
                      Max Items to Reprioritise Per Minute is required and must be a whole number.
                    </div>
                  </div>
                  <!-- END: Max Reprioritise -->

                  <!-- START: Max Archive -->
                  <div class="form-group form-content columns">
                    <div class="column is-one-quarter">
                      <label class="form-label" for="maxItemsToArchiveOnEachRun">Max Items To Archive On Each Run</label>
                    </div>
                    <div class="column is-half box">
                        <input type="text" class="form-control ml" id="maxItemsToArchiveOnEachRun"
                                required pattern="[0-9]*"
                                [(ngModel)]="model.maxItemsToArchiveOnEachRun" name="maxItemsToArchiveOnEachRun"
                                #maxItemsToArchiveOnEachRunControl="ngModel">
                        <div class="help-tip">
                          <p>This setting controls how many Events will be archived (deleted), at most, each minute by the TSS Archive function, if any.</p>
                        </div>
                    </div>
                  </div>
                  <div class="is-full">
                    <div [hidden]="maxItemsToArchiveOnEachRunControl.valid || maxItemsToArchiveOnEachRunControl.pristine"
                          class="alert alert-danger">
                      Max Items to Archive Per Minute is required and must be a whole number.
                    </div>
                  </div>
                  <!-- END: Max Reprioritise -->
                      
                  <!-- START: Refresh Frequency -->
                  <div class="form-group form-content columns">
                    <div class="column is-one-quarter">
                      <label class="form-label" for="refreshFrequencyInDays">Refresh Frequency in Days</label>
                    </div>
                    <div class="column is-half box">
                      <input type="text" class="form-control ml" id="refreshFrequencyInDays"
                                required pattern="[0-9]*"
                                [(ngModel)]="model.refreshFrequencyInDays" name="refreshFrequencyInDays"
                                #refreshFrequencyInDaysControl="ngModel">
                      <div class="help-tip">
                        <p>This setting controls how frequently, in days, TSS will ask ESB to check for fresh data in relation to a given 
                        Event or Form.</p>
                      </div>
                    </div>
                  </div>
                  <div class="is-full">
                    <div [hidden]="refreshFrequencyInDaysControl.valid || refreshFrequencyInDaysControl.pristine"
                          class="alert alert-danger">
                      Count of Days Between Refresh Requests is required and must be a whole number. Business analysts initially 
                      suggested a large figure and was set to 210 days. This is because it is expected some events are updated only 
                      very rarely.
                    </div>
                  </div>
                  <!-- END: Refresh Frequency -->



                  <!-- START: Max Stale Event Refresh Attempts -->
                  <div class="form-group form-content columns">
                    <div class="column is-one-quarter">
                      <label class="form-label" for="maxStaleEventRefreshAttempts">Max Stale Event Refresh Attempts</label>
                    </div>
                    <div class="column is-half box">
                      <input type="text" class="form-control ml" id="maxStaleEventRefreshAttempts"
                                required pattern="[0-9]*"
                                [(ngModel)]="model.maxStaleEventRefreshAttempts" name="maxStaleEventRefreshAttempts"
                                #maxStaleEventRefreshAttemptsControl="ngModel">
                      <div class="help-tip">
                        <p>This setting controls how many times the solution will request a fresh payload from the source system (ICMS) when a 
                          task qualifies for refreshing. Examples of this include when the last time an event was updated was over 210 days in 
                          the past. When we next receive a payload for a given event we reset its own refresh attempt counter to zero and 
                          reset the next refresh datetime to be 210 days further in the future. In this way, events keep themselves refresh
                          dynamically.</p>
                      </div>
                    </div>
                  </div>
                  <div class="is-full">
                    <div [hidden]="maxStaleEventRefreshAttemptsControl.valid || maxStaleEventRefreshAttemptsControl.pristine"
                          class="alert alert-danger">
                      This should be a small number greater than zero. A number from 1 to 3 is suitable.
                    </div>
                  </div>
                  <!-- END: Max Stale Event Refresh Attempts -->
                  


                  <!-- START: Months to Keep Closed or Deleted Events -->
                  <div class="form-group form-content columns">
                    <div class="column is-one-quarter">
                      <label class="form-label" for="monthsToKeepClosedEvents">Months to Keep Closed or Deleted Events</label>
                    </div>
                    <div class="column is-half box">
                      <input type="text" class="form-control ml" id="monthsToKeepClosedEvents"
                                required pattern="[0-9]*"
                                [(ngModel)]="model.monthsToKeepClosedEvents" name="monthsToKeepClosedEvents"
                                #monthsToKeepClosedEventsControl="ngModel">
                      <div class="help-tip">
                        <p>This setting controls how long the solution will retain an event that was closed or deleted. This is a value 
                          expressed in months. The default is 6 months. So, once an event is closed or deleted in ICMS, the TSS will 
                          set the ArchiveDt value to be 'now + 6 months'. When this date is reached, the Archive function will delete 
                          the event from the Task Subsystem.</p>
                      </div>
                    </div>
                  </div>
                  <div class="is-full">
                    <div [hidden]="monthsToKeepClosedEventsControl.valid || monthsToKeepClosedEventsControl.pristine"
                          class="alert alert-danger">
                          Months to Keep Closed or Deleted Events is required and must be a whole number. Originally set to 6 
                          meaning the solution will retain closed and deleted events for 6 months after they are completed. After this, 
                          they are deleted from the Task Subsystem solution.
                    </div>
                  </div>
                  <!-- END: Months to Keep Closed or Deleted Events -->



                  <!-- START: Core Processing Start Time -->
                  <div class="form-group form-content columns">
                    <div class="column is-one-quarter">
                      <label class="form-label" for="coreProcessingStartHour">Core Processing Start Time</label>
                    </div>
                    <div class="column is-half box">
                      <input type="text" class="form-control ml" id="coreProcessingStartHour"
                                required pattern="([0-1]?[0-9]|2[0-3])"
                                [(ngModel)]="model.coreProcessingStartHour" name="coreProcessingStartHour"
                                #coreProcessingStartHourControl="ngModel">
                      <div class="help-tip">
                        <p>This setting controls the start hour, from 0 to 23, in a 24 hour clock for when 
                          the Task Subsystem should do most of its processing. This covers 
                          classification, prioritisation and task refresh. For example, when a task 
                          classifies it will set the next reclassify date-time to be in 24 hours, and will 
                          then randomise the time part to be between this start hour and the defined 
                          core processing end time. If you have this set from 1am to 8pm then tasks 
                          will naturally reclassify etc between these hours each day.</p>
                      </div>
                    </div>
                  </div>
                  <div class="is-full">
                    <div [hidden]="coreProcessingStartHourControl.valid || coreProcessingStartHourControl.pristine"
                          class="alert alert-danger">
                          This should be a number from 0 to 23 and should be smaller than the end time.
                    </div>
                  </div>
                  <!-- END: Core Processing Start Time -->



                  <!-- START: Core Processing End Time -->
                  <div class="form-group form-content columns">
                    <div class="column is-one-quarter">
                      <label class="form-label" for="coreProcessingEndHour">Core Processing End Time</label>
                    </div>
                    <div class="column is-half box">
                      <input type="text" class="form-control ml" id="coreProcessingEndHour"
                                required pattern="([0-1]?[0-9]|2[0-3])"
                                [(ngModel)]="model.coreProcessingEndHour" name="coreProcessingEndHour"
                                #coreProcessingEndHourControl="ngModel">
                      <div class="help-tip">
                        <p>This setting controls the end hour, from 1 to 23, in a 24 hour clock for when 
                          the Task Subsystem should do most of its processing. This covers 
                          classification, prioritisation and task refresh. For example, when a task 
                          classifies it will set the next reclassify date-time to be in 24 hours, and will 
                          then randomise the time part to be between this start hour and the defined 
                          core processing end time. If you have this set from 1am to 8pm then tasks 
                          will naturally reclassify etc between these hours each day.</p>
                      </div>
                    </div>
                  </div>
                  <div class="is-full">
                    <div [hidden]="coreProcessingEndHourControl.valid || coreProcessingEndHourControl.pristine"
                          class="alert alert-danger">
                          This should be a number from 0 to 23 and should be greater than the start time.
                    </div>
                  </div>
                  <!-- END: Core Processing End Time -->

                  <div class="results">
                    <div data-title="Name">
                      <button type="submit" class="button" [disabled]="!cfgForm.form.valid">Submit</button>
                    </div>
                    <div class="success information" *ngIf="submitStarted === true && submitFailed !== true">
                      <p>Settings Updated</p>
                    </div>
                    <div class="is-warning information" *ngIf="submitFailed === true">
                      We were unable to save the configuration information.
                    </div>
                  </div>

                  <div class="space-above">&nbsp;</div>
                </div>
              </form>
            </div>
          </article>
        </div>
      </div>
    </loading-pane>
    <router-outlet></router-outlet>
  </div>
</section>

