<div>
  <div class="table-wrapper">
    <div class="table-heading table-sub-heading">
      <span>ICMS Form Summary Data</span><span>All Forms Related to this Subject Child</span>
    </div>
  </div>
  <div>
    <div role="tabpanel" class="table-layout">
      <div role="table">
        <div class="top-level">
          <div class="row" role="row">
            <div class="th" role="columnheader" title="Header Column Form Id" aria-hidden="true"><span>Form Id</span></div>
            <div class="th" role="columnheader" title="Header Column Source System Type" aria-hidden="true"><span>Source System Type</span></div>
            <div class="th" role="columnheader" title="Header Column Status" aria-hidden="true"><span>Status</span></div>
            <div class="th" role="columnheader" title="Header Column Item Created Dtm" aria-hidden="true"><span>Item Created Dtm</span></div>
          </div>

          <ng-container *ngFor="let nextForm of formDetails; let i = index;">
            <div class="row" role="row" [attr.data-form-id]="nextForm.id" [ngClass]="{ 'even-row': i % 2 ==0 }">
              <div class="td emphasised" data-title="Name" role="cell">
                <div class="visually-hidden" title="Header Column Form Id">Form Id</div>
                <div>
                  <span [ngClass]="{'highlight': isAnchorDateForm(nextForm)}">{{ nextForm.id }}</span>
                </div>
              </div>
              <div class="td" data-title="Source System Type" role="cell">
                <div class="visually-hidden" title="Header Column Source System Type">Source System Type</div>
                <span>{{ lookupType(nextForm.sourceSystemType) }}</span>
              </div>
              <div class="td" data-title="Status" role="cell">
                <div class="visually-hidden" title="Header Column Status">Status</div>
                <span>{{ lookupStatus(nextForm.status) }}</span>
              </div>
              <div class="td" data-title="Item Created Dtm" role="cell">
                <div class="visually-hidden" title="Header Column Item Created Dtm">Item Created Dtm</div>
                <span>{{ nextForm.itemCreatedDtm ? formatShortDate(nextForm.itemCreatedDtm) : 'n/a' }}</span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="sub-heading-space">
    If the Anchor Date was taken from a form the form id will be highlighted <span class='highlight'>yellow</span>. For Anchor Date, we only use forms that are Case Notes of 
    type 'Prescribed Home Visit' that are 'Complete' and which list this Subject Child as a participant.
  </div>
</div>