import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  @Input() fullName: string;
  @Input() time: string;
  @Input() date: string;
  @Input() isAuthenticated: boolean;
}
