import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { SiteConfigurationService } from '../../../services/site-configuration.service';
import { WorkTracker } from '../../shared/loading-pane/work-tracker.service';

@Component({
  selector: 'app-home-visit-priority-rules',
  templateUrl: './home-visit-priority-rules.component.html',
  styleUrls: ['./home-visit-priority-rules.component.scss']
})
export class HomeVisitPriorityRulesComponent implements OnInit {
  model: Api.HomeVisitPriorityDTO;
  tracker: WorkTracker;
  
  retrieveFailed: boolean = false;
  submitStarted: boolean = false;
  submitFailed: boolean = false;
  largeView: boolean;

  configurations: Api.HVPriorityRule[];

  careArrangementCategory: string = 'Care Arrangement';
  inHomeCategory: string = 'In Home';
  inHomeCareAgreementCategory: string = 'In Home (Care Agreement)';

  constructor(private router: Router, private siteConfigurationService: SiteConfigurationService) {
    this.tracker = new WorkTracker(true);
    this.retrieveData();
  }

  ngOnInit(): void {
    this.largeView = window.innerWidth > 900;
  }

  async retrieveData() {
    this.retrieveFailed = false;

    await this.tracker.track(
      this.siteConfigurationService.getHomeVisitPrioritiesConfiguration().then(items => {
        this.model = items;
        if(!this.model) return;
        this.initialiseConfigurations();
      })
    )
    .catch(error => {
      console.error('Error retrieving Home Visit Priority Settings. Message: ' + error);
      this.retrieveFailed = true;
    });
  }

  initialiseConfigurations()
  {
    // Create a collection for the UI based on these properties
    this.configurations = [];

    this.configurations.push({
      category: this.careArrangementCategory, 
      amber: this.model.careArrangementAmberPriority,
      red: this.model.careArrangementRedPriority
    });

    this.configurations.push({
      category: this.inHomeCategory, 
      amber: this.model.inHomeAmberPriority,
      red: this.model.inHomeRedPriority
    });

    this.configurations.push({
      category: this.inHomeCareAgreementCategory, 
      amber: this.model.inHomeCareAgreementAmberPriority,
      red: this.model.inHomeCareAgreementRedPriority
    });
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.largeView = window.innerWidth > 900;
  }

  trackBy(index: number, item: Api.HVPriorityRule) {
    return ('' + item.category);
  }

  isEven(item: Api.HVPriorityRule): boolean {
    if (!this.configurations) return false;

    let index = this.configurations.indexOf(item, 0);

    return index % 2 === 0;
  }

  // A value in the data table has been updated. Update the associated property.
  changeValue(id: number, category: string, priority: string, event: any) {
    if(!event || !event.target) return;
    var value = event.target.textContent;
    // console.log('change value. id: ' + id + '. category: ' + category + '. priority: ' + priority + '. value: ' + value);

    if(category == this.careArrangementCategory)
    {
      if(priority == 'red') this.model.careArrangementRedPriority = value;
      else this.model.careArrangementAmberPriority = value;
    }
    else if(category == this.inHomeCategory)
    {
      if(priority == 'red') this.model.inHomeRedPriority = value;
      else this.model.inHomeAmberPriority = value;
    }
    else if(category == this.inHomeCareAgreementCategory)
    {
      if(priority == 'red') this.model.inHomeCareAgreementRedPriority = value;
      else this.model.inHomeCareAgreementAmberPriority = value;
    }
  }

  async onSubmit() {
    console.log('Running submit. Values: ');
    console.log(JSON.stringify(this.model));
    
    // Clear any existing error as we are trying again
    this.submitFailed = false;
    this.submitStarted = true;

    await this.tracker.track(
      this.siteConfigurationService.postHomeVisitPriorityConfiguration(this.model).then(items => {
        
      })
      .catch(error => {
        console.error('Error submitting updated Home Visit Priority Settings. Message: ' + error);
        this.submitFailed = true;
      })
    );
  }

  returnToSiteConfiguration()
  {
    this.router.navigate(['/app-site-admin']);
  }
}
