<li class="pt-4">
  <div class="field box pt-10">
      <h3><a class="button is-size-7" (click)="toggleIdSearch()">{{ showingIdIcon }}</a> Find by Event or Form Id</h3>
      <div [ngClass]="{'is-hidden-tablet is-hidden-touch': !showingIdSearch, 'column': true, 'is-12': true}">
          <h4>Select Event or Form</h4>
          <input type="radio" ng-model="category" name="category" value="icms.event" [checked]="eventType === true" (change)="onCategoryChange('icms.event')" tabindex="1"> Event
          <input type="radio" ng-model="category" name="category" value="icms.form" [checked]="formType === true" (change)="onCategoryChange('icms.form')" tabindex="1"> Form
          <div id="content">
              <h4>Event or Form Id</h4>
              <p>
                  <input class="input is-primary" ng-model="eventOrFormId" type="text" placeholder="Id to Search For" #itemId (keyup.enter)="onEventOrFormIdSelected(itemId.value)" (blur)="updateEventOrFormId(itemId.value)" tabindex="1">
              </p>
              <div class="help-tip">
                  <p>
                      Search for a specific event or form to display. <br />
                      Select the Event or Form option then enter the id. Example id: 8421
                  </p>
              </div>
              <p class="pt-4">
                  <a class="button refresh" (click)="runEventOrFormIdSearch()" tabindex="1" (keyup.enter)="runEventOrFormIdSearch()">Find Item</a>
              </p>

              <div *ngIf="showSearchRequiresId; then ifNoEventOrFormId;"></div>
              <ng-template #ifNoEventOrFormId>
                <div class="notification is-warning mt5">
                    <div class="columns">
                        <div class="column is-10">
                            Please enter an event or form id before selecting this search option.
                        </div>
                        <div class="column is-2">
                            <button class="delete button close" (click)="showSearchRequiresId=false" tabindex="1"></button>
                        </div>
                    </div>
                </div>
              </ng-template>
          </div>
      </div>
  </div>
</li>