<section>
  <div class="container main-content">
    <div [ngClass]="{ 'tile': largeView }">
      <div [ngClass]="{ 'tile': largeView, 'is-vertical': true, 'is-parent': true, 'is-12': true }">
        <article [ngClass]="{ 'tile': largeView, 'is-child': true, 'notification': largeView, 'box': largeView, 'main': true }">
          <div class="table-wrapper">
              <div class="table-heading">
                <h1>Data Migration File Upload</h1>
              </div>
          </div>
          <div class="">
            <div class="columns">
              <div class="column box" style="margin: 15px;">
                <p>This page provides the abilty to request data to be sent from ICMS to the Task Subsystem.</p>
                <p>Load a text file in csv format containing a collection of form and or event ids for which you would like ICMS to send data.</p>
                <p>The required format of this data is shown here.</p>
                <p>Please do include the header row.</p>

                <div class="content-site">
                  <div>
                    <form [formGroup]="form" (ngSubmit)="onSubmit()" class="space-above">
                      <input type="file" name="migrationData" (change)="onFileChange($event)" class="button" />
                      <button type="submit" class="button space-left">Upload</button>
                      <a *ngIf="dataLoading" class="button is-loading">Loading</a>
                    </form>
      
                    <!-- Error and Progress results -->
                    <div *ngIf="error">
                      <div class="error">{{ error.message }}</div>
                    </div>
                    <div *ngIf="uploadResponse.status === 'error'">
                      <div class="error">{{ uploadResponse.message }}</div>
                    </div>
                    <div *ngIf="uploadResponse.status === 'progress'">
                        File Upload Progress: {{uploadResponse.message}}%
                    </div>
                    <div *ngIf="uploadResponse.status === 'ok'">
                      <div class="success">
                          <p>Processing Complete.</p>
                          <p>Count of Events and Items Loaded: {{ uploadResponse.countLoaded }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="column">
                <table class="table">
                  <thead>
                    <tr><th>Example CSV Content</th></tr>
                    <tr><th>Source System,Type,Id</th></tr>
                  </thead>
                  <tbody>
                    <tr><td>icms,icms.event,5423</td></tr>
                    <tr><td>icms,icms.form,19872</td></tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </article>
      </div>
    </div>
    <router-outlet></router-outlet>
  </div>
</section>

