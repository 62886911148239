import { Component, OnInit, OnDestroy } from '@angular/core';
import { TssRootItemService } from '../../../../services/tss-root-item.service';
import { SubjectUserIdService } from '../../subject-user-id.service';
import { EventFormIdService } from '../../event-form-id.service';
import { Subscription } from 'rxjs';
import { WorkTracker } from '../../../../components/shared/loading-pane/work-tracker.service';

@Component({
  selector: 'app-view-tss-root-item',
  templateUrl: './view-tss-root-item.component.html',
  styleUrls: ['./view-tss-root-item.component.scss']
})

export class ViewTssRootItemComponent implements OnInit, OnDestroy {
  sourceData: Api.SubjectUserRuleResult;
  tracker: WorkTracker;

  showEvents: boolean;
  taskSubjectUserId: string;
  taskEventId: string;
  taskFormId: string;
  taskCategory: string;
  dataRetrieveReturnedEmpty: boolean;

  subjectUserIdChangedSubscription: Subscription;
  eventFormIdChangedSubscription: Subscription;

  constructor(private tssRootItemService: TssRootItemService, private subjectUserIdSubscription: SubjectUserIdService, private eventFormIdService: EventFormIdService) {
    this.tracker = new WorkTracker(true);
    this.dataRetrieveReturnedEmpty = false;
    this.showEvents = false;
    this.subscribeToSubjectUserIdChangedNotifications();
    this.subscribeToEventFormIdChangedNotifications();
  }

  ngOnInit() {
    this.retrieveEventDetailsByEventOrFormId();
  }

  ngOnDestroy() {
    if (this.subjectUserIdChangedSubscription) {
      this.subjectUserIdChangedSubscription.unsubscribe();
    }
    if (this.eventFormIdChangedSubscription) {
      this.eventFormIdChangedSubscription.unsubscribe();
    }
  }

  getSubjectUserNameAndIdDisplay() : string 
  {
    if(!this.sourceData || !this.sourceData.mostRecentSubjectUser) return "";

    return this.sourceData.mostRecentSubjectUser.givenName + ' ' + this.sourceData.mostRecentSubjectUser.familyName + ' ( ' + this.sourceData.mostRecentSubjectUser.subjectUserId + ' )';
  }

  public toggleEvent() : void
  {
    this.showEvents = !this.showEvents;
  }

  private subscribeToSubjectUserIdChangedNotifications() {
    if (!this.subjectUserIdChangedSubscription) {
      this.subjectUserIdChangedSubscription = this.subjectUserIdSubscription.currentSubjectUserIdChanged.subscribe(async (subjectUserId) => {
        this.taskSubjectUserId = subjectUserId;
        await this.tracker.track(this.retrieveEventDetailsBySubjectId());
      });
    }
  }

  private subscribeToEventFormIdChangedNotifications() {
    if (!this.eventFormIdChangedSubscription) {
      this.eventFormIdChangedSubscription = this.eventFormIdService.currentEventOrFormIdChanged.subscribe(async (eventOrFormId) => {

        //console.log('subscribeToEventFormIdChangedNotifications. taskEventId: ' + eventOrFormId);
        this.taskCategory = this.eventFormIdService.getCategory();
        if (this.taskCategory == 'icms.event') {
          this.taskEventId = eventOrFormId;
        }
        else // icms.form
        {
          this.taskFormId = eventOrFormId;
        }

        await this.tracker.track(this.retrieveEventDetailsByEventOrFormId());
      });
    }
  }

  async retrieveEventDetailsBySubjectId(): Promise<void> {
    if (!this.taskSubjectUserId || this.taskSubjectUserId.length < 1) return;

    // Reset previous values
    this.sourceData = null;

    try {
      return await this.tssRootItemService.getEventDetailsBySubjectId(this.taskSubjectUserId).then(result => {
        if (result.value != null) {
          this.dataRetrieveReturnedEmpty = false;
          this.sourceData = result.value;
        }
        else
        {
          this.dataRetrieveReturnedEmpty = true;
        }

      });
    } catch (error) {
      console.error(error);
    }
  }

  async retrieveEventDetailsByEventOrFormId() : Promise<void> {
    // Reset previous values
    this.sourceData = null;

    var idToRequest = this.taskEventId
    if (this.taskCategory == 'icms.form') {
      idToRequest = this.taskFormId;
    }

    // This will be set by the view-tss-item-id component
    if (!idToRequest || idToRequest === undefined || idToRequest === 'undefined') {
      return;
    }

    try {
      return await this.tssRootItemService.getEventDetailsByEventOrFormId(idToRequest, this.taskCategory).then(result => {
        if (result.value != null) {
          this.dataRetrieveReturnedEmpty = false;
          this.sourceData = result.value;

          this.initialiseSelectedSubjectChildId();
        }
        else
        {
          this.dataRetrieveReturnedEmpty = true;
        }

      });
    } catch (error) {
      console.log('error');
      console.log(JSON.stringify(error));
    }
  }

  initialiseSelectedSubjectChildId() {
    if (!this.sourceData || !this.sourceData.mostRecentSubjectUser) return;

    this.subjectUserIdSubscription.setSubjectUserId(this.sourceData.mostRecentSubjectUser.subjectUserId);
  }
}
