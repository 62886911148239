import { Component, Input, OnInit } from '@angular/core';
import { DisplayMapperService } from '../../../../../../services/display-mapper-service.service';
import * as codeTables  from '../../../../../../../api/icms_code_tables';
import * as moment from 'moment';

@Component({
  selector: 'app-view-tss-event-summary',
  templateUrl: './view-tss-event-summary.component.html',
  styleUrls: ['./view-tss-event-summary.component.scss']
})
export class ViewTssEventSummaryComponent implements OnInit {
  @Input() eventDetails: Api.ChildSourceEvent[];
  @Input() anchorDateEventId: string;
  @Input() subjectUserId: string;
  @Input() showHighlights: boolean;

  constructor(private displayMapper: DisplayMapperService) { }

  ngOnInit() {
    this.eventDetails = this.sortEvents(this.eventDetails);
  }

  sortEvents(forms: Api.ChildSourceEvent[])
  {
    return forms.sort((x: Api.ChildSourceEvent, y: Api.ChildSourceEvent) => {
      return moment.utc(y.sourceEvent.itemCreatedDtm).diff(moment.utc(x.sourceEvent.itemCreatedDtm));
    });
  }

  isAnchorDateForm(item: Api.TssSourceEvent) : boolean 
  {
    return this.anchorDateEventId && item.id == this.anchorDateEventId;
  }

  lookupStatus(status: string): string {
    return this.displayMapper.getDisplayValue(codeTables.getEventStatusGroupDescriptor, status);
  }

  lookupType(type: string): string {
    return this.displayMapper.getDisplayValue(codeTables.getEventTypeGroupDescriptor, type);
  }

  formatShortDate(date: string): string {
    return !!date
      ? moment(date)
        .local()
        .format('DD/MM/YYYY HH:mm:ss')
      : 'n/a';
  }
}
