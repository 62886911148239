import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'app-view-tss-item-id',
  templateUrl: './view-tss-item-id.component.html',
  styleUrls: ['./view-tss-item-id.component.scss']
})
export class ViewTssItemIdComponent implements OnInit {
  @Input() subjectUserId: string;
  @Output() selectedSubjectUser = new EventEmitter<string>();

  constructor() { }

  ngOnInit() {
  }

  // dynamically bind style
  setClasses() {
    const classes = {
      tssRootItem: true
    };

    return classes;
  }

  getAssociatedDetails() {
    this.selectedSubjectUser.emit(this.subjectUserId);
  }
}
