<div class="content">
  <div *ngIf="!subjectUsers" class="has-text-centered emphasis">
    <div class="table-heading">
      <span>ICMS Subject User Details</span>
    </div>
    <h5 style="padding: 1rem 1rem">-- No Subject Users to Display --</h5>
  </div>
  <div>
    <div *ngIf="subjectUsers; then ifSubjectUsers;"></div>
    <ng-template #ifSubjectUsers>
      <select placeholder="Subject Users" (change)="itemSelected($event.target.value)">
        <option *ngFor="let userName of subjectUserNames" [value]="userName">
          {{userName}}
        </option>
      </select>

      <div *ngIf="subjectUserPropertyMaps; then ifSubjectUserPropertyMaps;"></div>
      <ng-template #ifSubjectUserPropertyMaps>
        <div *ngFor="let subjectUserPropertyData of subjectUserPropertyMaps">
          <div *ngIf="subjectUserIsSelected(subjectUserPropertyData); then ifNextSubjectUser;"></div>
          <ng-template #ifNextSubjectUser>
            <div class="table-layout">
              <div class="table">
                <div class="top-level">
                  <div class="table-heading">
                    <span>ICMS Subject User Details</span>
                    <span>{{ subjectUserPropertyData.subjectUserId }} ({{subjectUserPropertyData.subjectUserName}})</span>
                  </div>
                  <div class='content-shaded'>
                    <app-view-display-area [valueDisplayMap]="subjectUserPropertyData.selectedSubjectUserMap" [id]="subjectUserPropertyData.subjectUserId" objectType="TssSourceSubjectUser" [showRulesFields]="showEventSummaryHighlights(subjectUserPropertyData.subjectUserId)"></app-view-display-area>
                  </div>

                  <div class="table-wrapper">
                    <div class="row table-heading table-sub-heading">
                      <div class=""><span>ICMS Case Allocations</span></div>
                    </div>
                    <div class="sub-heading-space" *ngIf="showCaseAllocationHighlightMessage(subjectUserPropertyData)">
                      {{ getCaseAllocationHighlightMessage(subjectUserPropertyData) }}
                    </div>
                  </div>
                  <div *ngIf="subjectUserPropertyData.selectedSubjectUserCaseAllocationsPropertyMap && subjectUserPropertyData.selectedSubjectUserCaseAllocationsPropertyMap.length > 0">
                    <div *ngFor="let caseAllocationMap of subjectUserPropertyData.selectedSubjectUserCaseAllocationsPropertyMap; let i = index; trackBy: id" [attr.data-index]='i'>
                      <div [ngClass]="{ 'even-row': i % 2 == 0 }">
                        <app-view-display-area [valueDisplayMap]="caseAllocationMap" [valueToHighlight]="showCaseAllocationHighlightMessage(subjectUserPropertyData)"></app-view-display-area>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!subjectUserPropertyData.selectedSubjectUserCaseAllocationsPropertyMap || subjectUserPropertyData.selectedSubjectUserCaseAllocationsPropertyMap.length < 1">
                    <div class="has-text-centered emphasis">
                      None
                    </div>
                  </div>

                  <div class="table-wrapper">
                    <div class="row table-heading table-sub-heading">
                      <div class=""><span>ICMS Placements</span></div>
                    </div>
                  </div>
                  <div *ngIf="subjectUserPropertyData.selectedSubjectUserPlacementPropertyMap && subjectUserPropertyData.selectedSubjectUserPlacementPropertyMap.length > 0">
                    <div *ngFor="let placementMap of subjectUserPropertyData.selectedSubjectUserPlacementPropertyMap; let i = index; trackBy: id" [attr.data-index]='i'>
                      <div [ngClass]="{ 'even-row': i % 2 == 0 }">
                        <app-view-display-area [valueDisplayMap]="placementMap"></app-view-display-area>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!subjectUserPropertyData.selectedSubjectUserPlacementPropertyMap || subjectUserPropertyData.selectedSubjectUserPlacementPropertyMap.length < 1">
                    <div class="has-text-centered emphasis">
                      None
                    </div>
                  </div>

                  <div class="table-wrapper">
                    <div class="row table-heading table-sub-heading">
                      <div class=""><span>ICMS Court Orders</span></div>
                    </div>
                  </div>
                  <div *ngIf="subjectUserPropertyData.selectedSubjectUserCourtOrderPropertyMap && subjectUserPropertyData.selectedSubjectUserCourtOrderPropertyMap.length > 0">
                    <div *ngFor="let courtOrderMap of subjectUserPropertyData.selectedSubjectUserCourtOrderPropertyMap; let i = index; trackBy: id" [attr.data-index]='i'>
                      <div [ngClass]="{ 'even-row': i % 2 == 0 }">
                        <app-view-display-area [valueDisplayMap]="courtOrderMap"></app-view-display-area>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="!subjectUserPropertyData.selectedSubjectUserCourtOrderPropertyMap || subjectUserPropertyData.selectedSubjectUserCourtOrderPropertyMap.length < 1">
                    <div class="has-text-centered emphasis">
                      None
                    </div>
                  </div>

                  <!-- Summary view of all events related to this subject child -->
                  <div class="rules-section" *ngIf="subjectUserPropertyData.thisIsASubjectChild && sourceData && sourceData.eventsRelatedToThisChild && sourceData.mostRecentSubjectUser.subjectUserId == subjectUserPropertyData.subjectUserId">
                    <app-view-tss-event-summary 
                      [eventDetails]="sourceData.eventsRelatedToThisChild" 
                      [anchorDateEventId]="sourceData.eventOrFormIdAnchorDtmTakenFrom"
                      [subjectUserId]="subjectUserPropertyData.subjectUserId" 
                      [showHighlights]="showEventSummaryHighlights(subjectUserPropertyData.subjectUserId)"></app-view-tss-event-summary>
                  </div>

                  <!-- Summary view of all forms related to this subject child -->
                  <div class="rules-section" *ngIf="subjectUserPropertyData.thisIsASubjectChild && sourceData && sourceData.mostRecentSubjectUser.subjectUserId == subjectUserPropertyData.subjectUserId">
                    <app-view-tss-form-summary 
                      [eventDetails]="sourceData.eventsRelatedToThisChild" 
                      [anchorDateFormId]="sourceData.eventOrFormIdAnchorDtmTakenFrom" 
                      [subjectUserId]="subjectUserPropertyData.subjectUserId"></app-view-tss-form-summary>
                  </div>
                  <!-- [anchorDetails]="sourceData.anchorDateDetails" -->

                  <div class="table-heading table-alt-heading mt-6" *ngIf="subjectUserPropertyData.thisIsASubjectChild && sourceData.mostRecentSubjectUser.subjectUserId == subjectUserPropertyData.subjectUserId">
                    <span>TSS Values - Derived from Business Rules</span>
                  </div>
                  <div class="notification is-information info">
                    <div style="padding: 1rem 1rem">
                      The below rules show how the Task Subsytem derived the above rules-driven properties. That is, those properties highlighted with a purple traffic light.
                    </div>
                  </div>
                  <div>
                    <div *ngIf="sourceData && sourceData.ruleDrivers && subjectUserPropertyData.thisIsASubjectChild && sourceData.mostRecentSubjectUser.subjectUserId == subjectUserPropertyData.subjectUserId">
                      <app-view-rules-drivers [ruleResults]="sourceData.ruleDrivers" [ruleDisplayType]=0></app-view-rules-drivers>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </ng-template>
    </ng-template>
  </div>
</div>
