import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-view-state-task-detail',
  templateUrl: './view-state-task-detail.component.html',
  styleUrls: ['./view-state-task-detail.component.scss']
})
export class ViewStateTaskDetailComponent implements OnInit, OnChanges {
  @Input() task: Api.TssSubjectUserStateTask;
  @Input() isExpanded: boolean;

  requiresBookingStep: Api.TssSubjectUserStateTaskStep;
  requiresCaseNoteStep: Api.TssSubjectUserStateTaskStep;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(this.task && !!this.task.derivedStateTaskSteps && this.task.derivedStateTaskSteps.length > 0) {
      this.task.derivedStateTaskSteps.forEach(t => {
        if(t.stage == 1)
        {
          this.requiresBookingStep = t;
        }
        else if(t.stage == 3)
        {
          this.requiresCaseNoteStep = t;
        }
      });
    }
  }

  formatShortDate(date: string): string {
    return moment(date).format('YYYY-MM-DD HH:mm:ss');
  }

  formatDate(date: string): string {
    return moment(date).format('YYYY-MM-DD');
  }
}
