import { Component, OnInit, Input } from '@angular/core';
import { DisplayMapperService } from '../../../../../services/display-mapper-service.service';
import { EventFormIdService } from '../../../event-form-id.service';
import { Subscription } from 'rxjs';
import { TssRootItemService } from '../../../../../services/tss-root-item.service';
import { WorkTracker } from '../../../../shared/loading-pane/work-tracker.service';

@Component({
  selector: 'app-view-tss-source-form',
  templateUrl: './view-tss-source-form.component.html',
  styleUrls: ['./view-tss-source-form.component.scss']
})
export class ViewTssSourceFormComponent implements OnInit {
  @Input() sourceForms: Api.TssSourceForm[];
  @Input() parentEventId: string;
  @Input() subjectChildOwnerId: string;
  @Input() subjectChildOwnerName: string;

  tracker: WorkTracker;
  sourceFormPropertyMaps: Api.DisplayDataById[];
  public formIds: string[];
  allSelection: string;
  selectedFormId: string;
  refreshSendSucceeded: boolean;
  refreshSendFailed: boolean;

  showHideIcon: string;
  showing: boolean;
  displayMapper: DisplayMapperService;
  eventFormIdChangedSubscription: Subscription;
  //propertyMap: Array<Api.DisplayLookupInstance>;
  

  constructor(private tssRootItemService: TssRootItemService, private eventFormIdService: EventFormIdService, private mapper: DisplayMapperService) {
    this.displayMapper = mapper;
    this.tracker = new WorkTracker(true);
    this.subscribeToEventFormIdChangedNotifications();
  }

  ngOnInit() {
    this.allSelection = '--All--';
    this.showHideIcon = '+';
    this.showing = false;
    this.refreshSendSucceeded = false;
    this.refreshSendFailed = false;
    this.sourceFormPropertyMaps = [];
    //this.propertyMap = this.displayMapper.getSourceFormMap(this.sourceForm);
    this.initialiseDDLFormIds();
    this.initialisePropertyMaps();
  }

  ngOnDestroy() {
    if (this.eventFormIdChangedSubscription) {
      this.eventFormIdChangedSubscription.unsubscribe();
    }
  }

  initialiseDDLFormIds() {
    this.formIds = [];
    if (this.sourceForms) {
      this.formIds.push(this.allSelection);
      this.selectedFormId = this.allSelection;
      for (const form of  this.sourceForms) {
        this.formIds.push(this.createFormDisplayName(form));
      }
    }
    // Sort
    this.formIds = this.formIds.sort((x: string, y: string) => {
      return x.localeCompare(y);
    });
  }

  // We use this to be informed of the currently selected event or form id
  private subscribeToEventFormIdChangedNotifications() {
    if (!this.eventFormIdChangedSubscription) {
      this.eventFormIdChangedSubscription = this.eventFormIdService.currentEventOrFormIdChanged.subscribe(async (formId) => {
        var taskCategory = this.eventFormIdService.getCategory();
        if (taskCategory == 'icms.form') {
          console.log('tss-source-form: subscribeToEventFormIdChangedNotifications. selectedFormId: ' + formId);
          var form = this.findForm(formId);
          if(form != null)
          {
            this.selectedFormId = this.createFormDisplayName(form);
          }
          else
          {
            console.log("Unable to set selected form id as unable to find a form with id: " + formId);
          }
        }
      });
    }
  }

  findForm(formId: string) : Api.TssSourceForm 
  {
    if(!this.sourceForms) return null;
    this.sourceForms.forEach(f => {
      if(f.id == formId)
      {
        return f;
      }
    });
    return null;
  }

  createFormDisplayName(form: Api.TssSourceForm) : string 
  {
    return form.id + ' ( ' + form.extensionType + ' )';
  }

  getFormIdFromName(formName: string) : string 
  {
    return formName.substring(0, formName.indexOf(' ( '));
  }

  initialisePropertyMaps() {
    if (this.sourceForms) {
      for (const sourceForm of  this.sourceForms) {
        this.sourceFormPropertyMaps.push({
          id: sourceForm.id,
          data: this.displayMapper.getSourceFormMap(sourceForm, this.subjectChildOwnerId, this.subjectChildOwnerName), 
          sourceForm: sourceForm
        });
      }
    }
  }

  toggleTable() {
    this.showing = !this.showing;
    this.showHideIcon = this.showing ? '-' : '+';
  }

  itemSelected(selection: string) {
    this.selectedFormId = selection;

    console.log('taskFormId set to: ' + this.selectedFormId);

    //this.eventFormIdService.setEventOrFormIdAndCategory(this.getFormIdFromName(this.selectedFormId), 'icms.form');
  }

  formIsSelected(sourceFormPropertyData: Api.DisplayDataById)
  {
    var displayName = this.createFormDisplayName(sourceFormPropertyData.sourceForm);
    // console.log('Comparing next form: [' + displayName + '] against selected form id: [' + this.selectedFormId + ']. Returning: ' + (sourceFormPropertyData && (this.selectedFormId == this.allSelection || displayName == this.selectedFormId)));
    return (sourceFormPropertyData && (this.selectedFormId == this.allSelection || displayName == this.selectedFormId));
  }

  async refresh(formId: string) : Promise<void> {
    try {
      await this.tracker.track(this.tssRootItemService.requestRefreshFromICMS('icms.form', formId).then(result => {
        this.refreshSendSucceeded = !result.hasError;
        this.refreshSendFailed = !result || result.hasError;

      }));
    } catch (error) {
      console.error(error);
      this.refreshSendFailed = true;
      this.refreshSendSucceeded = false;
    }
  }
}
