import { Component, OnInit, HostListener } from '@angular/core';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  largeView: boolean;

  constructor() {
  }

  ngOnInit() {
    this.topFunction();
    this.largeView = window.innerWidth > 900;
  }

  ngOnDestroy() {

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.largeView = window.innerWidth > 900;
  }

  // When the user clicks on the button, scroll to the top of the document
  topFunction() {
    window.scrollTo(0, 0);
  }
}
