<header>
  <div>
    <ol>
      <li>Task Subsystem</li>
      <li>Data Viewer</li>
    </ol>
    <app-main-menu *ngIf="isAuthenticated" [fullName]="fullName"></app-main-menu>
  </div>
  <div>
    <div>
      <nav>
        <ul>
          <li routerLink="dashboard" routerLinkActive="sub-header-nav">
            <a routerLink="dashboard" routerLinkActive="sub-header-nav-active" tabindex="0">Dashboard</a>
          </li>
          <li routerLink="data-migration" routerLinkActive="sub-header-nav">
            <a routerLink="data-migration" routerLinkActive="sub-header-nav-active" tabindex="0">Migrate Data</a>
          </li>
          <li routerLink="app-site-admin" routerLinkActive="sub-header-nav">
            <a routerLink="app-site-admin" routerLinkActive="sub-header-nav-active" tabindex="0">Site Configuration</a>
          </li>
          <li routerLink="api-explorer" routerLinkActive="sub-header-nav">
            <a routerLink="api-explorer" routerLinkActive="sub-header-nav-active" tabindex="0">API Explorer</a>
          </li>
        </ul>
      </nav>
      <div>
        <span>{{ time }}</span>
        <span>{{ date }}</span>
      </div>
    </div>
  </div>
</header>
