<div>
  <ul>
    <li class="pt-4">
      <div class="field box pt-10">
        <h3><a class="button is-size-7" (click)="toggleFilterSearch()">{{ showingFilterIcon }}</a> Search Filters</h3>
        <div [ngClass]="{'is-hidden-tablet is-hidden-touch': !showingFilterSearch, 'column': true, 'is-12': true}">
          <div id="content">
              <h4>End Datetime</h4>
              <p>
                  <input class="input is-primary" type="text" placeholder="YYYY-MM-DDTHH:mm:ss" #endDate (keyup.enter)="onEndDateChange(endDate.value)" (blur)="onEndDateChange(endDate.value)" tabindex="1">
              </p>
              <div class="help-tip">
                  <p>
                    Find subject children who are related to at least one event with an ItemLastUpdatedDtm property lower than or equal to this value.<br />
                    When selecting one of the below subject child ids this will also pull back any other events related to the child. These other events are not 
                    filtered by this filter criteria.<br />
                    Example: 2019-02-26T04:20:17
                  </p>
              </div>
          </div>
          <hr />
          <div id="content">
              <h4>Count to Retrieve</h4>
              <p>
                  <input class="input is-primary" type="text" placeholder="Example: 5" #count (keyup.enter)="onCountChange(count.value)" (blur)="onCountChange(count.value)" tabindex="1">
              </p>
              <div class="help-tip">
                  <p>
                      Example: 5<br />The maximum count of records to retrieve.
                  </p>
              </div>
          </div>
          <hr />
          <div id="content">
              <h4>Filter Type</h4>
              <p>
                  <input class="input is-primary" type="text" placeholder="Example: 15500 or 15800" #filter (keyup.enter)="onFilterChange(filter.value)" (blur)="onFilterChange(filter.value)" tabindex="1">
              </p>
              <div class="help-tip">
                  <p>
                      The event type to retrieve. 15800 is an Ongoing Intervention type.
                  </p>
              </div>
          </div>
          <div *ngIf="dataLoading; then ifDataLoading; else ifNotLoadingData"></div>
          <ng-template #ifNotLoadingData>
              <div id="content">
                  <a class="button refresh" (click)="retrieveIds()" (keyup.enter)="retrieveIds()" tabindex="1">Refresh</a>
                  <div class="help-tip">
                      <p>
                          All of the above fields are optional. When blank, the solution retrieves the most recent 20 records prior to 'now'.
                      </p>
                  </div>
              </div>
          </ng-template>
          <ng-template #ifDataLoading>
              <div id="content">
                  <a class="button refresh" (click)="retrieveIds()" (keyup.enter)="retrieveIds()" tabindex="1">Refresh</a>
                  <a class="button is-loading">Loading</a>
                  <div class="help-tip">
                      <p>
                          All of the above fields are optional. When blank, the solution retrieves the most recent 20 records prior to 'now'.
                      </p>
                  </div>
              </div>
          </ng-template>

          <div *ngIf="dataLoadedNoData; then ifDataLoadedNoData;"></div>
          <ng-template #ifDataLoadedNoData>
            <div class="notification is-warning">
              <button class="delete" (click)="dataLoadedNoData=false" tabindex="1"></button>
              We were unable to find any data based on this search
            </div>
          </ng-template>
        </div>
      </div>
    </li>
  </ul>
  <div class="pt-4">
      <div class="field box">
        <loading-pane [tracker]="tracker">
          <ul>
              <h3>{{ matchedItemsDescription }}</h3>
              
              <app-view-tss-item-id *ngFor="let subjectUserId of subjectUserIdData" [subjectUserId]="subjectUserId"
                  (selectedSubjectUser)="onSubjectUserSelected($event)">
              </app-view-tss-item-id>
          
              <p style="padding-top: 10px;">{{ updating }}</p>
              <p>{{ itemsRetrievedDescription }}</p>
          </ul>
        </loading-pane>
      </div>
  </div>
</div>
