  <section>
    <div class="container main-content">
        <div [ngClass]="{ 'tile': largeView }">
          <div [ngClass]="{ 'tile': largeView, 'is-vertical': true, 'is-parent': true, 'is-3': true }">
            <article [ngClass]="{ 'tile': largeView, 'is-child': true, 'notification': largeView, 'box': largeView, 'sidebar': true }" style="height: 100%;">
              <div class="table-wrapper">
                  <div class="table-heading">
                      Search
                  </div>
              </div>
              <ul>
                <app-view-subject-id-search></app-view-subject-id-search>
                <app-view-item-id-search></app-view-item-id-search>
              </ul>
              <div class="table-wrapper pt-4">
                <div class="table-sub-heading">
                  Filtered Search
                </div>
              </div>
              <ul>
                <app-view-subject-filter-search></app-view-subject-filter-search>
              </ul>
            </article>
          </div>
          <div [ngClass]="{ 'tile': largeView, 'is-parent': true }">
            <article [ngClass]="{ 'tile': largeView, 'is-child': true, 'notification': largeView, 'main-content': true, 'box': largeView, 'main': true }">
              <div class="content">
                <div class="table-wrapper">
                    <div class="table-heading">
                      Search Results
                    </div>
                </div>
                <div class="content">
                  <app-view-tss-root-item></app-view-tss-root-item>
                </div>
              </div>
            </article>
          </div>
        </div>
        <button (click)="topFunction()" id="toTopBtn" title="Go to top">Top</button>
        <router-outlet></router-outlet>
    </div>
  </section>
