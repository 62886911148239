import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SearchSectionOpenedService {
  private currentSectionOpen = new Subject<string>();
  public currentSectionOpenChanged = this.currentSectionOpen.asObservable();

  constructor() {
  }

  private ChangeCurrentSectionOpen(sectionOpened: string) {
    this.currentSectionOpen.next(sectionOpened);
  }

  public setSectionOpened(sectionOpened: string): void {
    this.ChangeCurrentSectionOpen(sectionOpened);
  }
}
