<div class="content mt20">
  <div class="row" role="row">
    <div class="td" role="cell">
      &nbsp;
    </div>
    <div class="td" role="cell">
      <div>
        <button [ngClass]="{ 'toggle-details': true, 'highlight-button': showing }" (click)="toggleTable()">
          <span *ngIf="!showing">View {{ pageTitle }} <i class="fas fa-angle-down"></i></span>
          <span *ngIf="showing">Hide {{ pageTitle }} <i class="fas fa-angle-up"></i></span>
        </button>
      </div>
    </div>
  </div>
  <div [ngClass]="{'is-hidden-tablet is-hidden-touch': !showing}">
      <app-view-display-area [valueDisplayMap]="propertyMap"></app-view-display-area>
  </div>
</div>
