<div class="details" #renderArea>
    <div #divToResize>
        <ng-container>
            <div class="row" #headerRow role="row">
                <div class="th" role="columnheader" title="Last Owner" aria-hidden="true"><span>Last Owner</span></div>
                <div class="th" role="columnheader" title="Priority" aria-hidden="true">Priority</div>
                <div class="th" role="columnheader" title="Processing Status" aria-hidden="true">Processing Status</div>
                <div class="th" role="columnheader" title="System Id" aria-hidden="true">System Id</div>
            </div>
            <div class="row" role="row">
                <div class="td" role="cell" data-title="Last Owner">
                    <div class="visually-hidden" title="Last Owner">Last Owner</div>
                    <span>{{ task.lastOwner }}</span>
                </div>
                <div class="td" role="cell" data-title="Priority">
                    <div class="visually-hidden" title="Priority">Priority</div>
                    <span>{{ task.priority }}</span>
                </div>
                <div class="td" role="cell" data-title="Processing Status">
                    <div class="visually-hidden" title="Processing Status">Processing Status</div>
                    <span>{{ task.processingStatus }}</span>
                </div>
                <div class="td" role="cell" data-title="System Id">
                    <div class="visually-hidden" title="System Id">System Id</div>
                    <span>{{ task.id }}</span>
                </div>
            </div>
            <!-- Next row -->
            <div class="row" #headerRow role="row">
                <div class="th" role="columnheader" title="Is Complete" aria-hidden="true"><span>Is Complete</span></div>
                <div class="th" role="columnheader" title="Is Obsolete" aria-hidden="true">Is Obsolete</div>
                <div class="th" role="columnheader" title="Is Invalid" aria-hidden="true">Is Invalid</div>
                <div class="th spacing" role="columnheader" title="" aria-hidden="true">&nbsp;</div>
            </div>
            <div class="row" role="row">
                <div class="td" role="cell" data-title="Is Complete">
                    <div class="visually-hidden" title="Is Complete">Is Complete</div>
                    <span>{{ task.isComplete }}</span>
                </div>
                <div class="td" role="cell" data-title="Is Obsolete">
                    <div class="visually-hidden" title="Is Obsolete">Is Obsolete</div>
                    <span>{{ task.isObsolete }}</span>
                </div>
                <div class="td" role="cell" data-title="Is Invalid">
                    <div class="visually-hidden" title="Is Invalid">Is Invalid</div>
                    <span>{{ task.isInvalid }}</span>
                </div>
                <div class="td spacing" role="cell" data-title="">
                    <div class="visually-hidden" title="">&nbsp;</div>
                    <span>&nbsp;</span>
                </div>
            </div>
            <!-- Next row -->
            <div class="row" #headerRow role="row">
                <div class="th" role="columnheader" title="Record Created Dtm" aria-hidden="true"><span>Record Created Dtm</span></div>
                <div class="th" role="columnheader" title="Record Last Updated Dtm" aria-hidden="true">Record Last Updated Dtm</div>
                <div class="th" role="columnheader" title="Is Overdue with Moderate Priority" aria-hidden="true">Is Overdue with Moderate Priority</div>
                <div class="th" role="columnheader" title="Is Overdue with High Priority" aria-hidden="true">Is Overdue with High Priority</div>
            </div>
            <div class="row" role="row">
                <div class="td" role="cell" data-title="Record Created Dtm">
                    <div class="visually-hidden" title="Record Created Dtm">Record Created Dtm</div>
                    <span>{{
                        task.recordCreatedDtm ? formatShortDate(task.recordCreatedDtm) : 'n/a'
                        }}</span>
                </div>
                <div class="td" role="cell" data-title="Record Last Updated Dtm">
                    <div class="visually-hidden" title="Record Last Updated Dtm">Record Last Updated Dtm</div>
                    <span>{{
                        task.recordLastUpdatedDtm ? formatShortDate(task.recordLastUpdatedDtm) : 'n/a'
                        }}</span>
                </div>
                <div class="td" role="cell" data-title="Is Overdue with Moderate Priority">
                    <div class="visually-hidden" title="Is Overdue with Moderate Priority">Is Overdue with Moderate Priority</div>
                    <span>{{ task.isOverdueModeratePriority }}</span>
                </div>
                <div class="td" role="cell" data-title="Is Overdue with High Priority">
                    <div class="visually-hidden" title="Is Overdue with High Priority">Is Overdue with High Priority</div>
                    <span>{{ task.isOverdueHighPriority }}</span>
                </div>
            </div>
            <!-- Next row: Step: Requires Booking -->
            <div class="row" #headerRow role="row" *ngIf="requiresBookingStep">
                <div class="th" role="columnheader" title="Task Step Name" aria-hidden="true"><span>Task Step Name</span></div>
                <div class="th" role="columnheader" title="Step Due Dtm" aria-hidden="true">Step Due Dtm</div>
                <div class="th" role="columnheader" title="Actioned Dtm" aria-hidden="true">Actioned Dtm</div>
                <div class="th" role="columnheader" title="Actioned By" aria-hidden="true">Actioned By</div>
            </div>
            <div class="row" role="row" *ngIf="requiresBookingStep">
                <div class="td" role="cell" data-title="Task Step Name">
                    <div class="visually-hidden" title="Task Step Name">Task Step Name</div>
                    <span>Requires Booking</span>
                </div>
                <div class="td" role="cell" data-title="Step Due Dtm">
                    <div class="visually-hidden" title="Step Due Dtm">Step Due Dtm</div>
                    <span>{{
                        requiresBookingStep.dueDtm ? formatDate(requiresBookingStep.dueDtm) : 'n/a'
                        }}</span>
                </div>
                <div class="td" role="cell" data-title="Actioned Dtm">
                    <div class="visually-hidden" title="Actioned Dtm">Actioned Dtm</div>
                    <span>{{
                        requiresBookingStep.actionedDtm ? formatDate(requiresBookingStep.actionedDtm) : 'n/a'
                        }}</span>
                </div>
                <div class="td" role="cell" data-title="Actioned By">
                    <div class="visually-hidden" title="Actioned By">Actioned By</div>
                    <span>{{ requiresBookingStep.actionedByUpn }}</span>
                </div>
            </div>
            <!-- Next row: Step: Requires Case Note -->
            <div class="row" #headerRow role="row" *ngIf="requiresCaseNoteStep">
                <div class="th" role="columnheader" title="Task Step Name" aria-hidden="true"><span>Task Step Name</span></div>
                <div class="th" role="columnheader" title="Step Due Dtm" aria-hidden="true">Step Due Dtm</div>
                <div class="th" role="columnheader" title="Actioned Dtm" aria-hidden="true">Actioned Dtm</div>
                <div class="th" role="columnheader" title="Actioned By" aria-hidden="true">Actioned By</div>
            </div>
            <div class="row" role="row" *ngIf="requiresCaseNoteStep">
                <div class="td" role="cell" data-title="Task Step Name">
                    <div class="visually-hidden" title="Task Step Name">Task Step Name</div>
                    <span>Requires Case Note</span>
                </div>
                <div class="td" role="cell" data-title="Step Due Dtm">
                    <div class="visually-hidden" title="Step Due Dtm">Step Due Dtm</div>
                    <span>{{
                        requiresCaseNoteStep.dueDtm ? formatDate(requiresCaseNoteStep.dueDtm) : 'n/a'
                        }}</span>
                </div>
                <div class="td" role="cell" data-title="Actioned Dtm">
                    <div class="visually-hidden" title="Actioned Dtm">Actioned Dtm</div>
                    <span>{{
                        requiresCaseNoteStep.actionedDtm ? formatDate(requiresCaseNoteStep.actionedDtm) : 'n/a'
                        }}</span>
                </div>
                <div class="td" role="cell" data-title="Actioned By">
                    <div class="visually-hidden" title="Actioned By">Actioned By</div>
                    <span>{{ requiresCaseNoteStep.actionedByUpn }}</span>
                </div>
            </div>
        </ng-container>
    </div>
  </div>
  