<div class="content">
  <div *ngIf="!sourceForms || sourceForms.length < 1">
    <div class="table-heading">
      <span>ICMS Form Details</span>
      <span>Forms for Event Id - {{ parentEventId }}</span>
    </div>
    <div class="has-text-centered emphasis">
      <h5 style="padding: 1rem 1rem">-- No Forms to Display --</h5>
    </div>
  </div>
  <div>
    <div *ngIf="sourceForms && sourceForms.length > 0; then ifSourceForms;"></div>
    <ng-template #ifSourceForms>
      <div class="table-heading">
        <span>Forms Related to this Event</span>
        <span>Forms for Event Id - {{ parentEventId }}</span>
      </div>

      <div *ngIf="refreshSendSucceeded; then ifRefreshSendSucceeded;"></div>
      <ng-template #ifRefreshSendSucceeded>
        <div class="notification is-success">
          <div class="columns">
            <div class="column is-10">
              We have sent a request to ESB to send the Task Subsystem a new event payload.
            </div>
            <div class="column is-2">
              <button class="delete button close" (click)="refreshSendSucceeded=false"></button>
            </div>
          </div>
        </div>
      </ng-template>
      <div *ngIf="refreshSendFailed; then ifRefreshSendFailed;"></div>
      <ng-template #ifRefreshSendFailed>
        <div class="notification is-warning">
          <div class="columns">
            <div class="column is-10">
              We are sorry. Something went wrong sending a request to ESB asking for fresh data. Try refreshing the page and trying again. If the 
          problem persists contact support to assist.
            </div>
            <div class="column is-2">
              <button class="delete button close" (click)="refreshSendFailed=false"></button>
            </div>
          </div>
        </div>
      </ng-template>
      
      <select placeholder="Related Forms" (change)="itemSelected($event.target.value)">
        <option *ngFor="let formId of formIds">
          {{ formId }}
        </option>
      </select>

      <div *ngIf="sourceFormPropertyMaps; then ifSourceFormPropertyMaps;"></div>
      <ng-template #ifSourceFormPropertyMaps>
        <div *ngFor="let sourceFormPropertyData of sourceFormPropertyMaps">
          <div *ngIf="formIsSelected(sourceFormPropertyData); then ifNextSourceForm;"></div>
          <ng-template #ifNextSourceForm>
            <div class="table-layout">
              <div class="table">
                <div class="top-level">
                  <div class="table-heading">
                    <span>ICMS Form Details</span>
                    <span>Form - {{ sourceFormPropertyData.id }}</span>
                  </div>
                  <div class='content-shaded'>
                    <loading-pane [tracker]="tracker">
                      <!-- Present the main form details-->
                      <app-view-display-area [valueDisplayMap]="sourceFormPropertyData.data"></app-view-display-area>

                      <a class="button" (click)="refresh(sourceFormPropertyData.sourceForm.id)">Ask ESB for Fresh Form Data</a>

                      <!-- Present the 'extension' content of the form, if any -->
                      <div *ngIf="!!sourceFormPropertyData.sourceForm && (!!sourceFormPropertyData.sourceForm.cpCaseNote || !!sourceFormPropertyData.sourceForm.familyRiskEval || !!sourceFormPropertyData.sourceForm.familyReunificationAssessment || !!sourceFormPropertyData.sourceForm.familyRiskReEvaluationInHomeCase)">
                          <app-view-tss-form-extension [sourceForm]=sourceFormPropertyData.sourceForm></app-view-tss-form-extension>
                      </div>
                    </loading-pane>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </ng-template>
    </ng-template>
  </div>
</div>
