<section>
  <div class="container main-content">
    <loading-pane [tracker]="tracker">
      <div [ngClass]="{ 'tile': largeView }">
        <div [ngClass]="{ 'tile': largeView, 'is-vertical': true, 'is-parent': true, 'is-12': true }">
          <article [ngClass]="{ 'tile': largeView, 'is-child': true, 'notification': largeView, 'box': largeView, 'main': true }">
            <div id="content" class="table-wrapper" role="table">
              <div class="table-heading">
                <h1>Site Administration - Home Visits Priority Rules</h1>
              </div>
              <div>
                <form (ngSubmit)="onSubmit()" #cfgForm="ngForm">
                  <div>
                    <div class="columns">
                      <div class="column information">
                        This configuration will change the overdue priority visits that Child Safety staff see in the Overdue Visits tile in the CS Portal web site. 
                        This must only be changed on approval of the Director, Child Protection Practice, Office of the Chief Practitioner. 
                        An audit log of changes to this section is maintained.
                      </div>
                      <div class="column is-one-third">
                        <div class="nav-box-right">
                          <div>
                            <button class="button" (click)="returnToSiteConfiguration()">Return to Site Configuration</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="top-level">
                    <div class="row" role="row">
                      <div class="th" role="columnheader" title="Header Column Category" aria-hidden="true"><span>Category</span></div>
                      <div class="th" role="columnheader" title="Header Column Amber" aria-hidden="true"><span>Amber (Days)</span></div>
                      <div class="th" role="columnheader" title="Header Column Red" aria-hidden="true"><span>Red (Days)</span></div>
                    </div>
                    <ng-container *ngFor="let config of configurations; trackBy: trackBy; let id = index">
                      <div class="row" [ngClass]="{ 'even-row': isEven(config) }" role="row">
                        <div class="td" data-title="Name" role="cell">
                          <div class="visually-hidden" title="Header Column Category">Category</div>
                          <div><span>{{ config.category }}</span></div>
                        </div>
                        <div class="td" data-title="Amber" role="cell">
                          <div class="visually-hidden" title="Header Column Amber">Amber</div>
                          <span>
                            <span class="input" contenteditable="true" (keyup)="changeValue(id, config.category, 'amber', $event)" (blur)="changeValue(id, config.category, 'amber', $event)">{{config.amber}}</span>
                          </span>
                        </div>
                        <div class="td" data-title="Red" role="cell">
                          <div class="visually-hidden" title="Header Column Red">Red</div>
                          <span>
                            <span class="input" contenteditable="true" (keyup)="changeValue(id, config.category, 'red', $event)" (blur)="changeValue(id, config.category, 'red', $event)">{{config.red}}</span>
                          </span>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                  <div class="results">
                    <div data-title="Name">
                      <button type="submit" class="button" [disabled]="!cfgForm.form.valid">Submit</button>
                    </div>
                    <div class="success information" *ngIf="submitStarted === true && submitFailed !== true">
                      <p>Settings Updated</p>
                    </div>
                    <div class="is-warning information" *ngIf="submitFailed === true">
                      We were unable to save the configuration information.
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </article>
        </div>
      </div>
    </loading-pane>
    <router-outlet></router-outlet>
  </div>
</section>
  
  