import { Component, OnInit } from '@angular/core';
import { EventFormIdService } from '../../event-form-id.service';
import { SearchSectionOpenedService } from '../search-section-opened.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-view-item-id-search',
  templateUrl: './view-item-id-search.component.html',
  styleUrls: ['./view-item-id-search.component.scss']
})
export class ViewItemIdSearchComponent implements OnInit {
  searchSectionName: string;
  showingIdSearch: boolean;
  showingIdIcon: string;
  eventType: boolean;
  formType: boolean;
  showSearchRequiresId: boolean;

  eventOrFormId: string;
  category: string;

  searchSectionOpenedSubscription: Subscription;
  currentOpenedSection: string;

  constructor(private eventOrFormIdService: EventFormIdService, private searchSectionOpenedService: SearchSectionOpenedService) {
    this.subscribeToSectionOpenedNotifications();
  }

  ngOnInit() {
    this.searchSectionName = 'ItemIdSearch';
    this.showingIdSearch = false;
    this.showingIdIcon = '+';
    this.category = 'icms.event';
    this.eventType = true;
    this.formType = false;
    this.showSearchRequiresId = false;
  }

  ngOnDestroy() {
    if (this.searchSectionOpenedSubscription) {
      this.searchSectionOpenedSubscription.unsubscribe();
    }
  }

  private subscribeToSectionOpenedNotifications() {
    if (!this.searchSectionOpenedSubscription) {
      this.searchSectionOpenedSubscription = this.searchSectionOpenedService.currentSectionOpenChanged.subscribe(async (currentOpenedSection) => {
        this.currentOpenedSection = currentOpenedSection;

        if(currentOpenedSection && currentOpenedSection == this.searchSectionName)
        {
          this.showingIdSearch = true;
          this.showingIdIcon = '-';
        }
        else
        {
          this.showingIdSearch = false;
          this.showingIdIcon = '+';
        }
      });
    }
  }

  toggleIdSearch() {
    if((this.currentOpenedSection == undefined || this.currentOpenedSection == this.searchSectionName) && !!this.showingIdSearch)
    {
      // Toggle self closed. No need to publish as others know this was the area opened
      this.showingIdSearch = false;
      this.showingIdIcon = '+';
    }
    else
    {
      this.searchSectionOpenedService.setSectionOpened(this.searchSectionName);
    }
  }

  onCategoryChange(category: string) {
    this.category = category;
    if (category === 'icms.event') {
      this.setEventFormSelections(true, false);
    } else {
      this.setEventFormSelections(false, true);
    }
  }

  setEventFormSelections(eventValue: boolean, formValue: boolean) {
    this.formType = formValue;
    this.eventType = eventValue;
  }

  updateEventOrFormId(eventOrFormId: string) {
    this.eventOrFormId = eventOrFormId;
  }

  // Called when the Subject User Id field has been entered then focus blurs away or is entered.
  onEventOrFormIdSelected(eventOrFormId: string) {
    this.eventOrFormId = eventOrFormId;
    this.runEventOrFormIdSearch();
  }

  runEventOrFormIdSearch() {
    if(!this.eventOrFormId || this.eventOrFormId.length < 1 || !this.category || this.category.length < 1)
    {
      this.showSearchRequiresId = true;
      return;
    }
    this.showSearchRequiresId = false;

    this.eventOrFormIdService.setEventOrFormIdAndCategory(this.eventOrFormId, this.category);
  }
}
