import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-view-display-area',
  templateUrl: './view-display-area.component.html',
  styleUrls: ['./view-display-area.component.scss']
})
export class ViewDisplayAreaComponent implements OnInit {
  @Input() valueDisplayMap: Array<Api.DisplayLookupInstance>;
  @Input() objectType: string;
  @Input() valueToHighlight: string;
  @Input() showRulesFields: boolean;
  @Input() id: string;

  displayData: Array<Api.DisplayRowData>;

  constructor() { }

  ngOnInit() {
    this.displayData = [];
    var currentRow = {
      rowData: []
    };
    var index = 0;
    if(!this.valueDisplayMap) return;
    this.valueDisplayMap.forEach(v => {
      if(index % 3 == 0)
      {
        this.displayData.push(currentRow);
        currentRow = { rowData: [] };
      }
      currentRow.rowData.push(v);
      index++;
    });
    if(currentRow.rowData.length > 0)
    {
      while(currentRow.rowData.length < 3)
      {
        currentRow.rowData.push({});
      }
      this.displayData.push(currentRow);
    }
    //console.log('view-display-area displayData: ' + JSON.stringify(this.displayData));
  }

  getIdReference(displayName: string) : string {
    return displayName.replace(/\s+/g, '');
  }

  getKeyToDisplay(key: string): string {
    if (key.indexOf('@') >= 0) {
      return key.substring(0, key.indexOf('@'));
    }
    return key;
  }

  showHeader(instance : Api.DisplayLookupInstance) : boolean
  {
    if(!instance) return false;
    return this.showRulesFields || (!this.showRulesFields && !instance.rulesDerivedField);
  }

  showHomeVisitIcon(instance : Api.DisplayLookupInstance) : boolean
  {
    if(!instance) return false;
    return instance.rulesDerivedField && this.showRulesFields && instance.showRulesTrafficLights;
  }

  showPlainTextValue(instance : Api.DisplayLookupInstance) : boolean
  {
    if(!instance) return false;
    return (this.showRulesFields && !instance.showRulesTrafficLights) || (!this.showRulesFields && !instance.rulesDerivedField);
  }
}
