import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class EventFormIdService {
  private category: string;
  private currentEventOrFormId = new Subject<string>();
  public currentEventOrFormIdChanged = this.currentEventOrFormId.asObservable();

  constructor() {
  }

  private ChangeEventOrFormId(newEventOrFormId: string) {
    this.currentEventOrFormId.next(newEventOrFormId);
  }

  public setEventOrFormIdAndCategory(eventOrFormId: string, category: string): void {
    this.category = category;
    this.ChangeEventOrFormId(eventOrFormId);
  }

  public getCategory() : string {
    return this.category;
  }
}
