<div class="content-site mx-3 my-3">
  <div class="">
    <div class="">
      <div [ngClass]='{"tabs": true, "is-toggle": true, "is-toggle-rounded": largeView}'>
        <nav>
          <ul>
            <li [ngClass]='{"is-active": showSubjectUsers}' style="margin-top: 0px"><a (click)="subjectUsers()">Subject Users</a></li>
            <li [ngClass]='{"is-active": showClassStates}' style="margin-top: 0px"><a (click)="classifiedStates()">Classified States</a></li>
            <li [ngClass]='{"is-active": showClassTasks}' style="margin-top: 0px"><a (click)="classifiedTasks()">Classified Tasks</a></li>
          </ul>
        </nav>
      </div>
    </div>
  </div>

  <div *ngIf="showSubjectUsers">
    <app-view-tss-event-extension [subjectUsers]=itemSubjectUsers [sourceData]="sourceData"></app-view-tss-event-extension>
  </div>

  <div *ngIf="showClassStates">
    <app-view-tss-state [itemStates]="childStates" [subjectUserId]="subjectUserId" [subjectUserName]="subjectUserName" [sourceData]="sourceData"></app-view-tss-state>
  </div>

  <div *ngIf="showClassTasks">
    <app-view-tss-state-tasks [itemStates]="childStates" [id]="subjectUserId"></app-view-tss-state-tasks>
  </div>
</div>