<div class="content-site mx-3 my-3">
  <loading-pane [tracker]="tracker">
    <div *ngIf="dataRetrieveReturnedEmpty; then ifDataRetrieveReturnedEmpty;"></div>
    <ng-template #ifDataRetrieveReturnedEmpty>
      <div class="notification is-warning">
        <div class="columns">
          <div class="column is-10">
            We were unable to locate data for this search.
          </div>
          <div class="column is-2">
            <button class="delete button close" (click)="dataRetrieveReturnedEmpty=false"></button>
          </div>
        </div>
      </div>
    </ng-template>

    <div *ngIf="sourceData">
      <div class="table-heading table-subtle-heading">
        <span>Results Showing for {{ getSubjectUserNameAndIdDisplay() }}*</span>
      </div>

      <button (click)="toggleEvent()" class="button">
        <span *ngIf="!showEvents"><i class="fas fa-sync"></i> Show Events</span>
        <span *ngIf="showEvents"><i class="fas fa-sync"></i> Show Subject Users</span>
      </button>
      
      <app-view-tss-all-events [sourceData]="sourceData" *ngIf="sourceData" [ngClass]="{'is-hidden-tablet is-hidden-touch': !showEvents}"></app-view-tss-all-events>
  
      <app-view-tss-all-subjects [sourceData]="sourceData" *ngIf="sourceData" [ngClass]="{'is-hidden-tablet is-hidden-touch': showEvents}"></app-view-tss-all-subjects>
    </div>
  </loading-pane>
</div>
