import { Component, OnDestroy, OnInit } from '@angular/core';
import {Router} from "@angular/router"
import {SubscriptionLike} from "rxjs";
import {BroadcastService} from "./msal-angular";
import { MsalService} from "./msal-angular";
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  time: string;
  date: string;
  dateWithoutYear: string;
  public userInfo: any = null;
  fullName: string = 'Guest';
  isAuthenticated: boolean = false;
  private subscription: SubscriptionLike;
  public isIframe: boolean;

  title:string = 'Task Subsystem Data Viewer';

  constructor(
    private broadcastService: BroadcastService,
    private authService: MsalService, 
    private router: Router
  )
  {
    //  This is to avoid reload during acquireTokenSilent() because of hidden iframe
    this.isIframe = window !== window.parent && !window.opener;
    this.userInfo = this.authService.getUser();
    if(this.userInfo)
    {
      this.fullName = this.userInfo.name;
      this.isAuthenticated = true;
    }
    else {
      this.isAuthenticated = false;
    }
    this.updateDateDisplay();
  }

  ngOnInit() {
    this.broadcastService.subscribe("msal:loginFailure", (payload) => {
      console.log("login failure " + JSON.stringify(payload));
      this.isAuthenticated = false;
      this.userInfo = null;
      this.fullName = 'Guest';
      this.router.navigate(['/auth']);
    });

    this.subscription = this.broadcastService.subscribe("msal:loginSuccess", 
      (payload) => {
        //console.log("login success " + JSON.stringify(payload));    
        this.isAuthenticated = true;
        this.userInfo = this.authService.getUser();

        this.updateDateDisplay();
    }); 
  }

  ngOnDestroy() {
    // disconnect from broadcast service on component destroy
    this.broadcastService.getMSALSubject().next(1);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private updateDateDisplay() {
    var now = moment().local();
    this.time = now.format('h:mm a');
    this.date = now.format('ddd, MMM Do YYYY');
    this.dateWithoutYear = now.format('ddd, MMM Do');
  }
}
