import { Component, OnInit } from '@angular/core';
import { SubjectUserIdService } from '../../subject-user-id.service';
import { SearchSectionOpenedService } from '../search-section-opened.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-view-subject-id-search',
  templateUrl: './view-subject-id-search.component.html',
  styleUrls: ['./view-subject-id-search.component.scss']
})
export class ViewSubjectIdSearchComponent implements OnInit {
  searchSectionName: string;
  showingSubjectSearch: boolean;
  showingSubjectIcon: string;

  showSearchRequiresId: boolean;
  subjectId: string;

  searchSectionOpenedSubscription: Subscription;
  currentOpenedSection: string;

  constructor(private subjectUserIdSubscription: SubjectUserIdService, private searchSectionOpenedService: SearchSectionOpenedService) {
    this.subscribeToSectionOpenedNotifications();
  }

  ngOnInit() {
    this.searchSectionName = 'SubjectIdSearch';
    this.showingSubjectSearch = true;
    this.showingSubjectIcon = '-';
    this.showSearchRequiresId = false;
  }

  ngOnDestroy() {
    if (this.searchSectionOpenedSubscription) {
      this.searchSectionOpenedSubscription.unsubscribe();
    }
  }

  private subscribeToSectionOpenedNotifications() {
    if (!this.searchSectionOpenedSubscription) {
      this.searchSectionOpenedSubscription = this.searchSectionOpenedService.currentSectionOpenChanged.subscribe(async (currentOpenedSection) => {
        this.currentOpenedSection = currentOpenedSection;

        if(currentOpenedSection && currentOpenedSection == this.searchSectionName)
        {
          this.showingSubjectSearch = true;
          this.showingSubjectIcon = '-';
        }
        else
        {
          this.showingSubjectSearch = false;
          this.showingSubjectIcon = '+';
        }
      });
    }
  }
  
  toggleSubjectSearch() {
    if((this.currentOpenedSection == undefined || this.currentOpenedSection == this.searchSectionName) && !!this.showingSubjectSearch)
    {
      // Toggle self closed. No need to publish as others know this was the area opened
      this.showingSubjectSearch = false;
      this.showingSubjectIcon = '+';
    }
    else
    {
      this.searchSectionOpenedService.setSectionOpened(this.searchSectionName);
    }
  }

  updateSubjectUserId(selectedId: string) {
    this.subjectId = selectedId;
  }

  // Called when the Subject User Id field has been entered then focus blurs away or is entered.
  onSubjectIdSelected(selectedId: string) {
    this.subjectId = selectedId;
    this.runSubjectUserIdSearch();
  }

  // Person selected the 'Search' button for a manually entered Subject User Id
  runSubjectUserIdSearch() {
    if(!this.subjectId)
    {
      this.showSearchRequiresId = true;
      return;
    }
    this.showSearchRequiresId = false;

    this.subjectUserIdSubscription.setSubjectUserId(this.subjectId);
  }
}
