import { DisplayMapperService } from '../services/display-mapper-service.service';
import * as codeTables  from '../../api/icms_code_tables';

export class SubjectUserProperties {
    subjectUserName: string;
    subjectUserRole: string;
    subjectUserId: string;
    thisIsASubjectChild: boolean;
    selectedSubjectUserMap: Array<Api.DisplayLookupInstance>;
    selectedSubjectUserCaseAllocationsPropertyMap: Array<Api.DisplayLookupInstance>[];
    selectedSubjectUserPlacementPropertyMap: Array<Api.DisplayLookupInstance>[];
    selectedSubjectUserCourtOrderPropertyMap: Array<Api.DisplayLookupInstance>[];
  
    constructor(private subjectName: string, private subjectUser: Api.TssSourceSubjectUser, private displayMapper: DisplayMapperService) {
      if (!subjectUser) {
        return;
      }
      this.subjectUserId = subjectUser.subjectUserId;
      this.subjectUserName = subjectName;
      this.subjectUserRole = subjectUser.role;
      this.thisIsASubjectChild = subjectUser.role.trim() == codeTables.EventRole.Subjectchild_10013;
  
      this.selectedSubjectUserMap = displayMapper.getSubjectUserMap(subjectUser);
      this.initialiseCaseAllocationPropertyMaps(subjectUser, displayMapper);
      this.initialisePlacementPropertyMaps(subjectUser, displayMapper);
      this.initialiseCourtOrderPropertyMaps(subjectUser, displayMapper);
    }
  
    initialiseCaseAllocationPropertyMaps(subjectUser: Api.TssSourceSubjectUser, displayMapper: DisplayMapperService) {
      this.selectedSubjectUserCaseAllocationsPropertyMap = [];
      if (subjectUser.caseAllocations) {
        for (const caseAllocation of subjectUser.caseAllocations) {
          this.selectedSubjectUserCaseAllocationsPropertyMap.push(
            displayMapper.getCaseAllocationMap(caseAllocation)
          );
        }
      }
    }
  
    initialisePlacementPropertyMaps(subjectUser: Api.TssSourceSubjectUser, displayMapper: DisplayMapperService) {
      this.selectedSubjectUserPlacementPropertyMap = [];
      if (this.subjectUser.placements) {
        for (const placement of subjectUser.placements) {
          this.selectedSubjectUserPlacementPropertyMap.push(
            this.displayMapper.getPlacementMap(placement)
          );
        }
      }
    }
  
    initialiseCourtOrderPropertyMaps(subjectUser: Api.TssSourceSubjectUser, displayMapper: DisplayMapperService) {
      this.selectedSubjectUserCourtOrderPropertyMap = [];
      if (subjectUser.courtOrders) {
        for (const courtOrder of subjectUser.courtOrders) {
          this.selectedSubjectUserCourtOrderPropertyMap.push(
            this.displayMapper.getCourtOrderMap(courtOrder)
          );
        }
      }
    }
  }