import { Component, OnInit, Input, HostListener } from '@angular/core';
import { DisplayMapperService } from '../../../../../services/display-mapper-service.service';
import * as codeTables  from '../../../../../../api/icms_code_tables';
import { SubjectUserProperties }  from '../../../../../models/SubjectUserProperties';

@Component({
  selector: 'app-view-tss-event-extension',
  templateUrl: './view-tss-event-extension.component.html',
  styleUrls: ['./view-tss-event-extension.component.scss']
})
export class ViewTssEventExtensionComponent implements OnInit {
  @Input() subjectUsers: Api.TssSourceSubjectUser[];
  @Input() sourceData: Api.SubjectUserRuleResult;

  subjectChildDisplaySuffix: string;
  allSelection: string;
  selectedSubjectUserName: string;
  specialNoName: string = '<No Name>';
  public subjectUserNames: string[];
  dataLoading: boolean;

  displayMapper: DisplayMapperService;
  subjectUserPropertyMaps: SubjectUserProperties[];
  largeView: boolean;

  constructor(private mapper: DisplayMapperService) {
    this.displayMapper = mapper;
    this.subjectUserPropertyMaps = [];
  }

  async ngOnInit(): Promise<void> {
    this.allSelection = '--All--';
    this.subjectChildDisplaySuffix = '*';
    this.dataLoading = false;
    this.largeView = window.innerWidth > 900;
    this.ensureSubjectChildFirst();
    this.initialiseDDLUserNames();
    this.initialisePropertyMaps();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.largeView = window.innerWidth > 900;
  }

  ensureSubjectChildFirst()
  {
    if(!this.subjectUsers) return;
    var updatedList = [];
    for (const subjectUser of  this.subjectUsers) {
      if(subjectUser.role == codeTables.EventRole.Subjectchild_10013)
      {
        updatedList.push(subjectUser);
      }
    }
    for (const subjectUser of  this.subjectUsers) {
      if(subjectUser.role != codeTables.EventRole.Subjectchild_10013)
      {
        updatedList.push(subjectUser);
      }
    }
    this.subjectUsers = updatedList;
  }

  initialiseDDLUserNames() {
    this.subjectUserNames = [];
    if (this.subjectUsers) {
      this.subjectUserNames.push(this.allSelection);
      this.selectedSubjectUserName = this.allSelection;
      for (const subjectUser of  this.subjectUsers) {
        var subjectUserName = this.getSubjectUserName(subjectUser);
        if(subjectUser.role == codeTables.EventRole.Subjectchild_10013 && subjectUser.subjectUserId == this.sourceData.mostRecentSubjectUser.subjectUserId) 
        {
          subjectUserName = subjectUserName + this.subjectChildDisplaySuffix;
        }

        this.subjectUserNames.push(subjectUserName);
      }
    }
    // Sort
    this.subjectUserNames = this.subjectUserNames.sort((x: string, y: string) => {
      return x.localeCompare(y);
    });
  }

  initialisePropertyMaps() {
    if (this.subjectUsers) {
      for (const subjectUser of  this.subjectUsers) {
        const subjectUserName = this.getSubjectUserName(subjectUser);
        this.subjectUserPropertyMaps.push(new SubjectUserProperties(subjectUserName, subjectUser, this.displayMapper));
      }
    }
  }

  subjectUserIsSelected(subjectUserPropertyData: SubjectUserProperties)
  {
    var check = subjectUserPropertyData.subjectUserName + this.subjectChildDisplaySuffix;

    return (subjectUserPropertyData && (this.selectedSubjectUserName == this.allSelection || subjectUserPropertyData.subjectUserName == this.selectedSubjectUserName || 
        this.selectedSubjectUserName == subjectUserPropertyData.subjectUserName + this.subjectChildDisplaySuffix
    ));
  }

  getSubjectUserName(subjectUser: Api.TssSourceSubjectUser): string {
    var response = subjectUser.givenName + ' ' + subjectUser.familyName;
    if(response.trim().length == 0)
    {
      response = '[No Name]';
    }

    response += ' [' + this.displayMapper.getDisplayValue(codeTables.getEventRoleGroupDescriptor, subjectUser.role) + ']';
    if(response.trim().length < 1)
    {
      response = this.specialNoName;
    }
    return response;
  }

  itemSelected(selection: string) {
    this.selectedSubjectUserName = selection;
  }

  getCaseAllocationHighlightMessage(subjectUserPropertyData: SubjectUserProperties)
  {
    if(!subjectUserPropertyData || !this.sourceData || 
      this.sourceData.mostRecentSubjectUser.subjectUserId != subjectUserPropertyData.subjectUserId || 
      !this.sourceData.managementTypeTakenFromCaseAllocationTSSId) return;

    return 'The Management Type was taken from Case Allocation with TSS generated Id: ' + this.sourceData.managementTypeTakenFromCaseAllocationTSSId + '. This is highlighted below.';
  }

  showCaseAllocationHighlightMessage(subjectUserPropertyData: SubjectUserProperties)
  {
    return (subjectUserPropertyData && this.sourceData && 
      this.sourceData.mostRecentSubjectUser.subjectUserId == subjectUserPropertyData.subjectUserId && 
      this.sourceData.managementTypeTakenFromCaseAllocationTSSId);
  }

  showEventSummaryHighlights(selectedSubjectUserId: string)
  {
    return selectedSubjectUserId == this.sourceData.mostRecentSubjectUser.subjectUserId;
  }
}
