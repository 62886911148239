import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { appSettings, ApplicationSettings } from '../../settings';

@Injectable({
  providedIn: 'root'
})
export class SiteConfigurationService {
  applicationSettings: ApplicationSettings;
  tssMaxLastUpdatedDateTime: string = new Date().toISOString().substr(0, 19);

  constructor(private http: HttpClient) {
    this.applicationSettings = appSettings;
  }

  getSiteConfiguration(): Promise<Api.TssSiteConfiguration> {
    // console.log('getSiteConfiguration: ' + JSON.stringify(this.applicationSettings));
    return this.http.get<Api.TssSiteConfiguration>(
      `${this.applicationSettings.siteConfigUrl}`
      ).toPromise();
  }

  postSiteConfiguration(data: Api.TssSiteConfiguration) {
    return this.http.post<boolean>(
        `${this.applicationSettings.siteConfigUrl}`,
        data
      ).toPromise();
  }

  getHomeVisitPrioritiesConfiguration(): Promise<Api.HomeVisitPriorityDTO> {
    return this.http.get<Api.HomeVisitPriorityDTO>(
      `${this.applicationSettings.siteConfigUrl}/gethomevisitpriorities`
      ).toPromise();
  }

  postHomeVisitPriorityConfiguration(data: Api.HomeVisitPriorityDTO) {
    return this.http.post<boolean>(
        `${this.applicationSettings.siteConfigUrl}/updatehomevisitpriorities`, 
        data
      ).toPromise();
  }
}
