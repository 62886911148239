import { Routes } from '@angular/router';
import { MsalGuard } from './msal-angular';
import { AuthComponent } from './components/authentication/auth.component';
import { UnauthorisedComponent } from './components/unauthorised/unauthorised.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DataMigrationComponent } from './components/data-migration/data-migration.component';
import { SiteAdminComponent } from './components/site-admin/site-admin.component';
import { HomeVisitPriorityRulesComponent } from './components/site-admin/home-visit-priority-rules/home-visit-priority-rules.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ApiExplorerComponent } from './components/api-explorer/api-explorer.component';

export const appRoutes: Routes = [
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'home',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'data-migration',
    component: DataMigrationComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'app-site-admin',
    component: SiteAdminComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'app-site-admin/app-home-visit-priority-rules',
    component: HomeVisitPriorityRulesComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'app-api-explorer',
    component: ApiExplorerComponent,
    canActivate: [MsalGuard]
  },
  {
    path: 'auth',
    component: AuthComponent
  },
  {
    path: 'api-explorer',
    component: ApiExplorerComponent
  },
  {
    path: 'unauthorised',
    component: UnauthorisedComponent
  },
  {
    path: '**',
    component: PageNotFoundComponent
  }
];
