<footer>
  <ul>
    <li>
      <a href="https://www.csyw.qld.gov.au/" target="_blank" tabindex="0"> </a>
    </li>
    <li>
      <p>Internal Use Only. Not for redistribution.</p>
    </li>
    <li>
      <div *ngIf="isAuthenticated">
        <div class="tr">
          <div class="th">Generated By:</div>
          <div class="td">{{ fullName }}</div>
        </div>
        <div class="tr">
          <div class="th">Generated On:</div>
          <div class="td">{{ time }} {{ date }}</div>
        </div>
        <div class="tr">
          <div class="th">Build Number:</div>
          <div class="td">{{ applicationSettings.buildNumber }}</div>
        </div>
      </div>
    </li>
  </ul>
</footer>
