<div class="content">
  <div *ngIf="!selectedState" class="has-text-centered emphasis">
    <div class="table-heading">
      <span>Classified States</span>
    </div>
    <h5 style="padding: 1rem 1rem">-- No States to Display --</h5>
  </div>
  <div *ngIf="selectedState">
    <select placeholder="States" (change)="stateSelected($event.target.value)">
      <option *ngFor="let stateName of stateNames" [value]="stateName">
        {{stateName}}
      </option>
    </select>
  </div>
  <div *ngIf="!tasks" class="has-text-centered emphasis">
    <div class="table-heading">
      <span>Classified Tasks</span>
    </div>
    <h5 style="padding: 1rem 1rem">-- No Tasks to Display --</h5>
  </div>

  <div *ngIf="tasks">
    <div class="table-heading">
      <span>Classified Tasks</span>

      <span class="pull-right">
        <span>Sort Options</span>
        <select placeholder="OrderProperty" (change)="orderPropertySelected($event.target.value)" class="ml-1">
          <option *ngFor="let order of orderBy" [value]="order">
            {{order}}
          </option>
        </select>

        <select placeholder="PrecedenceProperty" (change)="precedencePropertySelected($event.target.value)" class="ml-1">
          <option *ngFor="let precedence of orderPrecedence" [value]="precedence">
            {{precedence}}
          </option>
        </select>
      </span>
    </div>

    <div class="container is-9">
      <div id="subjectchildtasks" role="tabpanel" class="tasks table-layout">
        <div role="table">
          <div [ngClass]="{ 'top-level': true }">
            <div class="row" role="row">
              <div class="th" role="columnheader" title="Task Due Date" aria-hidden="true"><span>Task Due Date</span></div>
              <div class="th" role="columnheader" title="Frequency" aria-hidden="true"><span>Frequency</span></div>
              <div class="th" role="columnheader" title="Case Manager" aria-hidden="true"><span>Case Manager</span></div>
              <div class="th" role="columnheader" title="Task Stage" aria-hidden="true"><span>Task Stage</span></div>
            </div>
            <!-- Need a 'no tasks' display concept -->
            <ng-container *ngFor="let task of pagedTasksToShow; trackBy: trackBy">
              <div class="row" role="row" [attr.data-task-id]="task.id"
                [ngClass]="{ 'even-row': isEven(task) }">
                <div class="td emphasised" data-title="Task Due Date" role="cell">
                  <div class="visually-hidden" title="Task Due Date">Task Due Date</div>
                  <div>
                    <div>
                      <span>{{
                        task.dueDtm ? formatDate(task.dueDtm) : 'n/a'
                        }}</span>
                    </div>
                  </div>
                </div>
                <div class="td" data-title="Frequency" role="cell">
                  <div class="visually-hidden" title="Frequency">Frequency</div>
                  <span title="Frequency">{{
                    getFrequencyDescription(task.frequency)
                }}</span>
                </div>
                <div class="td" data-title="Case Manager" role="cell">
                  <div class="visually-hidden" title="Case Manager">Case Manager</div>
                  <span>{{ task.owner }}</span>
                </div>
                <div class="td" data-title="Task Stage" role="cell">
                  <div class="visually-hidden" title="Task Stage">Task Stage</div>
                  <span title="Task Stage">{{ task.currentStage }}</span>
                </div>
                <div class="td" role="cell">
                  <div>
                    <button [ngClass]="{ 'toggle-details': true, 'highlight-button': isTaskSelected(task) }" (click)="toggleSelectedTask(task)"
                      [title]="isTaskSelected(task) ? 'View less task detail for ' + task.owner : 'View more child detail for ' + task.owner"
                      [attr.aria-label]="isTaskSelected(task) ? 'View less task detail for ' + task.owner : 'View more task detail for ' + task.owner">
                      <span *ngIf="!isTaskSelected(task)">View More <i class="fas fa-angle-down"></i></span>
                      <span *ngIf="isTaskSelected(task)">View Less <i class="fas fa-angle-up"></i></span>
                    </button>
                  </div>
                </div>
              </div>
              <app-view-state-task-detail [task]="task" [isExpanded]="isTaskSelected(task)"
                [ngClass]="{ 'even-row': isEven(task), 'reveal-details': isTaskSelected(task) }">
              </app-view-state-task-detail>
            </ng-container>
          </div>
        </div>
      </div>
      <app-pagination (pageChangedEvent)="onPageChangedEvent($event)" (pageSizeChangedEvent)="onPageSizeChangedEvent($event)" [recordCount]="taskCount" [style]="homeVisitsStyle"></app-pagination>
    </div>
  </div>
</div>