<div>
  <div class="table-wrapper">
    <div class="table-heading table-sub-heading">
      <span>ICMS Event Summary Data</span><span>All Events Related to this Subject Child</span>
    </div>
  </div>
  <div>
    <div role="tabpanel" class="table-layout">
      <div role="table">
        <div class="top-level">
          <div class="row" role="row">
            <div class="th" role="columnheader" title="Header Column Event Id" aria-hidden="true"><span>Event Id</span></div>
            <div class="th" role="columnheader" title="Header Column Source System Type" aria-hidden="true"><span>Source System Type</span></div>
            <div class="th" role="columnheader" title="Header Column Status" aria-hidden="true"><span>Status</span></div>
            <div class="th" role="columnheader" title="Header Column Item Created Dtm" aria-hidden="true"><span>Item Created Dtm</span></div>
          </div>

          <ng-container *ngFor="let nextEvent of eventDetails; let i = index;">
            <div class="row" role="row" [attr.data-event-id]="nextEvent.sourceEvent.id" [ngClass]="{ 'even-row': i % 2 ==0 }">
              <div class="td emphasised" data-title="Event Id" role="cell">
                <div class="visually-hidden" title="Header Column Event Id">Event Id</div>
                <div>
                  <span [ngClass]="{'highlight': isAnchorDateForm(nextEvent.sourceEvent) && showHighlights}">{{ nextEvent.sourceEvent.id }}</span>
                </div>
              </div>
              <div class="td" data-title="Source System Type" role="cell">
                <div class="visually-hidden" title="Header Column Source System Type">Source System Type</div>
                <span>{{ lookupType(nextEvent.sourceEvent.sourceSystemType) }}</span>
              </div>
              <div class="td" data-title="Status" role="cell">
                <div class="visually-hidden" title="Header Column Status">Status</div>
                <span>{{ lookupStatus(nextEvent.sourceEvent.status) }}</span>
              </div>
              <div class="td" data-title="Item Created Dtm" role="cell">
                <div class="visually-hidden" title="Header Column Item Created Dtm">Item Created Dtm</div>
                <span>{{ nextEvent.sourceEvent.itemCreatedDtm ? formatShortDate(nextEvent.sourceEvent.itemCreatedDtm) : 'n/a' }}</span>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="sub-heading-space" *ngIf="showHighlights">
    If the Anchor Date was taken from an event the event id will be highlighted <span class='highlight'>yellow</span>.
  </div>
</div>