import { Component, OnInit } from '@angular/core';
import { MsauthService} from '../../services/msauth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {
  redirectUri: string = null;

  loggedIn: boolean;

  constructor(private authService: MsauthService, private route: ActivatedRoute) {}

  async ngOnInit() {
    this.route.queryParams.subscribe(p => (this.redirectUri = p['redirectUrl']));
    this.loggedIn = this.authService.isAuthenticated();
  }

  login() {
   // this.authService.login("/"); // ["user.read"] ,"api://a88bb933-319c-41b5-9f04-eff36d985612/access_as_user"]);
   this.authService.login(this.redirectUri || '/');
  }

  logout() {
   this.authService.logout();
  }

  get isLoggedIn() {
    return this.authService.isAuthenticated(); // this.loggedIn;
  }

  get userName() {
    return this.authService.getUserName();
  }
}
