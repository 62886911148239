import { Component, OnInit } from '@angular/core';
import { TssRootItemService } from '../../../../services/tss-root-item.service';
import { SubjectUserIdService } from '../../subject-user-id.service';
import { SearchSectionOpenedService } from '../search-section-opened.service';
import { Subscription } from 'rxjs';
import { WorkTracker } from '../../../shared/loading-pane/work-tracker.service';

@Component({
  selector: 'app-view-subject-filter-search',
  templateUrl: './view-subject-filter-search.component.html',
  styleUrls: ['./view-subject-filter-search.component.scss']
})
export class ViewSubjectFilterSearchComponent implements OnInit {
  searchSectionName: string;
  subjectUserIdData: string[];
  showingFilterSearch: boolean;
  showingFilterIcon: string;
  dataLoading: boolean;
  dataLoadedNoData: boolean;
  updating: string;
  retrieveCount: number = 20;
  filterEventValue: string = 'All';
  endDate: string;
  matchedItemsDescription: string;
  itemsRetrievedDescription: string;
  tracker: WorkTracker;

  searchSectionOpenedSubscription: Subscription;
  currentOpenedSection: string;

  constructor(private tssRootItemService: TssRootItemService, private subjectUserIdSubscription: SubjectUserIdService, private searchSectionOpenedService: SearchSectionOpenedService) {
    this.showingFilterIcon = '+';
    this.subscribeToSectionOpenedNotifications();
  }

  async ngOnInit() {
    this.tracker = new WorkTracker(true);
    this.searchSectionName = 'SubjectFilterSearch';
    this.dataLoading = false;
    this.dataLoadedNoData = false;
    this.updating = '';
    await this.tracker.track(this.retrieveIds());
  }

  ngOnDestroy() {
    if (this.searchSectionOpenedSubscription) {
      this.searchSectionOpenedSubscription.unsubscribe();
    }
  }

  private subscribeToSectionOpenedNotifications() {
    if (!this.searchSectionOpenedSubscription) {
      this.searchSectionOpenedSubscription = this.searchSectionOpenedService.currentSectionOpenChanged.subscribe(async (currentOpenedSection) => {
        this.currentOpenedSection = currentOpenedSection;

        if(currentOpenedSection && currentOpenedSection == this.searchSectionName)
        {
          this.showingFilterSearch = true;
          this.showingFilterIcon = '-';
        }
        else
        {
          this.showingFilterSearch = false;
          this.showingFilterIcon = '+';
        }
      });
    }
  }

  toggleFilterSearch() {
    if((this.currentOpenedSection == undefined || this.currentOpenedSection == this.searchSectionName) && !!this.showingFilterSearch)
    {
      // Toggle self closed. No need to publish as others know this was the area opened
      this.showingFilterSearch = false;
      this.showingFilterIcon = '+';
    }
    else
    {
      this.searchSectionOpenedService.setSectionOpened(this.searchSectionName);
    }
  }

  onCountChange(value: number) {
    this.retrieveCount = value;
  }

  onEndDateChange(value: string) {
    if (!value || value.length !== 19) {
      this.endDate = '';
      if (value.length > 0) {
        alert('This is not a valid datetime. Please follow this example: 2019-02-26T04:20:17');
      }
    } else {
      // var dtToUse = new Date(value);
      this.endDate = value; // dtToUse.toISOString().substr(0, 19);

      /*console.log('Raw Date string value: ' + value);
      console.log('Date to string: ' + dtToUse);
      console.log('toISOString[0-19]: ' + this.endDate);
      console.log('toUTCString: ' + dtToUse.toUTCString());*/
    }
  }

  onFilterChange(value: string) {
    if (value && value.length > 0) {
      this.filterEventValue = value;
    } else {
      this.filterEventValue = 'All';
    }
  }

  onSubjectUserSelected(subjectUserId: string)
  {
    this.subjectUserIdSubscription.setSubjectUserId(subjectUserId);
  }

  async retrieveIds() : Promise<void> {
    let dateToUse = new Date().toISOString().substr(0, 19);
    if (this.endDate && this.endDate.length === 19) {
      dateToUse = this.endDate;
    }
    if (this.retrieveCount <= 0) {
      this.retrieveCount = 20;
    }
    this.updating = 'Updating...';
    this.matchedItemsDescription = '';
    this.itemsRetrievedDescription = '';

    // Show spinner
    this.dataLoading = true;
    this.dataLoadedNoData = false;

    return await this.tssRootItemService.getSubjectUserIds(this.retrieveCount, dateToUse, this.filterEventValue).then(result => {
      if(!result.hasError)
      {
        this.dataLoading = false;
        this.subjectUserIdData = result.value;
        if(!!result.value && result.value.length > 0)
        {
          this.subjectUserIdData = result.value.sort((x: string, y: string) => {
            return x.localeCompare(y);
          });
        }

        this.matchedItemsDescription = ('Search Retrieved ' + (this.subjectUserIdData ? '' + this.subjectUserIdData.length : '') + ' Records');
        this.itemsRetrievedDescription = ('Count Retrieved: ' + (this.subjectUserIdData ? '' + this.subjectUserIdData.length : ''));

        const dt = new Date();
        const dtDisplay = '' + dt.getFullYear() + '-' +
          this.toTwoDigitString((dt.getMonth() + 1)) + '-' +
          this.toTwoDigitString(dt.getDate()) + 'T' +
          this.toTwoDigitString(dt.getHours()) + ':' +
            this.toTwoDigitString(dt.getMinutes()) + ':' +
            this.toTwoDigitString(dt.getSeconds());

        this.updating = 'Last Updated: ' + dtDisplay;

        if (!this.subjectUserIdData || this.subjectUserIdData.length < 1) {
          this.dataLoadedNoData = true;
        }
      }
      else
      {
        console.log('Calling getSubjectUserIds. We have an error. Data: ' + JSON.stringify(result));
        this.dataLoading = false;
        this.dataLoadedNoData = true;
      }
    });
  }

  toTwoDigitString(value) {
    let response = value.toString();
    if (response.length === 1) {
      response = '0' + response;
    }
    return response;
  }
}
