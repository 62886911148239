import { Injectable } from '@angular/core';
import { MsalService} from '../msal-angular';

@Injectable({
  providedIn: 'root'
})
export class MsauthService {

  constructor(private authService: MsalService) {}

  public isAuthenticated(): boolean {
    const user = this.authService.getUser();
    return user != null;
  }

  getUserName() {
    const user = this.authService.getUser();
    if (!user) {
      return 'Guest';
    }
    return user.name;
  }

  login(returnRedirectPath: string) {
    // Typings don't have an entry for `login(path: string)`
    const svc = this.authService as any;
    svc.login(returnRedirectPath);
  }

  logout() {
    this.authService.logout();
  }

  public getAccessToken(): string {
    /*
    return new Promise((resolve, reject) => {
      return this.getToken();
    });*/
    return this.getToken();
  }

  clearCache() {
    const token = this.getToken();
    if (token != null) {
      // console.log('Clearing cache for scope: ' + token);
      this.authService.clearCacheForScope(token);
    }
  }

  private getToken(): string {
    const cacheResult = this.authService.getCachedTokenInternal([]);
    return cacheResult.token;
    /*
    if(user && user.idToken && user.idToken != 'undefined')
    {
      var token = user.idToken.valueOf();
      console.log('token? ' + JSON.stringify(user.idToken));
      return "";
    }
    return null;*/
  }
}
