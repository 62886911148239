import { Component, OnInit, Input } from '@angular/core';
import { DisplayMapperService } from '../../../../../services/display-mapper-service.service';
import { TssRootItemService } from '../../../../../services/tss-root-item.service';
import { SubjectUserIdService } from '../../../subject-user-id.service';
import { WorkTracker } from '../../../../shared/loading-pane/work-tracker.service';

@Component({
  selector: 'app-view-tss-state',
  templateUrl: './view-tss-state.component.html',
  styleUrls: ['./view-tss-state.component.scss']
})
export class ViewTssStateComponent implements OnInit {
  @Input() itemStates: Api.TssSubjectUserState[];
  @Input() subjectUserId: string;
  @Input() subjectUserName: string;
  @Input() sourceData: Api.SubjectUserRuleResult;

  tracker: WorkTracker;

  public stateNames: string[];
  selectedStateName: string;
  selectedState: Api.TssSubjectUserState;
  
  selectedStatePropertyMap: Array<Api.DisplayLookupInstance>;

  reclassifySucceeded: boolean;
  reclassifyFailed: boolean;

  displayMapper: DisplayMapperService;

  constructor(private tssRootItemService: TssRootItemService, private subjectUserIdSubscription: SubjectUserIdService, private mapper: DisplayMapperService) {
    this.displayMapper = mapper;
    this.tracker = new WorkTracker(true);
  }

  ngOnInit() {
    this.reclassifySucceeded = false;
    this.reclassifyFailed = false;

    if (this.itemStates) {
      this.initialiseStateNames();
    }
  }

  initialiseStateNames() {
    this.stateNames = [];
    if (this.itemStates) {
      for (let i = 0; i < this.itemStates.length; i++) {
        //console.log('this.itemStates[i].stateType: ' + this.itemStates[i].stateType);
        const stateType = this.itemStates[i].stateType;
        this.stateNames.push(stateType);
        if (i === 0) {
          this.itemSelected(stateType);
        }
      }
    }
  }

  itemSelected(selection: string) {
    this.selectedStateName = selection;

    if (this.itemStates) {
      for (const state of this.itemStates) {
        if (this.selectedStateName === state.stateType) {
          this.selectedState = state;
          this.selectedStatePropertyMap = this.displayMapper.getSubjectUserStateMap(this.selectedState);
        }
      }
    }
  }
  
  async reclassify() : Promise<void> {
    try {
      await this.tracker.track(this.tssRootItemService.reclassifySubjectChild(this.subjectUserId).then(result => {
        this.reclassifySucceeded = !result.hasError;

        if(this.reclassifySucceeded)
        {
          // As the classification details have changed we need to trigger a fresh data-retrieve
          this.subjectUserIdSubscription.setSubjectUserId(this.subjectUserId);
        }
        else
        {
          this.reclassifyFailed = true;
        }
      }));
    } catch (error) {
      console.error(error);
      this.reclassifyFailed = true;
    }
  }
}
