import { Component, Input, OnInit } from '@angular/core';
import { DisplayMapperService } from '../../../../../../services/display-mapper-service.service';
import * as codeTables  from '../../../../../../../api/icms_code_tables';
import * as moment from 'moment';

@Component({
  selector: 'app-view-tss-form-summary',
  templateUrl: './view-tss-form-summary.component.html',
  styleUrls: ['./view-tss-form-summary.component.scss']
})
export class ViewTssFormSummaryComponent implements OnInit {
  @Input() eventDetails: Api.ChildSourceEvent[];
  @Input() subjectUserId: string;
  @Input() anchorDateFormId: string;

  formDetails: Api.TssSourceForm[];

  constructor(private displayMapper: DisplayMapperService) { }

  ngOnInit() {
    // Construct form collection based on forms related to given events
    this.formDetails = [];
    if(!this.eventDetails || this.eventDetails.length < 1) return;

    this.eventDetails.forEach(e => {
      if(e.sourceEvent.sourceForms && e.sourceEvent.sourceForms.length > 0)
      {
        e.sourceEvent.sourceForms.forEach(f => {
          this.formDetails.push(f);
        });
      }
    });

    this.formDetails = this.sortForms(this.formDetails);
  }

  sortForms(forms: Api.TssSourceForm[])
  {
    if(!forms) return;
    return forms.sort((x: Api.TssSourceForm, y: Api.TssSourceForm) => {
      return moment.utc(y.itemCreatedDtm).diff(moment.utc(x.itemCreatedDtm));
    });
  }

  isAnchorDateForm(item: Api.TssSourceForm) : boolean 
  {
    return this.anchorDateFormId && item.id == this.anchorDateFormId;
  }

  lookupStatus(status: string): string {
    return this.displayMapper.getDisplayValue(codeTables.getFormInstanceStatusGroupDescriptor, status);
  }

  lookupType(type: string): string {
    return this.displayMapper.getDisplayValue(codeTables.getFormTypeGroupDescriptor, type);
  }

  formatShortDate(date: string): string {
    return !!date
      ? moment(date)
        .local()
        .format('DD/MM/YYYY HH:mm:ss')
      : 'n/a';
  }
}
