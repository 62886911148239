import { Component, OnInit, Input } from '@angular/core';
import { DisplayMapperService } from '../../../../../../services/display-mapper-service.service';

@Component({
  selector: 'app-view-tss-form-extension',
  templateUrl: './view-tss-form-extension.component.html',
  styleUrls: ['./view-tss-form-extension.component.scss']
})
export class ViewTssFormExtensionComponent implements OnInit {
  @Input() sourceForm: Api.TssSourceForm;
  showHideIcon: string;
  showing: boolean;
  pageTitle: string;
  displayMapper: DisplayMapperService;

  propertyMap: Array<Api.DisplayLookupInstance>;

  constructor(private mapper: DisplayMapperService) {
    this.displayMapper = mapper;
  }

  ngOnInit() {
    this.showHideIcon = '+';
    this.showing = false;
    this.pageTitle = this.sourceForm.extensionType;

    if (this.sourceForm && this.sourceForm.cpCaseNote != null) {
      this.propertyMap = this.displayMapper.getCPCaseNoteMap(this.sourceForm.cpCaseNote);
    }
    else if (this.sourceForm && this.sourceForm.familyRiskEval != null) {
      this.propertyMap = this.displayMapper.getFamilyRiskEvalMap(this.sourceForm.familyRiskEval);
    }
    else if (this.sourceForm && this.sourceForm.familyReunificationAssessment != null) {
      this.propertyMap = this.displayMapper.getFamilyReunificationAssessmentMap(this.sourceForm.familyReunificationAssessment);
    }
    else if (this.sourceForm && this.sourceForm.familyRiskReEvaluationInHomeCase != null) {
      this.propertyMap = this.displayMapper.getFamilyRiskReEvaluationInHomeCaseMap(this.sourceForm.familyRiskReEvaluationInHomeCase);
    }
  }

  toggleTable() {
    this.showing = !this.showing;
    this.showHideIcon = this.showing ? '-' : '+';
  }
}
