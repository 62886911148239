import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class SubjectUserIdService {
  private currentSubjectUserId = new Subject<string>();
  public currentSubjectUserIdChanged = this.currentSubjectUserId.asObservable();

  constructor() {
  }

  private ChangeSubjectUserId(newSubjectUserId: string) {
    this.currentSubjectUserId.next(newSubjectUserId);
  }

  public setSubjectUserId(subjectUserId: string): void {
    this.ChangeSubjectUserId(subjectUserId);
  }
}
