<div *ngIf="!selectedState" class="has-text-centered emphasis">
  <div class="table-heading">
    <span>Classified States</span>
  </div>
  <h5 style="padding: 1rem 1rem">-- No States to Display --</h5>
</div>
<div *ngIf="selectedState">
  <select placeholder="States" (change)="itemSelected($event.target.value)">
    <option *ngFor="let stateName of stateNames" [value]="stateName">
      {{stateName}}
    </option>
  </select>

  <div *ngIf="reclassifySucceeded; then ifReclassifySucceeded;"></div>
  <ng-template #ifReclassifySucceeded>
    <div class="notification is-success">
      <button class="delete" (click)="reclassifySucceeded=false"></button>
      We have reclassified this subject child.
    </div>
  </ng-template>
  <div *ngIf="reclassifyFailed; then ifReclassifyFailed;"></div>
  <ng-template #ifReclassifyFailed>
    <div class="notification is-warning">
      <button class="delete" (click)="reclassifyFailed=false"></button>
      We are sorry. Something went wrong reclassifying this event. Try refreshing the page.
    </div>
  </ng-template>

  <div *ngIf="selectedStatePropertyMap">
    <div class="table-heading">
      <span>Classified States</span>
      <span>{{ selectedStateName }} for {{ subjectUserName }} ({{ subjectUserId }})</span>
    </div>
    <div class="content-shaded">
      <loading-pane [tracker]="tracker">
        <app-view-display-area [valueDisplayMap]="selectedStatePropertyMap" [id]="subjectUserId" objectType="TssSubjectUserState" showRulesFields="true"></app-view-display-area>

        <a class="button" (click)="reclassify()">Recalculate Classified States</a>
      </loading-pane>
    </div>
    <div class="notification is-information info">
      <div style="padding: 1rem 1rem">
        The Task Subsystem provides the ability to model many 'States'. Currently, only one has been implemented - the 'Home Visit' state. 
        This data reflects values derived by application of Home Visit business rules for the given subject child. 
        Below this will appear any old and new home visit tasks that have or need to occur.
      </div>
    </div>

    <div class="table-heading table-alt-heading mt-6" *ngIf="sourceData && sourceData.mostRecentSubjectUser && sourceData.mostRecentSubjectUser.subjectUserId == subjectUserId">
      <span>TSS Values - Derived from Business Rules </span>
    </div>
    <div class="notification is-information info">
      <div style="padding: 1rem 1rem">
        The below rules show how the Task Subsytem derived the above rules-driven properties. That is, those properties highlighted with a purple traffic light.
      </div>
    </div>
    <div>
      <div *ngIf="sourceData && sourceData.ruleDrivers">
        <app-view-rules-drivers [ruleResults]="sourceData.ruleDrivers" [ruleDisplayType]=1></app-view-rules-drivers>
      </div>
    </div>
  </div>
</div>
