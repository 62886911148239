<li>
    <div class="field box">
        <h3><a class="button is-size-7" (click)="toggleSubjectSearch()">{{ showingSubjectIcon }}</a> Find by Subject Id</h3>
        <div [ngClass]="{'is-hidden-tablet is-hidden-touch': !showingSubjectSearch, 'column': true, 'is-12': true}">
            <div id="content">
                <div class="help-tip">
                    <p>
                        Search for a specific subject such as a subject child. <br />
                        When searching for a subject-child this will find all events owned by the subject child, and also all related events. <br />
                        When searching for a non-subject-child this will find all events on which this subject is mentioned. <br />
                    </p>
                </div>
                <h4>Subject Id</h4>
                <p>
                    <input class="input is-primary" ng-model="subjectId" type="text" placeholder="Subject Id to Search For" #subjectId (keyup.enter)="onSubjectIdSelected(subjectId.value)" (blur)="updateSubjectUserId(subjectId.value)" tabindex="1">
                </p>
                <p class="pt-4">
                    <a class="button refresh" (click)="runSubjectUserIdSearch()" tabindex="2" (keyup.enter)="runSubjectUserIdSearch()">Search</a>
                </p>

                <div *ngIf="showSearchRequiresId; then ifNoEventOrFormId;"></div>
                <ng-template #ifNoEventOrFormId>
                  <div class="notification is-warning mt5">
                      <div class="columns">
                          <div class="column is-10">
                            Please enter a subject user id before selecting this search option.
                          </div>
                          <div class="column is-2">
                              <button class="delete button close" (click)="showSearchRequiresId=false" tabindex="3"></button>
                          </div>
                      </div>
                  </div>
                </ng-template>
            </div>
        </div>
    </div>
</li>
