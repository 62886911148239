import { Component, OnInit, HostListener } from '@angular/core';
import { TssRootItemService } from '../../services/tss-root-item.service';
import { WorkTracker } from '../../components/shared/loading-pane/work-tracker.service';

@Component({
  selector: 'app-api-explorer',
  templateUrl: './api-explorer.component.html',
  styleUrls: ['./api-explorer.component.scss']
})
export class ApiExplorerComponent implements OnInit {
  dataLoading: boolean;
  dataToShow: string;
  placementEventId: number;
  error: string;
  tracker: WorkTracker;
  largeView: boolean;

  constructor(private tssRootItemService: TssRootItemService) {
    this.tracker = new WorkTracker(true);
  }

  ngOnInit() {
    this.placementEventId = 0;
    this.dataLoading = false;
    this.largeView = window.innerWidth > 900;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.largeView = window.innerWidth > 900;
  }

  async onSubmit() {
    // Clear any existing error as we are trying again
    this.error = null;
    this.dataLoading = true;

    try {
      await this.tracker.track(this.tssRootItemService.getPlacementDetails('' + this.placementEventId).then(result => {
        this.dataLoading = false;
        this.error = null;
        if(!result.hasError)
        {
          // this.syntaxHighlight(JSON.stringify(result.value, undefined, 4));
          this.dataToShow = this.syntaxHighlight(JSON.stringify(result.value, undefined, 4));
        }
        else
        {
          this.dataToShow = 'Something went wrong';
        }

      }));
    } catch (error) {
      console.error(error);
      this.dataLoading = false;
    }
  }

  // Courtesy: https://stackoverflow.com/questions/4810841/pretty-print-json-using-javascript
  syntaxHighlight(json) {
    if (typeof json != 'string') {
         json = JSON.stringify(json, undefined, 2);
    }
    json = json.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;');
    return json.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g, function (match) {
        return '<span>' + match + '</span>';
    });
}
}