import { Injectable } from '@angular/core';
import * as moment from 'moment';
import * as codeTables  from '../../api/icms_code_tables';

@Injectable({
  providedIn: 'root'
})
export class DisplayMapperService {
  ItemDetailPrefix: string;
  TssStatePrefix: string;
  TssToDoPrefix: string;
  TssSubjectUserPrefix: string;

  constructor() {
    this.ItemDetailPrefix = 'TssDerivedDetail';
    this.TssStatePrefix = 'TssSubjectUserState';
    this.TssToDoPrefix = 'TssSubjectUserStateTask';
    this.TssSubjectUserPrefix = 'TssSourceSubjectUser';
  }

  // Generate the field name that the server will recognise in order to lookup the calculated field description
  getFieldName(objectType: string, lookupValue: string): string {
    const prefix = objectType + '.';

    // Most display fields are simply the variable name without spaces.
    const response = lookupValue.replace(/ /g, '');

    return prefix + response;
  }

  getTasksMap(task: Api.TssSubjectUserStateTask): Array<Api.DisplayLookupInstance> {
    if (!task || !task.dueDtm) {
      return null;
    }
    const response = new Array<Api.DisplayLookupInstance>();
    const taskDate = moment(task.dueDtm).format('YYYY-MM-DD');

    this.addInstance(response, 'Current Stage', (task.currentStage ? task.currentStage.toString() : ''), 'Current Stage@' + taskDate, true);
    this.addInstance(response, 'Due Date', (task.dueDtm ? moment(task.dueDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Due Dtm@' + taskDate, true);
    this.addInstance(response, 'Frequency', this.getFrequencyDescription(task.frequency), 'Frequency@' + taskDate, true);
    this.addInstance(response, 'Is Complete', task.isComplete.toString(), 'Is Complete@' + taskDate, true);
    this.addInstance(response, 'Is Invalid', task.isInvalid.toString(), 'Is Invalid@' + taskDate, true);
    this.addInstance(response, 'Is Obsolete', task.isObsolete.toString(), 'Is Obsolete@' + taskDate, true);
    this.addInstance(response, 'Is Overdue High Priority', task.isOverdueHighPriority.toString(), 'Is Overdue High Priority@' + taskDate, true);
    this.addInstance(response, 'Is Overdue Moderate Priority', task.isOverdueModeratePriority.toString(), 'Is Overdue Moderate Priority@' + taskDate, true);
    this.addInstance(response, 'Last Owner', task.lastOwner, 'Last Owner@' + taskDate, true);
    this.addInstance(response, 'Owner Manager', task.owner, 'Owner@' + taskDate, true);
    this.addInstance(response, 'Priority', (task.priority ? task.priority.toString() : ''), 'Priority@' + taskDate, true);
    this.addInstance(response, 'Processing Status', (task.processingStatus ? task.processingStatus.toString() : ''), 'Processing Status@' + taskDate, true);
    this.addInstance(response, 'TSS Generated Id', task.id, 'Id@' + taskDate, true);
    this.addInstance(response, 'TSS Record Created Date', (task.recordCreatedDtm ? moment(task.recordCreatedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Record Created Dtm@' + taskDate, true);
    this.addInstance(response, 'TSS Record Last Updated Date', (task.recordLastUpdatedDtm ? moment(task.recordLastUpdatedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Record Last Updated Dtm@' + taskDate, true);

    if (task.derivedStateTaskSteps) {
      for (const taskStep of task.derivedStateTaskSteps) {
        const stage = taskStep.stage === 1 ? 'Requires Booking' :
          taskStep.stage === 2 ? 'Requires Visit' :
          taskStep.stage === 3 ? 'Requires Case Note' :
          taskStep.stage === 4 ? 'Invalid' : 'Complete';

        let description = 'Due: ' + moment(taskStep.dueDtm).format('YYYY-MM-DD HH:mm:ss') + '<br />';
        if (taskStep.actionedByUpn) {
          description += 'Actioned Dtm: ' + moment(taskStep.actionedDtm).format('YYYY-MM-DD HH:mm:ss') + '<br />';
          description += 'Actioned By: ' + taskStep.actionedByUpn;
        }

        this.addInstance(response, 'Step - ' + stage, description, 'Step - ' + stage + '@' + taskDate, true);
      }
    }

    return response;
  }

  getFrequencyDescription(frequencyInt: number) {
    let frequency = 'Unknown';
    if (frequencyInt >= 0) {
      frequency = frequencyInt === 0 ? 'Weekly' :
      frequencyInt === 1 ? 'Fortnightly' :
      frequencyInt === 2 ? 'Monthly' :
      frequencyInt === 3 ? 'Annually' : 'n/a';
    }
    return frequency;
  }

  getSubjectUserStateMap(itemState: Api.TssSubjectUserState): Array<Api.DisplayLookupInstance> {
    if (!itemState || !itemState.stateType) {
      return null;
    }
    const response = new Array<Api.DisplayLookupInstance>();

    this.addInstance(response, 'Anchor Date', (itemState.anchorDtm ? moment(itemState.anchorDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Anchor Dtm - State: ' + itemState.stateType, true, true);
    this.addInstance(response, 'Current Home Visit Frequency', this.getFrequencyDescription(itemState.frequency), 'Frequency - State: ' + itemState.stateType, true, true);
    this.addInstance(response, 'Count of Tasks To Generate', (itemState.countOfTasksToGenerate ? itemState.countOfTasksToGenerate.toString() : ''), 'Count Of Tasks To Generate - State: ' + itemState.stateType, true);
    this.addInstance(response, 'State Type', (itemState.stateType ? itemState.stateType.toString() : ''), 'State Type - State: ' + itemState.stateType, true);
    this.addInstance(response, 'TSS Generated Id', itemState.id, 'Id - State: ' + itemState.stateType, true);
    this.addInstance(response, 'TSS Record Created Date', (itemState.recordCreatedDtm ? moment(itemState.recordCreatedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Record Created Dtm - State: ' + itemState.stateType, true);
    this.addInstance(response, 'TSS Record Last Updated Date', (itemState.recordLastUpdatedDtm ? moment(itemState.recordLastUpdatedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Record Last Updated Dtm - State: ' + itemState.stateType, true);
    
    return response;
  }

  getBusinessLogicDetailPropertyMap(eventDerivedDetail: Api.TssDerivedDetail): Array<Api.DisplayLookupInstance> {
    if (!eventDerivedDetail || !eventDerivedDetail.categorisation) {
      return null;
    }

    const response = new Array<Api.DisplayLookupInstance>();

    this.addInstance(response, 'Archiving Date', (eventDerivedDetail.archivingDt ? moment(eventDerivedDetail.archivingDt).format('YYYY-MM-DD HH:mm:ss') : ''), 'Archiving Dt - Event: ' + eventDerivedDetail.parentEventId, true);
    this.addInstance(response, 'Categorisation', eventDerivedDetail.categorisation, 'Categorisation - Event: ' + eventDerivedDetail.parentEventId, true);
    this.addInstance(response, 'Enable Refresh', eventDerivedDetail.enableRefresh.toString(), 'Enable Refresh - Event: ' + eventDerivedDetail.parentEventId, true);
    this.addInstance(response, 'Last Refresh Date', (eventDerivedDetail.lastRefreshDtm ? moment(eventDerivedDetail.lastRefreshDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Last Refresh Dtm - Event: ' + eventDerivedDetail.parentEventId, true);
    this.addInstance(response, 'Refresh Attempt Count', eventDerivedDetail.refreshAttemptCount.toString(), 'Refresh Attempt Count - Event: ' + eventDerivedDetail.parentEventId, true);
    this.addInstance(response, 'Refresh Date', (eventDerivedDetail.refreshDtm ? moment(eventDerivedDetail.refreshDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Refresh Dtm - Event: ' + eventDerivedDetail.parentEventId, true);
    this.addInstance(response, 'Refresh Frequency Days', (eventDerivedDetail.refreshFrequencyDays ? eventDerivedDetail.refreshFrequencyDays.toString() : ''), 'Refresh Frequency Days - Event: ' + eventDerivedDetail.parentEventId, true);
    this.addInstance(response, 'Refresh Reason', eventDerivedDetail.refreshReason.toString(), 'Refresh Reason - Event: ' + eventDerivedDetail.parentEventId, true);
    this.addInstance(response, 'Restrict Presentation', eventDerivedDetail.restrictPresentation.toString(), 'Restrict Presentation - Event: ' + eventDerivedDetail.parentEventId, true);
    this.addInstance(response, 'Source System Name', eventDerivedDetail.sourceSystemName, 'Source System Name - Event: ' + eventDerivedDetail.parentEventId, true);
    // Note: This object has no Id property.
    this.addInstance(response, 'TSS Record Created Date', (eventDerivedDetail.recordCreatedDtm ? moment(eventDerivedDetail.recordCreatedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Record Created Dtm - Event: ' + eventDerivedDetail.parentEventId, true);
    this.addInstance(response, 'TSS Record Last Updated Date', (eventDerivedDetail.recordLastUpdatedDtm ? moment(eventDerivedDetail.recordLastUpdatedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Record Last Updated Dtm - Event: ' + eventDerivedDetail.parentEventId, true);

    return response;
  }

  // Safe lookup for a code table value and its related description. Return the lookup value if a code table entry is not found
  getDisplayValue(outFunc : (value: string) => any, lookupValue: string) : string
  {
    var lookupResult = outFunc(lookupValue);
    if(lookupResult && lookupResult.description)
    {
      return lookupResult.description + ' (' + lookupValue + ')';
    }
    return lookupValue;
  }

  getSourceFormMap(sourceForm: Api.TssSourceForm, subjectChildOwnerId: string, subjectChildOwnerName: string): Array<Api.DisplayLookupInstance> {
      if (!sourceForm || !sourceForm.id) {
        return null;
      }

      const response = new Array<Api.DisplayLookupInstance>();

      var statusDescription = this.getDisplayValue(codeTables.getFormInstanceStatusGroupDescriptor, sourceForm.status);
      var sourceSystemType = this.getDisplayValue(codeTables.getFormTypeGroupDescriptor, sourceForm.sourceSystemType);
      var team = this.getDisplayValue(codeTables.getDepartmentUnitGroupDescriptor, sourceForm.team);

      this.addInstance(response, 'Owned by Subject Child', subjectChildOwnerId + ' (' + subjectChildOwnerName + ')', 'Owned by Subject Child');

      this.addInstance(response, 'BusinessValue', sourceForm.businessValue, 'BusinessValue');
      this.addInstance(response, 'Category', sourceForm.category, 'Category');
      this.addInstance(response, 'Complexity', sourceForm.complexity, 'Complexity');
      this.addInstance(response, 'Correlation Id', (sourceForm.correlationId ? sourceForm.correlationId : ''), 'Correlation Id');
      this.addInstance(response, 'Destination Path', sourceForm.destinationPath, 'Destination Path');
      this.addInstance(response, 'Id', sourceForm.id, 'Id');
      this.addInstance(response, 'Item Completed Date', (sourceForm.itemCompletedDate ? moment(sourceForm.itemCompletedDate).format('YYYY-MM-DD HH:mm:ss') : ''), 'Item Completed Dtm');
      this.addInstance(response, 'Item Create User', sourceForm.itemCreateUser, 'Item Create User');
      this.addInstance(response, 'Item Created Date', (sourceForm.itemCreatedDtm ? moment(sourceForm.itemCreatedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Item Created Dtm');
      this.addInstance(response, 'Item Deleted Date', (sourceForm.itemDeletedDtm ? moment(sourceForm.itemDeletedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Item Deleted Dtm');
      this.addInstance(response, 'Item Ended Date', (sourceForm.itemEndedDtm ? moment(sourceForm.itemEndedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Item Ended Dtm');
      this.addInstance(response, 'Item Expired Date', (sourceForm.itemExpiredDate ? moment(sourceForm.itemExpiredDate).format('YYYY-MM-DD HH:mm:ss') : ''), 'Item Expired Dtm');
      this.addInstance(response, 'Item Last Updated Date', (sourceForm.itemLastUpdatedDtm ? moment(sourceForm.itemLastUpdatedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Item Last Updated Dtm');
      this.addInstance(response, 'Item Last Updated User', sourceForm.itemLastUpdatedUser, 'Item Last Updated User');
      this.addInstance(response, 'Item Started Date', (sourceForm.itemStartedDtm ? moment(sourceForm.itemStartedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Item Started Dtm');
      this.addInstance(response, 'Org', sourceForm.org, 'Org');
      this.addInstance(response, 'Owner', sourceForm.owner, 'Owner');
      this.addInstance(response, 'Parent Event Id', sourceForm.parentEventId, 'Parent Event Id');
      this.addInstance(response, 'Priority', (sourceForm.priority ? sourceForm.priority.toString() : ''), 'Priority');
      this.addInstance(response, 'Result', sourceForm.result, 'Result');
      this.addInstance(response, 'Source System Name', sourceForm.sourceSystemName, 'Source System Name');
      this.addInstance(response, 'Source System Type', sourceSystemType, 'Source System Type');
      this.addInstance(response, 'Status', statusDescription, 'Status');
      this.addInstance(response, 'Sub Type', sourceForm.subType, 'Sub Type');
      this.addInstance(response, 'Team', team, 'Team');
      this.addInstance(response, 'TSS Record Created Date', (sourceForm.recordCreatedDtm ? moment(sourceForm.recordCreatedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Record Created Dtm');
      this.addInstance(response, 'TSS Record Last Updated Date', (sourceForm.recordLastUpdatedDtm ? moment(sourceForm.recordLastUpdatedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Record Last Updated Dtm');

      return response;
  }

  getCPCaseNoteMap(cpCaseNote: Api.TssSourceCPCaseNote): Array<Api.DisplayLookupInstance> {
    const response = new Array<Api.DisplayLookupInstance>();

    const participants = (cpCaseNote.participants ? cpCaseNote.participants.join(', ') : '');

    var caseNoteType = this.getDisplayValue(codeTables.getCaseNoteDisplayGroupDescriptor, cpCaseNote.type);

    this.addInstance(response, 'Date', (cpCaseNote.date ? moment(cpCaseNote.date).format('YYYY-MM-DD HH:mm:ss') : ''), 'Date');
    this.addInstance(response, 'Participants', participants, 'Participants');
    this.addInstance(response, 'Type', caseNoteType, 'Type');
    this.addInstance(response, 'TSS Record Created Date', (cpCaseNote.recordCreatedDtm ? moment(cpCaseNote.recordCreatedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Record Created Dtm');
    this.addInstance(response, 'TSS Record Last Updated Date', (cpCaseNote.recordLastUpdatedDtm ? moment(cpCaseNote.recordLastUpdatedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Record Last Updated Dtm');

    return response;
  }

  getFamilyRiskEvalMap(familyRiskEval: Api.TssSourceFamilyRiskEval): Array<Api.DisplayLookupInstance> {
    const response = new Array<Api.DisplayLookupInstance>();

    const participants = (familyRiskEval.participants ? familyRiskEval.participants.join(', ') : '');

    var status = this.getDisplayValue(codeTables.getFormInstanceStatusGroupDescriptor, familyRiskEval.status);

    this.addInstance(response, 'Recorded Date', (familyRiskEval.recordedDate ? moment(familyRiskEval.recordedDate).format('YYYY-MM-DD HH:mm:ss') : ''), 'Recorded Date');
    this.addInstance(response, 'Status', status, 'Status');
    this.addInstance(response, 'Participants', participants, 'Participants');
    this.addInstance(response, 'TSS Record Created Date', (familyRiskEval.recordCreatedDtm ? moment(familyRiskEval.recordCreatedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Record Created Dtm');
    this.addInstance(response, 'TSS Record Last Updated Date', (familyRiskEval.recordLastUpdatedDtm ? moment(familyRiskEval.recordLastUpdatedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Record Last Updated Dtm');

    return response;
  }

  getFamilyReunificationAssessmentMap(familyReunificationAssessment: Api.TssSourceFamilyReunificationAssessment): Array<Api.DisplayLookupInstance> {
    const response = new Array<Api.DisplayLookupInstance>();

    const participants = (familyReunificationAssessment.participants ? familyReunificationAssessment.participants.join(', ') : '');

    var status = this.getDisplayValue(codeTables.getFormInstanceStatusGroupDescriptor, familyReunificationAssessment.status);

    this.addInstance(response, 'Behaviour Indicators Flag', familyReunificationAssessment.behaviourIndicatorsFlag, 'Behaviour Indicators Flag');
    this.addInstance(response, 'Domestic Violence Danger Flag', familyReunificationAssessment.domesticViolenceDangerFlag, 'Domestic Violence Danger Flag');
    this.addInstance(response, 'Expiry Date', (familyReunificationAssessment.expiryDate ? moment(familyReunificationAssessment.expiryDate).format('YYYY-MM-DD HH:mm:ss') : ''), 'Expiry Date');
    this.addInstance(response, 'Final Permanency Plan Rec', familyReunificationAssessment.finalPermanencyPlanRec, 'Final Permanency Plan Rec');
    this.addInstance(response, 'Immediate Needs Unmet Flag', familyReunificationAssessment.immediateNeedsUnmetFlag, 'Immediate Needs Unmet Flag');
    this.addInstance(response, 'Indicate Permanency Plan', familyReunificationAssessment.indicatePermanencyPlan, 'Indicate Permanency Plan');
    this.addInstance(response, 'Initial Application Date', (familyReunificationAssessment.initialApplicationDate ? moment(familyReunificationAssessment.initialApplicationDate).format('YYYY-MM-DD HH:mm:ss') : ''), 'Initial Application Date');
    this.addInstance(response, 'Injury Safety Flag', familyReunificationAssessment.injurySafetyFlag, 'Injury Safety Flag');
    this.addInstance(response, 'Living Conditions Hazardous Flag', familyReunificationAssessment.livingConditionsHazardousFlag, 'Living Conditions Hazardous Flag');
    this.addInstance(response, 'Other Flag', familyReunificationAssessment.otherFlag, 'Other Flag');
    this.addInstance(response, 'Parent Drug Misuse Flag', familyReunificationAssessment.parentDrugMisuseFlag, 'Parent Drug Misuse Flag');
    this.addInstance(response, 'Parent Impaired Functioning Flag', familyReunificationAssessment.parentImpairedFunctioningFlag, 'Parent Impaired Functioning Flag');
    this.addInstance(response, 'Parent Protection Failure Flag', familyReunificationAssessment.parentProtectionFailureFlag, 'Parent Protection Failure Flag');
    this.addInstance(response, 'Participants', participants, 'Participants');
    this.addInstance(response, 'Physical Harm Flag', familyReunificationAssessment.physicalHarmFlag, 'Physical Harm Flag');
    this.addInstance(response, 'Refused Access Flag', familyReunificationAssessment.refusedAccessFlag, 'Refused Access Flag');
    this.addInstance(response, 'Safety Level', familyReunificationAssessment.safetyLevel, 'Safety Level');
    this.addInstance(response, 'Sexual Abuse Suspected Flag', familyReunificationAssessment.sexualAbuseSuspectedFlag, 'Sexual Abuse Suspected Flag');
    this.addInstance(response, 'Status', status, 'Status');
    this.addInstance(response, 'Threat Pattern Flag', familyReunificationAssessment.threatPatternFlag, 'Threat Pattern Flag');
    this.addInstance(response, 'TSS Record Created Date', (familyReunificationAssessment.recordCreatedDtm ? moment(familyReunificationAssessment.recordCreatedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Record Created Dtm');
    this.addInstance(response, 'TSS Record Last Updated Date', (familyReunificationAssessment.recordLastUpdatedDtm ? moment(familyReunificationAssessment.recordLastUpdatedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Record Last Updated Dtm');

    return response;
  }

  getFamilyRiskReEvaluationInHomeCaseMap(familyRiskReEvaluationInHomeCase: Api.TssSourceFamilyRiskReEvaluationInHomeCase): Array<Api.DisplayLookupInstance> {
    const response = new Array<Api.DisplayLookupInstance>();

    const participants = (familyRiskReEvaluationInHomeCase.participants ? familyRiskReEvaluationInHomeCase.participants.join(', ') : '');

    this.addInstance(response, 'Commence Date', (familyRiskReEvaluationInHomeCase.commenceDate ? moment(familyRiskReEvaluationInHomeCase.commenceDate).format('YYYY-MM-DD HH:mm:ss') : ''), 'Commence Dtm');
    this.addInstance(response, 'Complete Date', (familyRiskReEvaluationInHomeCase.completeDate ? moment(familyRiskReEvaluationInHomeCase.completeDate).format('YYYY-MM-DD HH:mm:ss') : ''), 'Complete Dtm');
    this.addInstance(response, 'Decision', familyRiskReEvaluationInHomeCase.decision, 'Decision');
    this.addInstance(response, 'Participants', participants, 'Participants');
    this.addInstance(response, 'TSS Record Created Date', (familyRiskReEvaluationInHomeCase.recordCreatedDtm ? moment(familyRiskReEvaluationInHomeCase.recordCreatedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Record Created Dtm');
    this.addInstance(response, 'TSS Record Last Updated Date', (familyRiskReEvaluationInHomeCase.recordLastUpdatedDtm ? moment(familyRiskReEvaluationInHomeCase.recordLastUpdatedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Record Last Updated Dtm');

    return response;
  }

  // Retrieve a map of display field names and values
  // This will exclude the following properties:
  //   childItems:TssItemChild[];itemDetail:TssItemDetail;itemState:TssState[];extensions:TssEventExtension;
  getsourceEventMap(sourceEventData: Api.ChildSourceEvent): Array<Api.DisplayLookupInstance> {
    if (!sourceEventData || !sourceEventData.sourceEvent || !sourceEventData.sourceEvent.id) {
      return null;
    }

    const response = new Array<Api.DisplayLookupInstance>();
    var sourceEvent = sourceEventData.sourceEvent;

    var status = this.getDisplayValue(codeTables.getEventStatusGroupDescriptor, sourceEvent.status);
    var eventType = this.getDisplayValue(codeTables.getEventTypeGroupDescriptor, sourceEvent.sourceSystemType);
    var team = this.getDisplayValue(codeTables.getDepartmentUnitGroupDescriptor, sourceEvent.team);
    
    this.addInstance(response, 'Owned by Subject Child', sourceEventData.subjectChildId + ' (' + sourceEventData.subjectChildName + ')', 'Owned by Subject Child');

    this.addInstance(response, 'BusinessValue', sourceEvent.businessValue, 'BusinessValue');
    this.addInstance(response, 'Category', sourceEvent.category, 'Category');
    this.addInstance(response, 'Complexity', sourceEvent.complexity, 'Complexity');
    this.addInstance(response, 'Correlation Id', (sourceEvent.correlationId ? sourceEvent.correlationId : ''), 'Correlation Id');
    this.addInstance(response, 'Destination Path', sourceEvent.destinationPath, 'Destination Path');
    this.addInstance(response, 'Extension Type', sourceEvent.extensionType, 'Extension Type');
    this.addInstance(response, 'Id', sourceEvent.id, 'Id');
    this.addInstance(response, 'Item Completed Date', (sourceEvent.itemCompletedDate ? moment(sourceEvent.itemCompletedDate).format('YYYY-MM-DD HH:mm:ss') : ''), 'Item Completed Date');
    this.addInstance(response, 'Item Create User', sourceEvent.itemCreateUser, 'Item Create User');
    this.addInstance(response, 'Item Created Date', (sourceEvent.itemCreatedDtm ? moment(sourceEvent.itemCreatedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Item Created Dtm');
    this.addInstance(response, 'Item Deleted Date', (sourceEvent.itemDeletedDtm ? moment(sourceEvent.itemDeletedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Item Deleted Dtm');
    this.addInstance(response, 'Item Ended Date', (sourceEvent.itemEndedDtm ? moment(sourceEvent.itemEndedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Item Ended Dtm');
    this.addInstance(response, 'Item Expired Date', (sourceEvent.itemExpiredDate ? moment(sourceEvent.itemExpiredDate).format('YYYY-MM-DD HH:mm:ss') : ''), 'Item Expired Date');
    this.addInstance(response, 'Item Last Updated Date', (sourceEvent.itemLastUpdatedDtm ? moment(sourceEvent.itemLastUpdatedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Item Last Updated Dtm');
    this.addInstance(response, 'Item Last Updated User', sourceEvent.itemLastUpdatedUser, 'Item Last Updated User');
    this.addInstance(response, 'Item Started Date', (sourceEvent.itemStartedDtm ? moment(sourceEvent.itemStartedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Item Started Dtm');
    this.addInstance(response, 'Next Event Ids', sourceEvent.nextId.join(', '), 'Next Id');
    this.addInstance(response, 'Org', sourceEvent.org, 'Org');
    this.addInstance(response, 'Owner', sourceEvent.owner, 'Owner');
    this.addInstance(response, 'Previous Event Ids', sourceEvent.previousId.join(', '), 'Previous Id');
    this.addInstance(response, 'Priority', (sourceEvent.priority ? sourceEvent.priority.toString() : ''), 'Priority');
    this.addInstance(response, 'Result', sourceEvent.result, 'Result');
    this.addInstance(response, 'Source System Name', sourceEvent.sourceSystemName, 'Source System Name');
    this.addInstance(response, 'Source System Type', eventType, 'Source System Type');
    this.addInstance(response, 'Status', status, 'Status');
    this.addInstance(response, 'Sub Type', sourceEvent.subType, 'Sub Type');
    this.addInstance(response, 'Team', team, 'Team');
    this.addInstance(response, 'TSS Record Created Date', (sourceEvent.recordCreatedDtm ? moment(sourceEvent.recordCreatedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Record Created Dtm');
    this.addInstance(response, 'TSS Record Last Updated Date', (sourceEvent.recordLastUpdatedDtm ? moment(sourceEvent.recordLastUpdatedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Record Last Updated Dtm');

    return response;
  }

  getSubjectUserMap(subjectUser: Api.TssSourceSubjectUser): Array<Api.DisplayLookupInstance> {
    if (!subjectUser) {
      return null;
    }
    const response = new Array<Api.DisplayLookupInstance>();

    // Only show descriptions as being available for the subject child (we don't classify other subject users)
    const hasDescriptions = true;

    var role = this.getDisplayValue(codeTables.getEventRoleGroupDescriptor, subjectUser.role);

    this.addInstance(response, 'End Date', (subjectUser.endDate ? moment(subjectUser.endDate).format('YYYY-MM-DD HH:mm:ss') : ''), 'End Date');
    this.addInstance(response, 'Family Name', (subjectUser.familyName ? subjectUser.familyName : ''), 'Family Name');
    this.addInstance(response, 'Given Name', (subjectUser.givenName ? subjectUser.givenName : ''), 'Given Name');
    this.addInstance(response, 'Location Type', (subjectUser.locationType ? subjectUser.locationType : ''), 'Location Type', true, true);
    this.addInstance(response, 'Management Type', (subjectUser.managementType ? subjectUser.managementType : ''), 'Management Type', true, true);
    this.addInstance(response, 'Processing Status', (subjectUser.processingStatus ? subjectUser.processingStatus : ''), 'Processing Status');
    this.addInstance(response, 'Reclassify Date', (subjectUser.reclassifyDtm ? moment(subjectUser.reclassifyDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Reclassify Dtm');
    this.addInstance(response, 'Reprioritise Date', (subjectUser.reprioritiseDtm ? moment(subjectUser.reprioritiseDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Reprioritise Dtm');
    this.addInstance(response, 'Role', role, 'Role');
    this.addInstance(response, 'Subject User Id', (subjectUser.subjectUserId ? subjectUser.subjectUserId : ''), 'Subject User Id');
    this.addInstance(response, 'TSS Generated Id', subjectUser.id, 'Id');
    this.addInstance(response, 'TSS Record Created Date', (subjectUser.recordCreatedDtm ? moment(subjectUser.recordCreatedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Record Created Dtm');
    this.addInstance(response, 'TSS Record Last Updated Date', (subjectUser.recordLastUpdatedDtm ? moment(subjectUser.recordLastUpdatedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Record Last Updated Dtm');

    return response;
  }

  getCaseAllocationMap(caseAllocation: Api.TssSourceCaseAllocation): Array<Api.DisplayLookupInstance> {
    if (!caseAllocation) {
      return null;
    }
    const response = new Array<Api.DisplayLookupInstance>();

    var allocation = this.getDisplayValue(codeTables.getCaseAllocationGroupDescriptor, caseAllocation.allocationType);
    var managementType = this.getDisplayValue(codeTables.getCaseManagementGroupDescriptor, caseAllocation.managementType);

    this.addInstance(response, 'Allocation Type', allocation, 'Allocation Type');
    this.addInstance(response, 'Business User Id', (caseAllocation.businessUserId ? caseAllocation.businessUserId : ''), 'Business User Id');
    this.addInstance(response, 'End Date', (caseAllocation.endDate ? moment(caseAllocation.endDate).format('YYYY-MM-DD HH:mm:ss') : ''), 'End Date');
    this.addInstance(response, 'Management Type', managementType, 'Management Type');
    this.addInstance(response, 'Start Date', (caseAllocation.startDate ? moment(caseAllocation.startDate).format('YYYY-MM-DD HH:mm:ss') : ''), 'Start Date');
    this.addInstance(response, 'TSS Generated Id', caseAllocation.id, 'Id');
    this.addInstance(response, 'TSS Record Created DateTime', (caseAllocation.recordCreatedDtm ? moment(caseAllocation.recordCreatedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Record Created Dtm');
    this.addInstance(response, 'TSS Record Last Updated DateTime', (caseAllocation.recordLastUpdatedDtm ? moment(caseAllocation.recordLastUpdatedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Record Last Updated Dtm');

    return response;
  }

  getPlacementMap(placement: Api.TssSourcePlacement): Array<Api.DisplayLookupInstance> {
    if (!placement) {
      return null;
    }
    const response = new Array<Api.DisplayLookupInstance>();

    var placementType = this.getDisplayValue(codeTables.getPlacementTypeGroupDescriptor, placement.placementType);

    this.addInstance(response, 'End Date', (placement.endDate ? moment(placement.endDate).format('YYYY-MM-DD HH:mm:ss') : ''), 'End Date');
    this.addInstance(response, 'Event ID', (placement.eventID ? placement.eventID.toString() : ''), 'Event ID');
    this.addInstance(response, 'Placement ID', (placement.placementID ? placement.placementID.toString() : ''), 'Placement ID');
    this.addInstance(response, 'Placement Type', placementType, 'Placement Type');
    this.addInstance(response, 'Start Date', (placement.startDate ? moment(placement.startDate).format('YYYY-MM-DD HH:mm:ss') : ''), 'Start Date');
    this.addInstance(response, 'TSS Generated Id', placement.id, 'Id');
    this.addInstance(response, 'TSS Record Created DateTime', (placement.recordCreatedDtm ? moment(placement.recordCreatedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Record Created Dtm');
    this.addInstance(response, 'TSS Record Last Updated DateTime', (placement.recordLastUpdatedDtm ? moment(placement.recordLastUpdatedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Record Last Updated Dtm');

    return response;
  }

  getCourtOrderMap(courtOrder: Api.TssSourceCourtOrder): Array<Api.DisplayLookupInstance> {
    if (!courtOrder) {
      return null;
    }
    const response = new Array<Api.DisplayLookupInstance>();

    this.addInstance(response, 'Court', (courtOrder.court ? courtOrder.court : ''), 'Court');
    this.addInstance(response, 'Expiry Date', (courtOrder.expiryDate ? moment(courtOrder.expiryDate).format('YYYY-MM-DD HH:mm:ss') : ''), 'Expiry Date');
    this.addInstance(response, 'Order Type', (courtOrder.orderType ? courtOrder.orderType : ''), 'Order Type');
    this.addInstance(response, 'Order Type Display', (courtOrder.orderTypeDisplay ? courtOrder.orderTypeDisplay : ''), 'Order Type Display');
    this.addInstance(response, 'Start Date', (courtOrder.startDate ? moment(courtOrder.startDate).format('YYYY-MM-DD HH:mm:ss') : ''), 'Start Date');
    this.addInstance(response, 'TSS Generated Id', courtOrder.id, 'Id');
    this.addInstance(response, 'TSS Record Created DateTime', (courtOrder.recordCreatedDtm ? moment(courtOrder.recordCreatedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Record Created Dtm');
    this.addInstance(response, 'TSS Record Last Updated DateTime', (courtOrder.recordLastUpdatedDtm ? moment(courtOrder.recordLastUpdatedDtm).format('YYYY-MM-DD HH:mm:ss') : ''), 'Record Last Updated Dtm');

    return response;
  }

  addInstance(collection: Array<Api.DisplayLookupInstance>, name: string, value: string, lookup: string, rulesField: boolean = false, showTrafficLights: boolean = false)
  {
    collection.push({
      displayName: name, 
      displayValue: value, 
      lookupName: lookup, 
      rulesDerivedField: rulesField, 
      showRulesTrafficLights: showTrafficLights
    });
  }
}
