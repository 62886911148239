import { Component, OnInit, Input } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-view-tss-state-tasks',
  templateUrl: './view-tss-state-tasks.component.html',
  styleUrls: ['./view-tss-state-tasks.component.scss']
})
export class ViewTssStateTasksComponent implements OnInit {
  @Input() itemStates: Api.TssSubjectUserState[];
  @Input() id: string;

  tasks: Api.TssSubjectUserStateTask[];
  pagedTasksToShow: Api.TssSubjectUserStateTask[];

  // A technique to track which items are selected to be expanded
  selectedTasks: Api.TssSubjectUserStateTask[] = [];

  public stateNames: string[];
  selectedStateName: string;
  selectedState: Api.TssSubjectUserState;

  allSelection: string;
  public taskDueDate: string[];
  selectedTaskDueDate: string;

  sortTasksInAscendingOrder: boolean;
  orderBySelectedValue: string;
  orderBy: string[];
  orderPrecedence: string[];

  // Pagination settings
  currentPage: number = 0;
  pageSize: number = 10; // This is communicated from the pagination component
  taskCount: number = 0;
  homeVisitsStyle: string = 'home-visits';

  constructor() {
  }

  ngOnInit() {
    this.allSelection = '--All--';
    this.selectedTaskDueDate = this.allSelection;
    if (this.itemStates) {
      this.initialiseStateNames();
    }

    this.sortTasksInAscendingOrder = true;
    this.orderBySelectedValue = 'Due Date';
    this.orderBy = ['Due Date', 'Priority'];
    this.orderPrecedence = ['ASC', 'DESC'];
    this.runSort();
  }
  
  initialiseStateNames() {
    this.stateNames = [];
    if (this.itemStates) {
      for (let i = 0; i < this.itemStates.length; i++) {
        //console.log('this.itemStates[i].stateType: ' + this.itemStates[i].stateType);
        const stateType = this.itemStates[i].stateType;
        this.stateNames.push(stateType);
        if (i === 0) {
          this.stateSelected(stateType);
        }
      }
    }
  }

  stateSelected(selection: string) {
    this.selectedStateName = selection;

    if (this.itemStates) {
      for (const state of this.itemStates) {
        if (this.selectedStateName === state.stateType) {
          this.selectedState = state;
          this.tasks = this.selectedState.derivedStateTasks;
          this.taskCount = (!!this.tasks ? this.tasks.length : 0);
          this.sortTasksByDate();
        }
      }
    }
  }

  runSort()
  {
    if(this.orderBySelectedValue == 'Due Date')
    {
      this.sortTasksByDate();
    }
    else // Priority
    {
      this.sortTasksByPriority();
    }
    this.onPageChangedEvent(0);
  }

  sortTasksByDate() {
    var vm = this;
    if(!this.tasks) return;
    this.tasks.sort(function (a, b) {
      var aDtm = moment(a.dueDtm);
      var bDtm = moment(b.dueDtm);
      var response = 0;
      if(aDtm.isBefore(bDtm)) {
        response = vm.sortTasksInAscendingOrder ? -1 : 1;
      }
      else if(aDtm.isAfter(bDtm)) {
        response = vm.sortTasksInAscendingOrder ? 1 : -1;
      }
      return response;
    });
  }

  sortTasksByPriority() {
    var vm = this;
    this.tasks.sort(function (a, b) {
      var response = 0;
      if(a.priority < b.priority) {
        response = vm.sortTasksInAscendingOrder ? -1 : 1;
      }
      else if(a.priority > b.priority) {
        response = vm.sortTasksInAscendingOrder ? 1 : -1;
      }
      return response;
    });
  }

  // Driven by selection in UI of which property on which to sort
  orderPropertySelected(selection: string)
  {
    this.orderBySelectedValue = selection;
    this.runSort();
  }

  // Driven by selection in UI of preferred sort order
  precedencePropertySelected(selection: string)
  {
    this.sortTasksInAscendingOrder = selection == 'ASC';
    this.runSort();
  }

  formatDate(date: string): string {
    return moment(date).format('YYYY-MM-DD');
  }

  getFrequencyDescription(frequencyInt: number) {
    let frequency = 'Unknown';
    if (frequencyInt >= 0) {
      frequency = frequencyInt === 0 ? 'Weekly' :
      frequencyInt === 1 ? 'Fortnightly' :
      frequencyInt === 2 ? 'Monthly' :
      frequencyInt === 3 ? 'Annually' : 'n/a';
    }
    return frequency;
  }

  taskSelected(selection: string) {
    this.selectedTaskDueDate = selection;
  }
  /*
  taskIsSelected(keyValue: string)
  {
    return (keyValue && (this.selectedTaskDueDate == this.allSelection || moment(keyValue).format('YYYY-MM-DD HH:mm:ss') == this.selectedTaskDueDate));
  }*/

  toggleSelectedTask(task: Api.TssSubjectUserStateTask): void {
    if (this.isTaskSelected(task)) {
      let foundTask = this.selectedTasks.filter(o => o.id === task.id)[0];
      if (foundTask) {
        const index = this.selectedTasks.indexOf(foundTask, 0);
        if (index > -1) {
          this.selectedTasks.splice(index, 1);
        }
        return;
      }
    }

    this.selectedTasks.push(task);
  }

  isTaskSelected(task: Api.TssSubjectUserStateTask): boolean {
    if (!task || !this.selectedTasks) return false;

    return this.selectedTasks.filter(o => o.id === task.id).length > 0;
  }

  isEven(task: Api.TssSubjectUserStateTask): boolean {
    if (!this.pagedTasksToShow) return false;

    let index = this.pagedTasksToShow.indexOf(task, 0);

    return index % 2 === 0;
  }

  onPageChangedEvent(pageNumber: number)
  {
    this.currentPage = pageNumber;
    this.updatePagedVisitsToShow();
  }

  onPageSizeChangedEvent(pageSize: number)
  {
    this.pageSize = pageSize;
    this.updatePagedVisitsToShow();
  }

  updatePagedVisitsToShow()
  {
    var firstElToShow = this.pageSize * this.currentPage;
    var lastElToShow = firstElToShow + this.pageSize;

    // Update the values that are displayed. All data is already pre-loaded. Just paginate in-memory
    setTimeout(() => {
      if(!this.tasks || this.tasks.length < 1) 
      {
        this.pagedTasksToShow = [];  
      }
      else
      {
        this.pagedTasksToShow = this.tasks.slice(firstElToShow, lastElToShow);
      }
    });
  }
}
