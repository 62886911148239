<div class="details">
  <!-- Cycle through each row of data -->
  <ng-container *ngFor="let instance of displayData; let i = index">
    <div class="row" role="row">
      <div *ngFor="let value of instance.rowData; let i = index" [attr.data-index]="i" aria-hidden="true" class="th" role="columnheader" title="Header Column {{value.displayName}}">
        <span *ngIf="showHeader(value)">
          {{value && value.displayName ? value.displayName : '&nbsp;'}}
        </span>  
      </div>
    </div>
    <div class="row" role="row">
      <div *ngFor="let value of instance.rowData; let i = index" [attr.data-index]="i" class="td" [attr.data-title]="value.displayName" role="cell">
        <div class="visually-hidden" title="Header Column {{ value.displayName }}">{{ value.displayName }}</div>
        <div [ngClass]="{ 'break-word': value && value.displayValue, 'highlight': valueToHighlight && valueToHighlight == value.displayValue }">
          <div class="row" *ngIf="showHomeVisitIcon(value)">
            <div class="rule-column">
              <a [routerLink] = "['/']" [fragment]="getIdReference(value.displayName)" alt="Show Rule Details" data-title="Show Rule Details"> <div [ngClass]="{'circle': true, 'rules': true }"></div> </a>
            </div>
            <div class="rule-column">
              <a [routerLink] = "['/']" [fragment]="getIdReference(value.displayName)" alt="Show Rule Details" data-title="Show Rule Details" [innerHTML]="value && value.displayValue ? value.displayValue : '&nbsp;'"></a>
            </div>
          </div>
          <div *ngIf="showPlainTextValue(value)" [innerHTML]="value && value.displayValue ? value.displayValue : '&nbsp;'">
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template #descriptionContainer></ng-template>
