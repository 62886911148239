import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import './services/utils';
import { RouterModule } from '@angular/router';
import { appRoutes } from './app.routes';
import { StickyDirective } from './shared/directives/Sticky.directive';
import { AppComponent } from './app.component';
import { HeaderComponent, FooterComponent } from './components/page-layout';
import {
  MsalModule,
  MsalService,
  MsalConfig,
  MsalInterceptor
} from './msal-angular';

import {
  EventFormIdService,
  SubjectUserIdService
} from './components/dashboard';

import { SearchSectionOpenedService } from './components/dashboard/search/search-section-opened.service';

import {  MSAL_CONFIG } from './msal-angular/msal.service';
import { LogLevel } from 'msal';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ViewTssRootItemComponent } from './components/dashboard/results/view-tss-root-item/view-tss-root-item.component';
import { ViewTssItemIdsComponent } from './components/dashboard/search/view-tss-item-ids/view-tss-item-ids.component';
import { ViewTssItemIdComponent } from './components/dashboard/search/view-tss-item-id/view-tss-item-id.component';
import { ViewTssSourceFormComponent } from './components/dashboard/results/view-tss-all-events/view-tss-source-form/view-tss-source-form.component';
import { ViewTssFormExtensionComponent } from './components/dashboard/results/view-tss-all-events/view-tss-source-form/view-tss-form-extension/view-tss-form-extension.component';
import { ViewTssEventExtensionComponent } from './components/dashboard/results/view-tss-all-subjects/view-tss-event-extension/view-tss-event-extension.component';
import { ViewTssStateComponent } from './components/dashboard/results/view-tss-all-subjects/view-tss-state/view-tss-state.component';
import { ViewTssStateTasksComponent } from './components/dashboard/results/view-tss-all-subjects/view-tss-state-tasks/view-tss-state-tasks.component';
import { ViewDisplayAreaComponent } from './components/dashboard/results/view-display-area/view-display-area.component';
import { DashboardComponent } from './components/dashboard';
import { DataMigrationComponent } from './components/data-migration/data-migration.component';
import { SiteAdminComponent } from './components/site-admin/site-admin.component';
import { UnauthorisedComponent } from './components/unauthorised/unauthorised.component';
import { AuthComponent } from './components/authentication/auth.component';
import { MainMenuComponent } from './components/page-layout/header/main-menu/main-menu.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { ViewSubjectIdSearchComponent } from './components/dashboard/search/view-subject-id-search/view-subject-id-search.component';
import { ViewItemIdSearchComponent } from './components/dashboard/search/view-item-id-search/view-item-id-search.component';
import { ViewSubjectFilterSearchComponent } from './components/dashboard/search/view-subject-filter-search/view-subject-filter-search.component';
import { ViewTssEventSummaryComponent } from './components/dashboard/results/view-tss-all-subjects/view-tss-event-extension/view-tss-event-summary/view-tss-event-summary.component';
import { LoadingPaneComponent } from './components/shared/loading-pane/loading-pane.component';
import { ViewTssFormSummaryComponent } from './components/dashboard/results/view-tss-all-subjects/view-tss-event-extension/view-tss-form-summary/view-tss-form-summary.component';
import { ViewRulesDriversComponent } from './components/dashboard/results/view-tss-all-subjects/view-tss-event-extension/view-rules-drivers/view-rules-drivers.component';
import { ViewTssAllEventsComponent } from './components/dashboard/results/view-tss-all-events/view-tss-all-events.component';
import { ViewTssAllSubjectsComponent } from './components/dashboard/results/view-tss-all-subjects/view-tss-all-subjects.component';
import { ApiExplorerComponent } from './components/api-explorer/api-explorer.component';
import { HomeVisitPriorityRulesComponent } from './components/site-admin/home-visit-priority-rules/home-visit-priority-rules.component';
import { ViewStateTaskDetailComponent } from './components/dashboard/results/view-tss-all-subjects/view-tss-state-tasks/view-state-task-detail/view-state-task-detail.component';
import { PaginationComponent } from './shared/pagination/pagination.component';



//var settings = typeof window === "undefined" ? {} : (<any>window || {}).appConfig;
var settings = (<any>window || {}).appConfig;
//console.log('Value of settings');
//console.log(JSON.stringify(settings));

export function loggerCallback(logLevel, message, piiEnabled) {
  //console.log("client logging: " + message);
}

export const protectedResourceMap: [string, string[]][] = [
  //['http://localhost:5922/api/',[]]
  //['https://tssmanagerdev.azurewebsites.net/api',[]]
  [settings['TSS.ApiUrl'], []]
];

export function getConfig() {
  var response = {
    clientID: settings['AzureAd.ClientId'], //'caca4533-4839-4afb-96b4-91b0761f4c55', //settings['AzureAd.ClientId'], 
    authority: 'https://login.microsoftonline.com/' + settings['AzureAd.TenantId'],  //'95b907c2-752b-4850-88ad-86939ce522f0', //settings['AzureAd.TenantId'], 
    validateAuthority: true,
    redirectUri: window.location.origin,
    cacheLocation: 'localStorage',
    postLogoutRedirectUri: window.location.origin,
    navigateToLoginRequestUrl: false,
    //popUp: false,
    //unprotectedResources: ["https://www.microsoft.com/en-us/"],
    protectedResourceMap: protectedResourceMap,
    logger: loggerCallback,
    //correlationId: "1000",
    level: LogLevel.Verbose,
    piiLoggingEnabled: true
  };
  /*var request = new XMLHttpRequest();
  request.open('GET', "/api/settings", false);  // request application settings synchronous
  request.send(null);
  const response = JSON.parse(request.responseText);*/
  return response as MsalConfig;
}

@NgModule({
  declarations: [
    AppComponent,
    ViewTssRootItemComponent,
    ViewTssItemIdsComponent,
    ViewTssItemIdComponent,
    ViewTssSourceFormComponent,
    ViewTssFormExtensionComponent,
    ViewTssEventExtensionComponent,
    ViewDisplayAreaComponent,
    StickyDirective,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    DataMigrationComponent,
    SiteAdminComponent,
    UnauthorisedComponent,
    AuthComponent,
    MainMenuComponent,
    PageNotFoundComponent,
    ViewTssStateComponent,
    ViewTssStateTasksComponent,
    ViewSubjectIdSearchComponent,
    ViewItemIdSearchComponent,
    ViewSubjectFilterSearchComponent,
    ViewTssEventSummaryComponent,
    LoadingPaneComponent,
    ViewTssFormSummaryComponent,
    ViewRulesDriversComponent,
    ViewTssAllEventsComponent,
    ViewTssAllSubjectsComponent,
    ApiExplorerComponent,
    HomeVisitPriorityRulesComponent,
    ViewStateTaskDetailComponent,
    PaginationComponent
  ],
  exports: [
    StickyDirective
  ],
  imports: [
    MsalModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes,{
      anchorScrolling: 'enabled'
    })
  ],
  providers: [
    MsalService,
    {
      provide: MSAL_CONFIG,
      useValue: getConfig()
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    EventFormIdService,
    SubjectUserIdService, 
    SearchSectionOpenedService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
