import { Component, OnInit, HostListener } from '@angular/core';
import { SiteConfigurationService } from '../../services/site-configuration.service';
import { Router } from '@angular/router';
import { WorkTracker } from '../shared/loading-pane/work-tracker.service';

@Component({
  selector: 'app-site-admin',
  templateUrl: './site-admin.component.html',
  styleUrls: ['./site-admin.component.scss']
})
export class SiteAdminComponent implements OnInit {
  model: Api.TssSiteConfiguration;
  tracker: WorkTracker;

  retrieveFailed: boolean = false;
  submitStarted: boolean = false;
  submitFailed: boolean = false;
  largeView: boolean;

  constructor(private siteConfigurationService: SiteConfigurationService, private router: Router) {
    this.tracker = new WorkTracker(true);
    this.model = {
      coreProcessingStartHour: 0,
      coreProcessingEndHour: 0,
      maxItemsToArchiveOnEachRun: 0,
      maxItemsToReclassifyOnEachRun: 0,
      maxItemsToReprioritiseOnEachRun: 0,
      maxRefreshHitsToESBPerMinute: 0,
      maxStaleEventRefreshAttempts: 0,
      monthsToKeepClosedEvents: 0,
      refreshFrequencyInDays: 0
    }
  }

  ngOnInit() {
    this.largeView = window.innerWidth > 900;

    // Attempt to overwrite with values from the server
    this.retrieveSiteConfiguration();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.largeView = window.innerWidth > 900;
  }

  async retrieveSiteConfiguration() {
    this.retrieveFailed = false;

    await this.tracker.track(
      this.siteConfigurationService.getSiteConfiguration().then(items => {
        this.model = items;
      })
      .catch(error => {
        console.error('Error retrieving Site Configuration Settings. Message: ' + error);
        this.retrieveFailed = true;
      })
    );
  }

  async onSubmit() {
    // Clear any existing error as we are trying again
    this.submitFailed = false;
    this.submitStarted = true;
    
    await this.tracker.track(
      this.siteConfigurationService.postSiteConfiguration(this.model).then(items => {
        
      })
      .catch(error => {
        console.error('Error submitting updated Site Configuration Settings. Message: ' + error);
        this.submitFailed = true;
      })
    );
  }

  sendToHomeVisitPriorityRules()
  {
    this.router.navigate(['/app-site-admin/app-home-visit-priority-rules']);
  }
}
