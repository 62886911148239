import { Component, OnInit, Input, HostListener } from '@angular/core';

@Component({
  selector: 'app-view-tss-all-subjects',
  templateUrl: './view-tss-all-subjects.component.html',
  styleUrls: ['./view-tss-all-subjects.component.scss']
})
export class ViewTssAllSubjectsComponent implements OnInit {
  @Input() sourceData: Api.SubjectUserRuleResult;

  subjectUserId: string;
  subjectUserName: string;
  itemSubjectUsers: Api.TssSourceSubjectUser[];
  childStates: Api.TssSubjectUserState[];
  selectedTasks: Api.TssSubjectUserStateTask[];

  showSubjectUsers: boolean;
  showClassStates: boolean;
  showClassTasks: boolean;
  largeView: boolean;

  constructor() { }

  ngOnInit() {
    if(this.sourceData == null) return;

    this.subjectUserId = this.sourceData.mostRecentSubjectUser.subjectUserId;
    this.subjectUserName = this.sourceData.mostRecentSubjectUser.givenName + ' ' + this.sourceData.mostRecentSubjectUser.familyName;
    this.itemSubjectUsers = this.sourceData.allRelatedSubjectUsers;
    this.childStates = this.sourceData.mostRecentSubjectUser.derivedStates;

    this.showSubjectUsers = true;
    this.largeView = window.innerWidth > 900;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.largeView = window.innerWidth > 900;
  }

  subjectUsers() {
    this.toggleTabs();
    this.showSubjectUsers = true;
  }
  
  classifiedStates() {
    this.toggleTabs();
    this.showClassStates = true;
  }

  classifiedTasks() {
    this.toggleTabs();
    this.showClassTasks = true;
  }

  private toggleTabs() {
    this.showClassStates = false;
    this.showSubjectUsers = false;
    this.showClassTasks = false;
  }
}
