import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { appSettings, ApplicationSettings } from '../../settings';
import { IResponse } from './response-interface';

@Injectable({
  providedIn: 'root'
})
export class TssRootItemService {
  applicationSettings: ApplicationSettings;
  // tssItemsUrl:String=tss.items;
  // tssRefreshUrl:String=tss.refresh;
  sourceSystem: string;
  tssMaxLastUpdatedDateTime: string = new Date().toISOString().substr(0, 19);

  constructor(private http: HttpClient) {
    this.applicationSettings = appSettings;
    this.sourceSystem = 'icms';
  }

  async getPlacementDetails(placementId: string) : Promise<IResponse<Api.PlacementResponse>>
  {
    return this.http.get<Api.PlacementResponse>(
      `${this.applicationSettings.itemsUrl}/placementdata/${placementId}`
    )
    .asJsonResponse<Api.PlacementResponse>()
    .toPromise();
  }

  async getEventDetailsBySubjectId(subjectUserId: string) : Promise<IResponse<Api.SubjectUserRuleResult>> {
    return this.http.get<Api.SubjectUserRuleResult>(
      `${this.applicationSettings.itemsUrl}/subjectevents/${this.sourceSystem}/${subjectUserId}`
      )
      .asJsonResponse<Api.SubjectUserRuleResult>()
      .toPromise();
  }

  async getEventDetailsByEventOrFormId(eventOrFormId: string, category: string) : Promise<IResponse<Api.SubjectUserRuleResult>> {
    return this.http.get<Api.SubjectUserRuleResult>(
      `${this.applicationSettings.itemsUrl}/events/${this.sourceSystem}/${category}/${eventOrFormId}`
      )
      .asJsonResponse<Api.SubjectUserRuleResult>()
      .toPromise();
  }

  async getSubjectUserIds(countToGet, dateToUse, filterEventName) : Promise<IResponse<string[]>> {
    this.tssMaxLastUpdatedDateTime = new Date().toISOString().substr(0, 19);
    return this.http.get<string[]>(
      `${this.applicationSettings.itemsUrl}/identifiers/${dateToUse}/${countToGet}/${filterEventName}`
      )
      .asJsonResponse<string[]>()
      .toPromise();
  }

  async reclassifySubjectChild(subjectUserId: string) : Promise<IResponse<boolean>> {
    return this.http.get<boolean>(
      `${this.applicationSettings.itemsUrl}/${this.sourceSystem}/${subjectUserId}`
      )
      .asJsonResponse<boolean>()
      .toPromise();
  }

  async requestRefreshFromICMS(category: string, eventOrFormId: string) : Promise<IResponse<Api.RefreshResultDTO>> {
    if(!category || category == null) category = 'icms.event';
    return this.http.get<Api.TssResponse<Api.RefreshResultDTO>>(
      `${this.applicationSettings.refreshUrl}?category=${category}&id=${eventOrFormId}`
    )
    .asJsonResponse<Api.RefreshResultDTO>()
    .toPromise();
  }
}
