<section>
    <ng-container *ngIf="isLoggedIn">
      <p>
        You are currently logged in as <strong>{{ userName }}</strong>. Click the button below to log out.
        <button (click)="logout()">Logout</button>
      </p>
    </ng-container>
    <ng-container *ngIf="!isLoggedIn">
      <p>You are not currently logged in. Click the button below to log in. <button (click)="login()">Login</button></p>
    </ng-container>
  </section>
  