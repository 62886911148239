import { Injectable } from '@angular/core';
import { HttpClient, HttpEventType } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { appSettings, ApplicationSettings } from '../../settings';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  applicationSettings: ApplicationSettings;

  constructor(private httpClient: HttpClient) {
    this.applicationSettings = appSettings;
   }


  public upload(data) {
    // http://localhost:5922/api/DataMigration/guest@communities.qld.gov.au
    // curl -X POST "http://localhost:5922/api/DataMigration?upn=luke%40luke.com" -H
    //    "accept: application/json" -H "Content-Type: multipart/form-data" -F "file=@vmIPConfig.txt;type=text/plain"
    const uploadURL = `${this.applicationSettings.dataMigrationUrl}`;
    // console.log('uploadURL: ' + uploadURL);

    return this.httpClient.post<any>(uploadURL, data, {
      reportProgress: true,
      observe: 'events'
    }).pipe(map((event) => {

      switch (event.type) {
        case HttpEventType.UploadProgress:
          const progress = Math.round(100 * event.loaded / event.total);
          return { status: 'progress', message: progress };

        case HttpEventType.Response:
          return event.body;

        default:
          return { status: 'other', message: `Unhandled event: ${event.type}` };
      }
    }));
  }
}
