import { Component, Input } from '@angular/core';
import { appSettings, ApplicationSettings } from '../../../../settings';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent {
  applicationSettings: ApplicationSettings;

  @Input() fullName: string;
  @Input() time: string;
  @Input() date: string;
  @Input() isAuthenticated: boolean;

  constructor() {
    this.applicationSettings = appSettings;
  }
}
