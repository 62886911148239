<nav>
  <ul>
    <li tabindex="0">
      <i class="far fa-user-circle fa-lg"></i><span>{{fullName}}</span><i class="fas fa-angle-down"></i>
      <ul>
        <li>
          <a routerLink="/auth" class="logout-url" tabindex="0" >Logout</a>
        </li>
      </ul>
    </li>
  </ul>
</nav>