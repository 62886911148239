var settings = typeof window === "undefined" ? {} : (<any>window || {}).appConfig;

export let dataViewer = loadSetting<string>("TSS.DataViewerUrl");
export let apiBase = loadSetting<string>("TSS.ApiUrl");
export let refreshApiBase = loadSetting<string>("TSS.RefreshUrl");
export let appId = loadSetting<string>('AzureAd.ClientId');
export let authority = loadSetting<string>('AzureAd.Authority');
export let tenantId = loadSetting<string>('AzureAd.TenantId');
export let buildNumber = loadSetting<string>('Build.BuildNumber');

export let appSettings = {
  dataViewerUrl: dataViewer,
  apiBaseUrl: apiBase,
  itemsUrl: `${apiBase}/item`,
  refreshUrl: `${refreshApiBase}`,
  dataMigrationUrl: `${apiBase}/RefreshData`,
  siteConfigUrl: `${apiBase}/SiteConfig`,
  appId: appId,
  tenantId: tenantId,
  buildNumber: buildNumber,
  authority: authority
} as ApplicationSettings;

function loadSetting<T>(key: string) {
  if (settings[key] === undefined) {
    if (typeof window === "undefined") {
      return <any>"testSetting";
    } else {
      throw new Error(`Setting ${key} does not exist`);
    }
  }

  return <T>settings[key];
}

export interface ApplicationSettings {
  dataViewerUrl: string;
  apiBaseUrl: string;
  itemsUrl: string;
  refreshUrl: string;
  dataMigrationUrl: string;
  siteConfigUrl: string;
  appId: string;
  tenantId: string;
  buildNumber:string;
  authority: string;
}

