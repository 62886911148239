import { Component, OnInit, OnDestroy, HostListener, Input } from '@angular/core';
import { TssRootItemService } from '../../../../services/tss-root-item.service';
import { EventFormIdService } from '../../event-form-id.service';
import { Subscription } from 'rxjs';
import * as codeTables  from '../../../../../api/icms_code_tables';
import { DisplayMapperService } from '../../../../services/display-mapper-service.service';
import { WorkTracker } from '../../../../components/shared/loading-pane/work-tracker.service';

@Component({
  selector: 'app-view-tss-all-events',
  templateUrl: './view-tss-all-events.component.html',
  styleUrls: ['./view-tss-all-events.component.scss']
})

export class ViewTssAllEventsComponent implements OnInit, OnDestroy {
  @Input() sourceData: Api.SubjectUserRuleResult;
  tracker: WorkTracker;

  sourceEventCount: number;
  selectedEventName: string;
  allSelection: string;
  taskCategory: string;
  taskFormId: string;
  showItemDetails: boolean;
  showClassDetails: boolean;
  showRelatedForms: boolean;
  refreshSendSucceeded: boolean;
  refreshSendFailed: boolean;
  
  public itemNames: string[];
  private taskEventId: string;
  eventFormIdChangedSubscription: Subscription;

  largeView: boolean;

  displayMapper: DisplayMapperService;

  constructor(private tssRootItemService: TssRootItemService, private eventFormIdService: EventFormIdService, private mapper: DisplayMapperService) {
    this.displayMapper = mapper;
    this.showItemDetails = true;
    this.tracker = new WorkTracker(true);
    this.subscribeToEventFormIdChangedNotifications();
  }

  ngOnInit() {
    this.sourceEventCount = this.sourceData != null && this.sourceData.eventsRelatedToThisChild != null ? this.sourceData.eventsRelatedToThisChild.length : 0;
    this.allSelection = '--All--';
    this.selectedEventName = this.allSelection;
    this.refreshSendSucceeded = false;
    this.refreshSendFailed = false;
    //this.taskEventId = this.sourceEvent.id;
    //this.taskCategory = this.sourceEvent.sourceSystemType;
    this.largeView = window.innerWidth > 900;
    this.initialiseDDLItemNames();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.largeView = window.innerWidth > 900;
  }

  ngOnDestroy() {
    if (this.eventFormIdChangedSubscription) {
      this.eventFormIdChangedSubscription.unsubscribe();
    }
  }

  getSourceEventDisplayMap(sourceEvent : Api.ChildSourceEvent) : Array<Api.DisplayLookupInstance>
  {
    return this.displayMapper.getsourceEventMap(sourceEvent);
  }

  getClassDetailsDisplayMap(sourceEvent : Api.TssSourceEvent) : Array<Api.DisplayLookupInstance>
  {
    return this.displayMapper.getBusinessLogicDetailPropertyMap(sourceEvent.businessLogicDetail);
  }

  // We use this to be informed of the currently selected event or form id
  private subscribeToEventFormIdChangedNotifications() {
    if (!this.eventFormIdChangedSubscription) {
      this.eventFormIdChangedSubscription = this.eventFormIdService.currentEventOrFormIdChanged.subscribe(async (eventOrFormId) => {
        //console.log('subscribeToEventFormIdChangedNotifications. taskEventId: ' + eventOrFormId);
        this.taskCategory = this.eventFormIdService.getCategory();
        if (this.taskCategory == 'icms.event') {
          this.taskEventId = eventOrFormId;
        }
        else // icms.form
        {
          this.taskFormId = eventOrFormId;
        }
        //console.log('Refresh page. Next time we refresh we will ask for category: ' + this.taskCategory + '. The event id is: ' + this.taskEventId + '. The form id is: ' + this.taskFormId);
      });
    }
  }
  
  async refresh(eventId: string) : Promise<void> {
    try {
      await this.tracker.track(this.tssRootItemService.requestRefreshFromICMS('icms.event', eventId).then(result => {
        this.refreshSendSucceeded = !result.hasError;
        this.refreshSendFailed = !result || result.hasError;

      }));
    } catch (error) {
      console.error(error);
      this.refreshSendFailed = true;
      this.refreshSendSucceeded = false;
    }
  }

  itemDetails() {
    this.toggleTabs();
    this.showItemDetails = true;
  }

  classificationDetails() {
    this.toggleTabs();
    this.showClassDetails = true;
  }

  relatedForms() {
    this.toggleTabs();
    this.showRelatedForms = true;
  }

  private toggleTabs() {
    this.showItemDetails = false;
    this.showClassDetails = false;
    this.showRelatedForms = false;
  }

  // dynamically bind style
  setClasses() {
    const classes = {
      tssRootItem: true
      // 'is-complete': this.tssRootItem.rootItem.id == 'ICMS-event-1'
    };

    return classes;
  }

  initialiseDDLItemNames() {
    this.itemNames = [];
    this.itemNames.push(this.allSelection);
    this.selectedEventName = this.allSelection;
    if (this.sourceData && this.sourceData.eventsRelatedToThisChild) {
      for (const eventData of this.sourceData.eventsRelatedToThisChild) {
        var name = this.getEventIdAndTypeName(eventData.sourceEvent);
        this.itemNames.push(name);
      }
    }

    // Sort
    this.itemNames = this.itemNames.sort((x: string, y: string) => {
      return x.localeCompare(y);
    });
    
    this.itemSelected(this.allSelection);
  }

  getEventIdFromName(eventName : string) : string 
  {
    return eventName.substring(0, eventName.indexOf(' - ')).trim();
  }

  getEventIdAndTypeName(event : Api.TssSourceEvent) : string 
  {
    return event.id + ' - ' + this.displayMapper.getDisplayValue(codeTables.getEventTypeGroupDescriptor, event.sourceSystemType);
  }

  itemSelected(selection: string) {
    this.selectedEventName = selection;
    this.taskEventId = selection;

    if(this.taskEventId != this.allSelection)
    {
      this.taskEventId = this.getEventIdFromName(this.selectedEventName);
    }

    //console.log('taskEventId set to: ' + this.taskEventId);

    //this.eventFormIdService.setEventOrFormIdAndCategory(this.taskEventId, 'icms.event');
  }
}
