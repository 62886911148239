import { Component, OnInit, HostListener } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { UploadService } from '../../services/upload.service';

@Component({
  selector: 'app-data-migration',
  templateUrl: './data-migration.component.html',
  styleUrls: ['./data-migration.component.scss']
})
export class DataMigrationComponent implements OnInit {
  form: FormGroup;
  error: string;
  upn: string;
  dataLoading: boolean;
  largeView: boolean;
  uploadResponse = { status: '', message: '', filePath: '', countLoaded: 0 };

  constructor(private formBuilder: FormBuilder, private uploadService: UploadService) { }

  ngOnInit() {
    this.dataLoading = false;
    this.form = this.formBuilder.group({
      migrationData: ['']
    });
    this.largeView = window.innerWidth > 900;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.largeView = window.innerWidth > 900;
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.form.get('migrationData').setValue(file);
    }
  }

  setResults(results) {
    this.uploadResponse = results;
    if (this.uploadResponse && this.uploadResponse.status &&
      (this.uploadResponse.status === 'ok' || this.uploadResponse.status === 'error')) {
      this.dataLoading = false;
      // console.log('setResults. Setting dataLoading to false. Value: ' + JSON.stringify(this.uploadResponse));
    }
  }

  onSubmit() {
    // Clear any existing error as we are trying again
    this.error = null;
    this.dataLoading = true;
    // console.log('onSubmit. Setting dataLoading to true. Value: ' + this.dataLoading);

    const formData = new FormData();
    // 'file' is the name that will be transmitted to the server
    formData.append('file', this.form.get('migrationData').value);

    this.uploadService.upload(formData).subscribe(
      (res) => this.setResults(res),
      (err) => this.error = err
    );
  }
}
