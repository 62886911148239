<div class="content-site mx-3 my-3">
  <div class="" ng-if="sourceEventCount > 0">
    <div class="columns">
      <div class="column is-6">
        <div [ngClass]='{"tabs": true, "is-toggle": true, "is-toggle-rounded": largeView}'>
          <ul>
            <li [ngClass]='{"is-active": showItemDetails}' style="margin-top: 0px"><a (click)="itemDetails()">Item Details</a></li>
            <li [ngClass]='{"is-active": showRelatedForms}' style="margin-top: 0px"><a (click)="relatedForms()">Related Forms</a></li>
            <li [ngClass]='{"is-active": showClassDetails}' style="margin-top: 0px"><a (click)="classificationDetails()">Classification Details</a></li>
          </ul>
        </div>
      </div>
    </div>

    <div *ngIf="refreshSendSucceeded; then ifRefreshSendSucceeded;"></div>
    <ng-template #ifRefreshSendSucceeded>
      <div class="notification is-success">
        <div class="columns">
          <div class="column is-10">
            We have sent a request to ESB to send the Task Subsystem a new event payload.
          </div>
          <div class="column is-2">
            <button class="delete button close" (click)="refreshSendSucceeded=false"></button>
          </div>
        </div>
      </div>
    </ng-template>
    <div *ngIf="refreshSendFailed; then ifRefreshSendFailed;"></div>
    <ng-template #ifRefreshSendFailed>
      <div class="notification is-warning">
        <div class="columns">
          <div class="column is-10">
            We are sorry. Something went wrong sending a request to ESB asking for fresh data. Try refreshing the page and trying again. If the 
          problem persists contact support to assist.
          </div>
          <div class="column is-2">
            <button class="delete button close" (click)="refreshSendFailed=false"></button>
          </div>
        </div>
      </div>
    </ng-template>

    <div *ngIf="sourceEventCount > 0; then ifSourceEvents;"></div>
    <ng-template #ifSourceEvents>
      <select placeholder="Available Items" (change)="itemSelected($event.target.value)">
        <option *ngFor="let itemName of itemNames" [value]="itemName">
          {{itemName}}
        </option>
      </select>
    </ng-template>
    
    <div *ngIf="sourceData && sourceData.eventsRelatedToThisChild; then ifHaveSourceEvents;"></div>
    <ng-template #ifHaveSourceEvents>
      <div *ngFor="let sourceEventData of sourceData.eventsRelatedToThisChild" ng-if="sourceData.eventsRelatedToThisChild">
        <div *ngIf="sourceEventData && (taskEventId == allSelection || taskEventId == sourceEventData.sourceEvent.id); then ifHaveEvent;"></div>
        <ng-template #ifHaveEvent>
          <div *ngIf="showItemDetails">
            <div class="table-heading">
              <span>ICMS Event Details </span>
              <span>{{ getEventIdAndTypeName(sourceEventData.sourceEvent) }}</span>
            </div>
            <div class="content-shaded">
              <loading-pane [tracker]="tracker">
                <app-view-display-area [valueDisplayMap]="getSourceEventDisplayMap(sourceEventData)"></app-view-display-area>

                <div class="row" role="row">
                  <div class="td" role="cell" data-index="0">
                    <span>
                      <a class="button" (click)="refresh(sourceEventData.sourceEvent.id)">Ask ESB for Fresh Event Data</a>
                    </span>
                  </div>
                </div>
              </loading-pane>
            </div>
          </div>
          <div *ngIf="showRelatedForms">
            <app-view-tss-source-form [sourceForms]="sourceEventData.sourceEvent.sourceForms" [parentEventId]="sourceEventData.sourceEvent.id" 
              [subjectChildOwnerId]="sourceEventData.subjectChildId" [subjectChildOwnerName]="sourceEventData.subjectChildName"></app-view-tss-source-form>
          </div>
          <div *ngIf="showClassDetails">
            <div class="table-heading">
              <span>Classification Details</span>
              <span>{{ sourceEventData.sourceEvent.id }} - Task Subsystem Derived Properties</span>
            </div>
            <div class="content-shaded">
              <app-view-display-area [valueDisplayMap]="getClassDetailsDisplayMap(sourceEventData.sourceEvent)" objectType="TssDerivedDetail" showRulesFields="true"></app-view-display-area>
              <div class="notification is-information info">
                <div style="padding: 1rem 1rem">
                  These values manage when a given event will be deleted from the Task Subsystem (Archiving Date) and 
                  when we will ask ESB for fresh data (Refresh Date). These are very rare in the life of an event and are largely not relevant to a given child's tasks.
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </ng-template>
  </div>
</div>
